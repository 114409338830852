import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  yellow,
  spURL,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
  spURL_LocalPOS,
} from "../../App";
import { ItemBarcode } from "../../components/ItemBarcode";
import { PopupConfirm } from "./PopupConfirm";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { isInputWholeInteger } from "../../functions/IsInputWholeInteger";
import { isInputInRange } from "../../functions/IsInputInRange";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const BayLabel = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [LabelsResponse, setLabelsResponse] = useState(props.Response);
  const [ddlShelfPrinter, setDDLShelfPrinter] = useState("");
  const [isShelfPrinterSelected, setIsShelfPrinterSelected] = useState("");
  const [currentShelfPrinter, setCurrentShelfPrinter] = useState(props.currentShelfPrinter);
  const [BayAmount, setBayAmount] = useState("");
  const [StartingBayNo, setStartingBayNo] = useState("");

  const pageLoad = async () => {
    const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_Location`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });
    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setLabelsResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);

      setDDLShelfPrinter(
        spResponse.Table.map((item, index, arr) => (
          <option
            key={index}
            value={item.ShelfPrinter}
          >
            {item.ShelfPrinter}
          </option>
        ))
      );
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Back = () => {
    props.setResponse("");
    props.setPage("menu");
  };

  const cleanFields = () => {
    setIsShelfPrinterSelected(false);
  };

  const handleChange_DDL = () => {
    setLabelsResponse("");
  };

  const handleClick_Select = () => {
    setLabelsResponse("");

    if (isShelfPrinterSelected) {
      setCurrentShelfPrinter("");
      setIsShelfPrinterSelected(false);
    } else {
      const _shelfprinter = document.getElementById("labels-baylabel-shelfprinter").value;

      if (_shelfprinter === "") {
        setLabelsResponse("ERROR!: Shelf printer must be selected");
      } else {
        setCurrentShelfPrinter(_shelfprinter);
        setIsShelfPrinterSelected(true);
      }
    }
  };

  const handleClick_Print = async () => {
    const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_BayLabel_Add`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          ShelfPrinter: currentShelfPrinter,
          StartingBayNo: StartingBayNo,
          BayAmount: BayAmount,
        }),
      }),
    });
    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setLabelsResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setLabelsResponse(spResponse.Table[0].OutputString);
        cleanFields();
      }
    }
  };

  const isBayAmountValid = (_bayamount) => {
    if (!isInputWholeInteger(_bayamount)) {
      return false;
    }

    if (!isInputInRange(_bayamount, 1, 10)) {
      return false;
    }

    return true;
  };

  const isBayNoValid = (_startingbayno) => {
    if (!isInputWholeInteger(_startingbayno)) {
      return false;
    }

    // check if starting bayno is between range (ie: 1 - 9999)
    if (!isInputInRange(_startingbayno, 1, 2000 - BayAmount + 1)) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (!isShelfPrinterSelected) {
      setBayAmount("");
      setStartingBayNo("");
    }
  }, [isShelfPrinterSelected]);

  useEffect(() => {
    if (!isBayAmountValid(BayAmount)) {
      setStartingBayNo("");
    }
  }, [BayAmount]);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="input-row">
          <p>
            <strong>Shelf Printer:</strong>
          </p>
          {isShelfPrinterSelected ? (
            <p>{currentShelfPrinter}</p>
          ) : (
            <select
              name="shelfprinter"
              id="labels-baylabel-shelfprinter"
              onChange={handleChange_DDL}
            >
              <option value=""></option>
              {ddlShelfPrinter}
            </select>
          )}
        </div>
        <button
          className="signin-login-button btnHover"
          onClick={handleClick_Select}
        >
          {isShelfPrinterSelected ? "Change" : "Select"}
        </button>
        <br />
        {isShelfPrinterSelected ? (
          <div>
            <br />
            <div className="stocktake-create-input-row">
              <p>
                <strong>Amount:</strong>
              </p>
              <input
                type="number"
                id="labels-baylabel-bayamount"
                min="1"
                max="10"
                onChange={(e) => handleChange_Input(e, setBayAmount)}
              />
            </div>
            {isBayAmountValid(BayAmount) ? (
              <>
                <div className="stocktake-create-input-row">
                  <p>
                    <strong>Start Bay:</strong>
                  </p>
                  <input
                    type="number"
                    id="labels-baylabel-startingbayno"
                    onChange={(e) => handleChange_Input(e, setStartingBayNo)}
                  />
                </div>
                {isBayNoValid(StartingBayNo) ? (
                  <>
                    <div className="stocktake-create-input-row">
                      <p>
                        <strong>End Bay:</strong>
                      </p>
                      <p>{parseInt(StartingBayNo, 10) + parseInt(BayAmount, 10) - 1}</p>
                    </div>
                    <MenuButton
                      onClick={handleClick_Print}
                      button="P"
                      colour={yellow}
                      title="Print"
                    />
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            <br />
          </div>
        ) : (
          ""
        )}

        <p className="error-message">{LabelsResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={yellow}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
      </div>
    </div>
  );
};
