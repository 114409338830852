import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { ContentButton, FooterButton, MenuButton } from "../../components/HomepageButton";
import { blue, green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { PopupChangePassword } from "./PopupChangePassword";

import Barcode from "react-barcode";
import { exportComponentAsPNG } from "react-component-export-image";
import { trackPromise } from "react-promise-tracker";
import { PopupChangeStore } from "./PopupChangeStore";
import { runFetch } from "../../functions/RunFetch";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";

export const HandheldPermissionMain = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.MenuResponse);
  const [PermissionGroupTable, setPermissionGroupTable] = useState([]);
  const [staffBarcode, setStaffBarcode] = useState("123");

  const refUserID = useRef();
  const refDDLName = useRef();

  const emptyCurrentUser = {
    UserID: "",
    FirstName: "",
    LastName: "",
    Password: "",
    StoreNo: "",
    StoreName: "",
  };
  const [currentUser, setCurrentUser] = useState(emptyCurrentUser);

  const [SearchName, setSearchName] = useState("");
  const [ddlName, setDDLName] = useState([]);

  const [selectedPermissionGroup, setSelectedPermissionGroup] = useState("");

  const [show, setShow] = useState(false);
  const [showChangeStore, setShowChangeStore] = useState(false);

  const fetchData = async (_userid) => {
    setMenuResponse("");
    setCurrentUser(emptyCurrentUser);
    setPermissionGroupTable([]);
    setSelectedPermissionGroup("");

    const resObj = await runFetch(`${spURL}Handheld_Functions_Manage_PermissionGroup_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: _userid,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setCurrentUser((values) => ({
          ...values,
          UserID: spResponse.Table[0].UserID,
          FirstName: spResponse.Table[0].FirstName,
          LastName: spResponse.Table[0].LastName,
          Password: spResponse.Table[0].Password,
          AllocatedTo: spResponse.Table[0].AllocatedTo,
          StoreNo: spResponse.Table[0].StoreNo,
          StoreName: spResponse.Table[0].StoreName,
        }));
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setPermissionGroupTable(
          spResponse.Table1.map((item) => ({
            PermissionGroup: item.PermissionGroup,
            isUserInPermissionGroup: item.isUserInPermissionGroup,
          }))
        );
      }
    }
  };

  const getDDLName = async () => {
    setSelectedPermissionGroup("");

    const resObj = await runFetch(`${spURL}Handheld_Functions_Manage_DDLUser_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          SearchName: SearchName,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDDLName(
          spResponse.Table.map((item) => ({
            UserID: item.UserID,
            FirstName: item.FirstName,
            LastName: item.LastName,
          }))
        );
      }
    }
  };

  const handleClick_SearchName = () => {
    setPermissionGroupTable([]);
    setCurrentUser(emptyCurrentUser);
    getDDLName();
  };

  const handleClick_Back = () => {
    props.setMenuResponse("");
    props.setPage("menu");
  };

  const handleClick_TogglePermission = async () => {
    setMenuResponse("");
    if (selectedPermissionGroup === "") {
      setMenuResponse("No permission group selected.");
    } else {
      const resObj = await runFetch(`${spURL}Handheld_Functions_Manage_PermissionGroup_Toggle`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            UserID: currentUser.UserID,
            PermissionGroup: selectedPermissionGroup,
          }),
        }),
      });

      if (resObj.response === null) {
        setMenuResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setMenuResponse(spResponse.Table[0].OutputString);

          await fetchData(currentUser.UserID);
        }
      }
    }
  };

  const handleClick_ChangeStore = () => {
    setShowChangeStore(true);
  };

  const handleClick_CreateBarcode = async () => {
    const resObj = await runFetch(`${spURL}Stocktake_StaffBarcode_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: currentUser.UserID,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStaffBarcode(spResponse.Table[0].StaffBarcode);

        await exportComponentAsPNG(componentRef, {
          fileName: `${currentUser.FirstName}_${currentUser.LastName}`,
        });
      }
    }
  };

  const handleClick_ForceUnallocate = async () => {
    const resObj = await runFetch(`${spURL}Handheld_DeviceManagement_ForceDeallocateUser`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: currentUser.UserID,
          DeviceID: currentUser.AllocatedTo,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setMenuResponse(spResponse.Table[0].OutputString);
        await fetchData(currentUser.UserID);
      }
    }
  };

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{ width: "fit-content" }}
    >
      <Barcode
        value={props.Barcode}
        displayValue="false"
      />
    </div>
  ));

  const componentRef = useRef();

  return (
    <div className="page-container">
      <div className="handheldpermission-body-container">
        <div className="handheldpermission-top-row">
          <div className="blockedorders-input-row">
            <p>
              <strong>UserID:</strong>
            </p>
            <input
              type="text"
              id="handheldpermission-userid"
              autoFocus
              ref={refUserID}
              onKeyDown={(e) => OnEnterKeyDown(e, () => fetchData(refUserID.current.value))}
            />
            <ContentButton
              id="blockedorders-main-enter"
              colour={blue}
              button="Go"
              onClick={() => fetchData(refUserID.current.value)}
            />
          </div>
          <div className="blockedorders-input-row">
            <p>
              <strong>Search Name:</strong>
            </p>
            <input
              type="text"
              id="handheldpermission-searchname"
              onChange={(e) => handleChange_Input(e, setSearchName)}
              onKeyDown={(e) => OnEnterKeyDown(e, handleClick_SearchName)}
            />
            <ContentButton
              colour={blue}
              button="Go"
              onClick={handleClick_SearchName}
            />
          </div>
          <div className="blockedorders-input-row">
            <p>
              <strong>Name:</strong>
            </p>
            <select
              id="handheldpermission-name"
              ref={refDDLName}
              onChange={(e) =>
                handleChange_Input(e, (inp) => {
                  setCurrentUser((values) => ({
                    ...values,
                    UserID: inp,
                  }));
                })
              }
            >
              {ddlName.length > 0 &&
                ddlName.map((item) => (
                  <option
                    userid={item.UserID}
                    value={item.UserID}
                  >{`${item.FirstName} ${item.LastName}`}</option>
                ))}
            </select>
            <ContentButton
              colour={blue}
              button="Go"
              onClick={() => {
                fetchData(refDDLName.current.value);
              }}
            />
          </div>
        </div>
        <div className="blockedorders-content-container">
          <p className="error-message">{MenuResponse}</p>
          {PermissionGroupTable.length !== 0 && (
            <>
              <div className="handheldpermission-info-container">
                <div className="handheldpermission-staffinfo-container">
                  <div className="stocktake-create-input-row">
                    <p>
                      <strong>UserID:</strong> {currentUser.UserID}
                    </p>
                  </div>
                  <div className="stocktake-create-input-row">
                    <p>
                      <strong>Name:</strong> {`${currentUser.FirstName} ${currentUser.LastName}`}
                    </p>
                  </div>
                  <div className="stocktake-create-input-row">
                    <p>
                      <strong>StoreNo:</strong> {currentUser.StoreNo}
                    </p>
                  </div>
                  <div className="stocktake-create-input-row">
                    <p>
                      <strong>StoreName:</strong> {currentUser.StoreName}
                    </p>
                  </div>
                </div>
                {currentUser.AllocatedTo !== "" && (
                  <div className="handheldpermission-staffallocatedto-container">
                    <div className="info stocktake-create-input-row">
                      <p>
                        <strong>Allocated To:</strong> {currentUser.AllocatedTo}
                      </p>
                    </div>
                    <MenuButton
                      title="Unallocate"
                      colour={blue}
                      button="U"
                      onClick={handleClick_ForceUnallocate}
                    />
                  </div>
                )}
              </div>
              <table className="itemdetails-table">
                <thead>
                  <tr>
                    <th>Permission Group</th>
                    <th>Is User In Group?</th>
                  </tr>
                </thead>
                <tbody>
                  {PermissionGroupTable.map((item, index) => (
                    <tr
                      key={index}
                      className={`btnHover ${
                        item.PermissionGroup === selectedPermissionGroup && "background-lightblue"
                      }`}
                      onClick={() => {
                        setMenuResponse("");
                        setSelectedPermissionGroup(item.PermissionGroup);
                      }}
                    >
                      <td>{item.PermissionGroup}</td>
                      <td>{item.isUserInPermissionGroup ? "Yes" : "No"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <br />
              <div className="blockedorders-option-row">
                <MenuButton
                  title="Toggle Permission"
                  colour={blue}
                  button="TA"
                  onClick={handleClick_TogglePermission}
                />
                <MenuButton
                  title="Change Password"
                  colour={blue}
                  button="CP"
                  onClick={() => {
                    setShow(true);
                  }}
                />
                <MenuButton
                  title="Create Barcode"
                  colour={blue}
                  button="CB"
                  onClick={handleClick_CreateBarcode}
                />
                <MenuButton
                  title="Change Store"
                  colour={blue}
                  button="CS"
                  onClick={handleClick_ChangeStore}
                />
              </div>
              <div style={{ position: "absolute", top: -9999, left: -9999 }}>
                <ComponentToPrint
                  Barcode={staffBarcode}
                  ref={componentRef}
                />
              </div>
            </>
          )}

          <PopupChangePassword
            show={show}
            setShow={setShow}
            currentUser={currentUser}
            fetchData={fetchData}
            setResponse={setMenuResponse}
          />

          <PopupChangeStore
            show={showChangeStore}
            setShow={setShowChangeStore}
            currentUser={currentUser}
            fetchData={fetchData}
            setResponse={setMenuResponse}
          />
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={blue}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
      </div>
    </div>
  );
};
