import { useRef } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { red, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const AdminFinalise = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [storename, setStoreName] = store.useState("storename");

  const [StockTakeResponse, setStockTakeResponse] = useState("");

  const [ddlStockTake, setDDLStockTake] = useState([]);

  const refStockTake = useRef();

  const handleClick_Back = () => {
    setStockTakeResponse("");
    props.setPage("admin-menu");
  };

  const handleClick_Finalise = async () => {
    setStockTakeResponse("");

    const resObj = await runFetch(`${spURL}Stocktake_Function_Process`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StockTake: refStockTake.current.value,
          BayNo: "",
          Store: storeno,
          Function: "FINALISE",
          From: "",
          Quantity: "",
        }),
      }),
    });

    if (resObj.response === null) {
      setStockTakeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStockTakeResponse(spResponse.Table[0].OutputString);
      }
    }
    resetOption();
  };

  const resetOption = () => {
    refStockTake.current.value = "";
  };

  return (
    <div className="page-container">
      <div
        className={
          window.matchMedia("(max-width: 364px)").matches
            ? "handheld-body-container"
            : "desktop-body-container"
        }
      >
        <div className="stocktake-check-container">
          <div className="stocktake-create-input-row">
            <p>
              <strong>Store:</strong>
            </p>
            <p>{storename}</p>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>StockTake:</strong>
            </p>
            <select id="stocktake-admin-finalisestocktake-stocktake">
              <option
                value=""
                name=""
              ></option>
              {ddlStockTake.map((item, index) => (
                <option
                  key={index}
                  value={item}
                >
                  {item}
                </option>
              ))}
            </select>
          </div>
          <button
            className="signin-login-button btnHover"
            onClick={handleClick_Finalise}
          >
            Finalise
          </button>
          <br />
          <p className="error-message">{StockTakeResponse}</p>
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
