import { usePromiseTracker } from "react-promise-tracker";
import ClipLoader from "react-spinners/ClipLoader";

export const APILoadingComponent = (props) => {
  const { promiseInProgress } = usePromiseTracker({ delay: 250 });

  return (
    promiseInProgress && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, .5)",
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: "10000000",
          textAlign: "center",
        }}
      >
        <ClipLoader
          color="#000000"
          loading={promiseInProgress}
        />
      </div>
    )
  );
};
