import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { FooterButton } from "../../components/HomepageButton";
import {
  blue,
  orange,
  spURL,
  spURL_Local,
  spURL_LocalPOS,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
} from "../../App";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const AllocateBody = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [StaffID, setStaffID] = useState("");

  const [UpdateResponse, setUpdateResponse] = useState("");

  useEffect(() => {}, []);

  const handleChange_StaffID = (event) => {
    setStaffID(event.target.value);
  };

  const handleClick_Allocate = async () => {
    const res = await runFetch(`${spURL_LocalPOS}Handheld_DeviceManagement_AllocateUser`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: StaffID,
          StoreNo: storeno,
          DeviceID: localStorage.getItem("deviceID"),
          ManagerID: userid,
          DisplayName: localStorage.getItem("displayName"),
        }),
      }),
    });

    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setUpdateResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        window.location.href = "#/";
      }
    }
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <h4>
          <strong>ALLOCATE Device</strong>
        </h4>
        <div className="input-row">
          <p>
            <strong>Device Name:</strong>
          </p>
          <p>{localStorage.getItem("displayName")}</p>
        </div>
        <div className="input-row">
          <p>
            <strong>Allocated UserID:</strong>
          </p>
          <input
            type="text"
            onChange={handleChange_StaffID}
            name="staffid"
            id="devicemanagement-staffid"
          />
        </div>
        <button
          className="signin-login-button btnHover"
          onClick={handleClick_Allocate}
        >
          Allocate
        </button>
        <br />
        <p className="error-message">{UpdateResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={blue}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
      </div>
    </div>
  );
};
