import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, spURL_LocalPOS } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import { doPrintNewShelfLabel, doPrintShelfLabel } from "../../functions/ShelfLabelUtils";
import store from "../../store";

export const PopupConfirm = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [ConfirmResponse, setConfirmResponse] = useState("");

  const handleClose = () => {
    setConfirmResponse("");
    document.getElementById("itembarcode-barcode").value = "";
    document.getElementById("itembarcode-barcode").focus();
    props.setShow(false);
  };

  const handleClick_Yes = () => {
    setConfirmResponse("");

    async function addQuickLabel() {
      var response = "";

      const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_Add_QuickLabel`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            ShelfPrinter: props.ShelfPrinter,
            Override: 1,
            Barcode: props.Barcode,
          }),
        }),
      });
      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        setConfirmResponse(resObj.exception);
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);
        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          response = spResponse.Table[0].OutputString;

          await recordNoStockItemPrinted();
          await doPrintShelfLabel(props.ShelfPrinter, props.Barcode, 1, "MERCH");
        }
      }
      props.setMenuResponse(response);
      props.setBarcode("");
      handleClose();
    }

    async function recordNoStockItemPrinted() {
      await runFetch(`${spURL}Handheld_Label_NoStockItemPrinted_Add`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            Barcode: props.Barcode,
          }),
        }),
      });
    }

    async function addMerchLabel() {
      const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_Add_Item`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            BatchID: props.BatchID,
            ItemNo: props.ItemNo,
            LabelType: props.LabelType,
            Quantity: props.Quantity,
            From: "MERCH",
          }),
        }),
      });
      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        setConfirmResponse(resObj.exception);
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);
        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          props.setMenuResponse(spResponse.Table[0].OutputString);
          props.setBarcode("");
          props.setPage("merchlabel-add");
        }
      }
    }

    switch (props.From) {
      case "QUICK":
        addQuickLabel();
        break;
      case "MERCH":
        addMerchLabel();
        break;
      default:
        setConfirmResponse(
          "ERROR!: Program error. Please email itsupport@toymate.com.au for help."
        );
        break;
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <strong>
              {props.ConfirmType === "EDM"
                ? 'Item is on EDM. Use "Import" function for EDM price or continue for regular price.'
                : "Item is out of stock. Do you wish to continue?"}
            </strong>
            <br />

            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              No
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Yes}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
