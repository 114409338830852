import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const PopupSSC = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [ConfirmResponse, setConfirmResponse] = useState("");

  const handleClose = () => props.setShow(false);

  const handleShow = () => {
    setConfirmResponse(props.Response);
  };

  const handleClick_Yes = async () => {
    const resObj = await runFetch(`${spURL}Handheld_WHReceived_Receive_SSC_Override`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: userid,
          StoreNo: storeno,
          JobNo: props.JobNo,
          SSC: props.SSC,
        }),
      }),
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table[0].OutputString !== undefined) {
          props.setMenuResponse(spResponse.Table[0].OutputString);
          props.pageLoad();
          props.setShow(false);
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <p>
              <strong>
                This SSC does not belong to this Job No or has not been issued. Would you like to
                continue?
              </strong>
            </p>
          </div>
          <br />
          <p className="error-message">{ConfirmResponse}</p>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>No</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Yes}
            >
              <strong>Yes</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
