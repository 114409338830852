import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  yellow,
  spURL,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
  spURL_LocalPOS,
} from "../../App";
import { ItemBarcode } from "../../components/ItemBarcode";

import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { PopupPrint } from "./PopupPrint";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { handleChange_Input } from "../../functions/HandleChangeInput";

export const MerchLabelPrint = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [MenuResponse, setMenuResponse] = useState(props.Response);

  const [ddlBatchList, setDDLBatchList] = useState("");
  const [BatchID, setBatchID] = useState("");
  const [showPrint, setShowPrint] = useState(false);

  const pageLoad = async () => {
    const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_Print_List`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });
    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMenuResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);
      setDDLBatchList(
        spResponse.Table.map((item, index, arr) => (
          <option
            key={index}
            value={item.BatchID}
          >
            {item.BatchID}
          </option>
        ))
      );
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Back = () => {
    props.setResponse("");
    props.setCurrentMerchBatchID("");
    props.setCurrentMerchBatchPrinter("");
    props.setBarcode("");

    props.setPage("merchlabel-menu");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="input-row">
          <p>
            <strong>Batch:</strong>
          </p>
          <select
            name="batch"
            id="labels-print-batchid"
            onChange={(e) => handleChange_Input(e, setBatchID)}
          >
            <option value=""></option>
            {ddlBatchList}
          </select>
        </div>
        <button
          className="signin-login-button btnHover"
          onClick={() => setShowPrint(true)}
        >
          Select
        </button>
        <br />
        <p className="error-message">{MenuResponse}</p>

        <PopupPrint
          show={showPrint}
          setShow={setShowPrint}
          BatchID={BatchID}
          setMenuResponse={setMenuResponse}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={yellow}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
      </div>
    </div>
  );
};
