import React, { useState, useEffect } from "react";

import { Menu } from "./Menu";
import { ConNote } from "./ConNote";
import { Update } from "./Update";
import { EditHeader } from "./EditHeader";
import { EditLines } from "./EditLines";
import { Check } from "./Check";

export const PODCodeBody = () => {
  const [page, setPage] = useState("menu");
  const [PODCode, setPODCode] = useState("");
  const [PODCodeResponse, setPODCodeResponse] = useState("");

  const [ConNoteDetails, setConNoteDetails] = useState({
    Driver: "",
    TransportCompany: "",
    QuantityReceived: "",
    UnitType: "",
  });

  const [OriginalDetails, setOriginalDetails] = useState({
    Supplier: "",
    InvoiceNo: "",
    CartonsExpected: "",
    CartonsReceived: "",
    QuantityUnitsInvoiced: "",
    InvoiceValue_exGST: "",
  });

  const menuPage = (
    <Menu
      page={page}
      setPage={setPage}
      PODCodeResponse={PODCodeResponse}
      setPODCodeResponse={setPODCodeResponse}
    />
  );

  const connoteCreatePage = (
    <ConNote
      page={page}
      setPage={setPage}
      PODCodeResponse={PODCodeResponse}
      setPODCodeResponse={setPODCodeResponse}
      setConNoteDetails={setConNoteDetails}
      setOriginalDetails={setOriginalDetails}
      setPODCode={setPODCode}
      Type="CREATE"
    />
  );

  const connoteEditPage = (
    <ConNote
      page={page}
      setPage={setPage}
      PODCodeResponse={PODCodeResponse}
      setPODCodeResponse={setPODCodeResponse}
      ConNoteDetails={ConNoteDetails}
      setConNoteDetails={setConNoteDetails}
      PODCode={PODCode}
      setPODCode={setPODCode}
      Type="EDIT"
    />
  );

  const updateCreatePage = (
    <Update
      page={page}
      setPage={setPage}
      PODCodeResponse={PODCodeResponse}
      setPODCodeResponse={setPODCodeResponse}
      PODCode={PODCode}
      Type="NEW"
      OriginalDetails={OriginalDetails}
    />
  );

  const updateEditPage = (
    <Update
      page={page}
      setPage={setPage}
      PODCodeResponse={PODCodeResponse}
      setPODCodeResponse={setPODCodeResponse}
      PODCode={PODCode}
      Type="UPDATE"
      OriginalDetails={OriginalDetails}
    />
  );

  const editHeaderPage = (
    <EditHeader
      page={page}
      setPage={setPage}
      PODCodeResponse={PODCodeResponse}
      setPODCodeResponse={setPODCodeResponse}
      setPODCode={setPODCode}
      setConNoteDetails={setConNoteDetails}
    />
  );

  const editLinesPage = (
    <EditLines
      page={page}
      setPage={setPage}
      PODCodeResponse={PODCodeResponse}
      setPODCodeResponse={setPODCodeResponse}
      PODCode={PODCode}
      ConNoteDetails={ConNoteDetails}
      setConNoteDetails={setConNoteDetails}
      setOriginalDetails={setOriginalDetails}
    />
  );

  const checkPage = (
    <Check
      page={page}
      setPage={setPage}
      PODCodeResponse={PODCodeResponse}
      setPODCodeResponse={setPODCodeResponse}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "connote_create":
        return connoteCreatePage;
      case "connote_edit":
        return connoteEditPage;
      case "update_create":
        return updateCreatePage;
      case "update_edit":
        return updateEditPage;
      case "edit_header":
        return editHeaderPage;
      case "edit_lines":
        return editLinesPage;
      case "check":
        return checkPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
