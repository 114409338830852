import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { orange, spURL, store } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { PopupAllocateQty } from "./PopupAllocateQty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";

export const Allocation = (props) => {
  const [HeaderDetail, setHeaderDetail] = useState("");
  const [CurrentTO, setCurrentTO] = useState("");
  const [TransferTable, setTransferTable] = useState([]);
  const [show, setShow] = useState(false);
  const [AllocationResponse, setAllocationResponse] = useState("");

  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sortIcon, setSortIcon] = useState("");

  const handleClick_Back = () => {
    props.setCurrentStoreNo("");
    props.setPage("details");
  };

  const handleClick_Create = () => {
    async function createNewTO() {
      const resObj = await runFetch(`${spURL}Handheld_PerthWarehouse_Transfer_Create`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            PONumber: props.currentPO,
            StoreNo: props.currentStoreNo,
          }),
        }),
      });

      if (resObj.response === null) {
        setAllocationResponse(resObj.exception);
      } else {
        const spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          if (spResponse.Table.length === 0) {
            setAllocationResponse("Transfer failed.");
          } else {
            setCurrentTO({
              TONumber: spResponse.Table[0].TONumber,
            });
          }
        }
      }
    }

    createNewTO();
    getPOAllocationTable();
  };

  async function getPOAllocationTable() {
    const resObj = await runFetch(`${spURL}Handheld_PerthWarehouse_Allocation_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          PONumber: props.currentPO,
          ItemNo: props.currentItemNo,
          StoreNo: props.currentStoreNo,
        }),
      }),
    });

    if (resObj.response === null) {
      setAllocationResponse(resObj.exception);
    } else {
      const spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table.length === 0) {
          setAllocationResponse("No header detail found.");
        } else {
          setHeaderDetail({
            PONumber: spResponse.Table[0].PONumber,
            ItemNo: spResponse.Table[0].ItemNo,
            Description: spResponse.Table[0].Description,
            ReorderNumber: spResponse.Table[0].ReorderNumber,
            StoreID: spResponse.Table[0].StoreID,
            StoreName: spResponse.Table[0].StoreName,
            AllocationQty: spResponse.Table[0].AllocationQty,
            AllocatedQty: spResponse.Table[0].AllocatedQty,
            RemainingQty: spResponse.Table[0].RemainingQty,
          });
        }
      }
      if (spResponse.Table1 !== undefined) {
        if (spResponse.Table1.length === 0) {
          //setAllocationResponse("No items found.");
        } else {
          setTransferTable(
            spResponse.Table1.map((item) => ({
              TONumber: item.TONumber,
              SKUs: item.SKUs,
              Ctns: item.Ctns,
            }))
          );
        }
      }
    }
  }

  const handleClick_HeaderRow = (event) => {
    let _target;
    switch (event.target.tagName.toUpperCase()) {
      case "TH":
        _target = event.target;
        break;
      case "SVG":
        _target = event.target.parentElement;
        break;
      case "PATH":
        _target = event.target.parentElement.parentElement;
        break;
      default:
        break;
    }

    if (event.target.tagName.toUpperCase() !== "TR") {
      const _colname = _target.attributes["colname"].value;
      const _sortorder = _target.attributes["sortorder"].value;

      setSortColumn(_colname);

      if (_colname === sortColumn) {
        if (_sortorder === "ASC") {
          setSortOrder("DESC");
        } else {
          setSortOrder("ASC");
        }
      } else {
        setSortOrder("ASC");
      }
    }
  };

  useEffect(() => {
    switch (sortOrder) {
      case "ASC":
        setSortIcon(solid("sort-up"));
        break;
      case "DESC":
        setSortIcon(solid("sort-down"));
        break;
      default:
        setSortIcon(solid("sort"));
        break;
    }
  }, [sortOrder]);

  useEffect(() => {
    let newArr = TransferTable;

    if (newArr.length > 0 && sortColumn !== "") {
      newArr = newArr.sort((a, b) => {
        switch (sortColumn) {
          case "SKUs":
          case "Ctns":
            return sortOrder === "ASC"
              ? parseFloat(a[sortColumn]) - parseFloat(b[sortColumn])
              : parseFloat(b[sortColumn]) - parseFloat(a[sortColumn]);
          default:
            return sortOrder === "ASC"
              ? a[sortColumn].localeCompare(b[sortColumn])
              : b[sortColumn].localeCompare(a[sortColumn]);
        }
      });

      setTransferTable(newArr);
    }
  }, [sortColumn, sortOrder, TransferTable, setTransferTable]);

  useEffect(() => {
    getPOAllocationTable();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <h1 className="text-center">ALLOCATION</h1>
        {HeaderDetail.PONumber !== "" && (
          <>
            <div>
              <table className="perth-warehouse-header">
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <strong>PO Number: </strong>
                        {HeaderDetail.PONumber}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <strong>Description: </strong>
                        {HeaderDetail.Description}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <strong>Item Number: </strong>
                        {HeaderDetail.ItemNo}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <strong>Reorder Number: </strong>
                        {HeaderDetail.ReorderNumber}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <strong>Store: </strong>
                        {HeaderDetail.StoreName}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <strong>Allocation: </strong>
                        {HeaderDetail.AllocationQty}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <strong>Allocated: </strong>
                        {HeaderDetail.AllocatedQty}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <strong>Remaining: </strong>
                        {HeaderDetail.RemainingQty}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
        <div className="error-message">{AllocationResponse}</div>
        <table className="itemdetails-table">
          <thead>
            <tr
              className="btnHover"
              onClick={handleClick_HeaderRow}
            >
              <th
                colname="TONumber"
                sortorder={sortOrder}
              >
                TO Number
                {sortColumn === "TONumber" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
              </th>
              <th
                colname="SKUs"
                sortorder={sortOrder}
              >
                SKUs
                {sortColumn === "SKUs" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
              </th>
              <th
                colname="Ctns"
                sortorder={sortOrder}
              >
                Ctns
                {sortColumn === "Ctns" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
              </th>
            </tr>
          </thead>
          <tbody>
            {TransferTable.map((item, index) => (
              <tr
                key={`tablerow-${index}`}
                onClick={() => {
                  setCurrentTO(item.TONumber);
                  setShow(true);
                }}
                className="btnHover"
              >
                <td>{item.TONumber}</td>
                <td>{item.SKUs}</td>
                <td>{item.Ctns}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PopupAllocateQty
        show={show}
        setShow={setShow}
        currentTO={CurrentTO}
        currentItemNo={props.currentItemNo}
        currentRemaining={HeaderDetail.AllocationQty}
        fetchData={getPOAllocationTable}
      />

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
        <FooterButton
          button="Create"
          colour={orange}
          onClick={handleClick_Create}
        />
      </div>
    </div>
  );
};
