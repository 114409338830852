import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { spURL } from "../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../functions/RunFetch";
import { OnEnterKeyDown } from "../functions/OnEnterKeyDown";

library.add(faMagnifyingGlass);

export const ItemBarcode = (props) => {
  const [ItemMessage, setItemMessage] = useState({});
  const [showMessage, setShowMessage] = useState(false);

  const handleClick_Enter = async () => {
    const _barcode = document.getElementById("itembarcode-barcode").value;

    let willPopup = false;

    const resObj = await runFetch(`${spURL}Handheld_Item_Message_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Barcode: _barcode,
        }),
      }),
    });

    if (resObj.exception === "") {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        if (spResponse.Table[0].Message !== "") {
          setItemMessage({
            ItemNo: spResponse.Table[0].ItemNo,
            Message: spResponse.Table[0].Message,
          });

          willPopup = true;
        } else {
          willPopup = false;
        }
      } else {
        setItemMessage({
          ItemNo: "",
          Message: "",
        });

        willPopup = false;
      }
    }

    if (willPopup) {
      setShowMessage(true);
    } else {
      await props.barcodeClick();
    }
  };

  const handleClick_Search = () => {
    props.setShow(true);
  };

  return (
    <div>
      <form
        id="ItemBarcodeForm"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className={props.ItemDetails ? "itemsearch-itembarcode-input-row" : "input-row"}>
          <p>
            <strong>Barcode:</strong>
          </p>
          <input
            type="text"
            id="itembarcode-barcode"
            autoFocus
            autoComplete="off"
            onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Enter)}
          />
          {props.ItemDetails && (
            <FontAwesomeIcon
              icon="fa-solid fa-magnifying-glass"
              className="btnHover"
              onClick={handleClick_Search}
            />
          )}
        </div>

        <button
          id="itembarcode-enter"
          className="signin-login-button"
          type="button"
          onClick={handleClick_Enter}
        >
          Go
        </button>
      </form>
      <PopupMessage
        show={showMessage}
        setShow={setShowMessage}
        ItemMessage={ItemMessage}
        barcodeClick={props.barcodeClick}
      />
    </div>
  );
};

const PopupMessage = (props) => {
  const handleClose = () => {
    props.setShow(false);
    props.barcodeClick();
  };

  const handleShow = () => {};

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <p className="error-message">
              Item {props.ItemMessage.ItemNo}
              <br />
              {props.ItemMessage.Message}
            </p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Dismiss</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
