import React, { useState, useEffect } from "react";

import { Menu } from "./Menu";
import { Reports } from "./Reports";
import { Create } from "./Create";
import { Check } from "./Check";
import { Manage } from "./Manage";
import { Scan } from "./Scan";
import { AdminMenu } from "./AdminMenu";
import { AdminFinalise } from "./AdminFinalise";
import { AdminStaffBarcode } from "./AdminStaffBarcode";
import { AdminBlockSubCat } from "./AdminBlockSubCat";

export const StockTakeBody = () => {
  const [page, setPage] = useState("menu");

  const [StockTakeBay, setStockTakeBay] = useState("");
  const [From, setFrom] = useState("");

  const [CurrentStockTake, setCurrentStockTake] = useState("");

  const menuPage = (
    <Menu
      setPage={setPage}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
    />
  );

  const scanPage = (
    <Scan
      setPage={setPage}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
    />
  );

  const checkPage = (
    <Check
      setPage={setPage}
      setStockTakeBay={setStockTakeBay}
      setFrom={setFrom}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
    />
  );

  const createPage = <Create setPage={setPage} />;

  const managePage = (
    <Manage
      setPage={setPage}
      StockTakeBay={StockTakeBay}
      setStockTakeBay={setStockTakeBay}
      From={From}
      setFrom={setFrom}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
    />
  );

  const reportsPage = (
    <Reports
      setPage={setPage}
      CurrentStockTake={CurrentStockTake}
      setCurrentStockTake={setCurrentStockTake}
    />
  );

  const adminMenuPage = <AdminMenu setPage={setPage} />;

  const adminFinaliseStockTakePage = <AdminFinalise setPage={setPage} />;

  const adminStaffBarcodePage = <AdminStaffBarcode setPage={setPage} />;

  const adminBlockSubCatPage = <AdminBlockSubCat setPage={setPage} />;

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "scan":
        return scanPage;
      case "check":
        return checkPage;
      case "create":
        return createPage;
      case "manage":
        return managePage;
      case "reports":
        return reportsPage;
      case "admin-menu":
        return adminMenuPage;
      case "admin-finalise":
        return adminFinaliseStockTakePage;
      case "admin-staffbarcode":
        return adminStaffBarcodePage;
      case "admin-blocksubcat":
        return adminBlockSubCatPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
