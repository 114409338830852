import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { orange, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { PopupConfirm } from "./PopupConfirm";
import { PopupSSC } from "./PopupSSC";

export const SSCReceive = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [MenuResponse, setMenuResponse] = useState("");
  const [PopupResponse, setPopupResponse] = useState("");

  const [SSCTable, setSSCTable] = useState([]);

  const [show, setShow] = useState(false);
  const [showSSC, setShowSSC] = useState(false);

  const [currentSSC, setCurrentSSC] = useState("");

  const refSSCC = useRef();

  const pageLoad = async () => {
    refSSCC.current.value = "";
    setCurrentSSC("");

    const resObj = await runFetch(`${spURL}Handheld_WHReceived_Receive_List`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          JobNo: props.JobNo,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      setSSCTable(
        spResponse.Table.map((item, index, arr) => (
          <tr
            key={index}
            className={item.Colour === "ORANGE" && "background-orange"}
          >
            <td value={item.SSC}>{item.SSC}</td>
          </tr>
        ))
      );
    }
  };

  const handleClick_Add = async () => {
    const resObj = await runFetch(`${spURL}Handheld_WHReceived_Receive_SSC`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          JobNo: props.JobNo,
          SSC: refSSCC.current.value,
          UserID: userid,
        }),
      }),
    });

    if (resObj.response === null) {
      if (
        resObj.exception === "ERROR!: SSC does not belong to this Job No or has not been issued."
      ) {
        setCurrentSSC(refSSCC.current.value);
        setShowSSC(true);
      } else {
        setMenuResponse(resObj.exception);
      }
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table.length > 0) {
        setMenuResponse(spResponse.Table[0].OutputString);
        await pageLoad();
      }
    }
    refSSCC.current.value = "";
  };

  const handleClick_Submit = async () => {
    const resObj = await runFetch(`${spURL}Handheld_WHReceived_Receive_Submit`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          JobNo: props.JobNo,
          Override: 0,
          UserID: userid,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table.length > 0) {
        if (spResponse.Table[0].OutputString === "Success: Delivery Received") {
          props.setWHReceivedResponse(spResponse.Table[0].OutputString);
          props.setPage("menu");
        } else {
          setPopupResponse(spResponse.Table[0].OutputString);
          setShow(true);
        }
      }
    }
  };

  const handleClick_Back = () => {
    setMenuResponse("");
    props.setPage("whreceive");
  };

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <div className="page-container">
      <div
        className={
          window.matchMedia("(max-width: 364px)").matches
            ? "handheld-body-container"
            : "desktop-body-container"
        }
      >
        <div className="input-row">
          <p>
            <strong>Job Number: </strong>
            {props.JobNo}
          </p>
        </div>
        <div className="input-row">
          <p>
            <strong>SSC Number: </strong>
            <input
              type="text"
              id="whreceived-sscreceive-sscno"
              onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Add)}
              ref={refSSCC}
              autoFocus
            />
          </p>
        </div>
        <button
          className="signin-login-button"
          id="whreceived-sscreceive-add"
          onClick={handleClick_Add}
        >
          Add
        </button>

        <div>
          <table
            className="itemdetails-table"
            style={{ maxHeight: 300 }}
          >
            <thead>
              <tr>
                <td>
                  <strong>SSC</strong>
                </td>
              </tr>
            </thead>
            <tbody>{SSCTable}</tbody>
          </table>
        </div>

        <PopupConfirm
          show={show}
          setShow={setShow}
          setPage={props.setPage}
          JobNo={props.JobNo}
          Response={PopupResponse}
          setResponse={setPopupResponse}
          setWHReceivedResponse={props.setWHReceivedResponse}
        />

        <PopupSSC
          show={showSSC}
          setShow={setShowSSC}
          JobNo={props.JobNo}
          SSC={currentSSC}
          setMenuResponse={setMenuResponse}
          pageLoad={pageLoad}
        />

        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
        <FooterButton
          button="Sbmt"
          colour={orange}
          onClick={handleClick_Submit}
        />
      </div>
    </div>
  );
};
