import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import "bootstrap/dist/css/bootstrap.min.css";

import { red, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const SOH = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [storeQtyTable, setStoreQtyTable] = useState([]);
  const [MenuResponse, setMenuResponse] = useState("");

  useEffect(() => {
    async function getStoreQuantity() {
      const resObj = await runFetch(spURL + "Handheld_ItemDetail_Item_StoreQuantity", {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            ItemNo: props.itemno,
            StoreID: storeno,
          }),
        }),
      });

      if (resObj.response === null) {
        setMenuResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          setStoreQtyTable(
            spResponse.Table.map((item, index) => (
              <tr key={index}>
                <td>{item.StoreName}</td>
                <td
                  className="btnHover"
                  data-tip
                  data-for="on-hand"
                >
                  {item.SOH}
                </td>
                <td
                  className="btnHover"
                  data-tip
                  data-for="sales-today"
                >
                  {item.Sales0d}
                </td>
                <td
                  className="btnHover"
                  data-tip
                  data-for="sales-last-7d"
                >
                  {item.Sales7d}
                </td>
                <td
                  className="btnHover"
                  data-tip
                  data-for="sales-ytd"
                >
                  {item.SalesYTD}
                </td>
                <td
                  className="btnHover"
                  data-tip
                  data-for="on-order"
                >
                  {item.SOO}
                </td>
                <td
                  className="btnHover"
                  data-tip
                  data-for="on-transfer"
                >
                  {item.SOT}
                </td>
              </tr>
            ))
          );
        }
      }
      ReactTooltip.rebuild();
    }

    getStoreQuantity();
  }, []);

  const handleClick_SOH_Back = () => {
    props.setItemDetailsResponse("");
    props.setPage("details");
  };

  return (
    <div className="page-container">
      <div
        className={
          window.matchMedia("(max-width: 364px)").matches
            ? "handheld-body-container"
            : "desktop-body-container"
        }
      >
        <p>
          <strong>Item: {props.itemno}</strong>
        </p>
        <p>
          <strong>Store Quantity</strong>
        </p>
        <div>
          {storeQtyTable.length > 0 && (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th rowSpan={2}>Store</th>
                  <th rowSpan={2}>On Hand</th>
                  <th colSpan={3}>Sold</th>
                  <th rowSpan={2}>On Order</th>
                  <th rowSpan={2}>On Transf</th>
                </tr>
                <tr>
                  <th>Today</th>
                  <th>Last 7d</th>
                  <th>Year to Date</th>
                </tr>
              </thead>
              <tbody>{storeQtyTable}</tbody>
            </table>
          )}

          <p className="error-message">{MenuResponse}</p>
        </div>
        <ReactTooltip
          id="on-hand"
          place="bottom"
          effect="solid"
        >
          On Hand
        </ReactTooltip>
        <ReactTooltip
          id="sales-today"
          place="bottom"
          effect="solid"
        >
          Sales Today
        </ReactTooltip>
        <ReactTooltip
          id="sales-last-7d"
          place="bottom"
          effect="solid"
        >
          Sales Last 7d
        </ReactTooltip>
        <ReactTooltip
          id="sales-ytd"
          place="bottom"
          effect="solid"
        >
          Sales Year to Date
        </ReactTooltip>
        <ReactTooltip
          id="on-order"
          place="bottom"
          effect="solid"
        >
          On Order
        </ReactTooltip>
        <ReactTooltip
          id="on-transfer"
          place="bottom"
          effect="solid"
        >
          On Transfer
        </ReactTooltip>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_SOH_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
