import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { green, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const View = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [WebOrdersResponse, setWebOrdersResponse] = useState("");

  const [OrderTable, setOrderTable] = useState([]);

  const [selectedOrder, setSelectedOrder] = useState({
    OrderID: "",
    PickID: "",
  });

  const refOrderID = useRef();

  const handleClick_Search = async () => {
    const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_View_Order_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          OrderID: refOrderID.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setWebOrdersResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setOrderTable(
          spResponse.Table.map((item) => ({
            OrderID: item.id,
            PickID: item.PickID,
            Categories: item.CategoriesValue,
            OrderStatus: item.OrderStatus,
          }))
        );
      } else {
        setWebOrdersResponse("ERROR!: Order not found.");
      }
    }
  };

  const handleClick_Back = () => {
    props.setPage("menu");
  };

  const handleClick_Row = (event) => {
    const _class = event.target.parentElement.className;

    setWebOrdersResponse("");

    if (_class === "btnHover background-white") {
      if (selectedOrder.OrderID !== "") {
        setWebOrdersResponse("Please select one order only.");
      } else {
        event.target.parentElement.className = "btnHover background-lightblue";

        setSelectedOrder({
          OrderID: event.target.parentElement.attributes["orderid"].value,
          PickID: event.target.parentElement.attributes["pickid"].value,
        });
      }
    } else if (_class === "btnHover background-lightblue") {
      event.target.parentElement.className = "btnHover background-white";
      setSelectedOrder({
        OrderID: "",
        PickID: "",
      });
    }
  };

  const handleClick_View = () => {
    if (selectedOrder.OrderID === "" || selectedOrder.PickID === "") {
      setWebOrdersResponse("Please select an order to continue.");
    } else {
      props.setCurrentPicks([
        {
          OrderID: selectedOrder.OrderID,
          PickID: selectedOrder.PickID,
        },
      ]);

      props.setPage("lines-view");
    }
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>OrderID</strong>
          </p>
          <input
            type="text"
            id="weborders-orderid"
            ref={refOrderID}
            autoFocus
            onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Search)}
          />
        </div>
        <button
          className="signin-login-button"
          id="weborders-enter"
          onClick={handleClick_Search}
        >
          Search
        </button>
        <br />
        <div>
          {OrderTable.length === 0 ? (
            ""
          ) : (
            <>
              <table className="itemdetails-table">
                <thead>
                  <tr>
                    <th>OrderID</th>
                    <th>PickID</th>
                    <th>Categories</th>
                  </tr>
                </thead>
                <tbody>
                  {OrderTable.map((item, index) => (
                    <tr
                      key={index}
                      className="btnHover background-white"
                      orderid={item.OrderID}
                      pickid={item.PickID}
                      onClick={handleClick_Row}
                    >
                      <td>{item.OrderID}</td>
                      <td>{item.PickID}</td>
                      <td dangerouslySetInnerHTML={{ __html: item.Categories }}></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <br />
              <div className="blockedorders-option-row">
                <MenuButton
                  title="View"
                  button="V"
                  colour={green}
                  onClick={handleClick_View}
                />
              </div>
            </>
          )}
        </div>
        <p className="error-message">{WebOrdersResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
