import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { green, orange, spURL, spURL_NoInputJSON } from "../../App";
import { MenuButton, FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import { PopupException } from "./PopupException";

export const SSCCException = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.WHReceivedResponse);

  const [SSCCExceptionList, setSSCCExceptionList] = useState([]);

  const [selectedJobNo, setSelectedJobNo] = useState({
    StoreNo: "",
    JobNo: "",
    PalletReceived: "",
    MissingSSC: "",
    StoreName: "",
    IssuedDate: "",
    JobNoRecipient: "",
    JobReceiveDate: "",
    ExceptionReason: "",
  });

  const [show, setShow] = useState(false);

  const handleClick_Back = (event) => {
    setMenuResponse("");
    props.setWHReceivedResponse("");
    props.setPage("menu");
  };

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL}Handheld_WHReceived_SSCCException_List_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: "[{}]",
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setSSCCExceptionList(
          spResponse.Table.map((item) => ({
            StoreNo: item.StoreNo,
            JobNo: item.JobNo,
            PalletReceived: item.PalletReceived,
            MissingSSC: item.MissingSSC,
            StoreName: item.StoreName,
            IssuedDate: item.IssuedDate === "" ? "" : new Date(item.IssuedDate).toLocaleString(),
            JobNoRecipient: item.JobNoRecipient,
            JobReceiveDate: item.JobReceiveDate,
            ExceptionReason: item.ExceptionReason,
          }))
        );
      } else {
        setMenuResponse("ERROR!: No exceptions found.");
      }
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <div className="page-container">
      <div className="blockedorders-body-container">
        <p className="error-message">{MenuResponse}</p>
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>StoreNo</th>
              <th>JobNo</th>
              <th>Missing SSC</th>
              <th>Exception Reason</th>
            </tr>
          </thead>
          <tbody>
            {SSCCExceptionList.length === 0
              ? ""
              : SSCCExceptionList.map((item, index) => (
                  <tr
                    key={index}
                    className="btnHover"
                    onClick={() => {
                      setSelectedJobNo({
                        StoreNo: item.StoreNo,
                        JobNo: item.JobNo,
                        PalletReceived: item.PalletReceived,
                        MissingSSC: item.MissingSSC,
                        StoreName: item.StoreName,
                        IssuedDate: item.IssuedDate,
                        JobNoRecipient: item.JobNoRecipient,
                        JobReceiveDate: item.JobReceiveDate,
                        ExceptionReason: item.ExceptionReason,
                      });

                      setShow(true);
                    }}
                  >
                    <td>{item.StoreNo}</td>
                    <td>{item.JobNo}</td>
                    <td>{item.MissingSSC}</td>
                    <td>{item.ExceptionReason}</td>
                  </tr>
                ))}
          </tbody>
        </table>

        <PopupException
          JobNo={selectedJobNo}
          show={show}
          setShow={setShow}
          pageLoad={pageLoad}
          setMenuResponse={setMenuResponse}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          onClick={handleClick_Back}
          button="Back"
          colour={orange}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
