import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { green, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { ItemBarcode } from "../../components/ItemBarcode";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { PopupGiftCardScan } from "./GiftCardScan";

export const Pick = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [WebOrdersResponse, setWebOrdersResponse] = useState("");

  const [LinesTable, setLinesTable] = useState([]);

  const [showGiftCardScan, setShowGiftCardScan] = useState(false);

  const pageLoad = async () => {
    let uri = "";
    let inputObj = {};

    switch (props.Origin) {
      case "SINGLE":
        uri = "Handheld_CustomerOrder_Pick_Single_Line_Get";
        inputObj = props.CurrentPicks.map((item) => ({
          StoreNo: storeno,
          PickID: item.PickID,
        }));
        break;
      case "MULTIPLE":
        uri = "Handheld_CustomerOrder_Pick_Multiple_Line_Get";
        inputObj = { UserID: userid };
        break;
      case "VERIFY":
        uri = "Handheld_CustomerOrder_Verify_Line_Get";
        inputObj = props.CurrentPicks.map((item) => ({
          PickID: item.PickID,
        }));
        break;
    }

    const resObj = await runFetch(`${spURL}${uri}`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(inputObj),
      }),
    });

    if (resObj.response === null) {
      props.setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setLinesTable(
          spResponse.Table.map((item) => {
            let _background = "";
            switch (item.Colour) {
              case "Yellow":
                _background = "yellow";
                break;
              case "Green":
                _background = "lightgreen";
                break;
              default:
                _background = "white";
                break;
            }

            return props.Origin === "VERIFY"
              ? {
                  OrderID: item.OrderID,
                  Product: item.Product,
                  ItemNo: item.ItemNo,
                  QtyRequired: item.QtyRequired,
                  QtyVerified: item.QtyVerified,
                  Colour: item.Colour,
                  Background: `btnHover background-${_background}`,
                }
              : {
                  OrderID: item.OrderID,
                  Product: item.Product,
                  ItemNo: item.ItemNo,
                  QtyRequired: item.QtyRequired,
                  QtyPicked: item.QtyPicked,
                  Colour: item.Colour,
                  Background: `btnHover background-${_background}`,
                  Iss: item.Iss,
                };
          })
        );
      }
    }

    document.getElementById("itembarcode-barcode").value = "";
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Back = () => {
    props.setCurrentPicks([]);

    props.setMenuResponse("");
    setWebOrdersResponse("");

    props.setPage("menu");
  };

  const handleClick_Barcode = async () => {
    const _barcode = document.getElementById("itembarcode-barcode").value;

    props.setMenuResponse("");
    setWebOrdersResponse("");

    if (_barcode !== "") {
      let uri = "";

      switch (props.Origin) {
        case "SINGLE":
        case "MULTIPLE":
          uri = "Handheld_CustomerOrder_Pick_Single_UpdateQty";
          break;
        case "VERIFY":
          uri = "Handheld_CustomerOrder_Verify_UpdateQty";
          break;
        default:
          break;
      }

      const resObj = await runFetch(`${spURL}${uri}`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            UserID: userid,
            Barcode: _barcode,
            ItemCurrent: "",
          }),
        }),
      });

      if (resObj.response === null) {
        setWebOrdersResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          await pageLoad();
        }
      }
    } else {
      setWebOrdersResponse("ERROR!: Barcode field cannot be blank.");
    }

    document.getElementById("itembarcode-barcode").value = "";
  };

  const handleClick_Confirm = () => {
    props.setPage(`confirm-${props.Origin.toLowerCase()}`);
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <ItemBarcode barcodeClick={handleClick_Barcode} />
        <br />
        <p className="error-message">{WebOrdersResponse}</p>
        <div>
          {LinesTable.length === 0 ? (
            "No items found in order."
          ) : (
            <>
              <p>
                <strong>
                  Order {props.CurrentPicks.map((item) => item.OrderID).toString()} Items List
                </strong>
              </p>
              <table
                className="itemdetails-table"
                style={{ fontSize: 14 }}
              >
                <thead>
                  <tr>
                    <th>OrderID</th>
                    <th>SubCat/Desc/Item</th>
                    <th>Req</th>
                    {props.Origin === "VERIFY" ? (
                      <th>Ver</th>
                    ) : (
                      <>
                        <th>Pkd</th>
                        <th>Iss</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {LinesTable.map((item, index) => (
                    <tr
                      key={index}
                      className={item.Background}
                      colour={item.Colour}
                      itemno={item.ItemNo}
                      onClick={() => {
                        props.setCurrentItemNo(item.ItemNo);

                        props.setMenuResponse("");
                        setWebOrdersResponse("");

                        props.setPage(`item-${props.Origin.toLowerCase()}`);
                      }}
                    >
                      <td>{item.OrderID}</td>
                      <td dangerouslySetInnerHTML={{ __html: item.Product }}></td>
                      <td>{item.QtyRequired}</td>
                      {props.Origin === "VERIFY" ? (
                        <td>{item.QtyVerified}</td>
                      ) : (
                        <>
                          <td>{item.QtyPicked}</td>
                          <td>{item.Iss}</td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>

        <PopupGiftCardScan
          show={showGiftCardScan}
          setShow={setShowGiftCardScan}
          Origin={props.Origin}
          pageLoad={pageLoad}
        />
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
        <FooterButton
          button="Conf"
          colour={green}
          onClick={handleClick_Confirm}
        />
      </div>
    </div>
  );
};
