import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const PopupOOS = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [ConfirmResponse, setConfirmResponse] = useState("");

  const handleClose = () => {
    props.setShow(false);
  };

  const handleClick_Action = async (_action) => {
    const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_Pick_Orders_Commit`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(
          props.selectedOrder.map((item) => ({
            UserID: userid,
            PickID: item.PickID,
            OrderID: item.OrderID,
            StoreNo: storeno,
            Status: _action,
          }))
        ),
      }),
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      props.setWebOrdersResponse("OK!: Orders reverted as ISSUES.");
      props.pageLoad("OOS");
      props.setSelectedOrder([]);
      props.setShow(false);
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <strong>These are OOS orders.</strong>
            <br />
            <b>ISSUES</b> to revert these orders.
            <br />
            <b>SERVICE</b> to send to customer service.
            <br />
            <b>UNALLOCATE</b> to unallocate OOS items and pack the picked items.
            <br />
            <br />
            <div className="blockedorders-option-row">
              <MenuButton
                button="I"
                title="ISSUES"
                colour={green}
                onClick={() => handleClick_Action("REVERT")}
              />
              <MenuButton
                button="S"
                title="SERVICE"
                colour={green}
                onClick={() => handleClick_Action("INCOMPLETE")}
              />
              <MenuButton
                button="U"
                title="UNALLOCATE"
                colour={green}
                onClick={() => handleClick_Action("UNALLOCATE")}
              />
            </div>
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
