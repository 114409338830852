import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { spURL } from "../../App";
import { runFetch } from "../../functions/RunFetch";

export const PopupAllocateQty = (props) => {
  const handleClose = () => props.setShow(false);
  const [AllocationResponse, setAllocationResponse] = useState("");

  const refQty = useRef();

  const handleShow = () => {
    refQty.current.focus();
  };

  const handleSubmit = async () => {
    setAllocationResponse("");

    const resObj = await runFetch(`${spURL}Handheld_PerthWarehouse_AllocateQty`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          TONumber: props.currentTO,
          ItemNo: props.currentItemNo,
          Qty: refQty.current.value,
          Remaining: props.currentRemaining,
        }),
      }),
    });

    if (resObj.response === null) {
      setAllocationResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table[0].OutputString !== undefined) {
          setAllocationResponse(spResponse.Table[0].OutputString);
        }
      }
      handleClose();
      props.fetchData();
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Transfer: </strong>
              </p>
              <p>{props.currentTO}</p>
            </div>

            <div className="stocktake-create-input-row">
              <p>
                <strong>Qty to add:</strong>
              </p>
              <input
                type="text"
                id="perthwarehouse-allocateqty"
                ref={refQty}
              />
            </div>
            <p className="error-message">{props.allocation}</p>
          </div>
          <div className="error-message">{AllocationResponse}</div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
