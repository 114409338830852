import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { green, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const ItemView = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [WebOrdersResponse, setWebOrdersResponse] = useState("");

  const [ItemDetails, setItemDetails] = useState([]);
  const [QtyTable, setQtyTable] = useState([]);

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_View_Single_Item_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          PickID: props.CurrentPicks[0].PickID,
          ItemNo: props.CurrentItemNo,
        }),
      }),
    });

    if (resObj.response === null) {
      props.setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setItemDetails({
          Description: spResponse.Table[0].ExtendedDescription,
          Supplier: spResponse.Table[0].Supplier,
          ReorderNumber: spResponse.Table[0].ReorderNumber,
          ImageURL: spResponse.Table[0].ImageURL,
          SOH: spResponse.Table[0].SOH,
          MerchBay: spResponse.Table[0].MerchBay,
        });
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setQtyTable(
          spResponse.Table1.map((item) => {
            switch (item.Colour) {
              case "Yellow":
                return {
                  OrderID: item.OrderID,
                  QtyRequired: item.QtyRequired,
                  QtyPicked: item.QtyPicked,
                  QtyVerified: item.QtyVerified,
                  Colour: item.Colour,
                  Background: "background-yellow",
                };
              case "Green":
                return {
                  OrderID: item.OrderID,
                  QtyRequired: item.QtyRequired,
                  QtyPicked: item.QtyPicked,
                  QtyVerified: item.QtyVerified,
                  Colour: item.Colour,
                  Background: "background-lightgreen",
                };
              default:
                return {
                  OrderID: item.OrderID,
                  QtyRequired: item.QtyRequired,
                  QtyPicked: item.QtyPicked,
                  QtyVerified: item.QtyVerified,
                  Colour: item.Colour,
                  Background: "background-white",
                };
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Back = () => {
    props.setPage("lines-view");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          {ItemDetails === "" || QtyTable.length === 0 ? (
            ""
          ) : (
            <>
              <div className="itemdetails-image-itemno-container">
                <img
                  src={ItemDetails.ImageURL}
                  alt="Item"
                  width="100"
                />
                <div>
                  <p>{`${props.CurrentItemNo}, ${ItemDetails.Description}, ${ItemDetails.ReorderNumber}, ${ItemDetails.Supplier}`}</p>
                </div>
              </div>
              <p>
                <strong>StockOnHand</strong> {ItemDetails.SOH}
              </p>
              <p>
                <strong>MerchBay</strong> {ItemDetails.MerchBay}
              </p>
              <br />
              <table className="itemdetails-table">
                <thead>
                  <tr>
                    <th>OrderID</th>
                    <th>Required</th>
                    <th>Picked</th>
                    <th>Verified</th>
                  </tr>
                </thead>
                <tbody>
                  {QtyTable.map((item, index) => (
                    <tr
                      key={index}
                      className={item.Background}
                    >
                      <td>{item.OrderID}</td>
                      <td>{item.QtyRequired}</td>
                      <td>{item.QtyPicked}</td>
                      <td>{item.QtyVerified}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
        <p className="error-message">{WebOrdersResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
