import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { runFetch } from "../../functions/RunFetch";

export const PopupEquipmentDefault = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");
  const [ddlDevice, setDDLDevice] = useState([]);

  const refStore = useRef();
  const refDevice = useRef();
  const refNewDeviceContainer = useRef();
  const refNewDevice = useRef();
  const refDisplayNameContainer = useRef();
  const refDisplayName = useRef();
  const refConnectedToContainer = useRef();
  const refConnectedTo = useRef();
  const refModel = useRef();
  const refSerialNo = useRef();
  const refMACAddress = useRef();
  const refPassword = useRef();
  const refWarranty = useRef();
  const refWarrantyStart = useRef();
  const refWarrantyEnd = useRef();
  const refComments = useRef();

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleShow = () => {
    loadDDLDevice();
    handleFormChange(refDevice.current.value);
  };

  const loadFormContent = () => {
    refDevice.current.value = props.SelectedEquipment.Device;
    refDisplayName.current.value = props.SelectedEquipment.DisplayName;
    refConnectedTo.current.value = props.SelectedEquipment.ConnectedTo;
    refModel.current.value = props.SelectedEquipment.Model;
    refSerialNo.current.value = props.SelectedEquipment.SerialNo;
    refMACAddress.current.value = props.SelectedEquipment.MACAddress;
    refPassword.current.value = props.SelectedEquipment.Password;
    refWarranty.current.value = props.SelectedEquipment.Warranty;

    if (props.SelectedEquipment.WarrantyStartDate !== null) {
      refWarrantyStart.current.value = props.SelectedEquipment.WarrantyStartDate;
    }

    if (props.SelectedEquipment.WarrantyEndDate !== null) {
      refWarrantyEnd.current.value = props.SelectedEquipment.WarrantyEndDate;
    }

    refComments.current.value = props.SelectedEquipment.Comments;
  };

  const loadDDLDevice = async () => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_EquipmentList_DDLDevice_Get`, {
      method: "POST",
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDDLDevice(spResponse.Table);
      }
    }
  };

  const handleDelete = async () => {
    setPopupResponse("");

    const resObj = await runFetch(`${spURL}Handheld_EquipmentList_Store_DefaultEquipment_Delete`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: props.SelectedEquipment.StoreNo,
          Device: props.SelectedEquipment.Device,
          DisplayName: props.SelectedEquipment.DisplayName,
          ConnectedTo: props.SelectedEquipment.ConnectedTo,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setEquipmentListResponse(spResponse.Table[0].OutputString);
        props.setShow(false);
        props.getEquipmentList();
      }
    }
  };

  const handleSubmit = async () => {
    setPopupResponse("");

    let uri = "";
    let inputJSON = "";

    switch (props.Action) {
      case "EDIT":
        uri = `${spURL}Handheld_EquipmentList_Store_DefaultEquipment_Update`;
        inputJSON = JSON.stringify({
          StoreNo: props.SelectedEquipment.StoreNo,
          OldDevice: props.SelectedEquipment.Device,
          OldDisplayName: props.SelectedEquipment.DisplayName,
          OldConnectedTo: props.SelectedEquipment.ConnectedTo,
          Device:
            refDevice.current.value === "New Device..."
              ? refNewDevice.current.value
              : refDevice.current.value,
          DisplayName: refDisplayName.current.value,
          ConnectedTo: refConnectedTo.current.value,
          Model: refModel.current.value,
          SerialNo: refSerialNo.current.value,
          MACAddress: refMACAddress.current.value,
          Password: refPassword.current.value,
          Warranty: refWarranty.current.value,
          WarrantyStartDate: refWarrantyStart.current.value,
          WarrantyEndDate: refWarrantyEnd.current.value,
          Comments: refComments.current.value,
        });
        break;
      case "CREATE":
        uri = `${spURL}Handheld_EquipmentList_Store_DefaultEquipment_Create`;
        inputJSON = JSON.stringify({
          StoreNo: refStore.current.value,
          Device:
            refDevice.current.value === "New Device..."
              ? refNewDevice.current.value
              : refDevice.current.value,
          DisplayName: refDisplayName.current.value,
          ConnectedTo: refConnectedTo.current.value,
          Model: refModel.current.value,
          SerialNo: refSerialNo.current.value,
          MACAddress: refMACAddress.current.value,
          Password: refPassword.current.value,
          Warranty: refWarranty.current.value,
          WarrantyStartDate: refWarrantyStart.current.value,
          WarrantyEndDate: refWarrantyEnd.current.value,
          Comments: refComments.current.value,
        });
        break;
      default:
        break;
    }

    const resObj = await runFetch(uri, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: inputJSON,
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setEquipmentListResponse(spResponse.Table[0].OutputString);
        props.setShow(false);
        props.getEquipmentList();
      }
    }
  };

  const handleFormChange = (_devicevalue) => {
    switch (_devicevalue) {
      case "New Device...":
        refNewDeviceContainer.current.classList.remove("hidden");
        break;
      case "Monitor":
      case "Receipt Printer":
      case "Scanner":
        refDisplayNameContainer.current.classList.add("hidden");
        refConnectedToContainer.current.classList.remove("hidden");
        refNewDeviceContainer.current.classList.add("hidden");
        break;
      default:
        refDisplayNameContainer.current.classList.remove("hidden");
        refConnectedToContainer.current.classList.add("hidden");
        refNewDeviceContainer.current.classList.add("hidden");
        break;
    }
  };

  useEffect(() => {
    if (props.show && props.Action === "EDIT") {
      loadFormContent();
    }
  }, [props.show, props.Action, loadFormContent, ddlDevice, setDDLDevice]);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Store{props.Action === "CREATE" && "No"}:</strong>
              </p>
              {props.Action === "EDIT" && <p>{props.SelectedEquipment.StoreName}</p>}
              <input
                type="text"
                ref={refStore}
                className={props.Action !== "CREATE" && "hidden"}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Device:</strong>
              </p>
              <select
                ref={refDevice}
                onChange={(e) => handleFormChange(e.target.value)}
              >
                {ddlDevice.map((device, index) => (
                  <option
                    key={index}
                    value={device.Device}
                  >
                    {device.Device}
                  </option>
                ))}
              </select>
            </div>
            <div
              ref={refNewDeviceContainer}
              className="stocktake-create-input-row"
            >
              <p>
                <strong>New Device:</strong>
              </p>
              <input
                type="text"
                ref={refNewDevice}
                onChange={() => {
                  setPopupResponse("");
                }}
              />
            </div>
            <div
              ref={refDisplayNameContainer}
              className="stocktake-create-input-row"
            >
              <p>
                <strong>Display Name:</strong>
              </p>
              <input
                type="text"
                ref={refDisplayName}
                onChange={() => {
                  setPopupResponse("");
                }}
              />
            </div>
            <div
              ref={refConnectedToContainer}
              className="stocktake-create-input-row"
            >
              <p>
                <strong>ConnectedTo:</strong>
              </p>
              <input
                type="text"
                ref={refConnectedTo}
                onChange={() => {
                  setPopupResponse("");
                }}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Model:</strong>
              </p>
              <input
                type="text"
                ref={refModel}
                onChange={() => {
                  setPopupResponse("");
                }}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>SerialNo:</strong>
              </p>
              <input
                type="text"
                ref={refSerialNo}
                onChange={() => {
                  setPopupResponse("");
                }}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>MACAddress:</strong>
              </p>
              <input
                type="text"
                ref={refMACAddress}
                onChange={() => {
                  setPopupResponse("");
                }}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Password:</strong>
              </p>
              <input
                type="text"
                ref={refPassword}
                onChange={() => {
                  setPopupResponse("");
                }}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Warranty:</strong>
              </p>
              <input
                type="text"
                ref={refWarranty}
                onChange={() => {
                  setPopupResponse("");
                }}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Warranty Start:</strong>
              </p>
              <input
                type="date"
                ref={refWarrantyStart}
                onChange={() => {
                  setPopupResponse("");
                }}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Warranty End:</strong>
              </p>
              <input
                type="date"
                ref={refWarrantyEnd}
                onChange={() => {
                  setPopupResponse("");
                }}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Comments:</strong>
              </p>
              <input
                type="text"
                ref={refComments}
                onChange={() => {
                  setPopupResponse("");
                }}
              />
            </div>
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className={`popup-button ${props.Action !== "EDIT" && "hidden"}`}
              onClick={handleDelete}
            >
              <strong>Delete</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
