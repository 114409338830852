import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { green, spURL } from "../../App";
import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

import { PopupOOS } from "./PopupOOS";

export const Multiple = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [WebOrdersResponse, setWebOrdersResponse] = useState(props.MenuResponse);

  const [PickFilter, setPickFilter] = useState("NEW");
  const [OrderTable, setOrderTable] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState([]);

  const [showOOS, setShowOOS] = useState(false);

  const refOrderID = useRef();
  const refPrintWebLabels = useRef();

  const pageLoad = async (PickFilter) => {
    const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_Pick_Multiple_Order_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          UserID: userid,
          PickFilter: PickFilter,
        }),
      }),
    });

    if (resObj.response === null) {
      props.setMenuResponse(resObj.exception);
      props.setPage("menu");
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        const _userstatus = spResponse.Table[0].UserStatus;

        if (_userstatus === "ASSIGNED") {
          props.setPage("pick-multiple");
        }

        if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
          setOrderTable(
            spResponse.Table1.map((item) => {
              let _background = "";

              switch (item.OrderStatus) {
                case "NEW":
                  _background = "white";
                  break;
                case "ASSIGNED":
                  _background = "yellow";
                  break;
                case "COMPLETED":
                  _background = "lightgreen";
                  break;
                case "VERIFIED":
                  _background = "aquamarine";
                  break;
                case "ISSUES":
                  _background = "orange";
                  break;
                case "OOS":
                  _background = "salmon";
                  break;
                default:
                  _background = "hotpink";
                  break;
              }

              return {
                OrderID: item.id,
                PickID: item.PickID,
                Categories: item.CategoriesValue,
                Status: item.OrderStatus,
                AssignedStaff: item.staffname,
                Background: `btnHover background-${_background}`,
              };
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    pageLoad(PickFilter);
  }, []);

  const handleClick_Search = () => {
    setWebOrdersResponse("");

    const _orderid = refOrderID.current.value;

    // if orderID exists in table
    if (OrderTable.map((item) => item.OrderID.toString()).includes(_orderid)) {
      // find rowIndex of the orderID existing in table
      const index = OrderTable.findIndex((item) => item.OrderID.toString() === _orderid);

      // click on the orderID cell on the row found
      document.getElementById("weborders-ordertable-rows").rows[index].cells[0].click();
    } else {
      setWebOrdersResponse(`ERROR!: Order ${_orderid} not found.`);
    }
  };

  const handleClick_Back = () => {
    props.setMenuResponse("");
    setWebOrdersResponse("");
    props.setPage("menu");
  };

  const handleClick_Row = (event) => {
    const _class = event.target.parentElement.className;
    const _orderid = event.target.parentElement.attributes["orderid"].value;
    const _pickid = event.target.parentElement.attributes["pickid"].value;

    setWebOrdersResponse("");

    switch (_class) {
      case "btnHover background-white":
      case "btnHover background-yellow":
      case "btnHover background-lightgreen":
      case "btnHover background-orange":
      case "btnHover background-black":
        setSelectedOrder((values) => [
          ...values,
          {
            OrderID: event.target.parentElement.attributes["orderid"].value,
            PickID: event.target.parentElement.attributes["pickid"].value,
          },
        ]);
        event.target.parentElement.className = "btnHover background-lightblue";
        break;
      case "btnHover background-salmon":
        if (selectedOrder.length === 0) {
          setSelectedOrder((values) => [
            ...values,
            {
              OrderID: event.target.parentElement.attributes["orderid"].value,
              PickID: event.target.parentElement.attributes["pickid"].value,
            },
          ]);
          event.target.parentElement.className = "btnHover background-lightblue";
        } else {
          setWebOrdersResponse("Please select one order at a time");
        }
        break;
      case "btnHover background-lightblue":
        setSelectedOrder(
          selectedOrder.filter(
            (item) => `${item.OrderID}-${item.PickID}` !== `${_orderid}-${_pickid}`
          )
        );
        event.target.parentElement.className =
          event.target.parentElement.attributes["background"].value;
        break;
      default:
        break;
    }

    refOrderID.current.value = "";
  };

  const handleClick_Pick = async () => {
    setWebOrdersResponse("");

    if (selectedOrder.length === 0) {
      setWebOrdersResponse("Please select an order to continue.");
    } else {
      const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_Pick_Multiple_Assign`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(
            selectedOrder.map((item) => ({
              OrderID: item.OrderID,
              PickID: item.PickID,
              UserID: userid,
              PrintLabel: refPrintWebLabels.current.checked ? "1" : "0",
            }))
          ),
        }),
      });

      if (resObj.response === null) {
        setWebOrdersResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        props.setCurrentPicks(
          selectedOrder.map((item) => ({
            OrderID: item.OrderID,
            PickID: item.PickID,
          }))
        );

        props.setPage("pick-multiple");
      }
    }
  };

  const handleClick_PickFilter = (_filter) => {
    setWebOrdersResponse("");
    setOrderTable("");
    setSelectedOrder([]);
    setPickFilter(_filter);
    pageLoad(_filter);
  };

  const handleClick_Pack = async () => {
    setWebOrdersResponse("");

    if (selectedOrder.length === 0) {
      setWebOrdersResponse("Please select an order to continue.");
    } else {
      const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_PackingList_Queue_Add`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(
            selectedOrder.map((item) => ({
              OrderID: item.OrderID,
              PickID: item.PickID,
              UserID: userid,
              StoreNo: storeno,
            }))
          ),
        }),
      });

      if (resObj.response === null) {
        setWebOrdersResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        setWebOrdersResponse("OK!: Orders sent to be packed.");
        await pageLoad(PickFilter);
      }
    }
  };

  const handleClick_Unassign = async () => {
    setWebOrdersResponse("");

    if (selectedOrder.length === 0) {
      setWebOrdersResponse("Please select an order to continue.");
    } else {
      const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_Pick_Multiple_Orders_Commit`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(
            selectedOrder.map((item) => ({
              OrderID: item.OrderID,
              PickID: item.PickID,
              UserID: userid,
              StoreNo: storeno,
              Status: "Unassign",
            }))
          ),
        }),
      });

      if (resObj.exception !== "") {
        setWebOrdersResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        setWebOrdersResponse("OK!: Orders unassigned.");
        await pageLoad(PickFilter);
      }
    }
  };

  const handleClick_OOS = () => {
    // popup to confirm revert or refund
    setShowOOS(true);
  };

  return (
    <div className="weborders-page-container">
      <div className="weborders-top-row">
        <div className="weborders-option-row">
          <ContentButton
            button=""
            colour="white"
            id="weborders-pickfilter-new"
            onClick={() => {
              handleClick_PickFilter("NEW");
            }}
          />
          <ContentButton
            button=""
            colour="yellow"
            id="weborders-pickfilter-assigned"
            onClick={() => handleClick_PickFilter("ASSIGNED")}
          />
          <ContentButton
            button=""
            colour="lightgreen"
            id="weborders-pickfilter-completed"
            onClick={() => handleClick_PickFilter("COMPLETED")}
          />
          <ContentButton
            button=""
            colour="orange"
            id="weborders-pickfilter-issues"
            onClick={() => {
              handleClick_PickFilter("ISSUES");
            }}
          />
          <ContentButton
            button=""
            colour="salmon"
            id="weborders-pickfilter-oos"
            onClick={() => handleClick_PickFilter("OOS")}
          />
          {props.isPickManager && (
            <ContentButton
              button=""
              colour="black"
              id="weborders-pickfilter-allocated"
              onClick={() => handleClick_PickFilter("ALLOCATED")}
            />
          )}
          <ContentButton
            button=""
            colour="aquamarine"
            id="weborders-pickfilter-verified"
            onClick={() => handleClick_PickFilter("VERIFIED")}
          />
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>OrderID</strong>
          </p>
          <input
            type="text"
            id="weborders-orderid"
            ref={refOrderID}
            autoFocus
            onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Search)}
          />
        </div>
        <button
          className="signin-login-button hidden"
          id="weborders-enter"
          onClick={handleClick_Search}
        >
          Search
        </button>
        <p>
          <strong>{PickFilter} Orders Only</strong>
        </p>
        <div className="weborders-printweblabels-container">
          <input
            type="checkbox"
            id="weborders-printweblabels"
            name="weborders-printweblabels"
            ref={refPrintWebLabels}
          />
          <label htmlFor="weborders-printweblabels">Print web labels?</label>
        </div>
        <p className="error-message">{WebOrdersResponse}</p>
      </div>
      <div className="handheld-body-container">
        <div>
          {OrderTable.length === 0 ? (
            <p className="error-message">Currently no {PickFilter} orders.</p>
          ) : (
            <>
              <table
                className="itemdetails-table"
                id="weborders-ordertable"
              >
                <thead>
                  <tr>
                    <th>OrderID</th>
                    <th>PickID</th>
                    {PickFilter === "ALLOCATED" ? <th>Assigned Staff</th> : <th>Categories</th>}
                  </tr>
                </thead>
                <tbody id="weborders-ordertable-rows">
                  {PickFilter === "ALLOCATED"
                    ? OrderTable.map((item, index) => (
                        <tr
                          key={index}
                          className={item.Background}
                          background={item.Background}
                          orderid={item.OrderID}
                          pickid={item.PickID}
                          onClick={handleClick_Row}
                        >
                          <td>{item.OrderID}</td>
                          <td>{item.PickID}</td>
                          <td>{item.AssignedStaff}</td>
                        </tr>
                      ))
                    : OrderTable.map((item, index) => (
                        <tr
                          key={index}
                          className={item.Background}
                          background={item.Background}
                          orderid={item.OrderID}
                          pickid={item.PickID}
                          onClick={handleClick_Row}
                        >
                          <td>{item.OrderID}</td>
                          <td>{item.PickID}</td>
                          <td dangerouslySetInnerHTML={{ __html: item.Categories }}></td>
                        </tr>
                      ))}
                </tbody>
              </table>
              <br />
            </>
          )}

          <PopupOOS
            show={showOOS}
            setShow={setShowOOS}
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
            pageLoad={pageLoad}
            setWebOrdersResponse={setWebOrdersResponse}
          />
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
        {OrderTable === "" ? (
          ""
        ) : PickFilter === "NEW" || PickFilter === "ISSUES" ? (
          <FooterButton
            button="Pick"
            colour={green}
            onClick={handleClick_Pick}
          />
        ) : PickFilter === "COMPLETED" ? (
          <FooterButton
            button="Pack"
            colour={green}
            onClick={handleClick_Pack}
          />
        ) : PickFilter === "ALLOCATED" ? (
          <FooterButton
            button="UnAll"
            colour={green}
            onClick={handleClick_Unassign}
          />
        ) : PickFilter === "OOS" ? (
          <FooterButton
            button="OOS"
            colour={green}
            onClick={handleClick_OOS}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
