import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { PopupEntryDetails } from "./PopupEntryDetails";
import { runFetch } from "../../functions/RunFetch";

export const Manage = (props) => {
  const [BlockedOrdersResponse, setBlockedOrdersResponse] = useState("");

  const [ManageMode, setManageMode] = useState("");
  const [AddressTable, setAddressTable] = useState([]);
  const [EmailTable, setEmailTable] = useState([]);
  const [LoadingMessage, setLoadingMessage] = useState(false);
  const [EntryMode, setEntryMode] = useState("");
  const [SelectedEntry, setSelectedEntry] = useState("");
  const [showEntryDetails, setShowEntryDetails] = useState(false);

  const cleanTable = () => {
    setAddressTable([]);
    setEmailTable([]);
  };

  const handleChange_ManageMode = (e) => {
    setLoadingMessage(true);
    setBlockedOrdersResponse("");
    cleanTable();

    const _mode = e.target.value;

    setManageMode(_mode);
    if (_mode !== "") {
      switch (_mode) {
        case "Address":
          getManageTable(_mode, setAddressTable);
          break;
        case "Email":
          getManageTable(_mode, setEmailTable);
          break;
        default:
          break;
      }
    }
    setLoadingMessage(false);
  };

  const getManageTable = async (mode, setTable) => {
    const resObj = await runFetch(
      `${spURL_NoInputJSON}/BigCommerce_BlockedOrders_Manage_${mode}_List_Get`,
      {
        method: "POST",
      }
    );

    if (resObj.response === null) {
      setBlockedOrdersResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setTable(spResponse.Table);
      } else {
        setTable([]);
      }
    }
  };

  const handleClick_Row = (rowObj) => {
    setEntryMode("EDIT");
    setShowEntryDetails(true);
    setSelectedEntry(rowObj);
  };

  const handleClick_Add = () => {
    setEntryMode("ADD");
    setShowEntryDetails(true);
  };

  return (
    <div className="page-container">
      <div className="blockedorders-body-container">
        <h4>
          <strong>Manage Untrusted Customer</strong>
        </h4>
        <div className="blockedorders-input-row">
          <p>
            <strong>Mode:</strong>
          </p>
          <select onChange={handleChange_ManageMode}>
            <option value=""></option>
            <option value="Address">ADDRESS</option>
            <option value="Email">EMAIL</option>
          </select>
        </div>
        <p className="error-message">{BlockedOrdersResponse}</p>
        {LoadingMessage ? (
          <p>Loading...</p>
        ) : (
          <table className="itemdetails-table">
            <thead>
              {ManageMode === "Address" ? (
                <tr>
                  <th>Street1</th>
                  <th>PostCode</th>
                </tr>
              ) : ManageMode === "Email" ? (
                <tr>
                  <th>Email</th>
                </tr>
              ) : null}
            </thead>
            <tbody>
              {ManageMode === "Address"
                ? AddressTable.map((item, index) => (
                    <tr
                      key={index}
                      rowID={item.ID}
                      className="btnHover"
                      onClick={() => {
                        handleClick_Row(item);
                      }}
                    >
                      <td>{item.Street1}</td>
                      <td>{item.PostCode}</td>
                    </tr>
                  ))
                : ManageMode === "Email"
                ? EmailTable.map((item, index) => (
                    <tr
                      key={index}
                      rowID={item.ID}
                      className="btnHover"
                      onClick={() => {
                        handleClick_Row(item);
                      }}
                    >
                      <td>{item.Email}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        )}
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={() => {
            props.setPage("main");
          }}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
        {["Address", "Email"].includes(ManageMode) && (
          <FooterButton
            button="Add"
            colour={green}
            onClick={handleClick_Add}
          />
        )}
      </div>

      <PopupEntryDetails
        show={showEntryDetails}
        setShow={setShowEntryDetails}
        EntryMode={EntryMode}
        ManageMode={ManageMode}
        SelectedEntry={SelectedEntry}
        setBlockedOrdersResponse={setBlockedOrdersResponse}
        RefreshTable={() => {
          getManageTable(
            ManageMode,
            ManageMode === "Address"
              ? setAddressTable
              : ManageMode === "Email"
              ? setEmailTable
              : null
          );
        }}
      />
    </div>
  );
};
