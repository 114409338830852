import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { purple, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { trackPromise } from "react-promise-tracker";
import { Menu } from "./Menu";
import { PLP, PLPMenu } from "./PLPMenu";

export const WebsiteCMSBody = (props) => {
  const [page, setPage] = useState("menu");
  const [MenuResponse, setMenuResponse] = useState("");

  const menuPage = (
    <Menu
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
    />
  );

  const plpMenuPage = (
    <PLPMenu
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "plp":
        return plpMenuPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
