import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Link, useLocation } from "react-router-dom";
import {
  red,
  spURL,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
  spURL_GetIP,
  blue,
} from "../../App";
import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { convertToCurrency } from "../../functions/ConvertToCurrency";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { runFetch } from "../../functions/RunFetch";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { handleChange_Input } from "../../functions/HandleChangeInput";

export const PopupItemSearch = (props) => {
  const [itemTable, setItemTable] = useState([]);
  const [MenuResponse, setMenuResponse] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const refSearch = useRef();

  const handleClick_Search = () => {
    setMenuResponse("");
    setItemTable([]);
    exeSearchClick(searchTerm);
  };

  const exeSearchClick = async (_search) => {
    setCurrentIndex(0);

    const resObj = await runFetch(`${spURL}Handheld_ItemSearch_Item_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          SearchTerm: _search,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setItemTable(
          spResponse.Table.map((item, index) => ({
            Index: index,
            ItemNo: item.ItemNo,
            Product: item.Product,
            ImageURL: item.ImageURL,
          }))
        );
      }
    }
  };

  const handleClose = () => {
    setSearchTerm("");
    setCurrentIndex(0);
    setItemTable([]);
    props.setShow(false);
  };

  const handleShow = () => {
    refSearch.current.focus();
  };

  return (
    <Modal
      show={props.show}
      onShow={handleShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body>
        <div>
          <div className="itemsearch-input-row">
            <input
              type="text"
              id="itemsearch-search"
              onChange={(e) => handleChange_Input(e, setSearchTerm)}
              autoFocus
              onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Search)}
              ref={refSearch}
              placeholder="Search"
            />
            <ContentButton
              id="itemsearch-main-enter"
              colour={red}
              button="Go"
              onClick={handleClick_Search}
            />
          </div>
          {
            <div className="itemsearch-index-row">
              <button
                id="itemsearch-prev"
                className={`btnHover ${currentIndex === 0 && "disabled"}`}
                onClick={() => {
                  if (currentIndex !== 0) {
                    setCurrentIndex(currentIndex - 20);
                  }
                }}
              >
                Prev
              </button>
              <p>
                {currentIndex / 20 + 1} of {Math.floor(itemTable.length / 20) + 1}
              </p>
              <button
                id="itemsearch-next"
                className={`btnHover ${
                  currentIndex / 20 === Math.floor(itemTable.length / 20) && "disabled"
                }`}
                onClick={() => {
                  if (currentIndex / 20 !== Math.floor(itemTable.length / 20)) {
                    setCurrentIndex(currentIndex + 20);
                  }
                }}
              >
                Next
              </button>
            </div>
          }
        </div>
        <div className="itemsearch-table-row">
          <p className="error-message">{MenuResponse}</p>
          {
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>
                    <strong>Product</strong>
                  </th>
                  <th>
                    <strong>Image</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {itemTable
                  .filter((item) => item.Index >= currentIndex && item.Index < currentIndex + 20)
                  .map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => props.exeBarcodeClick(item.ItemNo)}
                      className="btnHover"
                    >
                      <td dangerouslySetInnerHTML={{ __html: item.Product }}></td>
                      <td>
                        <img
                          src={item.ImageURL}
                          width="50"
                          height="50"
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          }
        </div>
        <div className="popup-button-container">
          <button
            className="popup-button"
            onClick={handleClose}
          >
            <strong>Close</strong>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
