import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { useRef } from "react";
import { runFetch } from "../../functions/RunFetch";

export const WrongPrice = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [WrongPriceResponse, setWrongPriceResponse] = useState("");

  const refScanningPrice = useRef();
  const refCorrectPrice = useRef();
  const refType = useRef();

  const handleClose_WP = () => {
    props.setItemDetailsResponse("");
    props.setShow(false);
  };

  const handleShow_WP = () => {
    props.setItemDetailsResponse("");
    refScanningPrice.current.value = convertToCurrency(props.price, "");
    refCorrectPrice.current.value = convertToCurrency(props.price, "");
  };

  const handleSubmit_WP = async () => {
    props.setItemDetailsResponse("");

    const resObj = await runFetch(`${spURL}Handheld_WrongPrice_Process`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          ItemNo: props.itemno,
          StoreID: storeno,
          ScanningPrice: refScanningPrice.current.value,
          CorrectPrice: refCorrectPrice.current.value,
          Type: refType.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setWrongPriceResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table[0].OutputString !== undefined) {
          handleClose_WP();
          props.setItemDetailsResponse(spResponse.Table[0].OutputString);
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose_WP}
        onShow={handleShow_WP}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <h3 className="itemdetails-wp-title background-black">
              <strong>SCANNING PRICE</strong>
            </h3>
            <input
              className="itemdetails-wp-input"
              type="text"
              id="itemdetails-wp-scanningprice"
              ref={refScanningPrice}
              autoComplete="off"
            />
            <h3 className="itemdetails-wp-title background-black">
              <strong>CORRECT PRICE</strong>
            </h3>
            <input
              className="itemdetails-wp-input"
              type="text"
              id="itemdetails-wp-correctprice"
              ref={refCorrectPrice}
              autoComplete="off"
            />
            <h3 className="itemdetails-wp-title background-black">
              <strong>POS OR LABELS</strong>
            </h3>
            <select
              className="itemdetails-wp-input"
              name="type"
              id="itemdetails-wp-type"
              ref={refType}
            >
              <option value="POS">POS</option>
              <option value="LABELS">LABELS</option>
              <option value="BOTH">BOTH</option>
            </select>

            <p className="error-message">{WrongPriceResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose_WP}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit_WP}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
