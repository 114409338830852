import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  yellow,
  spURL,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
  spURL_LocalPOS,
} from "../../App";
import { ItemBarcode } from "../../components/ItemBarcode";
import { PopupConfirm } from "./PopupConfirm";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import { doPrintShelfLabel } from "../../functions/ShelfLabelUtils";
import store from "../../store";

export const QuickLabel = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.Response);
  const [ddlShelfPrinter, setDDLShelfPrinter] = useState("");
  const [isShelfPrinterSelected, setIsShelfPrinterSelected] = useState(
    props.currentShelfPrinter !== ""
  );
  const [currentShelfPrinter, setCurrentShelfPrinter] = useState(props.currentShelfPrinter);
  const [currentBarcode, setCurrentBarcode] = useState("");

  const [showPopupOOS, setShowPopupOOS] = useState(false);
  const [showPopupEDM, setShowPopupEDM] = useState(false);

  const [storeno, setStoreNo] = store.useState("storeno");

  const pageLoad = async () => {
    const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_Location`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });
    const resObj = JSON.parse(res);

    if (resObj.status === "ERROR!") {
      setMenuResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);
      setDDLShelfPrinter(
        spResponse.Table.map((item, index, arr) => (
          <option
            key={index}
            value={item.ShelfPrinter}
          >
            {item.ShelfPrinter}
          </option>
        ))
      );
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Back = () => {
    props.setResponse("");
    props.setCurrentMerchBatchID("");
    props.setCurrentShelfPrinter("");
    props.setBarcode("");
    props.setPage("menu");
  };

  const handleClick_Select = () => {
    setMenuResponse("");

    if (isShelfPrinterSelected) {
      setCurrentShelfPrinter("");
      setIsShelfPrinterSelected(false);
    } else {
      const _shelfprinter = document.getElementById("labels-quicklabel-shelfprinter").value;

      if (_shelfprinter === "") {
        setMenuResponse("ERROR!: Shelf printer must be selected");
      } else {
        setCurrentShelfPrinter(_shelfprinter);
        setIsShelfPrinterSelected(true);
      }
    }
  };

  const handleClick_Barcode = () => {
    setMenuResponse("");
    const _barcode = document.getElementById("itembarcode-barcode").value;

    setCurrentBarcode(_barcode);

    async function checkSOH() {
      let _soh = 0;
      let _edm = "";

      const resObj = await runFetch(`${spURL}Handheld_Label_Item_SOH`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            Barcode: _barcode,
          }),
        }),
      });

      if (resObj.response === null) {
        setMenuResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);
        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          _soh = parseInt(spResponse.Table[0].SOH);
        }

        if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
          _edm = spResponse.Table1[0].EDM;
        }
      }

      if (_edm === "YES") {
        setShowPopupEDM(true);
      } else if (_soh <= 0) {
        setShowPopupOOS(true);
      } else {
        await addQuickLabel();
      }
    }

    async function addQuickLabel() {
      const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_Add_QuickLabel`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            ShelfPrinter: currentShelfPrinter,
            Override: 0,
            Barcode: _barcode,
          }),
        }),
      });
      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        setMenuResponse(resObj.exception);
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);
        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          // do print shelf label
          await doPrintShelfLabel(currentShelfPrinter, _barcode, 1, "MERCH");
        }
      }
      // clear input field
      setCurrentBarcode("");
      document.getElementById("itembarcode-barcode").value = "";
    }
    checkSOH();
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="input-row">
          <p>
            <strong>Shelf Printer:</strong>
          </p>
          {isShelfPrinterSelected ? (
            <p>{currentShelfPrinter}</p>
          ) : (
            <select
              name="shelfprinter"
              id="labels-quicklabel-shelfprinter"
              onChange={() => setMenuResponse("")}
            >
              <option value=""></option>
              {ddlShelfPrinter}
            </select>
          )}
        </div>
        <button
          className="signin-login-button btnHover"
          onClick={handleClick_Select}
        >
          {isShelfPrinterSelected ? "Change" : "Select"}
        </button>
        <br />
        {isShelfPrinterSelected ? (
          <div>
            <br />
            <ItemBarcode barcodeClick={handleClick_Barcode} />
            <br />
          </div>
        ) : (
          ""
        )}

        <p className="error-message">{MenuResponse}</p>

        <PopupConfirm
          show={showPopupOOS}
          setShow={setShowPopupOOS}
          setPage={props.setPage}
          setUpdateBarcode={props.setBarcode}
          setMenuResponse={setMenuResponse}
          ConfirmType="OOS"
          From="QUICK"
          Barcode={currentBarcode}
          setBarcode={setCurrentBarcode}
          ShelfPrinter={currentShelfPrinter}
        />

        <PopupConfirm
          show={showPopupEDM}
          setShow={setShowPopupEDM}
          setPage={props.setPage}
          setUpdateBarcode={props.setBarcode}
          setMenuResponse={setMenuResponse}
          ConfirmType="EDM"
          From="QUICK"
          Barcode={currentBarcode}
          setBarcode={setCurrentBarcode}
          ShelfPrinter={currentShelfPrinter}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={yellow}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
      </div>
    </div>
  );
};
