import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Webcam from "react-webcam";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCamera, faX, faRotateLeft } from "@fortawesome/free-solid-svg-icons";

library.add(faCamera);
library.add(faX);
library.add(faRotateLeft);

export const PopupCameraScreen = (props) => {
  const [SnappedImage, setSnappedImage] = useState(null);

  const refCamera = useRef();

  const cleanImage = () => {
    setSnappedImage(null);
  };

  const handleClose = () => {
    cleanImage();
    props.setShow(false);
  };

  const handleShow = () => {
    cleanImage();
  };

  const handleClick_Retake = () => {
    cleanImage();
  };

  const handleClick_Snap = () => {
    setSnappedImage(refCamera.current.getScreenshot());
  };

  const handleClick_Save = () => {
    props.processSnappedImage(SnappedImage);
    handleClose();
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        fullscreen
        centered
      >
        <Modal.Body>
          <div className="storeaudittool-camera-preview">
            <Webcam
              audio={false}
              ref={refCamera}
              className={SnappedImage && "hidden"}
              screenshotFormat="image/jpeg"
              videoConstraints={{ facingMode: "environment", width: 300, height: 450 }}
            />
            <img
              className={!SnappedImage && "hidden"}
              src={SnappedImage}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Cancel
            </button>
            {SnappedImage ? (
              <button
                className="popup-button"
                onClick={handleClick_Retake}
              >
                <FontAwesomeIcon icon="fa-solid fa-rotate-left" />
              </button>
            ) : (
              <button
                className="popup-button"
                onClick={handleClick_Snap}
              >
                <FontAwesomeIcon icon="fa-solid fa-camera" />
              </button>
            )}
            <button
              className={`popup-button ${!SnappedImage && "visibility-hidden"}`}
              onClick={handleClick_Save}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
