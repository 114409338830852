import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { red, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const SOH = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [storeQtyTable, setStoreQtyTable] = useState([]);

  useEffect(() => {
    async function getSOH() {
      const resObj = await runFetch(`${spURL}Handheld_ItemDetailAdmin_Item_StoreQuantity`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            ItemNo: props.itemno,
            StoreID: storeno,
          }),
        }),
      });

      if (resObj.response === null) {
        props.setPage("barcode");
        props.setItemDetailsResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          setStoreQtyTable(spResponse.Table);
        }
      }
      ReactTooltip.rebuild();
    }
    getSOH();
  }, []);

  return (
    <div className="page-container">
      <div
        className={
          window.matchMedia("(max-width: 364px)").matches
            ? "handheld-body-container"
            : "desktop-body-container"
        }
      >
        <p>
          <strong>Item: {props.itemno}</strong>
        </p>
        <p>
          <strong>Store Quantity</strong>
        </p>
        <div>
          {storeQtyTable.length > 0 && (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th rowSpan={2}>Store</th>
                  <th rowSpan={2}>On Hand</th>
                  <th colSpan={3}>Sold</th>
                  <th rowSpan={2}>On Order</th>
                  <th rowSpan={2}>On Transf</th>
                </tr>
                <tr>
                  <th>Today</th>
                  <th>Last 7d</th>
                  <th>Year to Date</th>
                </tr>
              </thead>
              <tbody>
                {storeQtyTable.map((item, index) => (
                  <tr key={index}>
                    <td>{item.StoreName}</td>
                    <td
                      className="btnHover"
                      data-tip
                      data-for="on-hand"
                    >
                      {item.SOH}
                    </td>
                    <td
                      className="btnHover"
                      data-tip
                      data-for="sales-today"
                    >
                      {item.Sales0d}
                    </td>
                    <td
                      className="btnHover"
                      data-tip
                      data-for="sales-last-7d"
                    >
                      {item.Sales7d}
                    </td>
                    <td
                      className="btnHover"
                      data-tip
                      data-for="sales-ytd"
                    >
                      {item.SalesYTD}
                    </td>
                    <td
                      className="btnHover"
                      data-tip
                      data-for="on-order"
                    >
                      {item.SOO}
                    </td>
                    <td
                      className="btnHover"
                      data-tip
                      data-for="on-transfer"
                    >
                      {item.SOT}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={() => props.setPage("details")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>

      <ReactTooltip
        id="on-hand"
        place="bottom"
        effect="solid"
      >
        On Hand
      </ReactTooltip>
      <ReactTooltip
        id="sales-today"
        place="bottom"
        effect="solid"
      >
        Sales Today
      </ReactTooltip>
      <ReactTooltip
        id="sales-last-7d"
        place="bottom"
        effect="solid"
      >
        Sales Last 7d
      </ReactTooltip>
      <ReactTooltip
        id="sales-ytd"
        place="bottom"
        effect="solid"
      >
        Sales Year to Date
      </ReactTooltip>
      <ReactTooltip
        id="on-order"
        place="bottom"
        effect="solid"
      >
        On Order
      </ReactTooltip>
      <ReactTooltip
        id="on-transfer"
        place="bottom"
        effect="solid"
      >
        On Transfer
      </ReactTooltip>
    </div>
  );
};
