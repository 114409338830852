import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { green, orange, spURL, spURL_NoInputJSON } from "../../App";
import { MenuButton, FooterButton } from "../../components/HomepageButton";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { runFetch } from "../../functions/RunFetch";

export const Manager = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.WHReceivedResponse);

  const [ddlStore, setDDLStore] = useState([]);

  const [jobnoTable, setJobNoTable] = useState([]);

  const handleClick_Back = () => {
    setMenuResponse("");
    props.setWHReceivedResponse("");
    props.setPage("menu");
  };

  const handleChange_Store = async (_store) => {
    setMenuResponse("");

    const resObj = await runFetch(`${spURL}Handheld_WHReceived_Manager_JobNoList_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreName: _store,
        }),
      }),
    });

    if (resObj.response === null) {
      setJobNoTable([]);
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setJobNoTable(
          spResponse.Table.map((item) => ({
            JobNo: item.JobNo,
            DeliveryDate: item.DeliveryDate,
            DeliveryWindow: item.DeliveryWindow,
            PalletAmount: item.PalletAmount,
          }))
        );
      } else {
        setJobNoTable([]);
        setMenuResponse("ERROR!: No jobs found.");
      }
    }
  };

  useEffect(() => {
    async function getStoreList() {
      const resObj = await runFetch(`${spURL}Handheld_WHReceived_Manager_StoreList_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: "[{}]",
        }),
      });

      if (resObj.response === null) {
        setMenuResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setDDLStore(
            spResponse.Table.map((item, index) => (
              <option
                value={item.StoreName}
                key={index}
              >
                {item.StoreName}
              </option>
            ))
          );
        } else {
          setMenuResponse("ERROR!: No stores found.");
        }
      }
    }
    getStoreList();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>Store</strong>
          </p>
          <select onChange={(e) => handleChange_Input(e, handleChange_Store)}>
            <option value=""></option>
            {ddlStore}
          </select>
        </div>
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>JobNo</th>
              <th>Delivery Date</th>
              <th>Delivery Window</th>
              <th>Pallet Amt</th>
            </tr>
          </thead>
          <tbody>
            {jobnoTable.length === 0
              ? ""
              : jobnoTable.map((item, index) => (
                  <tr key={index}>
                    <td>{item.JobNo}</td>
                    <td>{new Date(item.DeliveryDate).toLocaleDateString()}</td>
                    <td>{item.DeliveryWindow}</td>
                    <td>{item.PalletAmount}</td>
                  </tr>
                ))}
          </tbody>
        </table>
        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          onClick={handleClick_Back}
          button="Back"
          colour={orange}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
