import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { runFetch } from "../../functions/RunFetch";

export const PopupChangeStore = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const refNewStoreNo = useRef();

  const handleClose = () => {
    setPopupResponse("");
    refNewStoreNo.current.value = "";
    props.setShow(false);
  };

  const handleShow = () => {
    refNewStoreNo.current.focus();
  };

  const handleSubmit = async () => {
    const resObj = await runFetch(`${spURL}Handheld_Functions_Manage_Staff_ChangeStore`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: props.currentUser.UserID,
          NewStoreNo: refNewStoreNo.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setResponse(spResponse.Table[0].OutputString);
        props.fetchData(props.currentUser.UserID);
        handleClose();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>UserID:</strong>
              </p>
              <p>{props.currentUser.UserID}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Current Store:</strong>
              </p>
              <p>
                {props.currentUser.StoreName} | {props.currentUser.StoreNo}
              </p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>New StoreNo:</strong>
              </p>
              <input
                type="text"
                ref={refNewStoreNo}
              />
            </div>
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
