import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { MenuButton, FooterButton } from "../../components/HomepageButton";
import { PopupLines } from "./PopupLines";
import { PopupReallocate } from "./PopupReallocate";
import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";
import { handleChange_Input } from "../../functions/HandleChangeInput";

export const UnallocatedOrdersBody = (props) => {
  const [MenuResponse, setMenuResponse] = useState("");

  const [Orders, setOrders] = useState([]);

  const [Preorder, setPreorder] = useState("N");
  const [currentItemNo, setCurrentItemNo] = useState("");
  const [currentOrderID, setCurrentOrderID] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);

  const [showReallocatePopup, setShowReallocatePopup] = useState(false);
  const [showLinesPopup, setShowLinesPopup] = useState(false);

  const pageLoad = async () => {
    setMenuResponse("");
    setSelectedOrders([]);
    const resObj = await runFetch(spURL_NoInputJSON + "Unallocated_WebOrders_Refresh", {
      method: "POST",
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setOrders(
          spResponse.Table.map((item, index) => ({
            PickID: item.PickID,
            OrderID: item.OrderID,
            ItemNo: item.ItemNo,
            ProdID: item.ProdID,
            Description: item.Description,
            State: item.State,
            Quantity: item.Quantity,
            OnlineSOH: item.OnlineSOH,
            AubuSOH: item.AubuSOH,
            JoonSOH: item.JoonSOH,
            FounSOH: item.FounSOH,
            MajuSOH: item.MajuSOH,
            Preorder: item.Preorder,
          }))
        );
      }
    }
  };

  const handleClick_Row = (event) => {
    const _orderid = event.target.parentElement.attributes["orderid"].value;
    const _itemid = event.target.parentElement.attributes["itemID"].value;
    const _description = event.target.parentElement.attributes["description"].value;
    const _quantity = event.target.parentElement.attributes["quantity"].value;

    if (
      event.target.parentElement.className === "btnHover background-white" ||
      event.target.parentElement.className === "btnHover background-yellow"
    ) {
      event.target.parentElement.className = "btnHover background-lightblue";

      setSelectedOrders((values) => [
        ...values,
        {
          orderid: _orderid,
          itemid: _itemid,
          description: _description,
          quantity: _quantity,
        },
      ]);
    } else if (event.target.parentElement.className === "btnHover background-lightblue") {
      if (event.target.parentElement.attributes["background"].value === "yellow") {
        event.target.parentElement.className = "btnHover background-yellow";
      } else {
        event.target.parentElement.className = "btnHover background-white";
      }

      setSelectedOrders((values) => values.filter((items) => items.orderid !== _orderid));
    }
  };

  const handleClick_Reallocate = () => {
    if (selectedOrders.length > 0) {
      setShowReallocatePopup(true);
    } else {
      setMenuResponse("ERROR!: No orders selected.");
    }
  };

  const handleClick_Refund = async () => {
    if (selectedOrders.length === 0) {
      setMenuResponse("ERROR!: No orders selected.");
    } else {
      const resObj = await runFetch(`${spURL_Unallocated_WebOrders}Unallocated_WebOrders_Refund`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(selectedOrders),
        }),
      });

      if (resObj.response === null) {
        setMenuResponse(resObj.exception);
      } else {
        setMenuResponse("OK!: Order refunded.");
      }
    }
  };

  const handleClick_View = () => {
    if (selectedOrders.length > 0) {
      setShowLinesPopup(true);
    } else {
      setMenuResponse("ERROR!: No orders selected.");
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <div className="unallocatedorders-page-container">
      <div className="unallocatedorders-top-row">
        <div className="blockedorders-input-row">
          <p>
            <strong>Item Number:</strong>
          </p>
          <input
            type="text"
            id="unallocatedorders-itemno"
            onChange={(e) => handleChange_Input(e, setCurrentItemNo)}
          />
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>Order Number:</strong>
          </p>
          <input
            type="text"
            id="unallocatedorders-orderid"
            onChange={(e) => handleChange_Input(e, setCurrentOrderID)}
          />
        </div>
        <div className="blockedorders-input-row">
          <input
            type="radio"
            name="preorder-selection"
            id="unallocatedorders-radio-nonpreorders"
            onClick={() => setPreorder("N")}
            defaultChecked
          />
          <p>
            <strong>Non Pre-Orders</strong>
          </p>
        </div>
        <div className="blockedorders-input-row">
          <input
            type="radio"
            name="preorder-selection"
            id="unallocatedorders-radio-preorders"
            onClick={(e) => setPreorder("Y")}
          />
          <p>
            <strong>Pre-Orders</strong>
          </p>
        </div>
        <div>
          <input
            type="button"
            id="unallocatedorders-refresh"
            value="Refresh"
            onClick={pageLoad}
          />
        </div>
      </div>
      <div>
        <div className="blockedorders-content-container">
          {Orders.length !== 0 && (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th rowSpan={2}>OrderID</th>
                  <th rowSpan={2}>ItemNo</th>
                  <th rowSpan={2}>ProductID</th>
                  <th rowSpan={2}>Description</th>
                  <th rowSpan={2}>State</th>
                  <th rowSpan={2}>Qty</th>
                  <th colSpan={4}>SOH</th>
                </tr>
                <tr>
                  <th>Aubu</th>
                  <th>Joon</th>
                  <th>Foun</th>
                  <th>Maju</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: 16 }}>
                {Orders.reduce(function (filtered, order, index) {
                  if (
                    order.Preorder === Preorder &&
                    order.ItemNo.includes(currentItemNo) &&
                    order.OrderID.includes(currentOrderID)
                  ) {
                    var tableRow = (
                      <tr
                        key={index}
                        onClick={handleClick_Row}
                        orderid={order.OrderID}
                        itemID={order.ItemNo}
                        description={order.Description}
                        quantity={order.Quantity}
                        background={
                          parseInt(order.Quantity) > parseInt(order.AubuSOH) ? "yellow" : "white"
                        }
                        className={
                          parseInt(order.Quantity) > parseInt(order.AubuSOH)
                            ? "btnHover background-yellow"
                            : "btnHover background-white"
                        }
                      >
                        <td>{order.OrderID}</td>
                        <td>{order.ItemNo}</td>
                        <td>{order.ProdID}</td>
                        <td>{order.Description}</td>
                        <td>{order.State}</td>
                        <td>{order.Quantity}</td>
                        <td>{order.AubuSOH}</td>
                        <td>{order.JoonSOH}</td>
                        <td>{order.FounSOH}</td>
                        <td>{order.MajuSOH}</td>
                      </tr>
                    );

                    filtered.push(tableRow);
                  }

                  return filtered;
                }, [])}
              </tbody>
            </table>
          )}
          <br />
          <div className="blockedorders-option-row">
            <MenuButton
              title="Reallocate"
              colour={green}
              button="R"
              onClick={handleClick_Reallocate}
            />
            <MenuButton
              title="Refund"
              colour={green}
              button="R"
              onClick={handleClick_Refund}
            />
            <MenuButton
              title="View"
              colour={green}
              button="V"
              onClick={handleClick_View}
            />
          </div>

          <br />
          <p className="error-message">{MenuResponse}</p>

          <PopupLines
            show={showLinesPopup}
            setShow={setShowLinesPopup}
            selectedOrders={selectedOrders}
            setMenuResponse={setMenuResponse}
          />

          <PopupReallocate
            show={showReallocatePopup}
            setShow={setShowReallocatePopup}
            selectedOrders={selectedOrders}
            setSelectedOrders={setSelectedOrders}
            pageLoad={pageLoad}
            setMenuResponse={setMenuResponse}
          />
        </div>
      </div>
      <div className="footer-container menu-container">
        <Link to="/EComm">
          <FooterButton
            button="Back"
            colour={green}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
