import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ModalDelete } from "./ModalDelete";
import { FooterButton, MenuButton } from "../../components/HomepageButton";

import { yellow, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const Menu = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [MenuResponse, setMenuResponse] = useState("");
  const [show, setShow] = useState(false);

  const handleClick_Menu_Finalise = async () => {
    setMenuResponse("");

    const resObj = await runFetch(`${spURL}Handheld_BulkyItemMerch_Email_Queue_Add`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });
    if (resObj.exception !== null) {
      setMenuResponse(resObj.exception);
    }

    setShow(true);
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          <MenuButton
            onClick={() => {
              setMenuResponse("");
              props.setPage("floor");
            }}
            button="F"
            colour={yellow}
            title="Floor"
          />
          <MenuButton
            onClick={() => {
              setMenuResponse("");
              props.setPage("storeroom");
            }}
            button="S"
            colour={yellow}
            title="Storeroom"
          />
        </div>
        <div className="home-table">
          <MenuButton
            onClick={handleClick_Menu_Finalise}
            button="F"
            colour={yellow}
            title="Finalise"
          />
        </div>

        <p className="error-message">{MenuResponse}</p>

        <ModalDelete
          setShow={setShow}
          setMenuResponse={setMenuResponse}
          show={show}
        />
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={yellow}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
      </div>
    </div>
  );
};
