import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import store from "../../store";

export const PopupAllocateCage = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [PopupResponse, setPopupResponse] = useState("");

  const [ddlDepartment, setDDLDepartment] = useState([]);
  const [Department, setDepartment] = useState("");
  const [IsDepartmentAllocated, setIsDepartmentAllocated] = useState(false);

  const refDDLDepartment = useRef();

  const handleShow = async () => {
    setPopupResponse("");
    getDDLDepartment();

    const resObj = await runFetch(`${spURL}Handheld_CageAllocation_CageDetails_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          Cage: props.SelectedCage,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setIsDepartmentAllocated(true);
        setDepartment(spResponse.Table[0].Department);
      } else {
        setIsDepartmentAllocated(false);
        setDepartment("");
        refDDLDepartment.current.value = "";
      }
    }
  };

  const handleClose = () => {
    setPopupResponse("");
    setDDLDepartment([]);
    setDepartment("");
    setIsDepartmentAllocated(false);
    props.setCageAllocationResponse("");
    props.getCageList();
    props.setShow(false);
  };

  const getDDLDepartment = async () => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_CageAllocation_DDLDepartment_Get`, {
      method: "POST",
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDDLDepartment(spResponse.Table.map((item) => item.Department));
      } else {
        setDDLDepartment([]);
      }
    }
  };

  const handleClick_Allocate = async () => {
    setPopupResponse("");
    const resObj = await runFetch(
      `${spURL}${
        IsDepartmentAllocated
          ? "Handheld_CageAllocation_Cage_Unallocate"
          : "Handheld_CageAllocation_Cage_Allocate"
      }`,
      {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            Cage: props.SelectedCage,
            Department: Department,
          }),
        }),
      }
    );

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        if (IsDepartmentAllocated) {
          setIsDepartmentAllocated(false);
          setDepartment("");
        } else {
          setIsDepartmentAllocated(true);
        }

        props.setCageAllocationResponse(spResponse.Table[0].OutputString);
        handleClose();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="input-row">
              <p>
                <strong>Cage:</strong>
              </p>
              <p>{props.SelectedCage}</p>
            </div>
            <div className="input-row">
              <p>
                <strong>Department:</strong>
              </p>
              {IsDepartmentAllocated ? (
                <p>{Department}</p>
              ) : (
                <select
                  onChange={(e) => {
                    setPopupResponse("");
                    handleChange_Input(e, setDepartment);
                  }}
                  ref={refDDLDepartment}
                >
                  <option value=""></option>
                  {ddlDepartment.map((item, index) => (
                    <option
                      value={item}
                      key={index}
                    >
                      {item}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <button
              className={`signin-login-button btnHover ${Department === "" ? "disabled" : null}`}
              onClick={handleClick_Allocate}
            >
              {IsDepartmentAllocated ? "Unallocate" : "Allocate"}
            </button>
            <br />
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
