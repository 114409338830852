import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { HomepageButton, FooterButton } from "../components/HomepageButton";

import { green, yellow, spURL, getAccessData } from "../App";
import { trackPromise } from "react-promise-tracker";
import store from "../store";

export const ECommBody = () => {
  const [userid, setUserID] = store.useState("userid");
  const [Access, setAccess] = useState({});

  useEffect(() => {
    async function getAccess() {
      setAccess(await getAccessData(userid));
    }

    getAccess();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          {Access.WebOrders ? (
            <HomepageButton
              link="/WebOrders"
              button="WO"
              colour={green}
              title="Web Orders"
            />
          ) : (
            ""
          )}
          {Access.CustomerService ? (
            <HomepageButton
              link="/CustomerService"
              button="CS"
              colour={green}
              title="Customer Service"
            />
          ) : (
            ""
          )}
          {Access.PhoneOrders ? (
            <HomepageButton
              link="/PhoneOrders"
              button="PO"
              colour={green}
              title="Phone Orders"
            />
          ) : (
            ""
          )}
        </div>
        <div className="home-table">
          {Access.BlockedOrders ? (
            <HomepageButton
              link="/BlockedOrders"
              button="BO"
              colour={green}
              title="Blocked Orders"
            />
          ) : (
            ""
          )}
          {Access.FailedOrders ? (
            <HomepageButton
              link="/FailedOrders"
              button="FO"
              colour={green}
              title="Failed Orders"
            />
          ) : (
            ""
          )}
          {Access.ResendOrders ? (
            <HomepageButton
              link="/ResendOrders"
              button="RO"
              colour={green}
              title="Resend Orders"
            />
          ) : (
            ""
          )}
        </div>
        <div className="home-table">
          {Access.UnallocatedOrders ? (
            <HomepageButton
              link="/UnallocatedOrders"
              button="UO"
              colour={green}
              title="Unallocated Orders"
            />
          ) : (
            ""
          )}
          {Access.SearchTermManage ? (
            <HomepageButton
              link="/SearchTermManage"
              button="ST"
              colour={green}
              title="Search Term Manage"
            />
          ) : (
            ""
          )}
          {Access.WebBuffer ? (
            <HomepageButton
              link="/WebBuffer"
              button="WB"
              colour={green}
              title="Web Buffer"
            />
          ) : (
            ""
          )}
        </div>
        <div className="home-table">
          {Access.SmythsManage ? (
            <HomepageButton
              link="/SmythsManage"
              button="SM"
              colour={green}
              title="Smyths Manage"
            />
          ) : (
            ""
          )}
          <div className="home-table-items"></div>
          <div className="home-table-items"></div>
        </div>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={green}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
