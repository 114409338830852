import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { red, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const SOT = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [onTransferTable, setOnTransferTable] = useState([]);

  useEffect(() => {
    async function getTO() {
      const resObj = await runFetch(`${spURL}Handheld_ItemDetailAdmin_Item_StoreTransfer`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            ItemNo: props.itemno,
            StoreID: storeno,
          }),
        }),
      });

      if (resObj.response === null) {
        props.setPage("barcode");
        props.setItemDetailsResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table.length === 0) {
          props.setPage("details");
          props.setItemDetailsResponse("ERROR!: No stock on transfer.");
        } else if (spResponse.Table !== undefined) {
          setOnTransferTable(spResponse.Table);
        }
      }
    }

    getTO();
  }, []);

  return (
    <div className="page-container">
      <div
        className={
          window.matchMedia("(max-width: 364px)").matches
            ? "handheld-body-container"
            : "desktop-body-container"
        }
      >
        <p>
          <strong>Item: {props.itemno}</strong>
        </p>
        <br />
        <div>
          <p>
            <strong>On Transfer</strong>
          </p>
          {onTransferTable.length > 0 && (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>TransferNo</th>
                  <th>JobNo</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody>
                {onTransferTable.map((item, index) => (
                  <tr key={index}>
                    <td
                      data-tip
                      data-id="transferno"
                    >
                      {item.TransferNo}
                    </td>
                    <td
                      data-tip
                      data-id="jobno"
                    >
                      {item.JobNo}
                    </td>
                    <td
                      data-tip
                      data-id="from"
                    >
                      {item.From}
                    </td>
                    <td
                      data-tip
                      data-id="to"
                    >
                      {item.To}
                    </td>
                    <td
                      data-tip
                      data-id="qty"
                    >
                      {item.Quantity}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={() => props.setPage("details")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>

      <ReactTooltip
        id="transferno"
        place="bottom"
        effect="solid"
      >
        Transfer No
      </ReactTooltip>
      <ReactTooltip
        id="from"
        place="bottom"
        effect="solid"
      >
        From
      </ReactTooltip>
      <ReactTooltip
        id="to"
        place="bottom"
        effect="solid"
      >
        To
      </ReactTooltip>
      <ReactTooltip
        id="qty"
        place="bottom"
        effect="solid"
      >
        Quantity
      </ReactTooltip>
    </div>
  );
};
