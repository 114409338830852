import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import {
  spURL,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
  spURL_LocalPOS,
  spURL_LocalLabel,
} from "../../App";
import { runFetch, runLabelFetch } from "../../functions/RunFetch";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { doPrintShelfLabel } from "../../functions/ShelfLabelUtils";
import store from "../../store";

export const PopupPriceChangesCreateBatch = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [MenuResponse, setMenuResponse] = useState(props.Response);
  const [ddlShelfPrinter, setDDLShelfPrinter] = useState("");

  const handleShow = () => {
    pageLoad();
  };

  const handleClose = () => {
    setMenuResponse("");
    props.setShow(false);
  };

  const pageLoad = async () => {
    const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_Location`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });
    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMenuResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);

      setDDLShelfPrinter(
        spResponse.Table.map((item, index, arr) => (
          <option
            key={index}
            value={item.ShelfPrinter}
          >
            {item.ShelfPrinter}
          </option>
        ))
      );
    }
  };

  const handleClick_Create = () => {
    setMenuResponse("");

    const _batchid = document.getElementById("labels-merchlabel-create-batchid").value;
    const _shelfprinter = document.getElementById("labels-merchlabel-create-shelfprinter").value;

    async function createBatch() {
      const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_Create_Batch`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            BatchID: _batchid,
            ShelfPrinter: _shelfprinter,
          }),
        }),
      });
      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        setMenuResponse(resObj.exception);
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);

        props.setSelectedBatch(_batchid);
        props.setSelectedPrinter(_shelfprinter);
        props.setIsBatchSelected(true);
        handleClose();
      }
    }

    if (_batchid === "") {
      setMenuResponse("ERROR!: Batch Name cannot be blank.");
    } else if (_shelfprinter === "") {
      setMenuResponse("ERROR!: Shelf printer must be selected.");
    } else if (storeno === "") {
      setMenuResponse("ERROR!: You can only run this program from a store!");
    } else {
      createBatch();
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="input-row">
              <p>
                <strong>Batch Name:</strong>
              </p>
              <input
                type="text"
                id="labels-merchlabel-create-batchid"
              />
            </div>
            <div className="input-row">
              <p>
                <strong>Shelf Printer:</strong>
              </p>
              <select
                name="shelfprinter"
                id="labels-merchlabel-create-shelfprinter"
                onChange={() => setMenuResponse("")}
              >
                <option value=""></option>
                {ddlShelfPrinter}
              </select>
            </div>
            <br />
            <p className="error-message">{MenuResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Create}
            >
              Create
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
