import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { yellow, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";

export const MerchLabelMenu = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.Response);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          <MenuButton
            onClick={() => props.setPage("merchlabel-create")}
            button="C"
            colour={yellow}
            title="Create"
          />
          <MenuButton
            onClick={() => props.setPage("merchlabel-add")}
            button="M"
            colour={yellow}
            title="Manage Label"
          />
        </div>
        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={yellow}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
      </div>
    </div>
  );
};
