import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { runFetch } from "../../functions/RunFetch";

export const PopupAddSubCat = (props) => {
  const [ConfirmResponse, setConfirmResponse] = useState("");

  const [ddlDepartment, setDDLDepartment] = useState([]);
  const [ddlCategory, setDDLCategory] = useState([]);
  const [ddlSubCat, setDDLSubCat] = useState([]);

  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCat, setSelectedSubCat] = useState("");

  const handleClose = () => props.setShow(false);

  const handleShow = async () => {
    setConfirmResponse("");

    const resObj = await runFetch(`${spURL}Stocktake_List_Division`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: "[{}]",
      }),
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDDLDepartment(spResponse.Table.map((item) => item.Description));
      }
    }
  };

  const handleChange_Department = async (event) => {
    setConfirmResponse("");

    const _department = event.target.value;

    setSelectedDepartment(_department);

    const resObj = await runFetch(`${spURL}Stocktake_List_ItemCategory`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Division: _department,
        }),
      }),
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDDLCategory(spResponse.Table.map((item) => item.Description));
      } else {
        setDDLCategory([]);
      }
    }
  };

  const handleChange_Category = async (event) => {
    setConfirmResponse("");

    const _category = event.target.value;
    setSelectedCategory(_category);

    const resObj = await runFetch(`${spURL}Stocktake_List_ProductGroup`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Division: selectedDepartment,
          ItemCategory: _category,
        }),
      }),
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDDLSubCat(spResponse.Table.map((item) => item.Description));
      } else {
        setDDLSubCat([]);
      }
    }
  };

  const handleChange_SubCat = (event) => {
    setSelectedSubCat(event.target.value);
  };

  const handleClick_Add = async () => {
    setConfirmResponse("");

    const resObj = await runFetch(`${spURL}Stocktake_BlockedSubCatList_Add`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Department: selectedDepartment,
          Category: selectedCategory,
          SubCat: selectedSubCat,
        }),
      }),
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table[0].OutputString !== undefined) {
          if (spResponse.Table[0].OutputString.includes("OK!:")) {
            props.setStockTakeResponse(spResponse.Table[0].OutputString);
            props.setShow(false);
            props.refreshTable("ALL", "ALL");
          } else {
            setConfirmResponse(spResponse.Table[0].OutputString);
          }
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Department:</strong>
              </p>
              <select
                id="stocktake-admin-blocksubcat-department"
                onChange={handleChange_Department}
              >
                {ddlDepartment.map((item, index) => (
                  <option
                    value={item}
                    key={index}
                    name={item}
                  >
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Category:</strong>
              </p>
              <select
                id="stocktake-admin-blocksubcat-category"
                onChange={handleChange_Category}
              >
                {ddlCategory.map((item, index) => (
                  <option
                    value={item}
                    key={index}
                    name={item}
                  >
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>SubCat:</strong>
              </p>
              <select
                id="stocktake-admin-blocksubcat-subcat"
                onChange={handleChange_SubCat}
              >
                {ddlSubCat.map((item, index) => (
                  <option
                    value={item}
                    key={index}
                    name={item}
                  >
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <br />
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Add}
            >
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
