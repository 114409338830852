import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const PopupPickHistory = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [ConfirmResponse, setConfirmResponse] = useState("");

  const [HistoryList, setHistoryList] = useState([]);

  const handleClose = () => {
    props.setShow(false);
    setConfirmResponse("");
    setHistoryList([]);
  };
  const handleShow = async () => {
    setConfirmResponse("");
    const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_View_PickHistory_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(
          props.CurrentPicks.map((item) => ({
            OrderID: item.OrderID,
            PickID: item.PickID,
            StoreNo: storeno,
          }))
        ),
      }),
    });

    if (resObj.exception !== "") {
      setConfirmResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setHistoryList(
          spResponse.Table.map((item) => ({
            PickedBy: item.PickedBy,
            StatusBeforeProcessed: item.StatusBeforeProcessed,
            StatusAfterProcessed: item.StatusAfterProcessed,
            DateAssigned: item.DateAssigned,
            DateProcessed: item.DateProcessed,
            CommitStatus: item.CommitStatus,
          }))
        );
      } else {
        setConfirmResponse("No pick history in order");
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>OrderID:</strong>
              </p>
              <p>{props.CurrentPicks[0].OrderID}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>PickID:</strong>
              </p>
              <p>{props.CurrentPicks[0].PickID}</p>
            </div>
            <div style={{ overflow: "auto", maxHeight: 300 }}>
              <table className="itemdetails-table">
                <thead>
                  <tr>
                    <th>Picked By</th>
                    <th>Status Before</th>
                    <th>Status After</th>
                    <th>Date Assigned</th>
                    <th>Date Processed</th>
                    <th>Commit Status</th>
                  </tr>
                </thead>
                <tbody>
                  {HistoryList.map((item, index) => (
                    <tr key={index}>
                      <td>{item.PickedBy}</td>
                      <td>{item.StatusBeforeProcessed}</td>
                      <td>{item.StatusAfterProcessed}</td>
                      <td>{item.DateAssigned}</td>
                      <td>{item.DateProcessed}</td>
                      <td>{item.CommitStatus}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <br />
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
