import React, { useState, useEffect } from "react";
import { Header } from "./Header";
import { Lines } from "./Lines";
import { Details } from "./Details";
import { Allocation } from "./Allocation";

export const PerthWarehouseBody = () => {
  const [page, setPage] = useState("header");

  const [currentItemNo, setCurrentItemNo] = useState("");
  const [currentPO, setCurrentPO] = useState("");
  const [currentStoreNo, setCurrentStoreNo] = useState("");
  const [PerthWarehouseResponse, setPerthWarehouseResponse] = useState("");

  const headerPage = (
    <Header
      page={page}
      setPage={setPage}
      currentPO={currentPO}
      setCurrentPO={setCurrentPO}
      currentItemNo={currentItemNo}
      setCurrentItemNo={setCurrentItemNo}
      currentStoreNo={currentStoreNo}
      setCurrentStoreNo={setCurrentStoreNo}
      PerthWarehouseResponse={PerthWarehouseResponse}
      setPerthWarehouseResponse={setPerthWarehouseResponse}
    />
  );

  const linePage = (
    <Lines
      page={page}
      setPage={setPage}
      currentPO={currentPO}
      setCurrentPO={setCurrentPO}
      currentItemNo={currentItemNo}
      setCurrentItemNo={setCurrentItemNo}
      currentStoreNo={currentStoreNo}
      setCurrentStoreNo={setCurrentStoreNo}
      PerthWarehouseResponse={PerthWarehouseResponse}
      setPerthWarehouseResponse={setPerthWarehouseResponse}
    />
  );
  const detailsPage = (
    <Details
      page={page}
      setPage={setPage}
      currentPO={currentPO}
      setCurrentPO={setCurrentPO}
      currentItemNo={currentItemNo}
      setCurrentItemNo={setCurrentItemNo}
      currentStoreNo={currentStoreNo}
      setCurrentStoreNo={setCurrentStoreNo}
      PerthWarehouseResponse={PerthWarehouseResponse}
      setPerthWarehouseResponse={setPerthWarehouseResponse}
    />
  );

  const allocationPage = (
    <Allocation
      page={page}
      setPage={setPage}
      currentPO={currentPO}
      setCurrentPO={setCurrentPO}
      currentItemNo={currentItemNo}
      setCurrentItemNo={setCurrentItemNo}
      currentStoreNo={currentStoreNo}
      setCurrentStoreNo={setCurrentStoreNo}
      PerthWarehouseResponse={PerthWarehouseResponse}
      setPerthWarehouseResponse={setPerthWarehouseResponse}
    />
  );
  const getCurrentPage = (page) => {
    switch (page) {
      case "header":
        return headerPage;
      case "line":
        return linePage;
      case "details":
        return detailsPage;
      case "allocation":
        return allocationPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
