import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const ModalDelete = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const deleteLines = async () => {
    props.setMenuResponse("");

    const resObj = await runFetch(`${spURL}Handheld_BulkyItemMerch_Line_Delete`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });
    if (resObj.exception !== null) {
      props.setMenuResponse(resObj.exception);
    } else {
      props.setMenuResponse("Items successfully deleted.");
    }

    props.setShow(false);
  };

  return (
    <div>
      <Modal
        show={props.show}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>Report will be emailed. Would you like to delete these records?</div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={() => props.setShow(false)}
            >
              <strong>No</strong>
            </button>
            <button
              className="popup-button"
              onClick={deleteLines}
            >
              <strong>Yes</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
