import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";

export const PopupException = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const handleClose = () => props.setShow(false);

  const handleShow = () => {
    setPopupResponse("");
  };

  const handleClick_Resolve = async () => {
    const resObj = await runFetch(`${spURL}Handheld_WHReceived_SSCCException_Resolve`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          JobNo: props.JobNo.JobNo,
          SSCC: props.JobNo.MissingSSC,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setMenuResponse(spResponse.Table[0].OutputString);
        props.pageLoad();
        props.setShow(false);
      } else {
        setPopupResponse("ERROR!: Server error.");
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>JobNo</strong>
              </p>
              <p>{props.JobNo.JobNo}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Pallet Received</strong>
              </p>
              <p>{props.JobNo.PalletReceived}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Missing SSCC</strong>
              </p>
              <p>{props.JobNo.MissingSSC}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Issued Date:</strong>
              </p>
              <p>{props.JobNo.IssuedDate}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Recipient</strong>
              </p>
              <p>{props.JobNo.JobNoRecipient}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Received Date</strong>
              </p>
              <p>{props.JobNo.JobReceiveDate}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Exception Reason</strong>
              </p>
              <p>{props.JobNo.ExceptionReason}</p>
            </div>
          </div>
          <p className="error-message">{PopupResponse}</p>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Resolve}
            >
              <strong>Resolve</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
