import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";

import { MenuButton, FooterButton } from "../../components/HomepageButton";
import { blue, green, spURL } from "../../App";
import { PopupEmail } from "./PopupEmail";

// font awesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styl
import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const PrintReceiptBody = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [MenuResponse, setMenuResponse] = useState("");

  const [Transactions, setTransactions] = useState([]);

  const [searchType, setSearchType] = useState("SIMPLE");

  const [currentPOSNo, setCurrentPOSNo] = useState("");
  const [selectedReceiptNo, setSelectedReceiptNo] = useState("");
  const [currentReceiptNo, setCurrentReceiptNo] = useState("");
  const [currentItemNo, setCurrentItemNo] = useState("");
  const [currentMinAmount, setCurrentMinAmount] = useState("");
  const [currentMaxAmount, setCurrentMaxAmount] = useState("");
  const [currentReceipt, setCurrentReceipt] = useState("");
  const [currentTransactionReceiptNo, setCurrentTransactionReceiptNo] = useState("");
  const [currentDate, setcurrentDate] = useState(new Date().toISOString().substring(0, 10));
  const [currentPrintReceiptString, setCurrentPrintReceiptString] = useState([]);
  const [currentTenderType, setCurrentTenderType] = useState("");

  const [showEmail, setShowEmail] = useState(false);

  const [storeNames, setStoreNames] = useState([]);
  const [currentStore, setCurrentStore] = useState("");

  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sortIcon, setSortIcon] = useState("");

  const pageLoad = async (_date) => {
    setMenuResponse("");

    const resObj = await runFetch(
      `${spURL}Handheld_PrintReceipt_Transactions_Get${searchType === "SIMPLE" ? "_SIMPLE" : ""}`,
      {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(
            searchType === "SIMPLE"
              ? { TransactionReceiptNo: currentTransactionReceiptNo }
              : { StoreName: storeno, Date: _date }
          ),
        }),
      }
    );

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setTransactions(
          spResponse.Table.map((item) => ({
            Date: item.Date,
            POSNo: item.POSNo,
            ItemCount: item.ItemCount,
            Total: item.Total,
            ReceiptNo: item.ReceiptNo,
            TransactionNo: item.TransactionNo,
            StoreName: item.StoreName,
            Items: item.Items,
            TenderType: item.TenderType,
            TenderCount: item.TenderCount,
          }))
        );
      } else {
        setTransactions([]);
      }
    }
  };

  const handleClick_Row = async (_receiptNo) => {
    setSelectedReceiptNo(_receiptNo);
    setCurrentReceipt("");

    const resObj = await runFetch(`${spURL}Handheld_PrintReceipt_Transaction_Receipt_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          ReceiptNo: _receiptNo,
          PrintType: "LAST",
        }),
      }),
    });

    if (resObj.response === null) {
      setCurrentReceipt("");
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setCurrentReceipt(spResponse.Table[0].ReceiptString);
        setCurrentPrintReceiptString(
          spResponse.Table.map((item) => ({
            ReceiptString: item.ReceiptString,
            BarcodeString: item.BarcodeString,
          }))
        );
      }
    }
  };

  const handleClick_Email = () => {
    if (selectedReceiptNo !== "") {
      setMenuResponse("");
      setShowEmail(true);
    } else {
      setMenuResponse("ERROR!: No receipt number selected.");
    }
  };

  const getStoreNames = async () => {
    const resObj = await runFetch(`${spURL}Handheld_POS_Store`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: "[{}]",
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStoreNames(spResponse.Table.map((item) => item.StoreName));
      } else {
        setStoreNames([]);
      }
    }
  };

  useEffect(() => {
    var curr = new Date();
    var date = curr.toLocaleDateString();
    const [day, month, year] = date.split("/");
    date = `${year}-${month}-${day}`;

    setcurrentDate(date);

    getStoreNames();

    pageLoad(currentDate);
  }, [currentDate]);

  useEffect(() => {
    let newArr = Transactions;

    if (newArr.length > 0 && sortColumn !== "") {
      newArr = newArr.sort((a, b) => {
        if (sortColumn === "Total" || sortColumn === "ItemCount") {
          return sortOrder === "ASC"
            ? parseFloat(a[sortColumn]) - parseFloat(b[sortColumn])
            : parseFloat(b[sortColumn]) - parseFloat(a[sortColumn]);
        } else if (sortColumn === "Date") {
        } else {
          return sortOrder === "ASC"
            ? a[sortColumn].localeCompare(b[sortColumn])
            : b[sortColumn].localeCompare(a[sortColumn]);
        }
      });

      setTransactions(newArr);
    }
  }, [sortColumn, sortOrder, Transactions]);

  useEffect(() => {
    switch (sortOrder) {
      case "ASC":
        setSortIcon(solid("sort-up"));
        break;
      case "DESC":
        setSortIcon(solid("sort-down"));
        break;
      default:
        setSortIcon(solid("sort"));
        break;
    }
  }, [sortOrder]);

  useEffect(() => {
    pageLoad(currentDate);
  }, [searchType, currentTransactionReceiptNo]);

  const handleClick_HeaderRow = (event) => {
    let _target;
    switch (event.target.tagName.toUpperCase()) {
      case "TH":
        _target = event.target;
        break;
      case "SVG":
        _target = event.target.parentElement;
        break;
      case "PATH":
        _target = event.target.parentElement.parentElement;
        break;
      default:
        break;
    }

    if (event.target.tagName.toUpperCase() !== "TR") {
      const _colname = _target.attributes["colname"].value;
      const _sortorder = _target.attributes["sortorder"].value;

      if (_colname === "Date") {
      } else {
        setSortColumn(_colname);

        if (_colname === sortColumn) {
          if (_sortorder === "ASC") {
            setSortOrder("DESC");
          } else {
            setSortOrder("ASC");
          }
        } else {
          setSortOrder("ASC");
        }
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.target.parentElement.parentElement.classList.contains("disabled")) {
      event.preventDefault();
    }
  };

  const handleClick_AdvancedSearch = () => {
    if (searchType === "SIMPLE") {
      setSearchType("ADVANCED");
    } else if (searchType === "ADVANCED") {
      setSearchType("SIMPLE");
    }
  };

  return (
    <div className="printreceipt-page-container">
      <div className="printreceipt-top-row simple-row">
        <div
          className={
            searchType === "SIMPLE" ? "printreceipt-input-row" : "printreceipt-input-row disabled"
          }
        >
          <p>
            <strong>Transaction/Receipt No: </strong>
          </p>
          <input
            type="text"
            id="printreceipt-transreceiptno"
            onChange={(e) => handleChange_Input(e, setCurrentTransactionReceiptNo)}
          />
        </div>
        <div>
          <p
            className="printreceipt-advancedsearch-button"
            onClick={handleClick_AdvancedSearch}
          >
            Toggle advanced search
          </p>
        </div>
      </div>
      <div
        className={
          searchType === "ADVANCED"
            ? "printreceipt-top-row advanced-row"
            : "printreceipt-top-row advanced-row disabled"
        }
      >
        <div className="printreceipt-input-row">
          <p>
            <strong>StoreName:</strong>
          </p>
          <select
            onChange={(e) => handleChange_Input(e, setCurrentStore)}
            onKeyDown={handleKeyDown}
          >
            <option value=""></option>
            {storeNames.map((item, index) => (
              <option
                key={index}
                value={item}
              >
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="printreceipt-input-row">
          <p>
            <strong>Receipt No:</strong>
          </p>
          <input
            type="text"
            id="printreceipt-receiptno"
            onChange={(e) => handleChange_Input(e, setCurrentReceiptNo)}
          />
        </div>
        <div className="printreceipt-input-row">
          <p>
            <strong>POS No:</strong>
          </p>
          <input
            type="text"
            id="printreceipt-posno"
            onChange={(e) => handleChange_Input(e, setCurrentPOSNo)}
          />
        </div>
        <div className="printreceipt-input-row">
          <p>
            <strong>Transaction Date:</strong>
          </p>
          <input
            type="date"
            name="date-selection"
            required
            pattern="\d{4}-\d{2}-\d{2}"
            defaultValue={currentDate}
            id="printreceipt-date"
            onChange={(e) => handleChange_Input(e, pageLoad)}
          />
        </div>
        <div className="printreceipt-input-row">
          <p>
            <strong>ItemNo:</strong>
          </p>
          <input
            type="text"
            id="printreceipt-itemno"
            onChange={(e) => handleChange_Input(e, setCurrentItemNo)}
          />
        </div>
        <div className="printreceipt-input-row">
          <p>
            <strong>Amount:</strong>
          </p>
          <div>
            <input
              type="number"
              step="0.01"
              id="printreceipt-minamount"
              onChange={(e) => handleChange_Input(e, setCurrentMinAmount)}
            />{" "}
            -{" "}
            <input
              type="number"
              step="0.01"
              id="printreceipt-maxamount"
              onChange={(e) => handleChange_Input(e, setCurrentMaxAmount)}
            />
          </div>
        </div>
        <div className="printreceipt-input-row">
          <p>
            <strong>TenderType:</strong>
          </p>
          <select
            onChange={(e) => handleChange_Input(e, setCurrentTenderType)}
            onKeyDown={handleKeyDown}
          >
            <option value=""></option>
            <option value="CASH">CASH</option>
            <option value="EFTPOS">EFTPOS</option>
            <option value="GIFTCARD">GIFT CARD</option>
            <option value="CREDITVOUCHER">CREDIT VOUCHER</option>
            <option value="ZIPPAY">ZIPPAY INTEGRATION</option>
            <option value="Zip">ZIPPAY TYRO</option>
            <option value="Alipay">ALIPAY</option>
          </select>
        </div>
      </div>
      <div className="printreceipt-body-containter">
        <div>
          <p className="error-message">{MenuResponse}</p>
          {Transactions.length === 0 ? (
            <p className="error-message">There are currently no transactions found.</p>
          ) : (
            <table className="itemdetails-table">
              <thead>
                <tr
                  className="btnHover"
                  onClick={handleClick_HeaderRow}
                >
                  <th colname="Date">Date</th>
                  <th
                    colname="POSNo"
                    sortorder={sortOrder}
                  >
                    POS Number
                    {sortColumn === "POSNo" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="ItemCount"
                    sortorder={sortOrder}
                  >
                    Item Count
                    {sortColumn === "ItemCount" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="TenderCount"
                    sortorder={sortOrder}
                  >
                    Tender Count
                    {sortColumn === "TenderCount" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="Total"
                    sortorder={sortOrder}
                  >
                    Total
                    {sortColumn === "Total" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="ReceiptNo"
                    sortorder={sortOrder}
                  >
                    Receipt Number
                    {sortColumn === "ReceiptNo" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="StoreName"
                    sortorder={sortOrder}
                  >
                    Store
                    {sortColumn === "StoreName" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontSize: 16 }}>
                {Transactions.reduce(function (filtered, transaction, index) {
                  if (searchType === "SIMPLE") {
                    if (
                      transaction.ReceiptNo.includes(currentTransactionReceiptNo) ||
                      transaction.TransactionNo.includes(currentTransactionReceiptNo)
                    ) {
                      var tableRow = (
                        <tr
                          key={index}
                          className="btnHover background-white"
                          receiptno={transaction.ReceiptNo}
                          onClick={() => handleClick_Row(transaction.ReceiptNo)}
                        >
                          <td>{ConvertDateFormat(transaction.Date)}</td>
                          <td>{transaction.POSNo}</td>
                          <td>{transaction.ItemCount}</td>
                          <td>{transaction.TenderCount}</td>
                          <td>{convertToCurrency(transaction.Total, "$")}</td>
                          <td>{transaction.ReceiptNo}</td>
                          <td>{transaction.StoreName}</td>
                        </tr>
                      );
                      filtered.push(tableRow);
                    }
                  } else if (searchType === "ADVANCED") {
                    if (
                      transaction.ReceiptNo.includes(currentReceiptNo) &&
                      transaction.POSNo.includes(currentPOSNo.toUpperCase()) &&
                      transaction.StoreName.includes(currentStore)
                    ) {
                      let itemArray = transaction.Items.split(";");
                      let tenderTypeArray =
                        transaction.TenderType === "" ? [] : transaction.TenderType.split(",");

                      let itemMatchCount = 0;
                      let tenderTypeMatchCount = 0;

                      itemArray.forEach((item) => {
                        if (item.includes(currentItemNo)) {
                          itemMatchCount++;
                        }
                      });

                      tenderTypeArray.forEach((item) => {
                        if (item.includes(currentTenderType)) {
                          tenderTypeMatchCount++;
                        }
                      });

                      if (itemMatchCount > 0 && tenderTypeMatchCount > 0) {
                        if (
                          (currentMinAmount === "" && currentMaxAmount === "") ||
                          (parseFloat(transaction.Total) >= parseFloat(currentMinAmount) &&
                            parseFloat(transaction.Total) <= parseFloat(currentMaxAmount))
                        )
                          var tableRow = (
                            <tr
                              key={index}
                              className="btnHover background-white"
                              receiptno={transaction.ReceiptNo}
                              onClick={() => handleClick_Row(transaction.ReceiptNo)}
                            >
                              <td>{ConvertDateFormat(transaction.Date)}</td>
                              <td>{transaction.POSNo}</td>
                              <td>{transaction.ItemCount}</td>
                              <td>{transaction.TenderCount}</td>
                              <td>{convertToCurrency(transaction.Total, "$")}</td>
                              <td>{transaction.ReceiptNo}</td>
                              <td>{transaction.StoreName}</td>
                            </tr>
                          );
                        filtered.push(tableRow);
                      }
                    }
                  }

                  return filtered;
                }, [])}
              </tbody>
            </table>
          )}
          <PopupEmail
            show={showEmail}
            setShow={setShowEmail}
            receiptNo={selectedReceiptNo}
            receipt={currentPrintReceiptString}
            setMenuResponse={setMenuResponse}
          />
        </div>
        <div>
          <pre>{currentReceipt}</pre>
        </div>
      </div>
      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={blue}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
        <FooterButton
          button="Email"
          colour={blue}
          onClick={handleClick_Email}
        />
      </div>
    </div>
  );
};
