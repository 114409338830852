import React, { useState, useEffect } from "react";
import { Create } from "./Create";
import { Edit } from "./Edit";
import { Menu } from "./Menu";

export const StaffManageBody = () => {
  const [page, setPage] = useState("edit");
  const [MenuResponse, setMenuResponse] = useState("");

  const menuPage = (
    <Menu
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
    />
  );

  const createPage = (
    <Create
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
    />
  );

  const editPage = (
    <Edit
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "create":
        return createPage;
      case "edit":
        return editPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
