import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { runFetch } from "../../functions/RunFetch";

export const PopupChangePassword = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const refPassword = useRef();

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleShow = () => {
    setPopupResponse("");
    refPassword.current.focus();
  };

  const handleSubmit = async () => {
    const resObj = await runFetch(`${spURL}Handheld_Functions_Manage_Password_Update`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: props.currentUser.UserID,
          Password: refPassword.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table[0].OutputString !== undefined) {
          handleClose();
          props.setResponse(spResponse.Table[0].OutputString);
          props.fetchData(props.currentUser.UserID);
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>UserID:</strong>
              </p>
              <p>{props.currentUser.UserID}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Name:</strong>
              </p>
              <p>{`${props.currentUser.FirstName} ${props.currentUser.LastName}`}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Current Password:</strong>
              </p>
              <p>{props.currentUser.Password}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>New Password:</strong>
              </p>
              <input
                type="text"
                id="handheldpermission-password"
                ref={refPassword}
              />
            </div>
            <p className="error-messsage">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
