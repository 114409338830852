import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { orange, purple, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { runFetch } from "../../functions/RunFetch";
import { useRef } from "react";
import { PopupItemDetails } from "./PopupItemDetails";
import store from "../../store";

export const SSCCSplitBody = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [PageResponse, setPageResponse] = useState("");

  const [SSCC, setSSCC] = useState("");
  const [SSCCSplitTable, setSSCCSplitTable] = useState([]);

  const [SelectedItemNo, setSelectedItemNo] = useState("");
  const [showItemDetails, setShowItemDetails] = useState(false);

  const refSSCC = useRef();

  const handleKeyDown_SSCC = async () => {
    setPageResponse("");

    const resObj = await runFetch(`${spURL}Handheld_SSCCSplit_List_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          SSCC: SSCC,
        }),
      }),
    });

    if (resObj.response === null) {
      setPageResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setSSCCSplitTable(
          spResponse.Table.map((item) => ({
            ItemNo: item.ItemNo,
            Description: item.Description,
            Department: item.Department,
            Carton: item.Carton,
            All: item.All,
            Back: item.Back === 0 ? "" : item.Back,
            Front: item.Front === 0 ? "" : item.Front,
            Shop: item.Shop === 0 ? "" : item.Shop,
          }))
        );
      } else {
        setPageResponse("ERROR!: SSCC items not found.");
        setSSCCSplitTable([]);
      }
    }

    refSSCC.current.select();
  };

  const handleClick_Row = (_itemno) => {
    setSelectedItemNo(_itemno);
    setShowItemDetails(true);
  };

  const handleClick_Split = async () => {
    const resObj = await runFetch(`${spURL}Handheld_SSCCSplit_Split_Process`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          SSCC: SSCC,
        }),
      }),
    });
    if (resObj.response === null) {
      setPageResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setPageResponse(spResponse.Table[0].OutputString);
        setSSCCSplitTable([]);
        setSSCC("");
        refSSCC.current.value = "";
        refSSCC.current.focus();
      }
    }
  };

  useEffect(() => {
    if (refSSCC.current) {
      refSSCC.current.focus();
    }
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>SSCC</strong>
            </p>
            <input
              type="text"
              onChange={(e) => {
                setPageResponse("");
                handleChange_Input(e, setSSCC);
              }}
              onKeyDown={(e) => OnEnterKeyDown(e, handleKeyDown_SSCC)}
              ref={refSSCC}
            />
          </div>
          <button
            className="signin-login-button"
            id="weborders-enter"
            onClick={handleKeyDown_SSCC}
          >
            Go
          </button>
          <p className="error-message">{PageResponse}</p>
          {SSCCSplitTable.length > 0 ? (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th rowSpan={2}>Item</th>
                  <th colSpan={2}>Carton</th>
                  <th rowSpan={2}>Back</th>
                  <th rowSpan={2}>Front</th>
                  <th rowSpan={2}>Shop</th>
                </tr>
                <tr>
                  <th>This SSCC</th>
                  <th>This Job</th>
                </tr>
              </thead>
              <tbody>
                {SSCCSplitTable.map((item, index) => (
                  <tr
                    key={index}
                    onClick={(e) => handleClick_Row(item.ItemNo)}
                  >
                    <td style={{ padding: 0, fontSize: 10 }}>
                      <p>{item.ItemNo}</p>
                      <p>
                        <strong>{item.Description}</strong>
                      </p>
                      <p>{item.Department}</p>
                    </td>
                    <td>{item.Carton}</td>
                    <td>{item.All}</td>
                    <td>{item.Back}</td>
                    <td>{item.Front}</td>
                    <td>{item.Shop}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </div>

        <PopupItemDetails
          show={showItemDetails}
          setShow={setShowItemDetails}
          ItemNo={SelectedItemNo}
          setItemNo={setSelectedItemNo}
        />
      </div>
      <div className="footer-container menu-container">
        <Link to="/WHReceived">
          <FooterButton
            button="Back"
            colour={orange}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
        <FooterButton
          button="Split"
          colour={orange}
          class={SSCC !== "" && SSCCSplitTable.length > 0 ? null : "disabled"}
          onClick={handleClick_Split}
        />
      </div>
    </div>
  );
};
