import { useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { orange, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";

export const Check = (props) => {
  const [PODCheckTable, setPODCheckTable] = useState([]);

  const refPODCode = useRef();

  const handleClick = async () => {
    const resObj = await runFetch(`${spURL}POD_Check`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          PODCode: refPODCode.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      props.setPODCodeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setPODCheckTable(spResponse.Table);
      }
    }
  };

  const handleClick_Back = () => {
    props.setPODCodeResponse("");
    props.setPage("menu");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          <div className="input-row">
            <p>
              <strong>POD:</strong>
            </p>
            <input
              type="text"
              id="podcode-check-podcode"
              required
              autoFocus
            />
          </div>
          <button
            className="signin-login-button btnHover"
            onClick={handleClick}
          >
            OK
          </button>
        </div>
        {PODCheckTable.length > 0 && (
          <table className="itemdetails-table">
            <thead>
              <tr>
                <th>Status</th>
                <th>Supplier</th>
                <th>InvoiceNo</th>
              </tr>
            </thead>
            <tbody>
              {PODCheckTable.map((item, index) => (
                <tr key={`tablerow-${index}`}>
                  <td value={item.Status}>{item.Status}</td>
                  <td value={item.Supplier}>{item.Supplier}</td>
                  <td value={item.InvoiceNo}>{item.InvoiceNo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
