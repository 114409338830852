import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { orange, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";

export const Update = (props) => {
  const [ddlSupplier, setDDLSupplier] = useState([]);
  const [UpdateResponse, setUpdateResponse] = useState("");

  const refPartialSupplier = useRef();
  const refSupplier = useRef();
  const refInvoiceNo = useRef();
  const refCartonsExpected = useRef();
  const refCartonsReceived = useRef();
  const refInvoiceValue = useRef();

  const handleChange_PartialSupplier = async () => {
    const resObj = await runFetch(`${spURL}POD_Supplier_List`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          PartialSup: refPartialSupplier.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      props.setPODCodeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setDDLSupplier(
          spResponse.Table.map((item, index) => (
            <option
              key={index}
              id={item.Supplier}
            >
              {item.Supplier}
            </option>
          ))
        );
      }
    }

    if (props.Type === "UPDATE") {
      document.getElementById("podcode-update-supplier").value = props.OriginalDetails.Supplier;
    }
  };

  const handleClick = async () => {
    if (isNaN(refInvoiceValue.current.value.replace("$", "").replace(",", ""))) {
      setUpdateResponse("ERROR!: Value of Invoice must be numeric.");
    } else {
      const resObj = await runFetch(`${spURL}POD_Edit_Line_Update`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            PODCode: props.PODCode,
            Supplier: refSupplier.current.value,
            InvoiceNo: String(refInvoiceNo.current.value).trim(),
            Type: props.Type,
            OriginalSupplier: props.OriginalDetails.Supplier,
            OriginalInvoiceNo: String(props.OriginalDetails.InvoiceNo).trim(),
            CartonsExpected: String(refCartonsExpected.current.value).trim(),
            CartonsReceived: String(refCartonsReceived.current.value).trim(),
            QuantityUnitsInvoiced: 0,
            InvoiceValue_exGST: String(
              refInvoiceValue.current.value.replace("$", "").replace(",", "")
            ).trim(),
          }),
        }),
      });

      if (resObj.response === null) {
        setUpdateResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        props.setPage("edit_lines");
      }
    }
  };

  const handleClick_Back = () => {
    props.setPODCodeResponse("");
    props.setPage("edit_lines");
  };

  useEffect(() => {
    if (props.Type === "UPDATE") {
      refPartialSupplier.current.value = props.OriginalDetails.Supplier;
      refInvoiceNo.current.value = props.OriginalDetails.InvoiceNo;
      refCartonsExpected.current.value = props.OriginalDetails.CartonsExpected;
      refCartonsReceived.current.value = props.OriginalDetails.CartonsReceived;
      refInvoiceValue.current.value = props.OriginalDetails.InvoiceValue_exGST;

      handleChange_PartialSupplier();
    }
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="input-row">
          <p>
            <strong>POD:</strong>
          </p>
          <p>
            <strong>{props.PODCode}</strong>
          </p>
        </div>
        <div className="input-row">
          <p>
            <strong>Supplier:</strong>
          </p>
          <input
            type="text"
            onChange={handleChange_PartialSupplier}
            name="partialsupplier"
            id="podcode-update-partialsupplier"
            ref={refPartialSupplier}
          />
        </div>
        <div className="input-row">
          <p>
            <strong>Select:</strong>
          </p>
          <select
            name="supplier"
            id="podcode-update-supplier"
            ref={refSupplier}
          >
            {ddlSupplier}
          </select>
        </div>
        <div className="input-row">
          <p>
            <strong>InvoiceNo:</strong>
          </p>
          <input
            type="text"
            name="invoiceno"
            id="podcode-update-invoiceno"
            ref={refInvoiceNo}
          />
        </div>
        <div className="input-row">
          <p>
            <strong>Cartons/Pallets Expected:</strong>
          </p>
          <input
            type="text"
            name="cartonsexpected"
            id="podcode-update-cartonsexpected"
            ref={refCartonsExpected}
          />
        </div>
        <div className="input-row">
          <p>
            <strong>Cartons Received:</strong>
          </p>
          <input
            type="text"
            name="cartonsexpected"
            id="podcode-update-cartonsreceived"
            ref={refCartonsReceived}
          />
        </div>
        <div className="input-row">
          <p>
            <strong>Value of Invoice (ex GST):</strong>
          </p>
          <input
            type="text"
            name="invoicevalue"
            id="podcode-update-invoicevalue"
            ref={refInvoiceValue}
          />
        </div>
        <button
          className="signin-login-button btnHover"
          onClick={handleClick}
        >
          Update
        </button>
        <br />
        <p className="error-message">{UpdateResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
