import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { runFetch } from "../../functions/RunFetch";

export const PopupWFMKioskDetails = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const refUserName = useRef();
  const refPassword = useRef();
  const refPin = useRef();

  const cleanPopup = () => {
    setPopupResponse("");
    refUserName.current.value = "";
    refPassword.current.value = "";
    refPin.current.value = "";
  };

  const handleClose = () => {
    cleanPopup();
    props.setShow(false);
  };

  const handleShow = () => {
    setPopupResponse("");
    switch (props.WFMKioskAction) {
      case "EDIT":
        refUserName.current.value = props.StoreDetails.WFMKioskUserName;
        refPassword.current.value = props.StoreDetails.WFMKioskPassword;
        refPin.current.value = props.StoreDetails.WFMKioskPin;
        refUserName.current.select();
        break;
      case "ADD":
        cleanPopup();
        refUserName.current.select();
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    const resObj = await runFetch(
      `${spURL}Handheld_StoreList_StoreDetails_WFMKiosk_${props.WFMKioskAction}`,
      {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: props.SelectedStoreNo,
            UserName: refUserName.current.value,
            Password: refPassword.current.value,
            Pin: refPin.current.value,
          }),
        }),
      }
    );

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setResponse(spResponse.Table[0].OutputString);
        props.refreshStoreDetails();
        handleClose();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>UserName:</strong>
              </p>
              <input
                type="text"
                ref={refUserName}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Password:</strong>
              </p>
              <input
                type="text"
                ref={refPassword}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Pin:</strong>
              </p>
              <input
                type="text"
                ref={refPin}
              />
            </div>
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
