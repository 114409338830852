import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { MenuButton, FooterButton, ExcelFileButton } from "../../components/HomepageButton";
import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";

export const SmythsManageBody = (props) => {
  const [MenuResponse, setMenuResponse] = useState("");
  const [CurrentImportOption, setCurrentImportOption] = useState("EXISTING");

  const importExcel = async (file) => {
    var XLSX = require("xlsx");

    var reader = new FileReader();
    reader.onload = async function (e) {
      try {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });

        let completeDataParse = [];
        let completeSheetInfo = [];

        readedData.SheetNames.forEach((item) => {
          let ws = readedData.Sheets[item];

          let dataParse = XLSX.utils.sheet_to_json(ws, { header: 0, defval: "" });

          dataParse = dataParse.map((item) => ({
            ...item,
            SmythsItemNo: item.URL.substr(item.URL.length - 6),
          }));

          const sheetInfo = {
            SheetName: item,
            SheetData: dataParse,
          };

          completeSheetInfo = completeSheetInfo.concat(sheetInfo);
          completeDataParse = completeDataParse.concat(dataParse);
        });

        /* Convert array to json*/
        if (CurrentImportOption === "EXISTING") {
          const resObj = await runFetch(`${spURL}EComm_SmythsManage_ReorderNo_Lookup`, {
            method: "POST",
            body: new URLSearchParams({
              inputJSON: JSON.stringify(
                completeDataParse.map((item) => ({
                  SmythsItemNo: item.SmythsItemNo,
                }))
              ),
            }),
          });

          if (resObj.response === null) {
            setMenuResponse(resObj.exception);
          } else {
            let spResponse = JSON.parse(resObj.response);

            if (spResponse.Table !== undefined) {
              if (spResponse.Table.length > 0) {
                let matchedArr = [];
                matchedArr = spResponse.Table;

                completeSheetInfo.forEach((sheet) => {
                  sheet.SheetData = sheet.SheetData.map((item) => {
                    const itemMatched = matchedArr.find(
                      (i2) => i2.SmythsItemNo === item.SmythsItemNo
                    );

                    return itemMatched
                      ? {
                          ...item,
                          ["Toymate SKU"]: itemMatched.ItemNo,
                          ["Vendor Number"]: itemMatched.ReorderNumber,
                          ["SOH"]: itemMatched.SOH,
                          ["SOO"]: itemMatched.SOO,
                          ["SALES L4W"]: itemMatched["Sales L4W"],
                          ["Buyer"]: itemMatched.Buyer,
                          ["TM Category"]: itemMatched.Category,
                          ["TM SubCategory"]: itemMatched.SubCategory,
                          ["TM Supplier"]: itemMatched.Supplier,
                          ["TM Price Point"]: itemMatched.Price,
                          ["Number of Stores in Range"]: itemMatched["Number of Stores in Range"],
                          ["In Stock %"]: itemMatched["In Stock %"],
                          ["Web Listed (Y/N)"]: itemMatched["Web Listed (Y/N)"],
                          ["Comp Code Provided (Y/N)"]: itemMatched["Comp Code Provided (Y/N)"],
                        }
                      : item;
                  });
                });

                var workbook = XLSX.utils.book_new();

                const date_now = new Date().toLocaleDateString();
                const filename = `Smyths_${date_now}_Updated.xlsx`;

                //// Use this in the end to get rid of SmythsItemNo column
                completeSheetInfo.forEach((item) => {
                  delete item.SmythsItemNo;

                  var worksheet = XLSX.utils.json_to_sheet(item.SheetData);
                  XLSX.utils.book_append_sheet(workbook, worksheet, item.SheetName);
                });

                await XLSX.writeFileXLSX(workbook, filename);
              } else {
                setMenuResponse("ERROR!: No matches found.");
              }
            } else {
              setMenuResponse("ERROR!: Server error.");
            }
          }
        } else if (CurrentImportOption === "UPDATE") {
          completeDataParse = completeDataParse.filter(
            (data) => data["Vendor Number"] !== "" || data["Comp Code Provided (Y/N)"] !== ""
          );

          const resObj = await runFetch(`${spURL}EComm_SmythsManage_ReorderNo_Update`, {
            method: "POST",
            body: new URLSearchParams({
              inputJSON: JSON.stringify(
                completeDataParse.map((item) => ({
                  SmythsItemNo: item.SmythsItemNo,
                  VendorNumber: item["Vendor Number"],
                  CompCodeProvided: item["Comp Code Provided (Y/N)"],
                }))
              ),
            }),
          });

          if (resObj.response === null) {
            setMenuResponse(resObj.exception);
          } else {
            let spResponse = JSON.parse(resObj.response);

            if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
              setMenuResponse(spResponse.Table[0].OutputString);
            } else {
              setMenuResponse("ERROR!: Server error.");
            }
          }
        } else {
          setMenuResponse("ERROR!: No option specified.");
        }
      } catch (error) {
        setMenuResponse(error.message);
      }
    };
    reader.readAsBinaryString(file);

    document.getElementById("imported-excel").value = "";
  };

  const handleChange_Import = (e) => {
    setMenuResponse("");

    var files = e.target.files,
      f = files[0];

    if (e.target.value.includes(".xlsx") || e.target.value.includes(".xls")) {
      importExcel(f);
    } else {
      setMenuResponse("ERROR!: File type not supported. XLSX or XLS filetype only.");
    }
  };

  const handleChange_UploadOption_EXISTING = () => {
    setCurrentImportOption("EXISTING");
    document.getElementById("smythsmanage-radio-existing").checked = true;
  };

  const handleChange_UploadOption_UPDATE = () => {
    setCurrentImportOption("UPDATE");
    document.getElementById("smythsmanage-radio-update").checked = true;
  };

  return (
    <div className="page-container disable-select">
      <div className="desktop-body-container">
        <h6>
          <strong>
            Click 'Upload' button to upload Excel file to get EXISTING data or UPDATE data.
          </strong>
        </h6>
        <br />
        <p>
          <strong>Upload Options:</strong>
        </p>
        <div className="searchtermmanage-top-row">
          <div
            className="blockedorders-input-row btnHover"
            onClick={handleChange_UploadOption_EXISTING}
          >
            <input
              type="radio"
              name="upload-option"
              id="smythsmanage-radio-existing"
              defaultChecked
            />
            <p>
              <strong>Lookup from database</strong>
            </p>
          </div>
          <div
            className="blockedorders-input-row btnHover"
            onClick={handleChange_UploadOption_UPDATE}
          >
            <input
              type="radio"
              name="upload-option"
              id="smythsmanage-radio-update"
            />
            <p>
              <strong>Update database</strong>
            </p>
          </div>
        </div>
        <br />
        <div className="blockedorders-content-container">
          <div className="blockedorders-option-row">
            <ExcelFileButton
              id="imported-excel"
              onChange={handleChange_Import}
              title="Upload"
              colour={green}
              button="U"
            />
          </div>
          <br />
          <p className="error-message">{MenuResponse}</p>
        </div>
      </div>
      <div className="footer-container menu-container">
        <Link to="/EComm">
          <FooterButton
            button="Back"
            colour={green}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
