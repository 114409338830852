import React, { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { spURL } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

import { Barcode } from "./Barcode";
import { Details } from "./Details";

export const MerchandisingBody = () => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [page, setPage] = useState("barcode");
  const [MerchandisingResponse, setMerchandisingResponse] = useState("");

  const emptyItemDetails = {
    ExtendedDescription: "",
    ItemNo: "",
    ReorderNumber: "",
    Description: "",
    Supplier: "",
    Department: "",
    Category: "",
    SubCategory: "",
    Price: "",
    MSRP: "",
    ImageURL: "",
    WebURL: "",
    SOH: "",
    MerchInstruction: "",
    LastReceivedAmount: "",
    MerchSpot: "",
    MerchAreaAmount: "",
    YouHadAmount: "",
    IsOHSTagNeeded: "",
    WebOrderTotalQuantity: "",
    WebOrderOutstandingQuantity: "",
  };
  const [ItemDetails, setItemDetails] = useState(emptyItemDetails);

  const emptyMerchDetails = {
    BayLocation: "",
    AisleNo: "",
    FrontSectionQuantity: "",
    LeadSaleQuantity: "",
    StandardShelfQuantity: "",
    DepartmentExcessQuantity: "",
    StoreRoomBehindCounterQuantity: "",
  };

  const [MerchSpotDetails_1, setMerchSpotDetails_1] = useState(emptyMerchDetails);

  const cleanItemDetails = () => {
    setItemDetails(emptyItemDetails);
    setMerchSpotDetails_1(emptyMerchDetails);
  };

  const exeBarcodeClick = async (barcode) => {
    setMerchandisingResponse("");

    const resObj = await runFetch(`${spURL}Handheld_Merchandising_Details_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Barcode: barcode,
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setPage("barcode");
      setMerchandisingResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);
      if (spResponse.Table !== undefined) {
        setItemDetails((values) => ({
          ...values,
          ExtendedDescription: spResponse.Table[0].ExtendedDescription,
          ItemNo: spResponse.Table[0].ItemNo,
          ReorderNumber: spResponse.Table[0].ReorderNumber,
          Description: spResponse.Table[0].Description,
          Supplier: spResponse.Table[0].Supplier,
          Department: spResponse.Table[0].Department,
          Category: spResponse.Table[0].Category,
          SubCategory: spResponse.Table[0].SubCategory,
          Price: spResponse.Table[0].Price,
          MSRP: spResponse.Table[0].MSRP,
          ImageURL: spResponse.Table[0].ImageURL,
          WebURL: spResponse.Table[0].WebURL,
          SOH: spResponse.Table[0].SOH,
          SOO: spResponse.Table[0].SOO,
          SOT: spResponse.Table[0].SOT,
          MerchInstruction: spResponse.Table[0].MerchInstruction,
          IsOHSTagNeeded: spResponse.Table[0].IsOHSTagNeeded,
          MerchSpot: spResponse.Table[0].MerchSpot,
          MerchAreaAmount: spResponse.Table[0].MerchAreaAmount,
          WebOrderTotalQuantity: spResponse.Table[0].WebOrderTotalQuantity,
          WebOrderOutstandingQuantity: spResponse.Table[0].WebOrderOutstandingQuantity,
        }));
      }

      if (spResponse.Table1 !== undefined) {
        setMerchSpotDetails_1({
          BayLocationStandard: spResponse.Table1[0].BayLocationStandard,
          BayLocationLead: spResponse.Table1[0].BayLocationLead,
          AisleNo: spResponse.Table1[0].AisleNo,
          FrontSectionQuantity: spResponse.Table1[0].FrontSectionQuantity,
          LeadSaleQuantity: spResponse.Table1[0].LeadSaleQuantity,
          StandardShelfQuantity: spResponse.Table1[0].StandardShelfQuantity,
          DepartmentExcessQuantity: spResponse.Table1[0].DepartmentExcessQuantity,
          StoreRoomBehindCounterQuantity: spResponse.Table1[0].StoreRoomBehindCounterQuantity,
        });
      }

      setPage("details");
    }
  };

  const barcodePage = (
    <Barcode
      setPage={setPage}
      MerchandisingResponse={MerchandisingResponse}
      setMerchandisingResponse={setMerchandisingResponse}
      exeBarcodeClick={exeBarcodeClick}
    />
  );

  const detailsPage = (
    <Details
      setPage={setPage}
      cleanItemDetails={cleanItemDetails}
      MerchandisingResponse={MerchandisingResponse}
      setMerchandisingResponse={setMerchandisingResponse}
      ItemDetails={ItemDetails}
      MerchSpotDetails_1={MerchSpotDetails_1}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "barcode":
        return barcodePage;
      case "details":
        return detailsPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
