import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { orange } from "../../App";
import { MenuButton, FooterButton } from "../../components/HomepageButton";

export const Menu = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.WHReceivedResponse);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          <MenuButton
            onClick={() => props.setPage("create")}
            button="C"
            colour={orange}
            title="Create"
          />
          <MenuButton
            onClick={() => props.setPage("add")}
            button="A"
            colour={orange}
            title="Add"
          />
          <MenuButton
            onClick={() => props.setPage("receive")}
            button="R"
            colour={orange}
            title="Receive"
          />
        </div>
        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={orange}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
