import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { ContentButton, FooterButton, MenuButton } from "../../components/HomepageButton";
import { blue, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { PopupCreateEditMessage } from "./PopupCreateEditMessage";
import { runFetch } from "../../functions/RunFetch";
import { handleChange_Input } from "../../functions/HandleChangeInput";

export const POSMessageBody = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.MenuResponse);

  const [MessageTable, setMessageTable] = useState([]);

  const [currentStoreName, setCurrentStoreName] = useState("ALL");
  const [currentPOSTerminalNo, setCurrentPOSTerminalNo] = useState("");
  const [currentShowUnexpiredOnly, setCurrentShowUnexpiredOnly] = useState(true);

  const [ddlStore, setDDLStore] = useState([]);
  const [SelectedMessage, setSelectedMessage] = useState([]);

  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL}Handheld_POSMessage_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Source: "OPS",
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setMessageTable(
          spResponse.Table.map((item) => ({
            MessageID: item.MessageID,
            StoreName: item.StoreName,
            POSTerminalNo: item.POSTerminalNo,
            Message: item.Message,
            CreatedBy: item.CreatedBy,
            DateCreated: new Date(item.DateCreated),
            DateExpired: new Date(item.DateExpired),
            IsExpired: item.IsExpired,
            Background:
              item.IsExpired === "1" ? "btnHover background-salmon" : "btnHover background-white",
          }))
        );

        setDDLStore(
          spResponse.Table.map((item) => item.StoreName).filter(
            (value, index, self) => self.indexOf(value) === index
          )
        );
      }
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Create = () => {
    setMenuResponse("");
    setShowCreate(true);
  };

  const handleClick_Edit = () => {
    if (SelectedMessage.length === 1) {
      setMenuResponse("");
      setShowEdit(true);
    } else {
      setMenuResponse("ERROR!: Select only one row to edit");
    }
  };

  const handleClick_Delete = async () => {
    if (SelectedMessage.length === 0) {
      setMenuResponse("ERROR!: No message selected.");
    } else {
      const resObj = await runFetch(`${spURL}Handheld_POSMessage_Delete`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(
            SelectedMessage.map((item) => ({
              MessageID: item.MessageID,
            }))
          ),
        }),
      });

      if (resObj.response === null) {
        setMenuResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setMenuResponse(spResponse.Table[0].OutputString);
          pageLoad();
        }
      }
    }
  };

  const handleChange_ShowUnexpiredOnly = (event) => {
    setSelectedMessage([]);
    setCurrentShowUnexpiredOnly(event.target.checked);
  };

  return (
    <div className="unallocatedorders-page-container">
      <div className="unallocatedorders-top-row">
        <div className="blockedorders-input-row">
          <p>
            <strong>Store:</strong>
          </p>
          <select
            id="posmesssagemain-store"
            onChange={(e) => handleChange_Input(e, setCurrentStoreName)}
          >
            <option value="ALL">ALL</option>
            {ddlStore.length > 0 &&
              ddlStore.map((item, index) => (
                <option
                  value={item}
                  key={index}
                >
                  {item}
                </option>
              ))}
          </select>
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>POS:</strong>
          </p>
          <input
            type="text"
            autoComplete="off"
            onChange={(e) => handleChange_Input(e, setCurrentPOSTerminalNo)}
          />
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>Show unexpired only:</strong>
          </p>
          <input
            type="checkbox"
            defaultChecked={true}
            onChange={handleChange_ShowUnexpiredOnly}
          />
        </div>
      </div>
      <div className="desktop-body-container">
        <h4>
          <strong>POS Message List</strong>
        </h4>
        <p className="error-message">{MenuResponse}</p>
        {MessageTable &&
          (MessageTable.length === 0 ? (
            "No message in list.  "
          ) : (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>
                    <strong>Store</strong>
                  </th>
                  <th>
                    <strong>POS</strong>
                  </th>
                  <th>
                    <strong>Message</strong>
                  </th>
                  <th>
                    <strong>Created By</strong>
                  </th>
                  <th>
                    <strong>Date Created</strong>
                  </th>
                  <th>
                    <strong>Date Expired</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {MessageTable.reduce(function (filtered, order, index) {
                  if (
                    (currentStoreName === "ALL" || order.StoreName === currentStoreName) &&
                    (order.POSTerminalNo === "ALL" ||
                      order.POSTerminalNo.includes(currentPOSTerminalNo.toUpperCase())) &&
                    (!currentShowUnexpiredOnly || order.IsExpired === "0")
                  ) {
                    var tableRow = (
                      <tr
                        key={index}
                        className={
                          SelectedMessage.map((message) => message.MessageID).includes(
                            order.MessageID
                          )
                            ? "btnHover background-lightblue"
                            : order.Background
                        }
                        messageid={order.MessageID}
                        onClick={() => {
                          setMenuResponse("");
                          let newArr = SelectedMessage;

                          const wasMessageSelected = SelectedMessage.map(
                            (message) => message.MessageID
                          ).includes(order.MessageID);

                          if (wasMessageSelected) {
                            newArr = newArr.filter((item) => item.MessageID !== order.MessageID);
                          } else {
                            newArr.push(order);
                          }

                          setSelectedMessage(newArr);
                        }}
                      >
                        <td>{order.StoreName}</td>
                        <td>{order.POSTerminalNo}</td>
                        <td>{order.Message}</td>
                        <td>{order.CreatedBy}</td>
                        <td>{order.DateCreated.toLocaleString()}</td>
                        <td>{order.DateExpired.toLocaleString()}</td>
                      </tr>
                    );

                    filtered.push(tableRow);
                  }

                  return filtered;
                }, [])}
              </tbody>
            </table>
          ))}
        <br />
        <div className="blockedorders-option-row">
          <MenuButton
            button="C"
            title="Create"
            colour={blue}
            onClick={handleClick_Create}
          />
          <MenuButton
            button="E"
            title="Edit"
            colour={blue}
            onClick={handleClick_Edit}
          />
          <MenuButton
            button="D"
            title="Delete"
            colour={blue}
            onClick={handleClick_Delete}
          />
        </div>

        <PopupCreateEditMessage
          show={showCreate}
          setShow={setShowCreate}
          setMenuResponse={setMenuResponse}
          pageLoad={pageLoad}
          function="CREATE"
        />

        <PopupCreateEditMessage
          show={showEdit}
          setShow={setShowEdit}
          setMenuResponse={setMenuResponse}
          pageLoad={pageLoad}
          SelectedMessage={SelectedMessage}
          function="EDIT"
        />
      </div>
      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={blue}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
      </div>
    </div>
  );
};
