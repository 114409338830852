import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { HomepageButton, FooterButton } from "../components/HomepageButton";

import { purple, yellow, spURL, getAccessData } from "../App";
import { trackPromise } from "react-promise-tracker";
import store from "../store";

export const MarketingBody = () => {
  const [userid, setUserID] = store.useState("userid");
  const [Access, setAccess] = useState({});

  useEffect(() => {
    async function getAccess() {
      setAccess(await getAccessData(userid));
    }

    getAccess();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          {Access.CompetitionDraw ? (
            <HomepageButton
              link="/CompetitionDraw"
              button="CD"
              colour={purple}
              title="Competition Draw"
            />
          ) : (
            ""
          )}
          {Access.MarketingGiftCardCreate ? (
            <HomepageButton
              link="/MarketingGiftCardCreate"
              button="CGC"
              colour={purple}
              title="Create Gift Card"
            />
          ) : (
            ""
          )}
          {Access.WebsiteCMS ? (
            <HomepageButton
              link="/WebsiteCMS"
              button="CMS"
              colour={purple}
              title="Website CMS"
            />
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={purple}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
      </div>
    </div>
  );
};
