import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { useReactToPrint } from "react-to-print";
import { MenuButton } from "../../components/HomepageButton";
import { blue } from "../../App";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PopupWFMKioskDetails } from "./PopupWFMKioskDetails";
import { runFetch } from "../../functions/RunFetch";

export const PopupStoreDetails = (props) => {
  const [isPrinting, setIsPrinting] = useState(false);
  // We store the resolve Promise being used in `onBeforeGetContent` here
  const promiseResolveRef = useRef(null);

  const [PopupResponse, setPopupResponse] = useState("");

  const [SelectedDetails, setSelectedDetails] = useState("");

  const emptyStoreDetails = {
    StoreName: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    PostCode: "",
    IPAddress: "",
    PhoneNo: "",
    RICOHMACAddress: "",
    ZIPMerchantID: "",
    ZIPLocationID: "",
    WFMKioskUserName: "",
    WFMKioskPassword: "",
    WFMKioskPin: "",
  };
  const [StoreDetails, setStoreDetails] = useState(emptyStoreDetails);

  const [TYRODetails, setTYRODetails] = useState([]);
  const [LabelPrintersDetails, setLabelPrintersDetails] = useState([]);
  const [ScannersDetails, setScannersDetails] = useState([]);
  const [ReturnAddresses, setReturnAddresses] = useState([]);

  const [SelectedFrom, setSelectedFrom] = useState("LOGANHOLME");
  const [IsReturnLabel, setIsReturnLabel] = useState(false);

  const [ToAddressAttn, setToAddressAttn] = useState("Store Manager");
  const [FromAddressAttn, setFromAddressAttn] = useState("IT Support");

  const emptyAddress = {
    AddressAttn: "",
    AddressFrom: "",
    Address1: "",
    Address2: "",
    City: "",
    State: "",
    PostCode: "",
  };
  const [ToAddress, setToAddress] = useState(emptyAddress);
  const [FromAddress, setFromAddress] = useState(emptyAddress);

  const [showWFMKioskDetails, setShowWFMKioskDetails] = useState(false);
  const [WFMKioskAction, setWFMKioskAction] = useState("");

  const componentRef = useRef();
  const refIsReturnLabel = useRef();

  const print = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      let toAddress = {
        AddressAttn: ToAddressAttn,
        AddressFrom: StoreDetails.StoreName.toUpperCase(),
        Address1: StoreDetails.Address1,
        Address2: StoreDetails.Address2,
        City: StoreDetails.City,
        State: StoreDetails.State,
        PostCode: StoreDetails.PostCode,
      };

      let filteredFromAddress = ReturnAddresses.filter(
        (item) => item.AddressFor === SelectedFrom
      )[0];

      let fromAddress = {
        AddressAttn: FromAddressAttn,
        AddressFrom: filteredFromAddress.AddressFor.toUpperCase(),
        Address1: filteredFromAddress.Address1,
        Address2: filteredFromAddress.Address2,
        City: filteredFromAddress.City,
        State: filteredFromAddress.State,
        PostCode: filteredFromAddress.PostCode,
      };

      if (IsReturnLabel) {
        let tmp = toAddress;

        toAddress = fromAddress;
        fromAddress = tmp;
      }

      setToAddress(toAddress);
      setFromAddress(fromAddress);

      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      // Reset the Promise resolve so we can print again
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
  });

  const handleClose = () => {
    cleanPopup();
    props.pageLoad();
    props.setShow(false);
  };

  const handleShow = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreList_StoreDetails_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: props.StoreNo,
        }),
      }),
    });

    if (resObj.exception !== "") {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStoreDetails({
          StoreName: spResponse.Table[0].StoreName,
          Address1: spResponse.Table[0].Address1,
          Address2: spResponse.Table[0].Address2,
          City: spResponse.Table[0].City,
          State: spResponse.Table[0].State,
          PostCode: spResponse.Table[0].PostCode,
          IPAddress: spResponse.Table[0].IPAddress,
          PhoneNo: spResponse.Table[0].PhoneNo,
          RICOHSerialNo: spResponse.Table[0].RICOHSerialNo,
          RICOHMACAddress: spResponse.Table[0].RICOHMACAddress,
          ZIPMerchantID: spResponse.Table[0].ZIPMerchantID,
          ZIPLocationID: spResponse.Table[0].ZIPLocationID,
          WFMKioskUserName: spResponse.Table[0].WFMKioskUserName,
          WFMKioskPassword: spResponse.Table[0].WFMKioskPassword,
          WFMKioskPin: spResponse.Table[0].WFMKioskPin,
          CiscoBackupSIMTelcoProvider: spResponse.Table[0].CiscoBackupSIMTelcoProvider,
          CiscoBackupSIMNumber: spResponse.Table[0].CiscoBackupSIMNumber,
        });
      } else {
        setStoreDetails(emptyStoreDetails);
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setTYRODetails(
          spResponse.Table1.map((item) => ({
            POS: item.POS,
            MID: item.MID,
            TID: item.TID,
            SerialNo: item.SerialNo,
          }))
        );
      } else {
        setTYRODetails([]);
      }

      if (spResponse.Table2 !== undefined && spResponse.Table2.length > 0) {
        setLabelPrintersDetails(
          spResponse.Table2.map((item) => ({
            Printer: item.Printer,
            IPAddress: item.IPAddress,
          }))
        );
      } else {
        setLabelPrintersDetails([]);
      }

      if (spResponse.Table3 !== undefined && spResponse.Table3.length > 0) {
        setScannersDetails(
          spResponse.Table3.map((item) => ({
            SerialNo: item.SerialNo,
            DeviceName: item.DeviceName,
          }))
        );
      } else {
        setScannersDetails([]);
      }
      if (spResponse.Table4 !== undefined && spResponse.Table4.length > 0) {
        setReturnAddresses(
          spResponse.Table4.map((item) => ({
            AddressFor: item.AddressFor,
            Address1: item.Address1,
            Address2: item.Address2,
            City: item.City,
            State: item.State,
            PostCode: item.PostCode,
          }))
        );
      } else {
        setReturnAddresses([]);
      }
    }
  };

  const cleanPopup = () => {
    setPopupResponse("");

    setSelectedDetails("");
    setStoreDetails(emptyStoreDetails);
    setTYRODetails([]);
    setLabelPrintersDetails([]);
    setScannersDetails([]);

    setReturnAddresses([]);
    setSelectedFrom("LOGANHOLME");
    setIsReturnLabel(false);
    setToAddressAttn("Store Manager");
    setFromAddressAttn("IT Support");
    setToAddress(emptyAddress);
    setFromAddress(emptyAddress);

    setShowWFMKioskDetails(false);
    setWFMKioskAction("");
  };

  // We watch for the state to change here, and for the Promise resolve to be available
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Body>
          <div>
            <div>
              <p className="error-message">{PopupResponse}</p>
              <br />
              <div className="storetransfer-input-row">
                <p>
                  <strong>Store:</strong>
                </p>
                <p>{StoreDetails.StoreName}</p>
              </div>
              <div className="storetransfer-input-row">
                <p>
                  <strong>Details</strong>
                </p>
                <select
                  onChange={(e) => {
                    setPopupResponse("");
                    handleChange_Input(e, setSelectedDetails);
                  }}
                >
                  <option value=""></option>
                  <option value="STOREADDRESS">Store Address</option>
                  <option value="MISC">Misc</option>
                </select>
              </div>
              <br />
              {SelectedDetails === "STOREADDRESS" ? (
                <>
                  <h5>
                    <strong>Store Address</strong>
                  </h5>
                  <p>{StoreDetails.Address1}</p>
                  <p>{StoreDetails.Address2}</p>
                  <p>
                    {StoreDetails.City} {StoreDetails.State} {StoreDetails.PostCode}
                  </p>
                  <hr />
                  <div className="printstoreaddress-settings-container">
                    <span>
                      <strong>From: </strong>
                    </span>
                    <div className="printstoreaddress-settings-radio-container">
                      <input
                        type="radio"
                        id="printstoreaddress-from-logan"
                        name="from-address"
                        value="LOGANHOLME"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedFrom("LOGANHOLME");
                          }
                        }}
                        defaultChecked
                      />
                      <label htmlFor="printstoreaddress-from-logan">Loganholme</label>
                    </div>
                    <div className="printstoreaddress-settings-radio-container">
                      <input
                        type="radio"
                        id="printstoreaddress-from-ho"
                        name="from-address"
                        value="HO"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedFrom("HO");
                          }
                        }}
                      />
                      <label htmlFor="printstoreaddress-from-ho">HO</label>
                    </div>
                  </div>
                  <div className="printstoreaddress-settings-container">
                    <label htmlFor="printstoreaddress-from-logan">
                      <strong>Is Return Label?</strong>
                    </label>
                    <input
                      type="checkbox"
                      id="printstoreaddress-returnlabel"
                      name="returnlabel"
                      ref={refIsReturnLabel}
                      onChange={(e) => setIsReturnLabel(e.target.checked)}
                    />
                  </div>
                  <div className="storetransfer-input-row">
                    <p>
                      <strong>Address To Attn: </strong>
                    </p>
                    <input
                      type="text"
                      onChange={(e) => setToAddressAttn(e.target.value)}
                      defaultValue="Store Manager"
                    />
                  </div>
                  <div className="storetransfer-input-row">
                    <p>
                      <strong>Address From Attn: </strong>
                    </p>
                    <input
                      type="text"
                      onChange={(e) => setFromAddressAttn(e.target.value)}
                      defaultValue="IT Support"
                    />
                  </div>
                  <br />
                  <MenuButton
                    title="Print"
                    colour={blue}
                    button="P"
                    onClick={print}
                  />
                </>
              ) : null}
              {SelectedDetails === "TYRO" ? (
                <>
                  <h5>
                    <strong>TYRO</strong>
                  </h5>
                  <table className="itemdetails-table">
                    <thead>
                      <tr>
                        <th>POS</th>
                        <th>MID</th>
                        <th>TID</th>
                        <th>SerialNo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {TYRODetails.map((item, index) => (
                        <tr key={index}>
                          <td>{item.POS}</td>
                          <td>{item.MID}</td>
                          <td>{item.TID}</td>
                          <td>{item.SerialNo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : null}
              {SelectedDetails === "LABELPRINTERS" ? (
                <>
                  <h5>
                    <strong>Label Printers</strong>
                  </h5>
                  <table className="itemdetails-table">
                    <thead>
                      <tr>
                        <th>Printer</th>
                        <th>IP Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {LabelPrintersDetails.map((item, index) => (
                        <tr key={index}>
                          <td>{item.Printer}</td>
                          <td>{item.IPAddress}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : null}
              {SelectedDetails === "SCANNERS" ? (
                <>
                  <h5>
                    <strong>Scanners</strong>
                  </h5>
                  <table className="itemdetails-table">
                    <thead>
                      <tr>
                        <th>Device Name</th>
                        <th>Serial No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ScannersDetails.map((item, index) => (
                        <tr key={index}>
                          <td>{item.DeviceName}</td>
                          <td>{item.SerialNo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : null}
              {SelectedDetails === "MISC" ? (
                <>
                  <h5>
                    <strong>Store</strong>
                  </h5>
                  <div className="storetransfer-input-row">
                    <p>
                      <strong>IP Address</strong>
                    </p>
                    <p>{StoreDetails.IPAddress}</p>
                  </div>
                  <div className="storetransfer-input-row">
                    <p>
                      <strong>Phone</strong>
                    </p>
                    <a href={`tel:${StoreDetails.PhoneNo}`}>
                      <p>{StoreDetails.PhoneNo}</p>
                    </a>
                  </div>
                  <div className="storetransfer-input-row">
                    <p>
                      <strong>Cisco SIM Provider</strong>
                    </p>
                    <p>{StoreDetails.CiscoBackupSIMTelcoProvider}</p>
                  </div>
                  <div className="storetransfer-input-row">
                    <p>
                      <strong>Cisco SIM Number</strong>
                    </p>
                    <p>{StoreDetails.CiscoBackupSIMNumber}</p>
                  </div>
                  <br />
                  <h5>
                    <strong>RICOH</strong>
                  </h5>
                  <div className="storetransfer-input-row">
                    <p>
                      <strong>Serial Number</strong>
                    </p>
                    <p>{StoreDetails.RICOHSerialNo}</p>
                  </div>
                  <div className="storetransfer-input-row">
                    <p>
                      <strong>MAC Address</strong>
                    </p>
                    <p>{StoreDetails.RICOHMACAddress}</p>
                  </div>
                  <br />
                  <h5>
                    <strong>ZIP</strong>
                  </h5>
                  <div className="storetransfer-input-row">
                    <p>
                      <strong>Merchant ID</strong>
                    </p>
                    <p>{StoreDetails.ZIPMerchantID}</p>
                  </div>
                  <div className="storetransfer-input-row">
                    <p>
                      <strong>Location ID</strong>
                    </p>
                    <p>{StoreDetails.ZIPLocationID}</p>
                  </div>
                  <br />
                  <div className="popup-storelist-wfmkiosk-title-container">
                    <h5 className="wfmkiosk-title">
                      <strong>WFM Kiosk</strong>
                    </h5>
                    <div className="wfmkiosk-action-container">
                      <FontAwesomeIcon
                        className={`btnHover popup-icon ${
                          StoreDetails.WFMKioskUserName !== "" && "disabled"
                        }`}
                        icon="fa-regular fa-square-plus"
                        onClick={(e) => {
                          if (!e.target.classList.contains("disabled")) {
                            setPopupResponse("");
                            setWFMKioskAction("ADD");
                            setShowWFMKioskDetails(true);
                          }
                        }}
                      />

                      <FontAwesomeIcon
                        className={`btnHover popup-icon ${
                          StoreDetails.WFMKioskUserName === "" && "disabled"
                        }`}
                        icon="fa-regular fa-pen-to-square"
                        onClick={(e) => {
                          if (!e.target.classList.contains("disabled")) {
                            setPopupResponse("");
                            setWFMKioskAction("EDIT");
                            setShowWFMKioskDetails(true);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="storetransfer-input-row">
                    <p>
                      <strong>UserName</strong>
                    </p>
                    <p>{StoreDetails.WFMKioskUserName}</p>
                  </div>
                  <div className="storetransfer-input-row">
                    <p>
                      <strong>Password</strong>
                    </p>
                    <p>{StoreDetails.WFMKioskPassword}</p>
                  </div>
                  <div className="storetransfer-input-row">
                    <p>
                      <strong>Pin</strong>
                    </p>
                    <p>{StoreDetails.WFMKioskPin}</p>
                  </div>
                </>
              ) : null}
            </div>

            <div style={{ display: "none" }}>
              <ComponentToPrint
                ref={componentRef}
                ToAddress={ToAddress}
                FromAddress={FromAddress}
                ToAddressAttn={ToAddressAttn}
                FromAddressAttn={FromAddressAttn}
              />
            </div>

            <PopupWFMKioskDetails
              show={showWFMKioskDetails}
              setShow={setShowWFMKioskDetails}
              WFMKioskAction={WFMKioskAction}
              SelectedStoreNo={props.StoreNo}
              StoreDetails={StoreDetails}
              setResponse={setPopupResponse}
              refreshStoreDetails={handleShow}
            />
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "92.5vw",
          height: "95vh",
          padding: "20px 13px",
        }}
      >
        <h1 style={{ textAlign: "left" }}>
          <strong>Attn: {props.ToAddress.AddressAttn}</strong>
        </h1>
        <h1 style={{ textAlign: "left" }}>
          <strong>TOYMATE {props.ToAddress.AddressFrom}</strong>
        </h1>
        <h1 style={{ textAlign: "left" }}>
          <strong>{props.ToAddress.Address1}</strong>
        </h1>
        <h1 style={{ textAlign: "left" }}>
          <strong>{props.ToAddress.Address2}</strong>
        </h1>
        <h1 style={{ textAlign: "left" }}>
          <strong>
            {props.ToAddress.City} {props.ToAddress.State} {props.ToAddress.PostCode}
          </strong>
        </h1>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "right",
          }}
        >
          <div
            style={{
              flex: "0 0 50%",
              textAlign: "left",
            }}
          >
            <h4>
              <strong>From:</strong>
            </h4>
            <h4>
              <strong>{props.FromAddress.AddressAttn}</strong>
            </h4>
            <h4>
              <strong>TOYMATE {props.FromAddress.AddressFrom}</strong>
            </h4>
            <h4>
              <strong>{props.FromAddress.Address1}</strong>
            </h4>
            <h4>
              <strong>{props.FromAddress.Address2}</strong>
            </h4>
            <h4>
              <strong>
                {props.FromAddress.City} {props.FromAddress.State} {props.FromAddress.PostCode}
              </strong>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
});
