import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const PopupDetails = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [ConfirmResponse, setConfirmResponse] = useState("");

  const [JobNoDetails, setJobNoDetails] = useState({
    From: "",
    Carrier: "",
    PickupDateTime: "",
    DeliveryWindow: "",
    PalletAmount: "",
    Note: "",
  });

  const handleClose = () => props.setShow(false);

  const handleShow = async () => {
    setConfirmResponse("");

    const resObj = await runFetch(`${spURL}Handheld_WHReceived_JobNo_Details_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          JobNo: props.JobNo,
        }),
      }),
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table.length > 0) {
          setJobNoDetails({
            From: spResponse.Table[0].From,
            Carrier: spResponse.Table[0].Carrier,
            PickupDateTime: new Date(spResponse.Table[0].PickupDateTime).toLocaleString(),
            DeliveryDate: new Date(spResponse.Table[0].DeliveryDate).toLocaleDateString(),
            DeliveryWindow: spResponse.Table[0].DeliveryWindow,
            PalletAmount: spResponse.Table[0].PalletAmount,
            Note: spResponse.Table[0].Note,
          });
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>From</strong>
              </p>
              <p>{JobNoDetails.From}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Carrier</strong>
              </p>
              <p>{JobNoDetails.Carrier}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Pickup Time</strong>
              </p>
              <p>{JobNoDetails.PickupDateTime}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Delivery Date</strong>
              </p>
              <p>{JobNoDetails.DeliveryDate}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Delivery Window</strong>
              </p>
              <p>{JobNoDetails.DeliveryWindow}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Pallet Amt</strong>
              </p>
              <p>{JobNoDetails.PalletAmount}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Note</strong>
              </p>
              <p>{JobNoDetails.Note}</p>
            </div>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
