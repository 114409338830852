import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { yellow, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";

export const Menu = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.Response);

  const handleClick_Menu_Quick = () => {
    props.setPage("quicklabel");
  };

  const handleClick_Menu_Merch = () => {
    props.setPage("merchlabel-menu");
  };

  const handleClick_Menu_Bay = () => {
    props.setPage("baylabel");
  };

  const handleClick_Menu_PriceChanges = () => {
    props.setPage("pricechanges-settings");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          <MenuButton
            onClick={handleClick_Menu_Quick}
            button="QL"
            colour={yellow}
            title="Quick Label"
          />
          <MenuButton
            onClick={handleClick_Menu_Merch}
            button="ML"
            colour={yellow}
            title="Merch Label"
          />
          <MenuButton
            onClick={handleClick_Menu_Bay}
            button="BL"
            colour={yellow}
            title="Bay Label"
          />
        </div>
        <div className="home-table">
          <MenuButton
            onClick={handleClick_Menu_PriceChanges}
            button="PC"
            colour={yellow}
            title="Price Changes"
          />
        </div>
        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={yellow}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
      </div>
    </div>
  );
};
