import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { green, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

import { PopupPickHistory } from "./PopupPickHistory";

export const LinesView = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [WebOrdersResponse, setWebOrdersResponse] = useState("");

  const [LinesTable, setLinesTable] = useState([]);

  const [show, setShow] = useState(false);

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_View_Line_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(
          props.CurrentPicks.map((item) => ({
            StoreNo: storeno,
            PickID: item.PickID,
            OrderID: item.OrderID,
          }))
        ),
      }),
    });

    if (resObj.response === null) {
      props.setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        let orderstatusbackground = "";

        switch (spResponse.Table[0].OrderStatus) {
          case "NEW":
            orderstatusbackground = "background-white";
            break;
          case "ASSIGNED":
            orderstatusbackground = "background-yellow";
            break;
          case "COMPLETED":
            orderstatusbackground = "background-lightgreen";
            break;
          case "ISSUES":
            orderstatusbackground = "background-orange";
            break;
          case "OOS":
            orderstatusbackground = "background-salmon";
            break;
          case "CUSTOMERSERVICE":
            orderstatusbackground = "background-salmon";
            break;
          case "VERIFIED":
            orderstatusbackground = "background-aquamarine";
            break;
        }

        setLinesTable(
          spResponse.Table.map((item) => {
            switch (item.Colour) {
              case "Yellow":
                return {
                  Product: item.Product,
                  ItemNo: item.ItemNo,
                  QtyRequired: item.QtyRequired,
                  QtyPicked: item.QtyPicked,
                  QtyVerified: item.QtyVerified,
                  Colour: item.Colour,
                  OrderStatus: item.OrderStatus,
                  OrderStatusBackground: orderstatusbackground,
                  Background: "btnHover background-yellow",
                };
              case "Green":
                return {
                  Product: item.Product,
                  ItemNo: item.ItemNo,
                  QtyRequired: item.QtyRequired,
                  QtyPicked: item.QtyPicked,
                  QtyVerified: item.QtyVerified,
                  Colour: item.Colour,
                  OrderStatus: item.OrderStatus,
                  OrderStatusBackground: orderstatusbackground,
                  Background: "btnHover background-lightgreen",
                };
              default:
                return {
                  Product: item.Product,
                  ItemNo: item.ItemNo,
                  QtyRequired: item.QtyRequired,
                  QtyPicked: item.QtyPicked,
                  QtyVerified: item.QtyVerified,
                  Colour: item.Colour,
                  OrderStatus: item.OrderStatus,
                  OrderStatusBackground: orderstatusbackground,
                  Background: "btnHover background-white",
                };
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Back = () => {
    props.setPage("view");
  };

  const handleClick_History = () => {
    setShow(true);
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          {LinesTable.length === 0 ? (
            <p className="error-message">No items found in order.</p>
          ) : (
            <>
              <p>
                <strong>
                  Order {props.CurrentPicks.map((item) => item.OrderID).toString()} Items List
                </strong>
              </p>
              <p className={LinesTable[0].OrderStatusBackground}>
                Status: {LinesTable[0].OrderStatus}
              </p>
              <table className="itemdetails-table">
                <thead>
                  <tr>
                    <th>SubCat/Desc/Item</th>
                    <th>Req</th>
                    <th>Pkd</th>
                    <th>Ver</th>
                  </tr>
                </thead>
                <tbody>
                  {LinesTable.map((item, index) => (
                    <tr
                      key={index}
                      className={item.Background}
                      colour={item.Colour}
                      itemno={item.ItemNo}
                      onClick={() => {
                        props.setCurrentItemNo(item.ItemNo);
                        props.setPage("item-view");
                      }}
                    >
                      <td dangerouslySetInnerHTML={{ __html: item.Product }}></td>
                      <td>{item.QtyRequired}</td>
                      <td>{item.QtyPicked}</td>
                      <td>{item.QtyVerified}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
        <p className="error-message">{WebOrdersResponse}</p>

        <PopupPickHistory
          show={show}
          setShow={setShow}
          CurrentPicks={props.CurrentPicks}
        />
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
        <FooterButton
          button="Hist"
          colour={green}
          onClick={handleClick_History}
        />
      </div>
    </div>
  );
};
