import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { orange, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const Receive = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [MenuResponse, setMenuResponse] = useState("");
  const [ddlDescription, setDDLDescription] = useState([]);
  const [detailsTable, setDetailsTable] = useState([]);

  const refDescription = useRef();

  const pageLoad = async () => {
    setDetailsTable("");

    const resObj = await runFetch(`${spURL}Handheld_Transfer_Receive_List`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      setDDLDescription(
        spResponse.Table.map((item, index, arr) => (
          <option
            key={index}
            value={item.TransferID}
          >
            {item.TransferID}
          </option>
        ))
      );
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Back = () => {
    setMenuResponse("");
    props.setPage("menu");
  };

  const handleClick_Details = async () => {
    const resObj = await runFetch(`${spURL}Handheld_Transfer_Receive_Detail`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Store: storeno,
          TransferID: refDescription.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      setDetailsTable(
        spResponse.Table.map((item, index) => (
          <tr key={index}>
            <td>{item.Item}</td>
            <td>{item.Description}</td>
            <td>{item.Quantity}</td>
          </tr>
        ))
      );
    }
  };

  const handleClick_Receive = async () => {
    setMenuResponse("");
    const resObj = await runFetch(`${spURL}Handheld_Transfer_Receive_Process`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Store: storeno,
          UserID: userid,
          TransferID: refDescription.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      setMenuResponse(spResponse.Table[0].OutputString);
      await pageLoad();
    }
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="storetransfer-input-row">
          <p>
            <strong>Transfer No</strong>
          </p>
          <select
            name="description"
            id="storetransfer-receive-description"
            ref={refDescription}
          >
            {ddlDescription}
          </select>
        </div>
        <button
          className="signin-login-button"
          onClick={handleClick_Details}
        >
          Details
        </button>

        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>
                <strong>Item</strong>
              </th>
              <th>
                <strong>Description</strong>
              </th>
              <th>
                <strong>Qty</strong>
              </th>
            </tr>
          </thead>
          <tbody>{detailsTable}</tbody>
        </table>

        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
        <FooterButton
          button="Rcv"
          colour={orange}
          onClick={handleClick_Receive}
        />
      </div>
    </div>
  );
};
