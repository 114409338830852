import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const StoreQuit = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [StoreQuitResponse, setStoreQuitResponse] = useState("");
  const [isStoreQuit, setIsStoreQuit] = useState(0);
  const [LastMarkdownDate, setLastMarkdownDate] = useState("");
  const [NextMarkdownDate, setNextMarkdownDate] = useState("");

  const refMarkdownPrice = useRef();

  const handleClose_SQ = () => {
    props.setItemDetailsResponse("");
    props.setShow(false);
  };

  const handleSubmit_SQ = async () => {
    props.setItemDetailsResponse("");

    if (isNaN(refMarkdownPrice.current.value) || isNaN(props.limit)) {
      setStoreQuitResponse("ERROR!: Price must be numeric");
    } else {
      if (parseFloat(refMarkdownPrice.current.value) < parseFloat(props.limit)) {
        setStoreQuitResponse("ERROR!: Price cannot be below " + props.limit);
      } else {
        const resObj = await runFetch(`${spURL}Handheld_StoreQuit_Process`, {
          method: "POST",
          body: new URLSearchParams({
            inputJSON: JSON.stringify({
              ItemNo: props.itemno,
              StoreID: storeno,
              Price: refMarkdownPrice.current.value,
            }),
          }),
        });

        if (resObj.response === null) {
          setStoreQuitResponse(resObj.exception);
        } else {
          let spResponse = JSON.parse(resObj.response);

          if (spResponse.Table !== undefined) {
            if (spResponse.Table[0].OutputString !== undefined) {
              window.location.href = `#/Labels?From=SQ&ItemNo=${
                props.itemno
              }&Response=${encodeURIComponent(spResponse.Table[0].OutputString)}`;
            }
          }
        }
      }
    }
  };

  const handleShow = () => {
    setIsStoreQuit(0);

    if (props.ItemType === "STOREQUIT") {
      setIsStoreQuit(1);
      refMarkdownPrice.current.value = convertToCurrency(props.limit, "");
    }

    if (props.MarkDownDate !== null) {
      setLastMarkdownDate(new Date(props.MarkDownDate).toLocaleDateString());
    }

    if (props.NextMarkDownDate !== null) {
      setNextMarkdownDate(ConvertDateFormat(props.NextMarkDownDate));
    }
  };

  return (
    <>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose_SQ}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <h3 className="itemdetails-sq-title background-black">
              <strong>MARKDOWN</strong>
            </h3>
            <p>
              <strong>Last: {LastMarkdownDate}</strong>
            </p>
            <p>
              <strong>Allowed: {NextMarkdownDate}</strong>
            </p>
            <p>
              <strong>Limit: {convertToCurrency(props.limit, "")}</strong>
            </p>

            {isStoreQuit ? (
              <>
                <h3 className="itemdetails-sq-title background-green">
                  <strong>ENTER MARKDOWN PRICE</strong>
                </h3>
                <input
                  className="itemdetails-sq-input"
                  type="text"
                  id="itemdetails-sq-markdown-price"
                  ref={refMarkdownPrice}
                  autoComplete="off"
                />
              </>
            ) : (
              <h3 className="itemdetails-sq-title background-red">
                <strong>ITEM IS NOT STORE QUIT</strong>
              </h3>
            )}
            <br />
            <p className="error-message">{StoreQuitResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose_SQ}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit_SQ}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
