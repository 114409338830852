import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, spURL_GetDDLCity } from "../../App";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";

export const PopupUpdateShipping = (props) => {
  const [ConfirmResponse, setConfirmResponse] = useState("");
  const [ddlCity, setDDLCity] = useState("");

  const refStreet1 = useRef();
  const refStreet2 = useRef();
  const refCity = useRef();
  const refZip = useRef();
  const refState = useRef();
  const refCountry = useRef();

  /* Helpers */
  const friendlyHttpStatus = {
    200: "OK",
    201: "Created",
    202: "Accepted",
    203: "Non-Authoritative Information",
    204: "No Content",
    205: "Reset Content",
    206: "Partial Content",
    300: "Multiple Choices",
    301: "Moved Permanently",
    302: "Found",
    303: "See Other",
    304: "Not Modified",
    305: "Use Proxy",
    306: "Unused",
    307: "Temporary Redirect",
    400: "Bad Request",
    401: "Unauthorized",
    402: "Payment Required",
    403: "Forbidden",
    404: "Not Found",
    405: "Method Not Allowed",
    406: "Not Acceptable",
    407: "Proxy Authentication Required",
    408: "Request Timeout",
    409: "Conflict",
    410: "Gone",
    411: "Length Required",
    412: "Precondition Required",
    413: "Request Entry Too Large",
    414: "Request-URI Too Long",
    415: "Unsupported Media Type",
    416: "Requested Range Not Satisfiable",
    417: "Expectation Failed",
    418: "I'm a teapot",
    429: "Too Many Requests",
    500: "Internal Server Error",
    501: "Not Implemented",
    502: "Bad Gateway",
    503: "Service Unavailable",
    504: "Gateway Timeout",
    505: "HTTP Version Not Supported",
  };

  const handleClose = () => props.setShow(false);

  const [Shipping, setShipping] = useState({
    ID: "",
    Name: "",
    Email: "",
    Phone: "",
    Street1: "",
    Street2: "",
    City: "",
    Zip: "",
    State: "",
    Country: "",
  });

  const handleShow = async () => {
    const resObj = await runFetch(`${spURL}FailedOrders_UpdateShippingAddress_Details`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          OrderID: props.OrderID,
        }),
      }),
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setShipping((values) => ({
          ...values,
          ID: spResponse.Table[0].id,
          Street1: spResponse.Table[0].street_1,
          Street2: spResponse.Table[0].street_2,
          City: spResponse.Table[0].city,
          Zip: spResponse.Table[0].zip,
          State: spResponse.Table[0].state,
          Country: spResponse.Table[0].country,
        }));

        refStreet1.current.value = spResponse.Table[0].street_1;
        refStreet2.current.value = spResponse.Table[0].street_2;
        refCity.current.value = spResponse.Table[0].city;
        refZip.current.value = spResponse.Table[0].zip;
        refState.current.value = spResponse.Table[0].state;
        refCountry.current.value = spResponse.Table[0].country;
      }
    }
    getDDLCity();
  };

  // get list of suburb from auspost api
  // POST request using fetch inside useEffect React hook

  async function getDDLCity() {
    let stateAPI = "";

    switch (refState.current.value.toUpperCase()) {
      case "NEW SOUTH WALES":
        stateAPI = "NSW";
        break;
      case "QUEENSLAND":
        stateAPI = "QLD";
        break;
      case "AUSTRALIAN CAPITAL TERRITORY":
        stateAPI = "ACT";
        break;
      case "VICTORIA":
        stateAPI = "VIC";
        break;
      case "TASMANIA":
        stateAPI = "TAS";
        break;
      case "SOUTH AUSTRALIA":
        stateAPI = "SA";
        break;
      case "WESTERN AUSTRALIA":
        stateAPI = "WA";
        break;
      case "NORTHERN TERRITORY":
        stateAPI = "NT";
        break;
      default:
        stateAPI = refState.current.value;
        break;
    }

    if (refZip.current.value.length == 4) {
      const resObj = await runFetch(spURL_GetDDLCity, {
        method: "POST",
        body: new URLSearchParams({
          city: refCity.current.value,
          state: stateAPI,
          zip: refZip.current.value,
        }),
      });
      let data = JSON.parse(resObj.response);

      if (data.statuscode === "OK") {
        const _responseObj = JSON.parse(data.result);

        if (_responseObj.results.length > 0) {
          setDDLCity(
            _responseObj.results.map((item, index) => (
              <option
                key={index}
                value={item}
              >
                {item}
              </option>
            ))
          );
        } else {
          setConfirmResponse("ERROR!: No suburbs found for given zip and state");
        }
      } else {
        setConfirmResponse(`Error ${data.statuscode}: ${friendlyHttpStatus[data.statuscode]}`);
      }
    } else {
      setConfirmResponse("");
    }
  }

  const handleClick_Update = async () => {
    let data = new FormData();

    data.append("OrderID", props.OrderID);
    data.append("ID", Shipping.ID);
    data.append("NewStreet1", refStreet1.current.value);
    data.append("NewStreet2", refStreet2.current.value);
    data.append("NewCity", refCity.current.value);
    data.append("NewZip", refZip.current.value);
    data.append("NewState", refState.current.value);
    data.append("NewCountry", refCountry.current.value);

    const res = await trackPromise(
      fetch(
        "https://prod-15.australiaeast.logic.azure.com:443/workflows/655e529078da4772b4b8b6ed43fe9330/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Fd6h9mO5-a39oi34LSFe0rqyfhZPCPnUGOboUtWZGBE",
        {
          method: "POST",
          body: data,
          "Content-Type": "application/x-www-form-urlencoded",
        }
      )
    );

    res
      .blob()
      .then((blob) => blob.text())
      .then((blob) => {
        props.setMenuResponse(blob);
        props.setShow(false);
      });
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <strong>Current Shipping Address:</strong>
            <br />
            <p>{props.Shipping.Street1}</p>
            {props.Shipping.Street2 && <p>{props.Shipping.Street2}</p>}
            <p>{props.City}</p>
            <p>{props.Shipping.Zip + " " + props.Shipping.State}</p>
            <p>{props.Shipping.Country}</p>
            <br />
            <div className="input-row">
              <p>
                <strong>Street 1:</strong>
              </p>
              <input
                type="text"
                id="failedorders-updateshipping-street1"
                ref={refStreet1}
              />
            </div>
            <div className="input-row">
              <p>
                <strong>Street 2:</strong>
              </p>
              <input
                type="text"
                id="failedorders-updateshipping-street2"
                ref={refStreet2}
              />
            </div>
            <div className="input-row">
              <p>
                <strong>Zip:</strong>
              </p>
              <input
                type="text"
                id="failedorders-updateshipping-zip"
                onChange={getDDLCity}
                ref={refZip}
              />
            </div>
            <div className="input-row">
              <p>
                <strong>City:</strong>
              </p>
              <select
                name="city"
                id="failedorders-updateshipping-city"
                ref={refCity}
              >
                <option value={props.Shipping.City}>{props.Shipping.City}</option>
                {ddlCity}
              </select>
            </div>
            <div className="input-row">
              <p>
                <strong>State:</strong>
              </p>
              <input
                type="text"
                id="failedorders-updateshipping-state"
                ref={refState}
              />
            </div>
            <div className="input-row">
              <p>
                <strong>Country:</strong>
              </p>
              <input
                type="text"
                id="failedorders-updateshipping-country"
                ref={refCountry}
              />
            </div>
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Update}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
