import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, purple } from "../../App";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";
import { handleChange_Input } from "../../functions/HandleChangeInput";

export const PopupCreate = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");
  const [DrawName, setDrawName] = useState("");
  const [BCPage, setBCPage] = useState("");
  const [PageTitle, setPageTitle] = useState("");
  const [URLName, setURLName] = useState("");
  const [ddlBCPage, setDDLBCPage] = useState([]);

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleShow = async () => {
    setPopupResponse("");
    const resObj = await runFetch(`${spURL_NoInputJSON}CompetitionDraw_AvailableBCPage_Get`, {
      method: "POST",
    });
    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDDLBCPage(spResponse.Table.map((item) => item.BCPage));
      } else {
        setDDLBCPage([]);
      }
    }
  };

  const handleClick_Submit = async () => {
    setPopupResponse("");
    const resObj = await runFetch(`${spURL}CompetitionDraw_Draw_Create`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          DrawName: DrawName.trim(),
          BCPage: BCPage.trim(),
          PageTitle: PageTitle.trim(),
          URLName: URLName.trim(),
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setMenuResponse(spResponse.Table[0].OutputString);
        props.pageLoad();

        props.setShow(false);
      }
    }

    // do BC redirect
    await trackPromise(
      runFetch(`${spURL}CreateBCRedirect`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify([
            {
              from_path: `/monthlywin/${encodeURIComponent(URLName.trim())}/`,
              site_id: 1000,
              to: {
                type: "url",
                url: `https://toymate.com.au/monthlywin${BCPage}/?title=${encodeURIComponent(
                  PageTitle.trim()
                )}`,
              },
            },
          ]),
        }),
      })
    );
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <p>
              <strong>CREATE Draw</strong>
            </p>
            <br />
            <div className="stocktake-create-input-row">
              <p>
                <strong>Draw Name</strong>
              </p>
              <input
                type="text"
                onChange={(e) => {
                  setPopupResponse("");
                  handleChange_Input(e, setDrawName);
                }}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>BC Page</strong>
              </p>
              <select
                onChange={(e) => {
                  setPopupResponse("");
                  handleChange_Input(e, setBCPage);
                }}
                id="competitiondraw-bcpage"
              >
                <option value=""></option>
                {ddlBCPage.map((item, index) => (
                  <option
                    key={index}
                    value={item}
                  >
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Page Title</strong>
              </p>
              <input
                type="text"
                onChange={(e) => {
                  setPopupResponse("");
                  handleChange_Input(e, setPageTitle);
                }}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>URL Name to link</strong>
              </p>
              <input
                type="text"
                onChange={(e) => {
                  setPopupResponse("");
                  handleChange_Input(e, setURLName);
                }}
              />
            </div>
            <br />
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Submit}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
