import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { purple, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";
import { useRef } from "react";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";

export const MarketingGiftCardCreateBody = (props) => {
  const [userid, setUserID] = store.useState("userid");

  const [PageResponse, setPageResponse] = useState("");

  const refGiftCardNo = useRef();
  const refGiftCardAmount = useRef();

  const handleClick_Create = async () => {
    setPageResponse("");
    if (refGiftCardNo.current.value === "") {
      setPageResponse("ERROR!: Gift Card number cannot be blank.");
    } else {
      if (isNaN(refGiftCardAmount.current.value)) {
        setPageResponse("ERROR!: Gift Card amount must be a number.");
      } else {
        if (parseInt(refGiftCardAmount.current.value, 10) <= 0) {
          setPageResponse("ERROR!: Gift Card amount must be valid.");
        } else {
          const resObj = await runFetch(`${spURL}MarketingGiftCardCreate_GiftCardCreate`, {
            method: "POST",
            body: new URLSearchParams({
              inputJSON: JSON.stringify({
                GiftCardNo: refGiftCardNo.current.value,
                GiftCardAmount: refGiftCardAmount.current.value,
                UserID: userid,
              }),
            }),
          });

          if (resObj.response === null) {
            setPageResponse(resObj.exception);
          } else {
            let spResponse = JSON.parse(resObj.response);

            if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
              setPageResponse(spResponse.Table[0].OutputString);

              await sendEmail();
              cleanInputs();
            }
          }
        }
      }
    }
  };

  const sendEmail = async () => {
    const resObj = await runFetch(`${spURL}SendMarketingGiftCardEmail`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          PrintString: `GiftCardNo: ${refGiftCardNo.current.value}\nAmount: ${refGiftCardAmount.current.value}`,
        }),
      }),
    });

    if (resObj.response === null) {
      setPageResponse(resObj.exception);
    }
  };

  const cleanInputs = () => {
    refGiftCardNo.current.value = "";
    refGiftCardAmount.current.value = "";
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          <h1>
            <strong>CREATE Gift Card</strong>
          </h1>
          <div className="storetransfer-input-row">
            <p>
              <strong>Gift Card No:</strong>
            </p>
            <input
              type="text"
              id="marketinggiftcard-create-giftcardno"
              ref={refGiftCardNo}
              onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Create)}
            />
          </div>
          <div className="storetransfer-input-row">
            <p>
              <strong>Amount:</strong>
            </p>
            <input
              type="text"
              id="marketinggiftcard-create-giftcardamount"
              ref={refGiftCardAmount}
              onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Create)}
            />
          </div>
        </div>
        <br />
        <p className="error-message">{PageResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <Link to="/Marketing">
          <FooterButton
            button="Back"
            colour={purple}
          />
        </Link>
        <FooterButton
          button="Create"
          id="marketinggiftcard-create-create"
          colour={purple}
          onClick={handleClick_Create}
        />
      </div>
    </div>
  );
};
