import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const NotScanning = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [NotScanningResponse, setNotScanningResponse] = useState("");

  const refBarcode = useRef();

  const handleClose_NS = () => {
    props.setItemDetailsResponse("");
    props.setShow(false);
  };

  const handleShow_NS = () => {
    props.setItemDetailsResponse("");
    refBarcode.current.focus();
  };

  const handleSubmit_NS = async () => {
    const resObj = await runFetch(`${spURL}Handheld_NotScanning_Process`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          ItemNo: props.itemno,
          StoreID: storeno,
          Barcode: refBarcode.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setNotScanningResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table[0].OutputString !== undefined) {
          handleClose_NS();
          props.setItemDetailsResponse(spResponse.Table[0].OutputString);
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose_NS}
        onShow={handleShow_NS}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <h3 className="itemdetails-ns-title background-black">
              <strong>BARCODE TO ADD</strong>
            </h3>
            <input
              className="itemdetails-ns-input"
              type="text"
              id="itemdetails-ns-barcode"
              ref={refBarcode}
              autoComplete="off"
            />
            <br />
            <p className="error-message">{NotScanningResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose_NS}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit_NS}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
