import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, spURL_LocalPOS } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const PopupBatch = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [ConfirmResponse, setConfirmResponse] = useState("");
  const [ddlBatch, setDDLBatch] = useState("");

  const handleClose = () => props.setShow(false);

  const handleShow = async () => {
    const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_BatchList`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });
    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setConfirmResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDDLBatch(
          spResponse.Table.map((item, index) => (
            <option
              key={index}
              value={item.BatchID}
            >
              {item.BatchID}
            </option>
          ))
        );
      }
    }
  };

  const handleClick_Ok = () => {
    async function createBatch() {
      const _batchid = document.getElementById("labels-batch-createbatch").value;

      const res = await runFetch(spURL_LocalPOS + "Handheld_Label_Create_Batch", {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            BatchID: _batchid,
            ShelfPrinter: props.ShelfPrinter,
          }),
        }),
      });
      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        setConfirmResponse(resObj.exception);
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);
        sendBatch(_batchid);
      }
    }

    async function sendBatch(batchid) {
      const res = await runFetch(spURL_LocalPOS + "Handheld_Label_Add_Item", {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            BatchID: batchid,
            ItemNo: props.AddItemDetails.ItemNo,
            Quantity: props.AddItemDetails.Quantity,
            LabelType: props.AddItemDetails.LabelType,
            From: "MERCH",
          }),
        }),
      });
      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        setConfirmResponse(resObj.exception);
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          let response = spResponse.Table[0].OutputString;
          switch (props.Origin) {
            case "QUICK":
              props.setResponse(response);
              props.setQuickLabelBarcode("");
              props.setPage("quicklabel");
              break;
            case "QUIT":
              window.location.href = `#/ItemDetails?From=Labels&ItemNo=${
                props.AddItemDetails.ItemNo
              }&Response=${encodeURIComponent(response)}`;
              break;
            default:
              setConfirmResponse(
                "ERROR!: Program error. Please email itsupport@toymate.com.au for help."
              );
              break;
          }
        }
      }
    }

    const _createbatchid = document.getElementById("labels-batch-createbatch").value;
    const _selectbatchid = document.getElementById("labels-batch-selectbatch").value;

    if (_createbatchid !== "" && _selectbatchid === "") {
      createBatch();
    } else if (_createbatchid === "" && _selectbatchid !== "") {
      sendBatch(_selectbatchid);
    } else {
      setConfirmResponse("ERROR!: Please create or select batch only.");
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Create Batch</strong>
              </p>
              <input
                type="text"
                id="labels-batch-createbatch"
              />
            </div>
            <strong>OR</strong>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Select Batch</strong>
              </p>
              <select
                name="selectbatch"
                id="labels-batch-selectbatch"
              >
                <option value=""></option>
                {ddlBatch}
              </select>
            </div>
            <br />
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Ok}
            >
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
