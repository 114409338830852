import React, { useState } from "react";
import { Link } from "react-router-dom";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, yellow } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";

export const Details = (props) => {
  const handleClick_Details_Back = () => {
    props.setMerchandisingResponse("");
    props.cleanItemDetails();
    props.setPage("barcode");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="itemdetails-image-itemno-container">
          <img
            src={props.ItemDetails.ImageURL}
            alt="Item"
            width="100"
          />
          <div className="merchandising-itemno-container">
            <p>
              <strong>{props.ItemDetails.ItemNo}</strong>
            </p>
            <p>
              <strong>{props.ItemDetails.ReorderNumber}</strong>
            </p>
            <p>{props.ItemDetails.Supplier}</p>
          </div>
          <div className="merchandising-department-container">
            <p>{props.ItemDetails.Department.substring(0, 25)}</p>
            <p>
              {">"}
              {props.ItemDetails.Category.substring(0, 25)}
            </p>
            <p>
              {">>"}
              {props.ItemDetails.SubCategory.substring(0, 25)}
            </p>
          </div>
        </div>

        <div className="merchandising-description-container">
          <a
            className="itemdetails-description-link"
            href={
              props.ItemDetails.WebURL === ""
                ? ""
                : `https://toymate.com.au${props.ItemDetails.WebURL}`
            }
            target="_blank"
          >
            <strong>{props.ItemDetails.ExtendedDescription}</strong>
            <br />
            Click for website
          </a>
        </div>

        <div className="itemdetails-price-container">
          <p>
            <strong>
              {convertToCurrency(props.ItemDetails.Price, "$")} (MSRP:{" "}
              {convertToCurrency(props.ItemDetails.MSRP, "$")})
            </strong>
          </p>
        </div>

        <table className="merchandising-stock-table">
          <thead>
            <tr>
              <th>SOO</th>
              <th>SOT</th>
              <th>SOH</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{props.ItemDetails.SOO}</td>
              <td>{props.ItemDetails.SOT}</td>
              <td>{props.ItemDetails.SOH}</td>
            </tr>
          </tbody>
        </table>

        <p>
          <strong>
            Web Orders - Total: {props.ItemDetails.WebOrderTotalQuantity}, Outstanding:{" "}
            {props.ItemDetails.WebOrderOutstandingQuantity}
          </strong>
        </p>

        <div className="merchandising-aisleno">
          <h5>
            <strong>
              {props.ItemDetails.Category}
              <br />
              Section {props.MerchSpotDetails_1.AisleNo}
            </strong>
          </h5>
          <p>({props.ItemDetails.SubCategory})</p>
        </div>

        <p>
          This item is a <strong>{props.ItemDetails.MerchSpot}</strong> item.
        </p>
        <p>
          This item is to be in <strong>{props.ItemDetails.MerchAreaAmount}</strong> areas.
        </p>
        <br />
        <p>Instruction:</p>
        <p>
          <strong>{props.ItemDetails.MerchInstruction}</strong>
        </p>
        {props.ItemDetails.IsOHSTagNeeded.toString() === "1" ? (
          <p className="error-message">
            <strong>DANGER TOO LARGE FOR TOP SHELF</strong>
          </p>
        ) : null}
        <br />
        <div className="merchandising-merchspot-container">
          <div>
            <p>
              <strong>Standard Section</strong>
            </p>
            <p>
              Max PCS: <strong>{props.MerchSpotDetails_1.StandardShelfQuantity}</strong>
            </p>
            <p>
              Bay: <strong>{props.MerchSpotDetails_1.BayLocationStandard}</strong>
            </p>
          </div>
          <div>
            <p>
              <strong>Lead Sale Section</strong>
            </p>
            <p>
              Max PCS: <strong>{props.MerchSpotDetails_1.LeadSaleQuantity}</strong>
            </p>
            <p>
              Bay: <strong>{props.MerchSpotDetails_1.BayLocationLead}</strong>
            </p>
          </div>
          <div>
            <p>
              <strong>Front Section</strong>
            </p>
            <p>
              Max PCS: <strong>{props.MerchSpotDetails_1.FrontSectionQuantity}</strong>
            </p>
          </div>
          <div>
            <p>
              <strong>Department Excess</strong>
            </p>
            <p>
              Max PCS: <strong>{props.MerchSpotDetails_1.DepartmentExcessQuantity}</strong>
            </p>
          </div>
          <div>
            <p>
              <strong>StoreRoom / Behind Counter</strong>
            </p>
            <p>
              Max PCS: <strong>{props.MerchSpotDetails_1.StoreRoomBehindCounterQuantity}</strong>
            </p>
          </div>
          {["SIDECAP", "CLIPSTRIP", "PLUSHSTRIP"].includes(props.ItemDetails.MerchSpot) ? (
            <></>
          ) : (
            /*<div>
                                     <p><strong>{props.ItemDetails.MerchSpot}</strong></p>
                                     <p>Max PCS: <strong>{props.MerchSpotDetails_1.FrontActivation}</strong></p>
                                </div>*/
            <></>
            /*<div>
                                    <p><strong>FRONT ACTIVATION</strong></p>
                                    <p>Max PCS: <strong>{props.MerchSpotDetails_1.FrontActivation}</strong></p>
                                    <p>Bay Location: <strong>{props.MerchSpotDetails_1.FrontActivationBayLocation}</strong></p>
                                </div>*/
          )}
        </div>
        <br />
        <br />
        <p className="error-message">{props.MerchandisingResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={yellow}
          onClick={handleClick_Details_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
      </div>
    </div>
  );
};
