import React, { Suspense, useEffect, useState } from "react";

import { Barcode } from "./Barcode";
import { Details } from "./Details.jsx";
import { SOO } from "./SOO";
import { SOT } from "./SOT";
import { SOH } from "./SOH";
import { ItemBarcode } from "./ItemBarcode";
import { spURL, spURL_GetIP } from "../../App";
import { runFetch } from "../../functions/RunFetch";

export const ItemDetailsHOBody = () => {
  const [page, setPage] = useState("barcode");

  const [ItemDetailsResponse, setItemDetailsResponse] = useState("");

  const [data, setData] = useState({
    CanNotBeMarkDownBelow: "",
    Category: "",
    Department: "",
    Description: "",
    ExtendedDescription: "",
    ImageURL: "",
    ItemNo: "",
    ItemSource: "",
    ItemType: "",
    MSRP: "",
    MarkDownDate: "",
    MarkDownDateOK: "",
    NextMarkDownDate: "",
    Price: "",
    RangeStatus: "",
    ReorderNumber: "",
    SOH: "",
    SOHWH: "",
    SOO: "",
    SOT: "",
    Sales0d: "",
    Sales7d: "",
    Sales28d: "",
    SubCategory: "",
    Supplier: "",
    WebURL: "",
    LastReceiptDate: "",
    InnerMPQ: "",
    OuterMPQ: "",
    LastDateModified: "",
  });

  const [edmTable, setEDMTable] = useState("");

  const exeBarcodeClick = async (barcode) => {
    const resObj = await runFetch(`${spURL}Handheld_ItemDetailAdmin_Item_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Barcode: barcode,
        }),
      }),
    });

    if (resObj.response === null) {
      setPage("barcode");
      setItemDetailsResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setData((values) => ({
          ...values,
          Category: spResponse.Table[0].Category,
          Department: spResponse.Table[0].Department,
          Description: spResponse.Table[0].Description,
          ExtendedDescription: spResponse.Table[0].ExtendedDescription,
          ImageURL: spResponse.Table[0].ImageURL,
          ItemNo: spResponse.Table[0].ItemNo,
          MSRP: spResponse.Table[0].MSRP,
          Price: spResponse.Table[0].Price,
          ReorderNumber: spResponse.Table[0].ReorderNumber,
          SOH: spResponse.Table[0].SOH,
          SOHWH: spResponse.Table[0].SOHWH,
          SOO: spResponse.Table[0].SOO,
          SOT: spResponse.Table[0].SOT,
          SubCategory: spResponse.Table[0].SubCategory,
          Supplier: spResponse.Table[0].Supplier,
          WebURL: spResponse.Table[0].WebURL,
          InnerMPQ: spResponse.Table[0].InnerMPQ,
          OuterMPQ: spResponse.Table[0].OuterMPQ,
          LastReceiptDate: spResponse.Table[0].LastReceiptDate,
          LastDateModified: spResponse.Table[0].LastDateModified,
        }));
      }

      if (spResponse.Table1.length > 0) {
        setEDMTable(
          spResponse.Table1.map((item, index) => (
            <tr key={index}>
              <td>{item.Promotion}</td>
            </tr>
          ))
        );
      }

      setPage("details");
    }
  };

  const barcodePage = (
    <Barcode
      exeBarcodeClick={exeBarcodeClick}
      ItemDetailsResponse={ItemDetailsResponse}
      setItemDetailsResponse={setItemDetailsResponse}
    />
  );

  const detailsPage = (
    <Details
      data={data}
      setData={setData}
      page={page}
      setPage={setPage}
      edmTable={edmTable}
      setEDMTable={setEDMTable}
      ItemDetailsResponse={ItemDetailsResponse}
      setItemDetailsResponse={setItemDetailsResponse}
    />
  );

  const sooPage = (
    <SOO
      page={page}
      itemno={data.ItemNo}
      setPage={setPage}
      setItemDetailsResponse={setItemDetailsResponse}
      ItemDetailsResponse={ItemDetailsResponse}
    />
  );

  const sotPage = (
    <SOT
      page={page}
      itemno={data.ItemNo}
      setPage={setPage}
      setItemDetailsResponse={setItemDetailsResponse}
      ItemDetailsResponse={ItemDetailsResponse}
    />
  );

  const sohPage = (
    <SOH
      page={page}
      itemno={data.ItemNo}
      setPage={setPage}
      setItemDetailsResponse={setItemDetailsResponse}
      ItemDetailsResponse={ItemDetailsResponse}
    />
  );

  const itemBarcodePage = (
    <ItemBarcode
      page={page}
      setPage={setPage}
      ItemNo={data.ItemNo}
      ItemDetailsResponse={ItemDetailsResponse}
      setItemDetailsResponse={setItemDetailsResponse}
    />
  );

  const getCurrentPage = (page) => {
    var currentPage;

    switch (page) {
      case "barcode":
        currentPage = barcodePage;
        break;
      case "details":
        currentPage = detailsPage;
        break;
      case "soo":
        currentPage = sooPage;
        break;
      case "sot":
        currentPage = sotPage;
        break;
      case "soh":
        currentPage = sohPage;
        break;
      case "itembarcode":
        currentPage = itemBarcodePage;
        break;
      default:
        currentPage = "";
        break;
    }

    return currentPage;
  };

  return getCurrentPage(page);
};
