import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faNoteSticky } from "@fortawesome/free-regular-svg-icons";

import { orange, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import { FooterButton } from "../../components/HomepageButton";
import { PopupDetails } from "./PopupDetails";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

library.add(faNoteSticky);

export const WHReceive = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [storename, setStoreName] = store.useState("storename");
  const [userid, setUserID] = store.useState("userid");

  const [JobNoTable, setJobNoTable] = useState([]);
  const [selectedJobNo, setSelectedJobNo] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  const [MenuResponse, setMenuResponse] = useState("");

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL}Handheld_WHReceived_Receive_JobNo_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setJobNoTable(
          spResponse.Table.map((item, index) => (
            <tr
              className="btnHover"
              key={index}
            >
              <td onClick={() => handleClick_Row(item.JobNo)}>{item.JobNo}</td>
              <td onClick={() => handleClick_Row(item.JobNo)}>
                {new Date(item.DeliveryDate).toLocaleDateString()}
              </td>
              <td onClick={() => handleClick_Row(item.JobNo)}>{item.PalletAmount}</td>
              <td
                onClick={() => {
                  setSelectedJobNo(item.JobNo);
                  setShowDetails(true);
                }}
              >
                <FontAwesomeIcon icon="fa-regular fa-note-sticky" />
              </td>
            </tr>
          ))
        );
      }
    }
  };

  const handleClick_Row = async (_jobno) => {
    const resObj = await runFetch(`${spURL}Handheld_WHReceived_Receive`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: userid,
          StoreCode: storeno,
          ReceivingStoreName: storename,
          JobNo: _jobno,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      props.setJobNo(_jobno);
      props.setPage("sscreceive");
    }
  };

  const handleClick_Back = () => {
    setMenuResponse("");
    props.setWHReceivedResponse("");
    props.setPage("menu");
  };

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        {JobNoTable.length === 0 ? (
          "There are no jobs available to receive"
        ) : (
          <table className="itemdetails-table">
            <thead>
              <tr>
                <th colSpan={2}>Job Number</th>
                <th>Amt</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{JobNoTable}</tbody>
          </table>
        )}
        <br />
        <p className="error-message">{MenuResponse}</p>

        <PopupDetails
          show={showDetails}
          setShow={setShowDetails}
          JobNo={selectedJobNo}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
