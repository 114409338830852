import { useRef } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { green } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";

export const Return = (props) => {
  const [WebOrdersResponse, setWebOrdersResponse] = useState(props.MenuResponse);

  const refOrderID = useRef();

  const handleClick_Back = () => {
    props.setPage("menu");
  };

  const handleClick_Select = () => {
    props.setCurrentPicks([
      {
        OrderID: refOrderID.current.value,
        PickID: "",
      },
    ]);

    props.setPage("lines-return");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="stocktake-create-input-row">
          <p>
            <strong>OrderID</strong>
          </p>
          <input
            type="text"
            id="weborders-orderid"
            ref={refOrderID}
            autoFocus
            onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Select)}
          />
        </div>
        <button
          className="signin-login-button"
          id="weborders-enter"
          onClick={handleClick_Select}
        >
          Select
        </button>
        <br />
        <p className="error-message">{WebOrdersResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
