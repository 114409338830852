import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { runFetch } from "../../functions/RunFetch";

export const PopupMPQ = (props) => {
  const [MPQResponse, setMPQResponse] = useState("");

  const refInnerMPQ = useRef();
  const refOuterMPQ = useRef();

  const handleClose = () => props.setShow(false);

  const handleShow = () => {
    refInnerMPQ.current.value = props.InnerMPQ;
    refOuterMPQ.current.value = props.OuterMPQ;
    refInnerMPQ.current.select();
  };

  const handleSubmit = async () => {
    const resObj = await runFetch(`${spURL}Handheld_ItemDetailAdmin_Item_MPQ_Update`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          ItemNo: props.ItemNo,
          InnerMPQ: refInnerMPQ.current.value,
          OuterMPQ: refOuterMPQ.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setMPQResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table[0].OutputString !== undefined) {
          handleClose();

          props.setInnerMPQ(refInnerMPQ.current.value);
          props.setOuterMPQ(refOuterMPQ.current.value);
          props.setItemDetailsResponse(spResponse.Table[0].OutputString);
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <h4 className="itemdetails-wp-title background-red">
              <strong>Edit MPQ</strong>
            </h4>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Old Inner MPQ:</strong>
              </p>
              <p>{props.InnerMPQ}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>New Inner MPQ:</strong>
              </p>
              <input
                type="text"
                id="itemdetailsho-innermpq"
                ref={refInnerMPQ}
                autoComplete="off"
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Old Outer MPQ:</strong>
              </p>
              <p>{props.OuterMPQ}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>New Outer MPQ:</strong>
              </p>
              <input
                type="text"
                id="itemdetailsho-outermpq"
                ref={refOuterMPQ}
                autoComplete="off"
              />
            </div>
            <p className="error-message">{MPQResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
