export const isInputWholeInteger = (numInput) => {
    // check if input is a number
    if (isNaN(numInput)) {
        return false;
    }

    // check if input is whole number
    if (numInput % 1 !== 0) {
        return false;
    }

    return true;
}