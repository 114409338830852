import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, spURL_LocalPOS } from "../../App";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const PopupChangePassword = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [ChangePasswordResponse, setChangePasswordResponse] = useState("");

  const [CurrentPassword, setCurrentPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [ConfirmNewPassword, setConfirmNewPassword] = useState("");

  const handleClose = () => {
    setChangePasswordResponse("");
    props.setShow(false);
  };

  const handleShow = () => {
    setChangePasswordResponse("");
  };

  const handleSubmit = () => {
    setChangePasswordResponse("");
    if (NewPassword === ConfirmNewPassword) {
      doChangePassword();
    } else {
      setChangePasswordResponse("ERROR!: New password do not match.");
    }

    async function doChangePassword() {
      const resObj = await runFetch(`${spURL}Handheld_StaffManage_Staff_ChangePassword`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            StaffID: props.StaffID,
            OldPassword: CurrentPassword,
            Password: NewPassword,
          }),
        }),
      });

      if (resObj.response === null) {
        setChangePasswordResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          if (spResponse.Table[0].OutputString !== undefined) {
            doChangeLocalPassword();
            handleClose();
            props.setEditResponse(spResponse.Table[0].OutputString);
          }
        }
      }
    }

    async function doChangeLocalPassword() {
      await runFetch(`${spURL_LocalPOS}POS_Staff_ChangePassword`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StaffID: props.StaffID,
            Password: NewPassword,
          }),
        }),
      });
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <p>
              <strong>CHANGE PASSWORD</strong>
            </p>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Current:</strong>
              </p>
              <input
                type="text"
                className="form-password"
                id="popup-changepassword-currentpassword"
                onChange={(e) => handleChange_Input(e, setCurrentPassword)}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>New:</strong>
              </p>
              <input
                type="text"
                className="form-password"
                id="popup-changepassword-newpassword"
                onChange={(e) => handleChange_Input(e, setNewPassword)}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Confirm New:</strong>
              </p>
              <input
                type="text"
                className="form-password"
                id="popup-changepassword-confirmnewpassword"
                onChange={(e) => handleChange_Input(e, setConfirmNewPassword)}
              />
            </div>
            <p className="error-message">{ChangePasswordResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
