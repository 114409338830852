import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { FooterButton } from "../../components/HomepageButton";
import { blue, orange, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { trackPromise } from "react-promise-tracker";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { runFetch } from "../../functions/RunFetch";

export const DeviceAuthUpdate = (props) => {
  const [deviceDetails, setDeviceDetails] = useState(props.currentDevice);
  const [UpdateResponse, setUpdateResponse] = useState("");

  const refDeviceID = useRef();
  const refDeviceAuthCode = useRef();
  const refDisplayName = useRef();
  const refStoreNo = useRef();
  const refSite = useRef();
  const refDeviceType = useRef();
  const refStatus = useRef();

  useEffect(() => {
    refDeviceID.current.value = props.currentDevice.DeviceID;
    refDeviceAuthCode.current.value = props.currentDevice.DeviceAuthCode;
    refDisplayName.current.value = props.currentDevice.DisplayName;
    refStoreNo.current.value = props.currentDevice.StoreNo;
    refSite.current.value = props.currentDevice.Site;
    refDeviceType.current.value = props.currentDevice.DeviceType;
    refStatus.current.value = props.currentDevice.Status;
  }, []);

  // const handleChange_DeviceID = (event) => {
  //   const _deviceid = event.target.value;

  //   setDeviceDetails((values) => ({
  //     ...values,
  //     DeviceID: _deviceid,
  //   }));
  // };

  const handleClick_Done = async () => {
    const resObj = await runFetch(`${spURL}Handheld_DeviceAuth_Code_${props.Origin}`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(deviceDetails),
      }),
    });

    if (resObj.response === null) {
      setUpdateResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setMenuResponse(
          `Device successfully ${props.Origin === "CREATE" ? "created" : "edited"}.`
        );
        props.setPage("deviceauth-main");
      }
    }
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <h4>
          <strong>{props.Origin} Device</strong>
        </h4>
        <div className="input-row">
          <p>
            <strong>DeviceID:</strong>
          </p>
          <input
            type="text"
            onChange={(e) =>
              handleChange_Input(e, (inp) => {
                setDeviceDetails((values) => ({
                  ...values,
                  DeviceID: inp,
                }));
              })
            }
            ref={refDeviceID}
            name="deviceid"
            id="deviceauth-update-deviceid"
          />
        </div>
        <div className="input-row">
          <p>
            <strong>Device Auth Code:</strong>
          </p>
          <input
            type="text"
            onChange={(e) =>
              handleChange_Input(e, (inp) => {
                setDeviceDetails((values) => ({
                  ...values,
                  DeviceAuthCode: inp,
                }));
              })
            }
            ref={refDeviceAuthCode}
            name="deviceauthcode"
            id="deviceauth-update-deviceauthcode"
          />
        </div>
        <div className="input-row">
          <p>
            <strong>Display Name:</strong>
          </p>
          <input
            type="text"
            onChange={(e) =>
              handleChange_Input(e, (inp) => {
                setDeviceDetails((values) => ({
                  ...values,
                  DisplayName: inp,
                }));
              })
            }
            ref={refDisplayName}
            name="displayname"
            id="deviceauth-update-displayname"
          />
        </div>
        <div className="input-row">
          <p>
            <strong>StoreNo:</strong>
          </p>
          <input
            type="text"
            onChange={(e) =>
              handleChange_Input(e, (inp) => {
                setDeviceDetails((values) => ({
                  ...values,
                  StoreNo: inp,
                }));
              })
            }
            ref={refStoreNo}
            name="storeno"
            id="deviceauth-update-storeno"
          />
        </div>
        <div className="input-row">
          <p>
            <strong>Site:</strong>
          </p>
          <select
            onChange={(e) =>
              handleChange_Input(e, (inp) => {
                setDeviceDetails((values) => ({
                  ...values,
                  Site: inp,
                }));
              })
            }
            ref={refSite}
            name="site"
            id="deviceauth-update-site"
          >
            <option value="TMV2">TMV2</option>
            <option value="TM_PhoneOrders">TM_PhoneOrders</option>
            <option value="TM_Admin">TM_Admin</option>
          </select>
        </div>
        <div className="input-row">
          <p>
            <strong>DeviceType:</strong>
          </p>
          <input
            type="text"
            onChange={(e) =>
              handleChange_Input(e, (inp) => {
                setDeviceDetails((values) => ({
                  ...values,
                  DeviceType: inp,
                }));
              })
            }
            ref={refDeviceType}
            name="devicetype"
            id="deviceauth-update-devicetype"
          />
        </div>
        <div className="input-row">
          <p>
            <strong>Status:</strong>
          </p>
          <select
            onChange={(e) =>
              handleChange_Input(e, (inp) => {
                setDeviceDetails((values) => ({
                  ...values,
                  Status: inp,
                }));
              })
            }
            ref={refStatus}
            name="status"
            id="deviceauth-update-status"
          >
            <option value="ACTIVE">ACTIVE</option>
            <option value="INACTIVE">INACTIVE</option>
          </select>
        </div>
        <button
          className="signin-login-button btnHover"
          onClick={handleClick_Done}
        >
          {props.Origin === "CREATE" ? "Create" : "Update"}
        </button>
        <br />
        <p className="error-message">{UpdateResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={blue}
          onClick={() => {
            setUpdateResponse("");
            props.setPage("deviceauth-main");
          }}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
      </div>
    </div>
  );
};
