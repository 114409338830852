import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { red, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { PopupMPQ } from "./PopupMPQ";

export const Details = (props) => {
  const [userid, setUserID] = store.useState("userid");

  const handleClick_Details_Back = () => {
    props.setItemDetailsResponse("");
    props.setPage("barcode");
  };

  const handleClick_Details_SOO = () => {
    props.setItemDetailsResponse("");
    props.setPage("soo");
  };

  const handleClick_Details_SOT = () => {
    props.setItemDetailsResponse("");
    props.setPage("sot");
  };

  const handleClick_Details_SOH = () => {
    props.setItemDetailsResponse("");
    props.setPage("soh");
  };

  const handleClick_Details_MPQ = () => {
    props.setItemDetailsResponse("");
    setShowMPQ(true);
  };

  const handleClick_Details_DataSyncPush = async () => {
    const resObj = await runFetch(`${spURL}Handheld_ItemDetailAdmin_Item_DataSync_Push`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          ItemNo: props.data.ItemNo,
        }),
      }),
    });

    if (resObj.response === null) {
      props.setItemDetailsResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setItemDetailsResponse(spResponse.Table[0].OutputString);
      }
    }
  };

  const handleClick_Details_Barcode = () => {
    props.setItemDetailsResponse("");
    props.setPage("itembarcode");
  };

  const setInnerMPQ = (innermpq) => {
    props.setData((values) => ({
      ...values,
      InnerMPQ: innermpq,
    }));
  };

  const setOuterMPQ = (outermpq) => {
    props.setData((values) => ({
      ...values,
      OuterMPQ: outermpq,
    }));
  };

  const [isITPermissionGroup, setIsITPermissionGroup] = useState(false);

  const [showMPQ, setShowMPQ] = useState(false);
  const [showNS, setShowNS] = useState(false);

  useEffect(() => {
    setIsITPermissionGroup(false);

    async function getPermissionGroup() {
      const resObj = await runFetch(`${spURL}Handheld_PermissionGroup_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            UserID: userid,
          }),
        }),
      });

      if (resObj.response === null) {
        props.setItemDetailsResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          if (spResponse.Table.map((item) => item.PermissionGroup).includes("IT")) {
            setIsITPermissionGroup(true);
          }
        }
      }
    }
    getPermissionGroup();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="itemdetails-image-itemno-container">
          <img
            src={props.data.ImageURL}
            alt="Item"
            width="100"
          />
          <div>
            <p>
              <strong>{props.data.ItemNo}</strong>
            </p>
            <p>
              <strong>{props.data.ReorderNumber}</strong>
            </p>
            <p>{props.data.Supplier}</p>
          </div>
        </div>

        <div className="itemdetails-description-container">
          <a
            className="itemdetails-description-link"
            href={props.data.WebURL === "" ? "" : `https://toymate.com.au${props.data.WebURL}`}
            target="_blank"
          >
            <strong>{props.data.ExtendedDescription}</strong>
          </a>
        </div>

        <div>
          <p>{props.data.Department.substring(0, 25)}</p>
          <p>{`>${props.data.Category.substring(0, 25)}`}</p>
          <p>{`>>${props.data.SubCategory.substring(0, 25)}`}</p>
        </div>

        <div>
          <strong>Inner MPQ: {props.data.InnerMPQ}</strong>
        </div>
        <div>
          <strong>Outer MPQ: {props.data.OuterMPQ}</strong>
        </div>

        <div>
          <strong>Last Receipt Date: </strong>
          {props.data.LastReceiptDate}
        </div>

        <div>
          <strong>Last Modified Date: </strong>
          {props.data.LastDateModified}
        </div>

        <div className="itemdetails-price-container">
          <p>
            <strong>
              {convertToCurrency(props.data.Price, "$")} (MSRP:{" "}
              {convertToCurrency(props.data.MSRP, "$")})
            </strong>
          </p>
        </div>

        <div className="itemdetails-sq-ns-wp-container">
          <MenuButton
            title="MPQ"
            colour={red}
            button="MPQ"
            onClick={handleClick_Details_MPQ}
          />
          {isITPermissionGroup && (
            <MenuButton
              title="Data Sync Push"
              colour={red}
              button="DSP"
              onClick={handleClick_Details_DataSyncPush}
            />
          )}
          <MenuButton
            title="Barcode"
            colour={red}
            button="B"
            onClick={handleClick_Details_Barcode}
          />
        </div>

        <PopupMPQ
          show={showMPQ}
          setShow={setShowMPQ}
          ItemDetailsResponse={props.ItemDetailsResponse}
          setItemDetailsResponse={props.setItemDetailsResponse}
          ItemNo={props.data.ItemNo}
          InnerMPQ={props.data.InnerMPQ}
          setInnerMPQ={setInnerMPQ}
          OuterMPQ={props.data.OuterMPQ}
          setOuterMPQ={setOuterMPQ}
        />

        <div className="itemdetails-soo-sot-container">
          <div
            className="itemdetails-soo-button"
            onClick={handleClick_Details_SOO}
          >
            <strong>On Order</strong>
            <br />
            {props.data.SOO}
          </div>
          <div
            className="itemdetails-sot-button"
            onClick={handleClick_Details_SOT}
          >
            <strong>On Transfer</strong>
            <br />
            {props.data.SOT}
          </div>
        </div>

        <div className="itemdetails-table-container">
          <table className="itemdetails-table">
            <thead>
              <tr>
                <th colSpan={2}>
                  <strong>Stock</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  className="itemdetails-soh-button"
                  onClick={handleClick_Details_SOH}
                >
                  <strong>On Hand</strong>
                  <br />
                  {props.data.SOH}
                </td>
                <td>
                  <strong>WH Avail</strong>
                  <br />
                  {props.data.SOHWH}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* edm promotion table */}
        {props.edmTable !== "" && (
          <div>
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>
                    <strong>Current Promotion</strong>
                  </th>
                </tr>
              </thead>
              <tbody>{props.edmTable}</tbody>
            </table>
          </div>
        )}

        <p className="error-message">{props.ItemDetailsResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_Details_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
