import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { PopupBatch } from "./PopupBatch";
import { PopupConfirm } from "./PopupConfirm";

import { convertToCurrency } from "../../functions/ConvertToCurrency";

import { spURL, spURL_LocalPOS, yellow } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import { doPrintShelfLabel } from "../../functions/ShelfLabelUtils";
import store from "../../store";

export const Update = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [Response, setResponse] = useState("");

  const [Item, setItem] = useState({});
  const [Qty, setQty] = useState("");
  const [LabelType, setLabelType] = useState("");
  const [ddlLabelType, setDDLLabelType] = useState([]);

  const [show, setShow] = useState(false);
  const [AddItemDetails, setAddItemDetails] = useState({});

  const [showPopupOOS, setShowPopupOOS] = useState(false);
  const [showPopupEDM, setShowPopupEDM] = useState(false);

  useEffect(() => {
    setResponse("");

    async function getItem() {
      const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_Item_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            Barcode: props.Barcode,
          }),
        }),
      });
      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        props.setResponse(resObj.exception);
        switch (props.Origin) {
          case "QUICK":
            props.setPage("quicklabel");
            break;
          case "MERCH":
            props.setPage("merchlabel-add");
            break;
          default:
            break;
        }
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);
        if (spResponse.Table !== undefined) {
          setItem({
            ItemNo: spResponse.Table[0].ItemNo,
            Description: spResponse.Table[0].Description,
            Supplier: spResponse.Table[0].Supplier,
            ReorderNumber: spResponse.Table[0].ReorderNumber,
            ImageURL: spResponse.Table[0].ImageURL,
            Price: spResponse.Table[0].Price,
            MSRP: spResponse.Table[0].MSRP,
          });

          getDDLLabelType(spResponse.Table[0].ItemNo);
        }
      }
    }

    async function getDDLLabelType(_itemno) {
      const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_LabelType_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            ItemNo: _itemno,
            From: "MERCH",
          }),
        }),
      });
      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        props.setResponse(resObj.exception);
        switch (props.Origin) {
          case "QUICK":
            props.setPage("quicklabel");
            break;
          case "MERCH":
            props.setPage("merchlabel-add");
            break;
          default:
            break;
        }
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);
        if (spResponse.Table !== undefined) {
          setDDLLabelType(spResponse.Table.map((item) => item.LabelType));
        } else {
          setDDLLabelType([]);
        }
      }
    }

    getItem();

    document.getElementById("labels-update-qty").value = "1";
    setQty(1);
    document.getElementById("labels-update-qty").focus();
  }, []);

  const handleClick_Back = (event) => {
    props.setResponse("");
    props.setBarcode("");

    switch (props.Origin) {
      case "QUICK":
        props.setPage("quicklabel");
        break;
      case "MERCH":
        props.setPage("merchlabel-add");
        break;
      default:
        break;
    }
  };

  const handleClick_Confirm = async () => {
    setResponse("");
    const _labeltype = document.getElementById("labels-update-labeltype").value;
    const _origin = props.Origin;

    async function addQuickLabel() {
      const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_Add_QuickLabel`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            ShelfPrinter: props.currentShelfPrinter,
            Override: 1,
            Barcode: props.Barcode,
          }),
        }),
      });
      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        setResponse(resObj.exception);
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);

        props.setResponse(spResponse.Table[0].OutputString);
        props.setPage("quicklabel");
      }
    }

    var _soh = 0;
    var _edm = "";

    async function checkSOH() {
      const resObj = await runFetch(`${spURL}Handheld_Label_Item_SOH`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            Barcode: Item.ItemNo,
          }),
        }),
      });

      if (resObj.response === null) {
        setResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          _soh = parseInt(spResponse.Table[0].SOH);
        }

        if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
          _edm = spResponse.Table1[0].EDM;
        }

        if (_edm === "YES") {
          setShowPopupEDM(true);
        } else if (_soh <= 0) {
          setShowPopupOOS(true);
        } else {
          await addMerchLabel();
        }
      }
    }

    async function addMerchLabel() {
      const _labeltype = document.getElementById("labels-update-labeltype").value;
      const _qty = document.getElementById("labels-update-qty").value;

      const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_Add_Item`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            BatchID: props.currentMerchBatchID,
            ItemNo: Item.ItemNo,
            LabelType: _labeltype,
            Quantity: _qty,
            From: "MERCH",
          }),
        }),
      });
      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        setResponse(resObj.exception);
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          props.setBarcode("");
          props.setResponse(spResponse.Table[0].OutputString);
          props.setPage("merchlabel-add");
        }
      }
    }

    if (_origin === "QUICK") {
      if (_labeltype === "SHELF") {
        await addQuickLabel();
      } else {
        const _qty = document.getElementById("labels-update-qty").value;

        setAddItemDetails({
          ItemNo: Item.ItemNo,
          Quantity: _qty,
          LabelType: _labeltype,
        });
        setShow(true);
      }
    } else if (_origin === "MERCH") {
      await checkSOH();
    } else if (_origin === "QUIT") {
      const _qty = document.getElementById("labels-update-qty").value;

      setAddItemDetails({
        ItemNo: Item.ItemNo,
        Quantity: _qty,
        LabelType: _labeltype,
      });
      setShow(true);
    }
  };

  const handleChange_Qty = (event) => {
    setResponse("");
    setQty(event.target.value);
  };

  const handleChange_LabelType = async (event) => {
    setResponse("");
    setLabelType(event.target.value);
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="itemdetails-image-itemno-container">
          <img
            src={Item.ImageURL}
            alt="Item"
            width="100"
          />
          <div>
            <p>
              <strong>{Item.ItemNo}</strong>
            </p>
            <p>
              <strong>{Item.ReorderNumber}</strong>
            </p>
            <p>{Item.Supplier}</p>
          </div>
        </div>
        <br />
        <div className="itemdetails-price-container">
          <p>
            <strong>
              {convertToCurrency(Item.Price, "$")} (MSRP: {convertToCurrency(Item.MSRP, "$")})
            </strong>
          </p>
        </div>
        <br />
        <div className="stocktake-create-input-row">
          <p>
            <strong>Quantity</strong>
          </p>
          <input
            type="text"
            id="labels-update-qty"
            onChange={handleChange_Qty}
          />
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Label Type</strong>
          </p>
          <select
            name="labeltype"
            id="labels-update-labeltype"
            onChange={handleChange_LabelType}
          >
            <option value=""></option>
            {ddlLabelType.map((item, index) => (
              <option
                key={index}
                value={item}
              >
                {item}
              </option>
            ))}
          </select>
        </div>
        <button
          className="signin-login-button btnHover"
          onClick={handleClick_Confirm}
        >
          Confirm
        </button>
        <br />
        <p className="error-message">{Response}</p>

        <PopupBatch
          show={show}
          setShow={setShow}
          setPage={props.setPage}
          AddItemDetails={AddItemDetails}
          setQuickLabelBarcode={props.setBarcode}
          setResponse={props.setResponse}
          Origin={props.Origin}
          ShelfPrinter={props.currentShelfPrinter}
        />

        <PopupConfirm
          show={showPopupOOS}
          setShow={setShowPopupOOS}
          setPage={props.setPage}
          setMenuResponse={setResponse}
          setBarcode={props.setBarcode}
          ConfirmType="OOS"
          From="MERCH"
          BatchID={props.currentMerchBatchID}
          ShelfPrinter={props.currentShelfPrinter}
          ItemNo={Item.ItemNo}
          Quantity={Qty}
          LabelType={LabelType}
        />

        <PopupConfirm
          show={showPopupEDM}
          setShow={setShowPopupEDM}
          setPage={props.setPage}
          setMenuResponse={setResponse}
          setBarcode={props.setBarcode}
          ConfirmType="EDM"
          From="MERCH"
          BatchID={props.currentMerchBatchID}
          ShelfPrinter={props.currentShelfPrinter}
          ItemNo={Item.ItemNo}
          Quantity={Qty}
          LabelType={LabelType}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={yellow}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
      </div>
    </div>
  );
};
