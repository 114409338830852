import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";

export const Main = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.MenuResponse);
  const [BlockedOrdersTable, setBlockedOrdersTable] = useState("");
  const [LoadingMessage, setLoadingMessage] = useState(false);

  const refOrderID = useRef();

  const pageLoad = async () => {
    setMenuResponse("");
    setLoadingMessage(true);

    const resObj = await runFetch(`${spURL_NoInputJSON}BigCommerce_BlockedOrders_Refresh`, {
      method: "POST",
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setBlockedOrdersTable(
          spResponse.Table.map((item, index) => (
            <tr
              key={index}
              onClick={() => handleClick_Row(item.OrderID)}
              className="btnHover"
            >
              <td>{item.OrderID}</td>
              <td>{item.Provider}</td>
              <td>{item.Flag}</td>
              <td>{item.OrderPlaced}</td>
              <td>{item.BillingName}</td>
              <td>{convertToCurrency(item.OrderTotalValue, "$")}</td>
            </tr>
          ))
        );
      }
    }
    setLoadingMessage(false);
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Row = (_orderid) => {
    setMenuResponse("");

    props.setOrderID(_orderid);
    props.setPage("update");
  };

  const handleClick_Search = () => {
    setMenuResponse("");

    props.setOrderID(refOrderID.current.value);
    props.setPage("search");
  };

  return (
    <div className="page-container">
      <div className="blockedorders-body-container">
        <h4>
          <strong>Pending Blocked Orders List</strong>
        </h4>
        <div className="blockedorders-input-row">
          <p>
            <strong>OrderID:</strong>
          </p>
          <input
            type="text"
            id="blockedorders-main-orderid"
            autoFocus
            ref={refOrderID}
            onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Search)}
          />
          <ContentButton
            id="blockedorders-main-enter"
            colour={green}
            button="Go"
            onClick={handleClick_Search}
          />
        </div>
        <br />
        {LoadingMessage ? (
          <p>Loading...</p>
        ) : (
          BlockedOrdersTable && (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>
                    <strong>OrderID</strong>
                  </th>
                  <th>
                    <strong>Provider</strong>
                  </th>
                  <th>
                    <strong>Flag</strong>
                  </th>
                  <th>
                    <strong>OrderPlaced</strong>
                  </th>
                  <th>
                    <strong>BillingName</strong>
                  </th>
                  <th>
                    <strong>Total</strong>
                  </th>
                </tr>
              </thead>
              <tbody>{BlockedOrdersTable}</tbody>
            </table>
          )
        )}
        <br />
        <p className="error-message">{MenuResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <Link to="/EComm">
          <FooterButton
            button="Back"
            colour={green}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
        <FooterButton
          button="Mng"
          colour={green}
          onClick={() => {
            props.setPage("manage");
          }}
        />
      </div>
    </div>
  );
};
