import { useEffect, useState } from "react";
import { green, spURL } from "../../App";
import {
  format,
  subMonths,
  addMonths,
  startOfDay,
  endOfDay,
  startOfWeek,
  addDays,
  isSameDay,
  isPast,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  parseISO,
  startOfMonth,
  endOfMonth,
  getWeeksInMonth,
} from "date-fns";
import { PopupDeliveries } from "./PopupDeliveries";
import { FooterButton } from "../../components/HomepageButton";
import { Link } from "react-router-dom";
import { PopupUnblockTimeslot } from "./PopupUnblockTimeslot";
import { Modal } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const PopupChangeTimeslot = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [PopupResponse, setPopupResponse] = useState("");
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [currentSelectedDate, setCurrentSelectedDate] = useState(new Date());
  const [currentSelectedInterval, setCurrentSelectedInterval] = useState("");

  const [bookedTimeslot, setBookedTimeslot] = useState([]);

  const emptyStockTarget = {
    W1Target: 0,
    W1Max: 0,
    W1Booked: 0,
    W1Spare: 0,
    W2Target: 0,
    W2Max: 0,
    W2Booked: 0,
    W2Spare: 0,
    W3Target: 0,
    W3Max: 0,
    W3Booked: 0,
    W3Spare: 0,
    W4Target: 0,
    W4Max: 0,
    W4Booked: 0,
    W4Spare: 0,
    AllTarget: 0,
    AllMax: 0,
    AllBooked: 0,
    AllSpare: 0,
  };

  const onDateClickHandle = (day, dayStr, interval) => {
    let timeslotColour = bookedTimeslot
      .map((item) => `${parseISO(item.Date)}-${item.Interval}`)
      .includes(`${day}-${interval}`)
      ? bookedTimeslot.filter(
          (value) => `${parseISO(value.Date)}-${value.Interval}` === `${day}-${interval}`
        )[0].Colour
      : "";
    if (!isPast(day) && timeslotColour !== "RED") {
      setCurrentSelectedDate(day.toLocaleDateString("en-US"));
      setCurrentSelectedInterval(interval);
    }
  };

  const getDeliveries = async () => {
    const resObj = await runFetch(`${spURL}Handheld_IncomingDeliveries_Deliveries_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setBookedTimeslot(
          spResponse.Table.map((item) => ({
            Date: item.DeliveryDate,
            Interval: item.DeliveryInterval,
            Colour: item.Colour,
            DeliverySummary: item.DeliverySummary,
          }))
        );
      }
    }
  };

  useEffect(() => {
    getDeliveries();
  }, [currentWeek, currentMonth]);

  const renderHeader = () => {
    const dateFormat = "MMM yyyy";
    return (
      <>
        <div className="header row flex-middle">
          <div className="col"></div>
          <div className="col col-center">
            <span>{format(currentMonth, dateFormat)}</span>
          </div>
          <div className="col"></div>
        </div>
      </>
    );
  };
  const renderDays = (startDate) => {
    const dateFormat = "EEE";
    const days = [];
    days.push(<div className="col col-title"></div>);
    for (let i = 0; i < 7; i++) {
      days.push(
        <div
          className="col col-center"
          key={i}
        >
          {format(addDays(startDate, i), dateFormat)}
          <br />
          {format(addDays(startDate, i), "dd")}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };

  const renderCells = (startDate) => {
    const endDate = lastDayOfWeek(startDate, { weekStartsOn: 1 });

    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    for (let j = 0; j < 2; j++) {
      const interval = j === 0 ? "MORNING" : "AFTERNOON";
      while (day <= endDate) {
        days.push(
          <div className={`col cell col-title`}>
            <span className="number">
              {interval === "MORNING" ? (
                <>
                  Morning
                  <br />
                  (9am - 12pm)
                </>
              ) : (
                <>
                  Afternoon
                  <br />
                  (12pm - 4pm)
                </>
              )}
            </span>
          </div>
        );
        for (let i = 0; i < 7; i++) {
          formattedDate = format(day, dateFormat);
          const cloneDay = day;

          days.push(
            <div
              className={`col cell ${
                isPast(day, new Date())
                  ? "pastday"
                  : `${day.toLocaleDateString("en-US")}-${interval}` ===
                    `${currentSelectedDate}-${currentSelectedInterval}`
                  ? "selected"
                  : bookedTimeslot
                      .map((value) => `${parseISO(value.Date)}-${value.Interval}`)
                      .includes(`${day}-${interval}`)
                  ? `${bookedTimeslot
                      .filter(
                        (value) =>
                          `${parseISO(value.Date)}-${value.Interval}` === `${day}-${interval}`
                      )[0]
                      .Colour.toLowerCase()}day`
                  : "whiteday"
              } `}
              key={`${day}-${interval} `}
              dateinterval={`${day}-${interval} `}
              onClick={() => {
                const dayStr = format(cloneDay, "ccc dd MMM yy");
                onDateClickHandle(cloneDay, dayStr, interval);
              }}
            >
              <p>
                {bookedTimeslot
                  .map((value) => `${parseISO(value.Date)}-${value.Interval}`)
                  .includes(`${day}-${interval}`)
                  ? `${
                      bookedTimeslot.filter(
                        (value) =>
                          `${parseISO(value.Date)}-${value.Interval}` === `${day}-${interval}`
                      )[0].DeliverySummary
                    }`
                  : ""}
              </p>
            </div>
          );
          day = addDays(day, 1);
        }
      }
      day = startDate;
    }
    rows.push(
      <div
        className="row"
        key={day}
      >
        {days}
      </div>
    );
    days = [];

    return <div className="body">{rows}</div>;
  };

  const renderRolling4Weeks = () => {
    // get first day of current week
    let currentStartDayOfWeek = startOfWeek(currentMonth, { weekStartsOn: 1 });

    const weeks = [];

    // loop through all weeks of months and generate UI
    for (let currentWeek = 0; currentWeek < 4; currentWeek++) {
      weeks.push(renderDays(currentStartDayOfWeek));
      weeks.push(renderCells(currentStartDayOfWeek));

      currentStartDayOfWeek = addWeeks(currentStartDayOfWeek, 1);
    }

    return weeks;
  };

  const cleanPopup = () => {
    setPopupResponse("");
  };

  const handleClose = () => {
    cleanPopup();
    props.setShow(false);
  };

  const handleSubmit = async () => {
    const resObj = await runFetch(`${spURL}Handheld_IncomingDeliveries_Timeslot_Change`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          BookingID: props.ChangeTimeslotBookingID,
          NewDeliveryDate: currentSelectedDate,
          NewDeliveryInterval: currentSelectedInterval,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setIncomingDeliveriesResponse(spResponse.Table[0].OutputString);
        props.setPopupResponse("");
        cleanPopup();
        props.setShow(false);
        props.closePopup();
        props.getDeliveries();
      }
    }
  };

  useEffect(() => {
    renderRolling4Weeks();
  }, [currentSelectedDate, currentSelectedInterval]);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        size="lg"
        keyboard={true}
        centered
      >
        <Modal.Header>
          <p>
            <strong>CHANGE TIMESLOT</strong>
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="calendar">
            <p className="error-message">{PopupResponse}</p>
            <p>
              <strong>Old Timeslot: </strong>
              {props.selectedDate} {props.selectedInterval}
            </p>
            {renderRolling4Weeks()}
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Cancel</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit}
            >
              <strong>Change</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
