import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { blue, green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import { handleChange_Input } from "../../functions/HandleChangeInput";

export const DeviceAuth = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.MenuResponse);
  const [DeviceAuthTable, setDeviceAuthTable] = useState("");
  const [ddlStore, setDDLStore] = useState("");
  const [searchDeviceID, setSearchDeviceID] = useState("");
  const [searchDisplayName, setSearchDisplayName] = useState("");

  const [filterOptions, setFilterOptions] = useState({
    StoreName: "ALL",
    Status: "ALL",
    DeviceType: "ALL",
  });

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_DeviceAuth_DDLStore_Get`, {
      method: "POST",
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDDLStore(
          spResponse.Table.map((item, index) => (
            <option
              key={index}
              value={item.Store}
            >
              {item.Store}
            </option>
          ))
        );
      }
    }
    fetchData();
  };

  async function fetchData() {
    const resObj = await runFetch(`${spURL}Handheld_DeviceAuth_Refresh`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(filterOptions),
      }),
    });
    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setDeviceAuthTable(
          spResponse.Table.map((item) => ({
            DeviceID: item.DeviceID,
            DeviceAuthCode: item.DeviceAuthCode,
            DisplayName: item.DisplayName,
            StoreNo: item.StoreNo,
            Site: item.Site,
            DeviceType: item.DeviceType,
            Status: item.Status,
            DateCreated: item["Date Created"],
            DateAuthenticated: item["Date Authenticated"],
          }))
        );
      }
    }
  }

  // page first load
  useEffect(() => {
    pageLoad();
  }, []);

  // when filterOptions is changed
  useEffect(() => {
    fetchData();
  }, [filterOptions]);

  const handleChange_StoreName = (event) => {
    const _storename = event.target.value;

    setFilterOptions((values) => ({
      ...values,
      StoreName: _storename,
    }));
  };

  const handleChange_Status = (event) => {
    const _status = event.target.value;

    setFilterOptions((values) => ({
      ...values,
      Status: _status,
    }));
  };

  const handleChange_DeviceType = (event) => {
    const _devicetype = event.target.value;

    setFilterOptions((values) => ({
      ...values,
      DeviceType: _devicetype,
    }));
  };

  const handleChange_DeviceID = (event) => {
    setSearchDeviceID(event.target.value);
  };

  const handleChange_DisplayName = (event) => {
    setSearchDisplayName(event.target.value);
  };

  const handleClick_Row = (event) => {
    const _deviceid = event.target.parentElement.attributes["deviceid"].value;
    const _deviceauthcode = event.target.parentElement.attributes["deviceauthcode"].value;
    const _displayname = event.target.parentElement.attributes["displayname"].value;
    const _devicestoreno = event.target.parentElement.attributes["devicestoreno"].value;
    const _devicesite = event.target.parentElement.attributes["devicesite"].value;
    const _devicetype = event.target.parentElement.attributes["devicetype"].value;
    const _devicestatus = event.target.parentElement.attributes["devicestatus"].value;

    props.setCurrentDevice({
      DeviceID: _deviceid,
      DeviceAuthCode: _deviceauthcode,
      DisplayName: _displayname,
      StoreNo: _devicestoreno,
      Site: _devicesite,
      DeviceType: _devicetype,
      Status: _devicestatus,
    });

    props.setPage("deviceauth-edit");
  };

  const handleClick_Create = () => {
    props.setCurrentDevice({
      DeviceID: "",
      DeviceAuthCode: "",
      DisplayName: "",
      StoreNo: "",
      Site: "",
      DeviceType: "",
      Status: "",
    });

    props.setPage("deviceauth-create");
  };

  const handleClick_Back = () => {
    props.setMenuResponse("");
    props.setPage("menu");
  };

  return (
    <div className="unallocatedorders-page-container">
      <div className="unallocatedorders-top-row">
        <div className="blockedorders-input-row">
          <p>
            <strong>Store:</strong>
          </p>
          <select
            name="store"
            id="deviceauth-main-store"
            onChange={(e) => {
              handleChange_Input(e, (inp) => {
                setFilterOptions((values) => ({
                  ...values,
                  StoreName: inp,
                }));
              });
            }}
          >
            {ddlStore}
          </select>
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>Status:</strong>
          </p>
          <select
            name="status"
            id="deviceauth-main-status"
            onChange={(e) => {
              handleChange_Input(e, (inp) => {
                setFilterOptions((values) => ({
                  ...values,
                  Status: inp,
                }));
              });
            }}
          >
            <option value="ALL">ALL</option>
            <option value="ACTIVE">ACTIVE</option>
            <option value="INACTIVE">INACTIVE</option>
          </select>
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>DeviceType:</strong>
          </p>
          <select
            name="site"
            id="deviceauth-main-devicetype"
            onChange={(e) => {
              handleChange_Input(e, (inp) => {
                setFilterOptions((values) => ({
                  ...values,
                  DeviceType: inp,
                }));
              });
            }}
          >
            <option value="ALL">ALL</option>
            <option value="SCANSKU">SCANSKU</option>
            <option value="OTHER">OTHER</option>
          </select>
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>DeviceID:</strong>
          </p>
          <input
            type="text"
            id="deviceauth-main-deviceid"
            onChange={(e) => handleChange_Input(e, setSearchDeviceID)}
          />
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>DisplayName:</strong>
          </p>
          <input
            type="text"
            id="deviceauth-main-deviceauthcode"
            onChange={(e) => handleChange_Input(e, setSearchDisplayName)}
          />
        </div>
        <div>
          <input
            type="button"
            id="unallocatedorders-refresh"
            value="Refresh"
            onClick={fetchData}
          />
        </div>
      </div>
      <div>
        <div className="blockedorders-content-container">
          <p className="error-message">{MenuResponse}</p>
          {DeviceAuthTable.length === 0 ? (
            ""
          ) : (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>DeviceID</th>
                  <th>DeviceAuthCode</th>
                  <th>DisplayName</th>
                  <th>StoreNo</th>
                  <th>Site</th>
                  <th>DeviceType</th>
                  <th>Status</th>
                  <th>Date Created</th>
                  <th>Date Authenticated</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: 16 }}>
                {DeviceAuthTable.reduce(function (filtered, device, index) {
                  if (
                    device.DeviceID.includes(searchDeviceID.toUpperCase()) &&
                    device.DisplayName.includes(searchDisplayName.toUpperCase())
                  ) {
                    var tableRow = (
                      <tr
                        key={index}
                        className="btnHover"
                        onClick={handleClick_Row}
                        deviceid={device.DeviceID}
                        deviceauthcode={device.DeviceAuthCode}
                        displayname={device.DisplayName}
                        devicestoreno={device.StoreNo}
                        devicesite={device.Site}
                        devicetype={device.DeviceType}
                        devicestatus={device.Status}
                      >
                        <td>{device.DeviceID}</td>
                        <td>{device.DeviceAuthCode}</td>
                        <td>{device.DisplayName}</td>
                        <td>{device.StoreNo}</td>
                        <td>{device.Site}</td>
                        <td>{device.DeviceType}</td>
                        <td>{device.Status}</td>
                        <td>{ConvertDateFormat(device.DateCreated)}</td>
                        <td>{ConvertDateFormat(device.DateAuthenticated)}</td>
                      </tr>
                    );

                    filtered.push(tableRow);
                  }

                  return filtered;
                }, [])}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={blue}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
        <FooterButton
          button="Create"
          colour={blue}
          onClick={handleClick_Create}
        />
      </div>
    </div>
  );
};
