import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";

import {
  spURL,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
  spURL_LocalPOS,
  yellow,
} from "../../App";
import { runFetch } from "../../functions/RunFetch";
import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { PopupConfirmMerch } from "./PopupConfirmMerch";
import { PopupErrorMerch } from "./PopupErrorMerch";
import { PopupMerchandising } from "./PopupMerchandising";
import { PopupPriceChangeShelfPrinter } from "./PopupPriceChangeShelfPrinter";
import { Link } from "react-router-dom";
import store from "../../store";

export const PriceChangesItems = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [LabelResponse, setLabelResponse] = useState("");
  const [ConfirmMessage, setConfirmMessage] = useState("");
  const [ItemList, setItemList] = useState([]);
  const [ItemBarcode, setItemBarcode] = useState("");
  const [PickFilter, setPickFilter] = useState("UNSCANNED");
  const [IsSOHOnlyChecked, setIsSOHOnlyChecked] = useState(true);

  const [showErrorMerch, setShowErrorMerch] = useState(false);
  const [showConfirmMerch, setShowConfirmMerch] = useState(false);
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const [showMerchandising, setShowMerchandising] = useState(false);
  const [showPrintLabels, setShowPrintLabels] = useState(false);

  const [SelectedItemNo, setSelectedItemNo] = useState("");
  const [PrintItemNo, setPrintItemNo] = useState("");

  const refItemBarcode = useRef();
  const refSOHOnly = useRef();

  const handleClick_Back = () => {
    props.setPriceChangesCategory("");
    props.setPage("pricechanges-categories");
  };

  const getItemList = async () => {
    refItemBarcode.current.value = "";

    const resObj = await runFetch(`${spURL}Handheld_Label_PriceChange_ItemList_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          Category: props.PriceChangesCategory,
          PriceChangeMode: props.PriceChangesBatchDetails.Mode,
        }),
      }),
    });
    if (resObj.exception !== "") {
      setLabelResponse(resObj.exception);
      setShowErrorMerch(true);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setItemList(
          spResponse.Table.map((item) => ({
            ItemNo: item.ItemNo,
            Description: item.Description,
            Processed: item.Processed,
            SOH: item.SOH,
            ImageURL: item.ImageURL,
            Type: item.Type,
          }))
        );
      } else {
        setItemList([]);
      }
    }
  };

  const handleClick_PickFilter = (_filter) => {
    setLabelResponse("");
    setItemList([]);
    setPickFilter(_filter);
    getItemList(_filter);
    refItemBarcode.current.value = "";
    refItemBarcode.current.focus();
  };

  const handleClick_Search = async () => {
    setLabelResponse("");
    const resObj = await runFetch(`${spURL}Handheld_Label_PriceChange_Item_Process`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          Category: props.PriceChangesCategory,
          Barcode: ItemBarcode,
          PriceChangeMode: props.PriceChangesBatchDetails.Mode,
        }),
      }),
    });
    if (resObj.exception !== "") {
      setLabelResponse(resObj.exception);
      setShowErrorMerch(true);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        if (spResponse.Table[0].OutputString === "SCANNED") {
          setConfirmMessage(
            "Item has already been scanned for this week's price change. Continue?"
          );
          setPrintItemNo(spResponse.Table[0].ItemNo);
          setShowConfirmMerch(true);
        } else {
          setPrintItemNo(spResponse.Table[0].ItemNo);
          setShowPrintLabels(true);
          setLabelResponse(spResponse.Table[0].OutputString);
        }

        getItemList(PickFilter);
      }
    }

    refItemBarcode.current.value = "";
    refItemBarcode.current.select();
  };

  const handleClick_Reset = () => {
    setConfirmMessage("Do you want to reset all items as UNSCANNED?");
    setShowConfirmReset(true);
  };

  const addLabel = async () => {
    setShowPrintLabels(true);
  };

  const resetCategory = async () => {
    setLabelResponse("");

    const resObj = await runFetch(`${spURL}Handheld_Label_PriceChange_Category_Reset`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          Category: props.PriceChangesCategory,
          PriceChangeMode: props.PriceChangesBatchDetails.Mode,
        }),
      }),
    });
    if (resObj.exception !== "") {
      setLabelResponse(resObj.exception);
      setShowErrorMerch(true);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setLabelResponse(spResponse.Table[0].OutputString);
        getItemList(PickFilter);
      }
    }

    refItemBarcode.current.value = "";
    refItemBarcode.current.select();
  };

  const handleClick_SOHOnly = () => {
    setIsSOHOnlyChecked(refSOHOnly.current.checked);
  };

  const renderItemList = () => {
    if (ItemList.length === 0) {
      return <p className="error-message">No price changed items for this week.</p>;
    } else {
      return ItemList.filter((item) => {
        let showProcessed = 0;
        switch (PickFilter) {
          case "UNSCANNED":
            showProcessed = 0;
            break;
          case "SCANNED":
            showProcessed = 1;
            break;
          default:
            break;
        }

        return parseInt(showProcessed) === parseInt(item.Processed);
      })
        .filter((item) => {
          if (IsSOHOnlyChecked) {
            return parseInt(item.SOH) > 0;
          } else {
            return item;
          }
        })
        .map((item, index) => (
          // <tr key={index} className='btnHover' onClick={() => { props.setPriceChangesItemNo(item.ItemNo); props.setPage('PriceChanges-item') }}>
          <tr
            key={index}
            onClick={() => {
              refItemBarcode.current.value = "";
              setSelectedItemNo(item.ItemNo);
              setShowMerchandising(true);
            }}
            className={`btnHover ${item.Processed && "background-lightgreen"}`}
          >
            <td>
              <img
                width={100}
                src={item.ImageURL}
                alt={item.Description}
              />
            </td>
            <td>
              <strong>{item.Description}</strong>
              <br />
              {item.ItemNo}
            </td>
            <td>{item.SOH}</td>
            <td>{item.Type}</td>
          </tr>
        ));
    }
  };

  useEffect(() => {
    getItemList("UNSCANNED");
    refItemBarcode.current.value = "";
    refItemBarcode.current.focus();
  }, []);

  return (
    <div className="weborders-page-container">
      <div className="weborders-top-row">
        <div className="weborders-top-row-container pricechanges-top-row-container">
          <div className="weborders-option-row pricechanges-option-row">
            <ContentButton
              button=""
              colour="white"
              id="weborders-pickfilter-new"
              onClick={() => {
                handleClick_PickFilter("UNSCANNED");
              }}
            />
            <ContentButton
              button=""
              colour="lightgreen"
              id="weborders-pickfilter-completed"
              onClick={() => handleClick_PickFilter("SCANNED")}
            />
          </div>
          <div className="weborders-sohonly">
            <p>
              <strong>{PickFilter} Items</strong>
            </p>
            <div className="weborders-printweblabels-container">
              <input
                type="checkbox"
                id="weborders-printweblabels"
                ref={refSOHOnly}
                name="weborders-printweblabels"
                defaultChecked
                onClick={handleClick_SOHOnly}
              />
              <label htmlFor="weborders-printweblabels">In stock items only</label>
            </div>
          </div>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Barcode</strong>
          </p>
          <input
            type="text"
            ref={refItemBarcode}
            id="weborders-orderid"
            autoFocus
            onChange={(e) => {
              setLabelResponse("");
              handleChange_Input(e, setItemBarcode);
            }}
            onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Search)}
          />
        </div>
        <button
          className="signin-login-button hidden"
          id="weborders-enter"
          onClick={handleClick_Search}
        >
          Search
        </button>
        <p>
          <strong>Category: {props.PriceChangesCategory}</strong>
        </p>
      </div>
      <div className="handheld-body-container">
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>Image</th>
              <th>Item</th>
              <th>SOH</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>{renderItemList()}</tbody>
        </table>

        <PopupConfirmMerch
          show={showConfirmMerch}
          setShow={setShowConfirmMerch}
          ConfirmMessage={ConfirmMessage}
          actionOk={addLabel}
        />
        <PopupConfirmMerch
          show={showConfirmReset}
          setShow={setShowConfirmReset}
          ConfirmMessage={ConfirmMessage}
          actionOk={resetCategory}
        />
        <PopupErrorMerch
          show={showErrorMerch}
          setShow={setShowErrorMerch}
          LabelResponse={LabelResponse}
        />
        <PopupMerchandising
          show={showMerchandising}
          setShow={setShowMerchandising}
          ItemNo={SelectedItemNo}
        />
        <PopupPriceChangeShelfPrinter
          show={showPrintLabels}
          setShow={setShowPrintLabels}
          ItemNo={PrintItemNo}
          PriceChangesBatchDetails={props.PriceChangesBatchDetails}
        />
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={yellow}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
        <FooterButton
          button="Reset"
          colour={yellow}
          onClick={handleClick_Reset}
        />
      </div>
    </div>
  );
};
