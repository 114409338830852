import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { orange, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const EditHeader = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [PODHeaderTable, setPODHeaderTable] = useState([]);

  const handleClick_Back = () => {
    props.setPODCodeResponse("");
    props.setPage("menu");
  };

  useEffect(() => {
    async function getHeader() {
      const resObj = await runFetch(`${spURL}POD_Edit_Header`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Store: storeno,
          }),
        }),
      });

      if (resObj.response === null) {
        props.setPODCodeResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          if (spResponse.Table.length === 0) {
            props.setPODCodeResponse("No active POD found.");
            props.setPage("menu");
          } else {
            setPODHeaderTable(spResponse.Table);
          }
        }
      }
    }
    getHeader();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>PODCode</th>
              <th>Date</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {PODHeaderTable.map((item, index) => (
              <tr
                key={`tablerow-${index}`}
                onClick={() => {
                  props.setPODCodeResponse("");

                  props.setPODCode(item.PODCode);
                  props.setConNoteDetails({
                    Driver: item.Driver,
                    TransportCompany: item.TransportCompany,
                    QuantityReceived: item.QuantityReceived,
                    UnitType: item.UnitType,
                  });

                  props.setPage("edit_lines");
                }}
                className="btnHover"
              >
                <td>{item.PODCode}</td>
                <td>{ConvertDateFormat(item.Date)}</td>
                <td>{item.Name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
