import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { convertToCurrency } from "../../functions/ConvertToCurrency";

import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";

export const Main = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.MenuResponse);
  const [FailedOrdersTable, setFailedOrdersTable] = useState("");
  const [LoadingMessage, setLoadingMessage] = useState(false);

  const refOrderID = useRef();

  const pageLoad = async () => {
    setLoadingMessage(true);

    const resObj = await runFetch(`${spURL_NoInputJSON}FailedOrders_Refresh`, { method: "POST" });
    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table.length > 0) {
          setFailedOrdersTable(
            spResponse.Table.map((item, index) => (
              <tr
                key={index}
                onClick={() => {
                  props.setOrderID(item.OrderID);
                  props.setPage("update");
                }}
                className="btnHover"
              >
                <td>{item.OrderID}</td>
                <td>{item.Error}</td>
                <td>{item.OrderPlaced}</td>
                <td>{item.BillingName}</td>
                <td>{convertToCurrency(item.OrderTotalValue, "$")}</td>
              </tr>
            ))
          );
        } else {
          setMenuResponse("No orders found.");
        }
      }
    }
    setLoadingMessage(false);
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Search = () => {
    props.setOrderID(refOrderID.current.value);
    props.setPage("search");
  };

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <h4>
          <strong>Pending Failed Orders List</strong>
        </h4>
        <div className="blockedorders-input-row">
          <p>
            <strong>OrderID:</strong>
          </p>
          <input
            type="text"
            id="blockedorders-main-orderid"
            ref={refOrderID}
            autoFocus
            onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Search)}
          />
          <ContentButton
            id="blockedorders-main-enter"
            colour={green}
            button="Go"
            onClick={handleClick_Search}
          />
        </div>
        <br />
        {LoadingMessage ? (
          <p>Loading...</p>
        ) : (
          FailedOrdersTable && (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>
                    <strong>OrderID</strong>
                  </th>
                  <th>
                    <strong>Error </strong>
                  </th>
                  <th>
                    <strong>OrderPlaced</strong>
                  </th>
                  <th>
                    <strong>BillingName</strong>
                  </th>
                  <th>
                    <strong>Total</strong>
                  </th>
                </tr>
              </thead>
              <tbody>{FailedOrdersTable}</tbody>
            </table>
          )
        )}
        <br />
        <p className="error-message">{MenuResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <Link to="/EComm">
          <FooterButton
            button="Back"
            colour={green}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
