import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL } from "../../App";
import _default from "react-bootstrap/Modal";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const PopupBlockReason = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [PopupResponse, setPopupResponse] = useState("");
  const [selectedReason, setSelectedReason] = useState("");

  const refReason = useRef();

  const cleanReason = () => {
    setSelectedReason("");
    refReason.current.value = "";
  };

  const blockBookingSlot = async () => {
    const resObj = await runFetch(`${spURL}Handheld_IncomingDeliveries_Timeslot_Block`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          DeliveryDate: props.selectedDate,
          DeliveryInterval: props.selectedInterval,
          Reason: selectedReason,
        }),
      }),
    });
    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setIncomingDeliveriesResponse(spResponse.Table[0].OutputString);
        props.setPopupResponse("");
        cleanReason();
        props.setShow(false);
        props.closePopup();
        props.getDeliveries();
      }
    }
  };

  const handleClose = () => {
    setPopupResponse("");
    setSelectedReason("");

    props.setShow(false);
  };

  const handleShow = () => {
    setPopupResponse("");
  };

  const handleSubmit = () => {
    if (selectedReason !== "") {
      blockBookingSlot();
    } else {
      setPopupResponse("ERROR!: No block reason selected.");
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={true}
        centered
      >
        <Modal.Body>
          <div>
            <p className="popup-title">
              <strong>SELECT BLOCK REASON</strong>
            </p>
            <div className="input-row">
              <p>
                <strong>Reason</strong>
              </p>
              <select
                ref={refReason}
                onChange={(e) => handleChange_Input(e, setSelectedReason)}
              >
                <option
                  value=""
                  name=""
                ></option>
                <option
                  value="WH Delivery"
                  name="WH Delivery"
                >
                  WH Delivery
                </option>
                <option
                  value="Staff Shortage"
                  name="Staff Shortage"
                >
                  Staff Shortage
                </option>
              </select>
            </div>
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Cancel</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
