import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, blue } from "../../App";
import { runFetch } from "../../functions/RunFetch";

export const PopupEntryDetails = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const refStreet1 = useRef();
  const refPostCode = useRef();
  const refEmail = useRef();

  const cleanPopup = () => {
    setPopupResponse("");

    switch (props.ManageMode) {
      case "Address":
        refStreet1.current.value = "";
        refPostCode.current.value = "";
        break;
      case "Email":
        refEmail.current.value = "";
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    cleanPopup();
    props.setShow(false);
  };

  const handleShow = () => {
    props.setBlockedOrdersResponse("");
    setPopupResponse("");
    if (props.EntryMode === "EDIT") {
      switch (props.ManageMode) {
        case "Address":
          refStreet1.current.value = props.SelectedEntry.Street1;
          refPostCode.current.value = props.SelectedEntry.PostCode;

          refStreet1.current.focus();
          break;
        case "Email":
          refEmail.current.value = props.SelectedEntry.Email;

          refEmail.current.focus();
          break;
        default:
          break;
      }
    }
  };

  const handleClick_Delete = async () => {
    const resObj = await runFetch(
      `${spURL}BigCommerce_BlockedOrders_Manage_${props.ManageMode}_List_Delete`,
      {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            ID: props.SelectedEntry.ID,
          }),
        }),
      }
    );

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setBlockedOrdersResponse(spResponse.Table[0].OutputString);
        props.RefreshTable();
        props.setShow(false);
      }
    }
  };

  const handleClick_Ok = async () => {
    let action = "";
    let objJSON = {};

    switch (props.EntryMode) {
      case "ADD":
        action = "Add";

        if (props.ManageMode === "Address") {
          objJSON = {
            Street1: refStreet1.current.value,
            PostCode: refPostCode.current.value,
          };
        } else if (props.ManageMode === "Email") {
          objJSON = {
            Email: refEmail.current.value,
          };
        }
        break;
      case "EDIT":
        action = "Edit";

        if (props.ManageMode === "Address") {
          objJSON = {
            ID: props.SelectedEntry.ID,
            Street1: refStreet1.current.value,
            PostCode: refPostCode.current.value,
          };
        } else if (props.ManageMode === "Email") {
          objJSON = {
            ID: props.SelectedEntry.ID,
            Email: refEmail.current.value,
          };
        }
        break;
      default:
        break;
    }

    const resObj = await runFetch(
      `${spURL}BigCommerce_BlockedOrders_Manage_${props.ManageMode}_List_${action}`,
      {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(objJSON),
        }),
      }
    );

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setBlockedOrdersResponse(spResponse.Table[0].OutputString);
        props.RefreshTable();
        props.setShow(false);
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <h1>
              <strong>{props.EntryMode} Blocked Entry</strong>
            </h1>
            <br />
            {props.ManageMode === "Address" ? (
              <>
                <div className="stocktake-create-input-row">
                  <p>
                    <strong>Street1</strong>
                  </p>
                  <input
                    type="text"
                    ref={refStreet1}
                  />
                </div>
                <div className="stocktake-create-input-row">
                  <p>
                    <strong>PostCode</strong>
                  </p>
                  <input
                    type="text"
                    ref={refPostCode}
                  />
                </div>
              </>
            ) : null}
            {props.ManageMode === "Email" ? (
              <>
                <div className="stocktake-create-input-row">
                  <p>
                    <strong>Email</strong>
                  </p>
                  <input
                    type="text"
                    ref={refEmail}
                  />
                </div>
              </>
            ) : null}
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
            {props.EntryMode !== "ADD" ? (
              <button
                className="popup-button"
                onClick={handleClick_Delete}
              >
                Delete
              </button>
            ) : null}
            <button
              className="popup-button"
              onClick={handleClick_Ok}
            >
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
