import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ContentButton, FooterButton, MenuButton } from "../../components/HomepageButton";
import { blue, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { PopupStoreDetails } from "./PopupStoreDetails";
import { runFetch } from "../../functions/RunFetch";

export const StoreListMain = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.MenuResponse);
  const [SelectedStoreNo, setSelectedStoreNo] = useState("");
  const [StoreListTable, setStoreListTable] = useState([]);

  const [showStoreDetails, setShowStoreDetails] = useState(false);

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_StoreList_StoreList_Get`, {
      method: "POST",
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStoreListTable(
          spResponse.Table.map((item) => ({
            Status: item.Status,
            StoreNo: item.StoreNo,
            StoreName: item.StoreName,
            State: item.State,
            PostCode: item.PostCode,
            AreaManager: item.AreaManager,
            Prefix: item.Prefix,
            PhoneNo: item.PhoneNo,
          }))
        );
      } else {
        setStoreListTable([]);
      }
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <p className="error-message">{MenuResponse}</p>
        <p>
          <strong>OPEN stores</strong>
        </p>
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th className="desktop-only">State</th>
              <th className="desktop-only">Prefix</th>
              <th>StoreName</th>
              <th>StoreNo</th>
              <th className="desktop-only">PostCode</th>
              <th className="desktop-only">AreaManager</th>
              <th>Phone No.</th>
            </tr>
          </thead>
          <tbody>
            {StoreListTable.filter((item) => item.Status === "OPEN").map((item, index) => (
              <tr
                key={index}
                className="btnHover"
                storeno={item.StoreNo}
                onClick={() => {
                  setMenuResponse("");
                  setSelectedStoreNo(item.StoreNo);
                  setShowStoreDetails(true);
                }}
              >
                <td className="desktop-only">{item.State}</td>
                <td className="desktop-only">{item.Prefix}</td>
                <td>{item.StoreName}</td>
                <td>{item.StoreNo}</td>
                <td className="desktop-only">{item.PostCode}</td>
                <td className="desktop-only">{item.AreaManager}</td>
                <td>{item.PhoneNo}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <p>
          <strong>NEW stores</strong>
        </p>
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>State</th>
              <th>Prefix</th>
              <th>StoreName</th>
              <th>StoreNo</th>
              <th>PostCode</th>
              <th>AreaManager</th>
              <th>Phone No.</th>
            </tr>
          </thead>
          <tbody>
            {StoreListTable.filter((item) => item.Status === "NEW").map((item, index) => (
              <tr
                key={index}
                className="btnHover"
                storeno={item.StoreNo}
                onClick={() => {
                  setMenuResponse("");
                  setSelectedStoreNo(item.StoreNo);
                  setShowStoreDetails(true);
                }}
              >
                <td>{item.State}</td>
                <td>{item.Prefix}</td>
                <td>{item.StoreName}</td>
                <td>{item.StoreNo}</td>
                <td>{item.PostCode}</td>
                <td>{item.AreaManager}</td>
                <td>{item.PhoneNo}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <PopupStoreDetails
          show={showStoreDetails}
          setShow={setShowStoreDetails}
          StoreNo={SelectedStoreNo}
          pageLoad={pageLoad}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          onClick={() => props.setPage("menu")}
          colour={blue}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
      </div>
    </div>
  );
};
