import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";
import { useRef } from "react";

export const PopupConfirm = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [ConfirmResponse, setConfirmResponse] = useState(props.Response);
  const [PopupResponse, setPopupResponse] = useState("");

  const refPalletReceived = useRef();

  const handleClose = () => {
    props.setResponse("");
    props.setShow(false);
  };

  const handleShow = () => {
    setConfirmResponse(props.Response);
  };

  const handleClick_Yes = async () => {
    const resObj = await runFetch(`${spURL}Handheld_WHReceived_Receive_Submit`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: userid,
          StoreNo: storeno,
          JobNo: props.JobNo,
          PalletReceived: refPalletReceived.current.value,
          Override: "1",
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table[0].OutputString !== undefined) {
          props.setWHReceivedResponse(spResponse.Table[0].OutputString);
          props.setPage("menu");
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <p className="error-message">
              <strong>{ConfirmResponse}</strong>
            </p>
          </div>
          <br />
          <div className="stocktake-create-input-row">
            <p>
              <strong>Pallet received</strong>
            </p>
            <input
              type="text"
              id="whreceived-popupconfirm-palletreceived"
              ref={refPalletReceived}
            />
          </div>
          <br />
          <p className="error-message">{PopupResponse}</p>
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>No</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Yes}
            >
              <strong>Yes</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
