import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton, FooterButton } from "../../components/HomepageButton";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";

export const Menu = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [MenuResponse, setMenuResponse] = useState(props.MenuResponse);

  const handleClick_Manifest = async () => {
    const res = await trackPromise(
      fetch(
        "https://prod-28.australiaeast.logic.azure.com:443/workflows/6c21012c021542e5af273242c429e918/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=JvrXnqVMHO9e5dbRq9yFMmdFy8IVkok65Xej5VCsJc0",
        {
          method: "POST",
          body: new URLSearchParams({
            inputJSON: JSON.stringify({
              Store: storeno,
            }),
          }),
        }
      )
    );
    res
      .json()
      .then(() => {
        setMenuResponse("Email queued. Please check your inbox.");
      })
      .catch(() => {
        setMenuResponse("Server Error. Please try again later.");
      });
  };

  const handleClick_Single = () => {
    props.setMenuResponse("");
    props.setPage("single");
  };

  const handleClick_Multiple = () => {
    props.setMenuResponse("");
    props.setPage("multiple");
  };

  const handleClick_View = () => {
    props.setMenuResponse("");
    props.setPage("view");
  };

  const handleClick_Verify = () => {
    props.setMenuResponse("");
    props.setPage("verify");
  };

  const handleClick_WebReturns = () => {
    props.setMenuResponse("");
    props.setPage("return");
  };

  const handleClick_Manager = () => {
    props.setMenuResponse("");
    props.setPage("manager");
  };

  const handleClick_GiftCardLookup = () => {
    props.setMenuResponse("");
    props.setPage("giftcardlookup");
  };

  useEffect(() => {}, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          <MenuButton
            onClick={handleClick_Single}
            button="S"
            colour={green}
            title="Single"
          />
          <MenuButton
            onClick={handleClick_Multiple}
            button="M"
            colour={green}
            title="Multiple"
          />
          <MenuButton
            onClick={handleClick_View}
            button="V"
            colour={green}
            title="View"
          />
        </div>
        <div className="home-table">
          <MenuButton
            onClick={handleClick_Verify}
            button="V"
            colour={green}
            title="Verify"
          />
          <MenuButton
            onClick={handleClick_WebReturns}
            button="WR"
            colour={green}
            title="Web Returns"
          />
          {props.isPickManager && (
            <MenuButton
              onClick={handleClick_Manager}
              button="M"
              colour={green}
              title="Manager"
            />
          )}
        </div>
        <div className="home-table">
          <MenuButton
            onClick={handleClick_GiftCardLookup}
            button="GC"
            colour={green}
            title="Gift Card Lookup"
          />
          {store === "9903" ? (
            <MenuButton
              onClick={handleClick_Manifest}
              button="M"
              colour={green}
              title="Generate Missing Picklist"
            />
          ) : (
            ""
          )}
        </div>

        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <Link to="/EComm">
          <FooterButton
            button="Back"
            colour={green}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
