import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
export const PopupCreateEditMessage = (props) => {
  const [userid, setUserID] = store.useState("userid");

  const [PopupResponse, setPopupResponse] = useState("");
  const [ddlStore, setDDLStore] = useState([]);

  const refExpiryDate = useRef();
  const refStore = useRef();
  const refPOS = useRef();
  const refMessage = useRef();

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleShow = async () => {
    const resObj = await runFetch(`${spURL}Handheld_POS_Store`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: "[{}]",
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDDLStore(spResponse.Table.map((item) => item.StoreName));
      }
    }

    switch (props.function) {
      case "CREATE":
        var now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

        refExpiryDate.current.value = now.toISOString().slice(0, -5);
        refStore.current.value = "ALL";
        break;
      case "EDIT":
        let date = props.SelectedMessage[0].DateExpired;
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

        refStore.current.value = props.SelectedMessage[0].StoreName;
        refPOS.current.value = props.SelectedMessage[0].POSTerminalNo;
        refExpiryDate.current.value = date.toISOString().slice(0, -5);
        refMessage.current.value = props.SelectedMessage[0].Message;
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    const resObj = await runFetch(`${spURL}Handheld_POSMessage_${props.function}`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(
          props.function === "CREATE"
            ? {
                Source: "IT",
                StoreName: refStore.current.value,
                POSTerminalNo: refPOS.current.value,
                Message: refMessage.current.value,
                UserID: userid,
                DateExpired: refExpiryDate.current.value,
              }
            : {
                Source: "IT",
                MessageID: props.SelectedMessage[0].MessageID,
                StoreName: refStore.current.value,
                POSTerminalNo: refPOS.current.value,
                Message: refMessage.current.value,
                UserID: userid,
                DateExpired: refExpiryDate.current.value,
              }
        ),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setMenuResponse(spResponse.Table[0].OutputString);
        props.pageLoad();
        handleClose();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Store:</strong>
              </p>
              <select
                id="popupcreatemessage-store"
                ref={refStore}
              >
                <option value="ALL">ALL</option>
                {ddlStore.length > 0
                  ? ddlStore.map((item, index) => (
                      <option
                        value={item}
                        key={index}
                      >
                        {item}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>POS:</strong>
              </p>
              <input
                type="text"
                id="popupcreatemessage-pos"
                ref={refPOS}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Expiry Date:</strong>
              </p>
              <input
                type="datetime-local"
                id="popupcreatemessage-expirydate"
                ref={refExpiryDate}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Message:</strong>
              </p>
              <input
                type="text"
                id="popupcreatemessage-message"
                ref={refMessage}
              />
            </div>
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
