import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { store, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";
import { useRef } from "react";

export const PopupReallocate = (props) => {
  const [ConfirmResponse, setConfirmResponse] = useState("");
  const [ddlStore, setDDLStore] = useState("");

  const refLocation = useRef();

  const handleClose = () => props.setShow(false);

  const handleShow = async () => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Unallocated_WebOrders_Stores_Get`, {
      method: "POST",
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
    } else {
      const spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setDDLStore(
          spResponse.Table.map((item, index) => (
            <option
              key={index}
              value={item.StoreName}
            >
              {item.StoreName}
            </option>
          ))
        );
      }
    }
  };

  const handleClick_Confirm = async () => {
    const resObj = await runFetch(`${spURL}Unallocated_WebOrders_Reallocate`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(
          props.selectedOrders.map((item) => ({
            OrderID: item.orderid,
            ItemNo: item.itemid,
          }))
        ),
        storename: refLocation.current.value,
      }),
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
    } else {
      const spResponse = JSON.parse(resObj.response);

      props.setMenuResponse("OK!: Orders reallocated.");
      props.pageLoad();
      props.setShow(false);
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <strong>Select location to reallocate.</strong>
            <br />
            <br />
            <div className="input-row">
              <p>
                <strong>Location:</strong>
              </p>
              <select
                name="store"
                id="unallocatedorders-location"
                ref={refLocation}
              >
                {ddlStore}
              </select>
            </div>
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Confirm}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
