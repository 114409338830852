import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { orange, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const Header = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [POHeaderTable, setPOHeaderTable] = useState([]);
  const [AllocationResponse, setAllocationResponse] = useState("");

  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sortIcon, setSortIcon] = useState("");

  useEffect(() => {
    async function getHeader() {
      const resObj = await runFetch(`${spURL}Handheld_PerthWarehouse_Header_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Store: storeno,
          }),
        }),
      });

      if (resObj.response === null) {
        setAllocationResponse(resObj.exception);
      } else {
        const spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          if (spResponse.Table.length === 0) {
            setAllocationResponse("No PO's found.");
          } else {
            setPOHeaderTable(
              spResponse.Table.map((item) => ({
                PO: item.PO,
                ETA: item.ETA,
                SKUs: item.SKUs,
                Cartons: item.Cartons,
                Status: item.Status,
              }))
            );
          }
        }
      }
    }
    getHeader();
  }, []);

  const handleClick_HeaderRow = (event) => {
    let _target;
    switch (event.target.tagName.toUpperCase()) {
      case "TH":
        _target = event.target;
        break;
      case "SVG":
        _target = event.target.parentElement;
        break;
      case "PATH":
        _target = event.target.parentElement.parentElement;
        break;
      default:
        break;
    }

    if (event.target.tagName.toUpperCase() !== "TR") {
      const _colname = _target.attributes["colname"].value;
      const _sortorder = _target.attributes["sortorder"].value;

      setSortColumn(_colname);

      if (_colname === sortColumn) {
        if (_sortorder === "ASC") {
          setSortOrder("DESC");
        } else {
          setSortOrder("ASC");
        }
      } else {
        setSortOrder("ASC");
      }
    }
  };

  useEffect(() => {
    switch (sortOrder) {
      case "ASC":
        setSortIcon(solid("sort-up"));
        break;
      case "DESC":
        setSortIcon(solid("sort-down"));
        break;
      default:
        setSortIcon(solid("sort"));
        break;
    }
  }, [sortOrder]);

  useEffect(() => {
    let newArr = POHeaderTable;

    if (newArr.length > 0 && sortColumn !== "") {
      newArr = newArr.sort((a, b) => {
        switch (sortColumn) {
          case "SKUs":
          case "Cartons":
            return sortOrder === "ASC"
              ? parseFloat(a[sortColumn]) - parseFloat(b[sortColumn])
              : parseFloat(b[sortColumn]) - parseFloat(a[sortColumn]);
          default:
            return sortOrder === "ASC"
              ? a[sortColumn].localeCompare(b[sortColumn])
              : b[sortColumn].localeCompare(a[sortColumn]);
        }
      });

      setPOHeaderTable(newArr);
    }
  }, [sortColumn, sortOrder, POHeaderTable, setPOHeaderTable]);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <h1 className="text-center">HEADER</h1>
        {POHeaderTable.length !== 0 && (
          <>
            <div className="error-message">{AllocationResponse}</div>
            <table className="itemdetails-table">
              <thead>
                <tr
                  className="btnHover"
                  onClick={handleClick_HeaderRow}
                >
                  <th
                    colname="PO"
                    sortorder={sortOrder}
                  >
                    PO
                    {sortColumn === "PO" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="ETA"
                    sortorder={sortOrder}
                  >
                    ETA
                    {sortColumn === "ETA" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="SKUs"
                    sortorder={sortOrder}
                  >
                    SKUs
                    {sortColumn === "SKUs" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="Cartons"
                    sortorder={sortOrder}
                  >
                    Cartons
                    {sortColumn === "Cartons" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="Status"
                    sortorder={sortOrder}
                  >
                    Status
                    {sortColumn === "Status" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                </tr>
              </thead>
              <tbody>
                {POHeaderTable.map((item, index) => (
                  <tr
                    key={"tablerow-" + index}
                    onClick={() => {
                      if (["RECEIVED"].includes(item.Status)) {
                        props.setCurrentPO(item.PO);
                        props.setPage("line");
                      }
                    }}
                    className="btnHover"
                  >
                    <td>{item.PO} </td>
                    <td>{item.ETA}</td>
                    <td>{item.SKUs}</td>
                    <td>{item.Cartons}</td>
                    <td>{item.Status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={orange}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
