import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { red, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const Create = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [StockTakeResponse, setStockTakeResponse] = useState("");

  const [ddlDepartment, setDDLDepartment] = useState([]);
  const [ddlCategory, setDDLCategory] = useState([]);
  const [ddlSubCat, setDDLSubCat] = useState([]);

  const refDescription = useRef();
  const refPin = useRef();
  const refBays = useRef();
  const refDepartment = useRef();
  const refCategory = useRef();
  const refSubCat = useRef();

  useEffect(() => {
    async function getStockTake() {
      const resObj = await runFetch(`${spURL}Stocktake_List_Division`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: "[{}]",
        }),
      });

      if (resObj.response === null) {
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          setDDLDepartment(
            spResponse.Table.map((item, index) => (
              <option
                key={index}
                value={item.Description}
              >
                {item.Description}
              </option>
            ))
          );
        }
      }
    }
    getStockTake();
  }, []);

  const handleChange_ddlDepartment = async () => {
    if (refDepartment.current.value !== "") {
      const resObj = await runFetch(`${spURL}Stocktake_List_ItemCategory`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Division: refDepartment.current.value,
          }),
        }),
      });

      if (resObj.response === null) {
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          setDDLCategory(
            spResponse.Table.map((item, index) => (
              <option
                key={index}
                value={item.Description}
              >
                {item.Description}
              </option>
            ))
          );
        }
      }
    }
  };

  const handleChange_ddlCategory = async () => {
    if (refCategory.current.value !== "") {
      const resObj = await runFetch(`${spURL}Stocktake_List_ProductGroup`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Division: refDepartment.current.value,
            ItemCategory: refCategory.current.value,
          }),
        }),
      });

      if (resObj.response === null) {
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          setDDLSubCat(
            spResponse.Table.map((item, index) => (
              <option
                key={index}
                value={item.Description}
              >
                {item.Description}
              </option>
            ))
          );
        }
      }
    }
  };

  const handleClick = async () => {
    if (refDescription.current.value === "") {
      setStockTakeResponse("Description required.");
    } else if (refBays.current.value === "") {
      setStockTakeResponse("Bays required.");
    } else if (refPin.current.value === "") {
      setStockTakeResponse("Pin required.");
    } else {
      const resObj = await runFetch(`${spURL}Stocktake_Create`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Store: storeno,
            Description: refDescription.current.value,
            Bays: refBays.current.value,
            Division: refDepartment.current.value,
            ItemCategory: refCategory.current.value,
            ProductGroup: refSubCat.current.value,
            PinCode: refPin.current.value,
          }),
        }),
      });

      if (resObj.response === null) {
        setStockTakeResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        setStockTakeResponse(spResponse.Table[0].OutputString);
      }
    }
  };
  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Desc:</strong>
            </p>
            <input
              type="text"
              id="stocktake-create-description"
              ref={refDescription}
            />
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Bays:</strong>
            </p>
            <input
              type="text"
              id="stocktake-create-bays"
              ref={refBays}
            />
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Pin:</strong>
            </p>
            <input
              type="text"
              id="stocktake-create-pincode"
              ref={refPin}
              minLength={4}
              maxLength={4}
            />
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Dept:</strong>
            </p>
            <select
              name="department"
              id="stocktake-create-department"
              onChange={handleChange_ddlDepartment}
              ref={refDepartment}
            >
              {ddlDepartment}
            </select>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Cat:</strong>
            </p>
            <select
              name="department"
              id="stocktake-create-category"
              onChange={handleChange_ddlCategory}
              ref={refCategory}
            >
              {ddlCategory}
            </select>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>SubCat:</strong>
            </p>
            <select
              name="department"
              id="stocktake-create-subcat"
              ref={refSubCat}
            >
              {ddlSubCat}
            </select>
          </div>
          <button
            className="signin-login-button btnHover"
            onClick={handleClick}
          >
            Create
          </button>
        </div>

        <p className="error-message">{StockTakeResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
