import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { orange, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { ItemBarcode } from "../../components/ItemBarcode";
import { PopupConfirm } from "./PopupConfirm";
import { PopupHistory } from "./PopupHistory";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const Barcode = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [MenuResponse, setMenuResponse] = useState("");

  const [showConfirm, setShowConfirm] = useState(false);

  const [showHistory, setShowHistory] = useState(false);

  const [Item, setItem] = useState({
    ItemNo: "",
    ImageURL: "",
    Description: "",
    Supplier: "",
    Exists: "",
    ReorderNumber: "",
  });

  const [ItemDetails, setItemDetails] = useState("");

  const [CurrentScanMode, setCurrentScanMode] = useState("QUICK");

  const handleClick_Back = () => {
    resetToggle();

    setMenuResponse("");
    props.setPage("menu");
  };

  const resetToggle = () => {
    if (CurrentScanMode === "QUANTITY") {
      setCurrentScanMode("QUICK");
      document.getElementById("root").className = "";
    }
  };

  const handleClick_Toggle = () => {
    setItem({});
    setItemDetails("");

    if (CurrentScanMode === "QUICK") {
      setCurrentScanMode("QUANTITY");

      document.getElementById("root").className = "background-lightblue";
    } else {
      setCurrentScanMode("QUICK");
      document.getElementById("root").className = "";
    }

    document.getElementById("itembarcode-barcode").focus();
  };

  const handleClick_Ok = async () => {
    setMenuResponse("");
    const _barcode = document.getElementById("itembarcode-barcode").value;

    const resObj = await runFetch(`${spURL}Handheld_Transfer_Item_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Store: storeno,
          TransferID: props.TONo,
          Barcode: _barcode,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table.length > 0) {
        setItem((values) => ({
          ...values,
          ItemNo: spResponse.Table[0].ItemNo,
          Description: spResponse.Table[0].Description,
          ReorderNumber: spResponse.Table[0].ReorderNumber,
          Supplier: spResponse.Table[0].Supplier,
          Exists: spResponse.Table[0].AlreadyExists,
          ImageURL: spResponse.Table[0].ImageURL,
        }));

        setItemDetails(
          <div>
            <img
              src={spResponse.Table[0].ImageURL}
              width="100"
              height="100"
              alt="item"
            />
            <div>
              <p>{spResponse.Table[0].ItemNo}</p>
              <p>{spResponse.Table[0].Description}</p>
              <p>{spResponse.Table[0].ReorderNumber}</p>
              <p>{spResponse.Table[0].Supplier}</p>
            </div>
          </div>
        );

        if (CurrentScanMode === "QUICK") {
          await handleClick_Add(spResponse.Table[0].ItemNo, 1);
        }
      } else {
        setMenuResponse("ERROR!: Item not found.");
      }
    }
  };

  const handleClick_Add = async (_itemno, _qty) => {
    setMenuResponse("");

    const resObj = await runFetch(`${spURL}Handheld_Transfer_Add_Item`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Store: storeno,
          TransferID: props.TONo,
          Quantity: _qty,
          ItemNo: _itemno,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      setMenuResponse(spResponse.Table[0].OutputString);

      if (CurrentScanMode === "QUANTITY") {
        setItem({});
        setItemDetails("");
      }

      document.getElementById("itembarcode-barcode").value = "";
      document.getElementById("itembarcode-barcode").focus();
    }
  };

  const handleClick_History = () => {
    setMenuResponse("");
    setShowHistory(true);
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="storetransfer-input-row">
          <p>
            <strong>Transfer No</strong>
          </p>
          <p>
            <strong>{props.TONo}</strong>
          </p>
        </div>
        <ItemBarcode barcodeClick={handleClick_Ok} />
        <br />
        <div>
          <p>
            <strong>Current Scan Mode: </strong>
            {CurrentScanMode}
          </p>
        </div>
        <br />
        <div>
          <MenuButton
            title="Toggle Mode"
            colour={orange}
            button="TM"
            onClick={handleClick_Toggle}
          />
        </div>
        {ItemDetails === "" || CurrentScanMode === "QUICK" ? (
          ""
        ) : (
          <>
            <br />
            {ItemDetails}
            <div className="storetransfer-input-row">
              <p>
                <strong>Quantity</strong>
              </p>
              <input
                type="text"
                id="storetransfer-barcode-qty"
              />
            </div>
            <button
              className="signin-login-button"
              onClick={() =>
                handleClick_Add(
                  Item.ItemNo,
                  document.getElementById("storetransfer-barcode-qty").value
                )
              }
            >
              Add
            </button>
          </>
        )}

        <PopupHistory
          show={showHistory}
          setShow={setShowHistory}
          setMenuResponse={setMenuResponse}
          currentMode="STORE"
          TONo={props.TONo}
        />

        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
            onClick={resetToggle}
          />
        </Link>
        <FooterButton
          button="Hist"
          colour={orange}
          onClick={handleClick_History}
        />
      </div>
    </div>
  );
};
