import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { green, store, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { trackPromise } from "react-promise-tracker";
import { FileUploader } from "react-drag-drop-files";
import { BlobServiceClient } from "@azure/storage-blob";
import { useRef } from "react";
import { runFetch } from "../../functions/RunFetch";

export const PopupImage = (props) => {
  const types = ["jpg", "jpeg"];
  const blobServiceClient = new BlobServiceClient(
    `https://tmportalimages.blob.core.windows.net/?sp=racwd&st=2024-02-01T02:10:22Z&se=2029-12-31T02:00:00Z&spr=https&sv=2022-11-02&sr=c&sig=LVrYMkMr%2BNeHzen6g4WCbbocjmt899k5%2B0qbJvm1bI0%3D`
  ); // create a blobServiceClient
  const containerClient = blobServiceClient.getContainerClient("websiteimages"); // create a containerClient

  const [PopupResponse, setPopupResponse] = useState("");

  const [IsURLChanged, setIsURLChanged] = useState(false);

  const refBannerURL = useRef();
  const refBtnUpdate = useRef();

  const handleClose = () => {
    props.setShow(false);
    setPopupResponse("");
  };
  const handleShow = () => {
    setPopupResponse("");
    refBannerURL.current.value = props.ImageObj.WebsiteToURL;
    checkURLField();
  };

  const createBCRedirect = async () => {
    const resObj = await runFetch(`${spURL}CreateBCRedirect`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify([
          {
            from_path: props.ImageObj.WebsiteFromURL,
            site_id: 1000,
            to: {
              type: "url",
              url: refBannerURL.current.value,
            },
          },
        ]),
      }),
    });
  };

  const handleClick_Update = async () => {
    if (IsURLChanged) {
      const resObj = await runFetch(`${spURL}Handheld_WebsiteCMS_BannerURL_Update`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            ImageName: props.ImageObj.ImageName,
            BannerURL: refBannerURL.current.value,
          }),
        }),
      });

      if (resObj.response === null) {
        setPopupResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          await createBCRedirect();
          props.setShow(false);
        }
      }
    }
  };

  const handleChange_FileUploader = async (uploadedFile, deviceType) => {
    let fileName = "";
    let fileURL = "";

    switch (deviceType) {
      case "DESKTOP":
        fileName = props.ImageObj.DesktopFileName;
        fileURL = props.ImageObj.DesktopFileURL;
        break;
      case "MOBILE":
        fileName = props.ImageObj.MobileFileName;
        fileURL = props.ImageObj.MobileFileURL;
        break;
      default:
        fileName = "";
        fileURL = "";
        break;
    }

    setPopupResponse("");

    if (fileName === "" || fileURL === "") {
      setPopupResponse(`ERROR!: ${props.ImageObj.ImageName} image file upload error.`);
    } else {
      try {
        const blobName = fileName; // Specify a default blob name if needed
        const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
        await trackPromise(
          blobClient
            .uploadData(uploadedFile) // upload the image
            .then(() => {
              props.setShow(false);
            })
            .catch((err) => {
              setPopupResponse(`ERROR!: ${props.ImageObj.ImageName} image file upload error.`);
            })
        );
      } catch (error) {
        setPopupResponse(`ERROR!: ${props.ImageObj.ImageName} image file upload error.`);
      }
    }
  };

  const checkURLField = () => {
    setPopupResponse("");
    setIsURLChanged(refBannerURL.current.value !== props.ImageObj.WebsiteToURL);
  };

  useEffect(() => {
    if (refBannerURL.current) {
      checkURLField();
    }
  }, [refBannerURL.current, props.ImageObj.WebsiteToURL]);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Body>
          <div>
            <h1>
              <strong>{props.ImageObj.ImageName} Banner</strong>
            </h1>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Link to:</strong>
              </p>
              <input
                ref={refBannerURL}
                onChange={() => {
                  checkURLField();
                }}
                type="text"
              />
            </div>
            <button
              ref={refBtnUpdate}
              className={`signin-login-button btnHover ${IsURLChanged ? "" : "disabled"}`}
              onClick={handleClick_Update}
            >
              Update
            </button>
            <br />
            <div className="popup-websitecms-image-container">
              <div className="desktop-container">
                <p>
                  <strong>Desktop</strong>
                </p>
                <FileUploader
                  multiple={false}
                  handleChange={(f) => {
                    handleChange_FileUploader(f, "DESKTOP");
                  }}
                  types={types}
                  children={
                    props.ImageObj.DesktopFileURL === "" ? (
                      <button>Import image</button>
                    ) : (
                      <img
                        className="btnHover"
                        src={props.ImageObj.DesktopFileURL}
                      />
                    )
                  }
                />
              </div>
              <div className="mobile-container">
                <p>
                  <strong>Mobile</strong>
                </p>
                <FileUploader
                  multiple={false}
                  handleChange={(f) => {
                    handleChange_FileUploader(f, "MOBILE");
                  }}
                  types={types}
                  children={
                    props.ImageObj.MobileFileURL === "" ? (
                      <button>Import image</button>
                    ) : (
                      <img
                        className="btnHover"
                        src={props.ImageObj.MobileFileURL}
                      />
                    )
                  }
                />
              </div>
            </div>
            <br />
            <p className="error-message">{PopupResponse}</p>
          </div>
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
