import React, { useState } from "react";
import { Link } from "react-router-dom";

import { StoreQuit } from "./StoreQuit";
import { NotScanning } from "./NotScanning";
import { WrongPrice } from "./WrongPrice";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { red, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";

export const Details = (props) => {
  const handleClick_Details_Back = () => {
    props.setItemDetailsResponse("");

    switch (props.from) {
      case "ItemSearch":
        window.location.href = `#/ItemSearch?From=ItemDetails&SearchTerm=${props.searchTerm}&Index=${props.index}`;
        break;
      default:
        props.setEDMTable("");
        props.setPage("barcode");
        break;
    }
  };

  const [showSQ, setShowSQ] = useState(false);
  const [showWP, setShowWP] = useState(false);
  const [showNS, setShowNS] = useState(false);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="itemdetails-image-itemno-container">
          <img
            src={props.data.ImageURL}
            alt="Item"
            width="100"
          />
          <div>
            <p>
              <strong>{props.data.ItemNo}</strong>
            </p>
            <p>
              <strong>{props.data.ReorderNumber}</strong>
            </p>
            <p>{props.data.Supplier}</p>
          </div>
        </div>

        <div className="itemdetails-description-container">
          <a
            className="itemdetails-description-link"
            href={props.data.WebURL === "" ? "" : `https://toymate.com.au${props.data.WebURL}`}
            target="_blank"
          >
            <strong>{props.data.ExtendedDescription}</strong>
          </a>
        </div>

        <div>
          <p>{props.data.Department.substring(0, 25)}</p>
          <p>
            {">"}
            {props.data.Category.substring(0, 25)}
          </p>
          <p>
            {">>"}
            {props.data.SubCategory.substring(0, 25)}
          </p>
        </div>

        <div>
          <strong>Last Receipt Date: </strong>
          {props.data.LastReceiptDate}
        </div>

        <div>
          <strong>Last Modified Date: </strong>
          {props.data.LastDateModified}
        </div>

        <div className="itemdetails-price-container">
          <p>
            <strong>
              {convertToCurrency(props.data.Price, "$")} (MSRP:{" "}
              {convertToCurrency(props.data.MSRP, "$")})
            </strong>
          </p>
        </div>

        <div className="itemdetails-sq-ns-wp-container">
          <div className="itemdetails-sq-ns-wp-button">
            <MenuButton
              button="NS"
              title="Not Scanning"
              colour={red}
              onClick={() => setShowNS(true)}
            />
          </div>
          <div className="itemdetails-sq-ns-wp-button">
            <MenuButton
              button="WP"
              title="Wrong Price"
              colour={red}
              onClick={() => setShowWP(true)}
            />
          </div>
        </div>

        <StoreQuit
          show={showSQ}
          setShow={setShowSQ}
          itemno={props.data.ItemNo}
          setItemDetailsResponse={props.setItemDetailsResponse}
          limit={props.data.CanNotBeMarkDownBelow}
          NextMarkDownDate={props.data.NextMarkDownDate}
          MarkDownDate={props.data.MarkDownDate}
          MarkDownDateOk={props.data.MarkDownDateOK}
          ItemType={props.data.ItemType}
        />
        <WrongPrice
          show={showWP}
          setShow={setShowWP}
          setItemDetailsResponse={props.setItemDetailsResponse}
          itemno={props.data.ItemNo}
          price={props.data.Price}
        />
        <NotScanning
          show={showNS}
          setShow={setShowNS}
          setItemDetailsResponse={props.setItemDetailsResponse}
          itemno={props.data.ItemNo}
        />

        <div className="itemdetails-soo-sot-container">
          <div
            className="itemdetails-soo-button"
            onClick={() => props.setPage("soo")}
          >
            <strong>On Order</strong>
            <br />
            {props.data.SOO}
          </div>
          <div
            className="itemdetails-sot-button"
            onClick={() => props.setPage("sot")}
          >
            <strong>On Transfer</strong>
            <br />
            {props.data.SOT}
          </div>
        </div>

        <div className="itemdetails-table-container">
          <table className="itemdetails-table">
            <thead>
              <tr>
                <th colSpan={2}>
                  <strong>Stock</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  className="itemdetails-soh-button"
                  onClick={() => props.setPage("soh")}
                >
                  <strong>On Hand</strong>
                  <br />
                  {props.data.SOH}
                </td>
                <td>
                  <strong>WH Avail</strong>
                  <br />
                  {props.data.SOHWH}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="itemdetails-table">
            <thead>
              <tr>
                <th colSpan={3}>
                  <strong>Sales</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Today</strong>
                  <br />
                  {props.data.Sales0d}
                </td>
                <td>
                  <strong>7 Days</strong>
                  <br />
                  {props.data.Sales7d}
                </td>
                <td>
                  <strong>28 Days</strong>
                  <br />
                  {props.data.Sales28d}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* edm promotion table */}
        {props.edmTable === "" ? (
          ""
        ) : (
          <div>
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>
                    <strong>Current Promotion</strong>
                  </th>
                </tr>
              </thead>
              <tbody>{props.edmTable}</tbody>
            </table>
          </div>
        )}

        <p className="error-message">{props.ItemDetailsResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_Details_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
