import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { orange, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { runFetch } from "../../functions/RunFetch";

export const Details = (props) => {
  const [PODetailsTable, setPODetailsTable] = useState([]);
  const [HeaderDetail, setHeaderDetail] = useState("");
  const [AllocationResponse, setAllocationResponse] = useState("");

  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sortIcon, setSortIcon] = useState("");

  const handleClick_Back = () => {
    props.setCurrentItemNo("");
    props.setPage("line");
  };

  const handleClick_HeaderRow = (event) => {
    let _target;
    switch (event.target.tagName.toUpperCase()) {
      case "TH":
        _target = event.target;
        break;
      case "SVG":
        _target = event.target.parentElement;
        break;
      case "PATH":
        _target = event.target.parentElement.parentElement;
        break;
      default:
        break;
    }

    if (event.target.tagName.toUpperCase() !== "TR") {
      const _colname = _target.attributes["colname"].value;
      const _sortorder = _target.attributes["sortorder"].value;

      setSortColumn(_colname);

      if (_colname === sortColumn) {
        if (_sortorder === "ASC") {
          setSortOrder("DESC");
        } else {
          setSortOrder("ASC");
        }
      } else {
        setSortOrder("ASC");
      }
    }
  };

  useEffect(() => {
    switch (sortOrder) {
      case "ASC":
        setSortIcon(solid("sort-up"));
        break;
      case "DESC":
        setSortIcon(solid("sort-down"));
        break;
      default:
        setSortIcon(solid("sort"));
        break;
    }
  }, [sortOrder]);

  useEffect(() => {
    let newArr = PODetailsTable;

    if (newArr.length > 0 && sortColumn !== "") {
      newArr = newArr.sort((a, b) => {
        switch (sortColumn) {
          case "Allocation":
          case "Allocated":
          case "Remaining":
            return sortOrder === "ASC"
              ? parseFloat(a[sortColumn]) - parseFloat(b[sortColumn])
              : parseFloat(b[sortColumn]) - parseFloat(a[sortColumn]);
          default:
            return sortOrder === "ASC"
              ? a[sortColumn].localeCompare(b[sortColumn])
              : b[sortColumn].localeCompare(a[sortColumn]);
        }
      });

      setPODetailsTable(newArr);
    }
  }, [sortColumn, sortOrder, PODetailsTable, setPODetailsTable]);

  useEffect(() => {
    async function getDetails() {
      const resObj = await runFetch(`${spURL}Handheld_PerthWarehouse_Details_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            PONumber: props.currentPO,
            ItemNo: props.currentItemNo,
          }),
        }),
      });

      if (resObj.response === null) {
        setAllocationResponse(resObj.exception);
      } else {
        const spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          if (spResponse.Table.length === 0) {
            setAllocationResponse("No header detail found.");
          } else {
            setHeaderDetail({
              PONumber: spResponse.Table[0].PONumber,
              ItemNo: spResponse.Table[0].ItemNo,
              Description: spResponse.Table[0].Description,
              ReorderNumber: spResponse.Table[0].ReorderNumber,
            });
          }
        }
        if (spResponse.Table1 !== undefined) {
          if (spResponse.Table1.length === 0) {
            //setAllocationResponse("No items found.");
          } else {
            setPODetailsTable(
              spResponse.Table1.map((item) => ({
                StoreName: item.StoreName,
                StoreID: item.StoreID,
                ItemNo: item.ItemNo,
                Allocation: item.Allocation,
                Allocated: item.Allocated,
                Remaining: item.Remaining,
              }))
            );
          }
        }
      }
    }

    getDetails();
  }, []);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <h1 className="text-center">DETAIL</h1>
        <div>
          <table className="perth-warehouse-header">
            <tbody>
              <tr>
                <td>
                  <div>
                    <strong>PO Number: </strong>
                    {HeaderDetail.PONumber}
                  </div>
                </td>
                <td>
                  <div>
                    <strong>Description: </strong>
                    {HeaderDetail.Description}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <strong>Item Number: </strong>
                    {HeaderDetail.ItemNo}
                  </div>
                </td>
                <td>
                  <div>
                    <strong>Reorder Number: </strong>
                    {HeaderDetail.ReorderNumber}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="error-message">{AllocationResponse}</div>

        {PODetailsTable.length !== 0 && (
          <>
            <table className="itemdetails-table">
              <thead>
                <tr
                  className="btnHover"
                  onClick={handleClick_HeaderRow}
                >
                  <th
                    colname="StoreName"
                    sortorder={sortOrder}
                  >
                    Store
                    {sortColumn === "StoreName" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="Allocation"
                    sortorder={sortOrder}
                  >
                    Allocation
                    {sortColumn === "Allocation" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="Allocated"
                    sortorder={sortOrder}
                  >
                    Allocated
                    {sortColumn === "Allocated" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="Remaining"
                    sortorder={sortOrder}
                  >
                    Remaining
                    {sortColumn === "Remaining" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                </tr>
              </thead>
              <tbody>
                {PODetailsTable.map((item, index) => (
                  <tr
                    key={`tablerow-${index}`}
                    onClick={() => {
                      props.setCurrentItemNo(item.ItemNo);
                      props.setCurrentStoreNo(item.StoreID);
                      props.setPage("allocation");
                    }}
                    className="btnHover"
                  >
                    <td>{item.StoreName}</td>
                    <td>{item.Allocation}</td>
                    <td>{item.Allocated}</td>
                    <td>{item.Remaining}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
