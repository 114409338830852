import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { purple, store, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";

export const Menu = (props) => {
  const handleClick_Create = () => {
    props.setMenuResponse("");
    props.setPage("create");
  };

  const handleClick_Edit = () => {
    props.setMenuResponse("");
    props.setPage("edit");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          {/* <MenuButton onClick={handleClick_Create} button='OS' colour={purple} title='Onboard Staff' /> */}
          <MenuButton
            onClick={handleClick_Edit}
            button="ES"
            colour={purple}
            title="Edit Staff"
          />
        </div>
        <p className="error-message">{props.MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={purple}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
      </div>
    </div>
  );
};
