import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { purple, yellow, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";

export const Menu = (props) => {
  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          <MenuButton
            onClick={() => props.setPage("plp")}
            button="PLP"
            colour={purple}
            title="PLP"
          />
        </div>
      </div>

      <div className="footer-container menu-container">
        <Link to="/Marketing">
          <FooterButton
            button="Back"
            colour={purple}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
      </div>
    </div>
  );
};
