import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, yellow } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { CapitaliseString } from "../../functions/CapitaliseString";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const PopupBookingDetails = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [PopupResponse, setPopupResponse] = useState("");
  const [SupplierList, setSupplierList] = useState([]);

  const [SearchSupplier, setSearchSupplier] = useState("");
  const [Supplier, setSupplier] = useState("");
  const [Carrier, setCarrier] = useState("");
  const [UnitType, setUnitType] = useState("");
  const [UnitQty, setUnitQty] = useState("");
  const [PalletType, setPalletType] = useState("");
  const [PalletExchangeYN, setPalletExchangeYN] = useState("");

  const [isCartonSelected, setIsCartonSelected] = useState(false);

  const refSearchSupplier = useRef();
  const refSupplier = useRef();
  const refCarrier = useRef("");
  const refUnitTypeCarton = useRef("");
  const refUnitTypePallets = useRef("");
  const refUnitQty = useRef("");
  const refPalletType = useRef("");
  const refPalletExchangeY = useRef("");
  const refPalletExchangeN = useRef("");

  const cleanPopup = () => {
    setIsCartonSelected(false);
    setSupplierList([]);
    setSearchSupplier("");
    setSupplier("");
    setCarrier("");
    setUnitType("");
    setUnitQty("");
    setPalletType("");
    setPalletExchangeYN("");
    setIsCartonSelected(false);

    refSearchSupplier.current.value = "";
    refSupplier.current.value = "";
    refCarrier.current.value = "";
    refUnitTypeCarton.current.value = "";
    refUnitTypePallets.current.value = "";
    refUnitQty.current.value = "";
    refPalletType.current.value = "";
    refPalletExchangeY.current.value = "";
    refPalletExchangeN.current.value = "";
  };

  const clearSupplierField = () => {
    setSupplier("");
    refSupplier.current.value = "";
  };

  const handleClose = () => {
    props.setShow(false);
  };

  const handleShow = () => {
    if (props.from === "EDIT") {
      setSearchSupplier(props.EditBookingDetails.Supplier);
      refSearchSupplier.current.value = props.EditBookingDetails.Supplier;
      getSupplierList();

      setCarrier(props.EditBookingDetails.Carrier);
      refCarrier.current.value = props.EditBookingDetails.Carrier;

      if (parseInt(props.EditBookingDetails.PalletQty, 10) === 0) {
        setUnitType("CARTON");
        setUnitQty(props.EditBookingDetails.CartonQty);

        refUnitTypeCarton.current.checked = true;
        refUnitQty.current.value = props.EditBookingDetails.CartonQty;
      } else {
        setUnitType("PALLET");
        setUnitQty(props.EditBookingDetails.PalletQty);
        setPalletType(props.EditBookingDetails.PalletType);
        setPalletExchangeYN(props.EditBookingDetails.PalletExchangeYN);

        refUnitTypePallets.current.checked = true;
        refUnitQty.current.value = props.EditBookingDetails.PalletQty;

        refPalletType.current.value = props.EditBookingDetails.PalletType;
        if (props.EditBookingDetails.PalletExchangeYN === "Y") {
          refPalletExchangeY.current.checked = true;
        } else if (props.EditBookingDetails.PalletExchangeYN === "N") {
          refPalletExchangeN.current.checked = true;
        }
      }
    }
  };

  const handleChange_SearchSupplier = async (event) => {
    if (event.target.value.length >= 3) {
      await getSupplierList();
    } else {
      setSupplierList([]);
      clearSupplierField();
    }
  };

  const getSupplierList = async () => {
    const resObj = await runFetch(`${spURL}Handheld_IncomingDeliveries_SupplierList_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          SearchSupplier: refSearchSupplier.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setSupplierList(spResponse.Table.map((item) => item.SupplierName));

        if (props.from === "CREATE") {
          clearSupplierField();
        } else if (props.from === "EDIT") {
          setSupplier(props.EditBookingDetails.Supplier);
          refSupplier.current.value = props.EditBookingDetails.Supplier;
        }
      } else {
        setSupplierList([]);
        clearSupplierField();
      }
    }
  };

  const handleClick_Submit = async () => {
    const resObj = await runFetch(
      `${spURL}Handheld_IncomingDeliveries_Delivery_${CapitaliseString(props.from)}`,
      {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            UserID: userid,
            BookingID: props.EditBookingDetails.BookingID,
            DeliveryDate: props.selectedDate,
            DeliveryInterval: props.selectedInterval,
            Supplier: Supplier,
            Carrier: Carrier,
            UnitType: UnitType,
            UnitQty: UnitQty,
            PalletType: PalletType,
            PalletExchangeYN: PalletExchangeYN,
          }),
        }),
      }
    );
    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setPopupResponse(spResponse.Table[0].OutputString);
        props.getBookingList();
        cleanPopup();
        handleClose();
      }
    }
  };

  useEffect(() => {
    if (UnitType === "CARTON") {
      setIsCartonSelected(true);

      setPalletType("");
      setPalletExchangeYN("");
    } else if (UnitType === "PALLET") {
      setIsCartonSelected(false);
    }
  }, [UnitType]);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="incomingdeliveries-popup-container">
            <div className="popup-header-row">
              <p>
                <strong>{`${props.selectedDate} ${props.selectedInterval}`}</strong>
              </p>
            </div>
            <hr />
            <p>
              <strong>{props.from} Booking</strong>
            </p>
            <div className="incomingdeliveries-input-row">
              <p>
                <strong>Search Supplier:</strong>
              </p>
              <input
                ref={refSearchSupplier}
                type="text"
                id="searchsupplier"
                name="searchsupplier"
                onChange={handleChange_SearchSupplier}
              />
            </div>
            <div className="incomingdeliveries-input-row">
              <p>
                <strong>Supplier</strong>
              </p>
              <select
                ref={refSupplier}
                onChange={(e) => handleChange_Input(e, setSupplier)}
              >
                <option
                  value=""
                  name=""
                ></option>
                {SupplierList.map((item, index) => (
                  <option
                    key={index}
                    value={item}
                    name={item}
                  >
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="incomingdeliveries-input-row">
              <p>
                <strong>Carrier:</strong>
              </p>
              <input
                ref={refCarrier}
                type="text"
                id="carrier"
                name="carrier"
                onChange={(e) => handleChange_Input(e, setCarrier)}
              />
            </div>
            <div className="incomingdeliveries-input-row">
              <p>
                <strong>Unit Type</strong>
              </p>
              <div className="popup-radio-container">
                <input
                  ref={refUnitTypeCarton}
                  type="radio"
                  id="cartons"
                  name="unittype"
                  value="CARTON"
                  onChange={(e) => handleChange_Input(e, setUnitType)}
                />
                <label htmlFor="cartons">Cartons</label>
                <input
                  ref={refUnitTypePallets}
                  type="radio"
                  id="pallets"
                  name="unittype"
                  value="PALLET"
                  onChange={(e) => handleChange_Input(e, setUnitType)}
                />
                <label htmlFor="pallets">Pallets</label>
              </div>
            </div>
            <div className="incomingdeliveries-input-row">
              <p>
                <strong>Unit Qty</strong>
              </p>
              <input
                ref={refUnitQty}
                type="text"
                id="unitqty"
                name="unitqty"
                onChange={(e) => handleChange_Input(e, setUnitQty)}
              />
            </div>
            <div
              className={
                isCartonSelected
                  ? "incomingdeliveries-input-row hidden"
                  : "incomingdeliveries-input-row"
              }
            >
              <p>
                <strong>Pallet Type</strong>
              </p>
              <select
                ref={refPalletType}
                onChange={(e) => handleChange_Input(e, setPalletType)}
              >
                <option
                  value=""
                  name=""
                ></option>
                <option
                  value="CHEP"
                  name="CHEP"
                >
                  CHEP
                </option>
                <option
                  value="LOSCAM"
                  name="LOSCAM"
                >
                  LOSCAM
                </option>
                <option
                  value="ABBEY"
                  name="ABBEY"
                >
                  ABBEY
                </option>
              </select>
            </div>
            <div
              className={
                isCartonSelected
                  ? "incomingdeliveries-input-row hidden"
                  : "incomingdeliveries-input-row"
              }
            >
              <p>
                <strong>Pallet Exchange needed?</strong>
              </p>
              <div className="popup-radio-container">
                <input
                  ref={refPalletExchangeY}
                  type="radio"
                  id="palletexchangeY"
                  name="palletexchangeYN"
                  value="Y"
                  onChange={(e) => handleChange_Input(e, setPalletExchangeYN)}
                />
                <label htmlFor="palletexchangeY">Yes</label>
                <input
                  ref={refPalletExchangeN}
                  type="radio"
                  id="palletexchangeN"
                  name="palletexchangeYN"
                  value="N"
                  onChange={(e) => handleChange_Input(e, setPalletExchangeYN)}
                />
                <label htmlFor="palletexchangeN">No</label>
              </div>
            </div>
            <br />
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Submit}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
