import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  yellow,
  spURL,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
  spURL_LocalPOS,
} from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { useRef } from "react";

export const MerchLabelCreate = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [MenuResponse, setMenuResponse] = useState(props.Response);
  const [ddlShelfPrinter, setDDLShelfPrinter] = useState("");

  const refBatch = useRef();
  const refPrinter = useRef();

  useEffect(() => {
    async function getShelfList() {
      const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_Location`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
          }),
        }),
      });
      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        setMenuResponse(resObj.exception);
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);

        setDDLShelfPrinter(
          spResponse.Table.map((item, index, arr) => (
            <option
              key={index}
              value={item.ShelfPrinter}
            >
              {item.ShelfPrinter}
            </option>
          ))
        );
      }
    }

    getShelfList();
  }, []);

  const handleClick_Back = () => {
    props.setResponse("");
    props.setCurrentMerchBatchID("");
    props.setBarcode("");
    props.setPage("merchlabel-menu");
  };

  const handleClick_Create = async () => {
    setMenuResponse("");

    if (refBatch.current.value === "") {
      setMenuResponse("ERROR!: Batch Name cannot be blank.");
    } else if (refPrinter.current.value === "") {
      setMenuResponse("ERROR!: Shelf printer must be selected.");
    } else if (storeno === "") {
      setMenuResponse("ERROR!: You can only run this program from a store!");
    } else {
      const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_Create_Batch`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            BatchID: refBatch.current.value,
            ShelfPrinter: refPrinter.current.value,
          }),
        }),
      });
      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        setMenuResponse(resObj.exception);
      } else if (resObj.status === "OK!") {
        props.setBarcode("");
        props.setCurrentMerchBatchID(refBatch.current.value);
        props.setCurrentMerchBatchPrinter(refPrinter.current.value);
        props.setPage("merchlabel-add");
      }
    }
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="input-row">
          <p>
            <strong>Batch Name:</strong>
          </p>
          <input
            type="text"
            id="labels-merchlabel-create-batchid"
            ref={refBatch}
          />
        </div>
        <div className="input-row">
          <p>
            <strong>Shelf Printer:</strong>
          </p>
          <select
            name="shelfprinter"
            id="labels-merchlabel-create-shelfprinter"
            onChange={() => setMenuResponse("")}
            ref={refPrinter}
          >
            <option value=""></option>
            {ddlShelfPrinter}
          </select>
        </div>

        <button
          className="signin-login-button btnHover"
          onClick={handleClick_Create}
        >
          Create
        </button>
        <br />
        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={yellow}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
      </div>
    </div>
  );
};
