import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { green, purple, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";

export const CategoryReportManageBody = (props) => {
  const [PageResponse, setPageResponse] = useState("");
  const [CategoryList, setCategoryList] = useState([]);
  const [SelectedCategory, setSelectedCategory] = useState("");
  const [SelectedEnabled, setSelectedEnabled] = useState(0);

  const handleClick_Row = (_categoryCode, _toggledEnabled) => {
    setPageResponse("");
    setSelectedCategory(SelectedCategory === _categoryCode ? "" : _categoryCode);
    setSelectedEnabled(_toggledEnabled);
  };

  const handleClick_Toggle = async () => {
    if (SelectedCategory === "") {
      setPageResponse("ERROR!: No category selected.");
    } else {
      const resObj = await runFetch(`${spURL}Handheld_CategoryReportManage_Category_Update`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            CategoryCode: SelectedCategory,
            Enabled: SelectedEnabled,
          }),
        }),
      });

      if (resObj.response === null) {
        setPageResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setSelectedCategory("");
          setSelectedEnabled(0);
        }
      }
    }
    getCategoryList();
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  async function getCategoryList() {
    const resObj = await runFetch(
      `${spURL_NoInputJSON}Handheld_CategoryReportManage_CategoryList_Get`,
      {
        method: "POST",
      }
    );
    if (resObj.response === null) {
      setPageResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        let sortedTable = spResponse.Table.map((item) => ({
          CategoryCode: item["Category Code"],
          CategoryName: item["Category Name"],
          Enabled: item.Enabled,
        }));

        sortedTable.sort((a, b) => b.Enabled - a.Enabled);
        setCategoryList(sortedTable);
      } else {
        setCategoryList([]);
      }
    }
  }

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <p className="error-message">{PageResponse}</p>
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Enabled</th>
            </tr>
          </thead>
          <tbody>
            {CategoryList.sort((a, b) => parseInt(a.Enabled) >= parseInt(b.Enabled)).map(
              (item, index) => (
                <tr
                  key={index}
                  className={`btnHover ${
                    item.CategoryCode === SelectedCategory
                      ? "background-lightblue"
                      : item.Enabled === 1
                      ? "background-lightgreen"
                      : ""
                  }`}
                  onClick={(e) => handleClick_Row(item.CategoryCode, item.Enabled === 1 ? 0 : 1)}
                >
                  <td>{item.CategoryName}</td>
                  <td>{item.Enabled}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={purple}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
        <FooterButton
          button="Toggle"
          colour={purple}
          onClick={handleClick_Toggle}
        />
      </div>
    </div>
  );
};
