import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, purple } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { trackPromise } from "react-promise-tracker";

export const PopupExport = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleClick_Export = async (type) => {
    const res = await trackPromise(
      fetch(
        "https://prod-25.australiaeast.logic.azure.com:443/workflows/e864e17483b94d168ad6f44964295c32/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=BPTww-Ecih7BMMKb2rQWICLvPLzNPTrLWLafRORoSVQ",
        {
          method: "POST",
          body: new URLSearchParams({
            DrawName: props.DrawName.trim(),
            ExportType: type,
          }),
        }
      )
    );
    res
      .blob()
      .then((data) => data.text())
      .then((data) => {
        props.setMenuResponse(data);
        props.pageLoad();
        props.setShow(false);
      });
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <p>
              <strong>This is {props.Status === "OPEN" ? "an OPEN" : "a CLOSED"} draw.</strong>
            </p>
            <br />
            <p>
              <strong>Draw Name: </strong>
              {props.DrawName}
            </p>
            <p>
              <strong>Page Title: </strong>
              {props.PageTitle}
            </p>
            <p>
              <strong>URL: </strong>
              {props.URL}
            </p>
            <br />
            <br />
            <div className="blockedorders-option-row">
              <MenuButton
                button="E"
                title="EXPORT"
                colour={purple}
                onClick={() => {
                  handleClick_Export("EXPORT");
                }}
              />
              {props.Status === "OPEN" && (
                <MenuButton
                  button="E"
                  title="END"
                  colour={purple}
                  onClick={() => {
                    handleClick_Export("CLOSE");
                  }}
                />
              )}
            </div>
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
