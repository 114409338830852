import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL } from "../../App";
import _default from "react-bootstrap/Modal";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const PopupUnblockTimeslot = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [PopupResponse, setPopupResponse] = useState("");

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleShow = () => {
    setPopupResponse("");
  };

  const handleSubmit = async () => {
    const resObj = await runFetch(`${spURL}Handheld_IncomingDeliveries_Timeslot_Unblock`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          DeliveryDate: props.selectedDate,
          DeliveryInterval: props.selectedInterval,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setIncomingDeliveriesResponse(spResponse.Table[0].OutputString);
        props.setShow(false);
        props.getDeliveries();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={true}
        centered
      >
        <Modal.Body>
          <div>
            <p className="popup-title">
              <strong>Unblock this timeslot?</strong>
            </p>
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Cancel</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
