import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const PopupHistory = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [StockTakeResponse, setStockTakeResponse] = useState("");
  const [total, setTotal] = useState("");
  const [CheckItemTable, setCheckItemTable] = useState([]);

  const handleClose = () => props.setShow(false);

  const handleShow = async () => {
    setStockTakeResponse("");

    const resObj = await runFetch(`${spURL}Stocktake_Count_History`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Store: storeno,
          StockTake: props.StocktakeID,
          BayNo: props.BayNo,
        }),
      }),
    });

    if (resObj.response === null) {
      setStockTakeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table2 === undefined) {
        setCheckItemTable("");
        setStockTakeResponse(spResponse.Table[0].OutputString);
      } else {
        if (spResponse.Table.length > 0) {
          setCheckItemTable(
            spResponse.Table.map((item, index) => (
              <tr key={index}>
                <td>{item.Item}</td>
                <td>{item.Description}</td>
                <td>{item.Counted}</td>
              </tr>
            ))
          );
        } else {
          setStockTakeResponse("No items scanned.");
        }

        if (spResponse.Table1.length > 0) {
          setTotal((values) => ({
            ...values,
            totalItems: spResponse.Table1[0].TotalItems,
            totalQuantity: spResponse.Table1[0].TotalQuantity,
          }));
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="input-row">
              <p>
                <strong>Total Items:</strong> {total.totalItems}
              </p>
            </div>
            <div className="input-row">
              <p>
                <strong>Total Quantity:</strong> {total.totalQuantity}
              </p>
            </div>
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>
                    <strong>Item</strong>
                  </th>
                  <th>
                    <strong>Description</strong>
                  </th>
                  <th>
                    <strong>Qty</strong>
                  </th>
                </tr>
              </thead>
              <tbody>{CheckItemTable}</tbody>
            </table>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
