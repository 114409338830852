// components
import { Header } from "./components/Header";
import { HomepageButton, MenuButton } from "./components/HomepageButton";
import { UserInfo } from "./components/UserInfo";

// pages
import { SignIn } from "./pages/SignIn";

// functions
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

// image sources
import logo from "./assets/img/toymate-logo.png";

// stylesheet
import "./App.scss";
import "./fonts/Password/password.ttf";
import { isMobileTablet } from "./functions/IsMobileTablet";
import { trackPromise } from "react-promise-tracker";
import { checkIfOnline, runFetch } from "./functions/RunFetch";
import { useRef } from "react";
import store from "./store";
import { Link, useNavigate } from "react-router-dom";

export const red = "#ec1c24";
export const orange = "#ff7f27";
export const yellow = "#ffca18";
export const green = "#0ed145";
export const blue = "#3581df";
export const purple = "#b83dba";

export const spURL = "https://tmapi-handheld.azurewebsites.net/api/values/Post/";
export const spURL_NoInputJSON =
  "https://tmapi-handheld.azurewebsites.net/api/values/PostNoInputJSON/";
export const spURL_Unallocated_WebOrders =
  "https://tmapi-handheld.azurewebsites.net/api/values/PostUnallocatedOrders/";
export const spURL_GetDDLCity =
  "https://tmapi-handheld.azurewebsites.net/api/values/Get/GetDDLCity/";
export const spURL_GetIP = "https://tmapi-handheld.azurewebsites.net/api/values/Get/IP/";
export const spURL_Local = `${
  process.env.REACT_APP_ENV === "STORE"
    ? "http://192.168.15.201/HANDHELDAPI"
    : "https://tmapi-handheld.azurewebsites.net"
}/api/values/Post/`;
export const spURL_LocalPOS = "http://192.168.15.201/POSAPI/api/values/Post/";
export const spURL_LocalPOS_Get = "http://192.168.15.201/POSAPI/api/values/Get";
export const spURL_LocalLabel = "http://192.168.15.201/HANDHELDAPI/api/";

export const getAccessData = async (userid) => {
  const isOnline = await checkIfOnline();

  if (isOnline) {
    const resObj = await runFetch(`${spURL}Handheld_Functions_Permission_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          UserID: userid,
        }),
      }),
    });

    if (resObj.response === null) {
      console.log(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        return spResponse.Table.reduce(
          (obj, item) => ((obj[item.Function] = item.Access), obj),
          {}
        );
      }
    }
  }

  return [];
};

const IdleTimeout = (doLogout) => {
  const onPrompt = () => {
    // Fire a Modal Prompt
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    doLogout();
  };

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  };

  const onAction = (event) => {
    // Do something when a user triggers a watched event
  };

  const {
    start,
    reset,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * 60 * 30, // milliseconds, ie 1000 * 60 * 5 = 5 mins
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    emitOnAllTabs: false,
  });
};

export const checkAccess = async (currentSite, userid, setIsSignedIn) => {
  const resObj = await runFetch(`${spURL}Handheld_Functions_Permission_Get`, {
    method: "POST",
    body: new URLSearchParams({
      inputJSON: JSON.stringify({
        UserID: userid,
      }),
    }),
  });

  if (resObj.response === null) {
    setIsSignedIn("0");
  } else {
    let spResponse = JSON.parse(resObj.response);

    if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
      var site = spResponse.Table.find((item) => item.Function === currentSite);

      if (site.Access === 0 || site.Access === null) {
        setIsSignedIn("0");
      }
    } else {
      setIsSignedIn("0");
    }
  }
};

export const checkDeviceAuth = async (accessedFunction, ip, userid, setSession) => {
  await trackPromise(
    fetch(spURL_GetIP, { method: "POST", body: new URLSearchParams({ inputJSON: "[{}]" }) })
      // internet is ok
      .then(async () => {
        const resObj = await trackPromise(
          runFetch(`${spURL}Handheld_DeviceAuth_Log_Add`, {
            method: "POST",
            body: new URLSearchParams({
              inputJSON: JSON.stringify({
                IPAddress: ip,
                DeviceID: localStorage.getItem("deviceID"),
                UserID: userid,
                Function: accessedFunction,
                Site: "TMV2",
              }),
            }),
          })
        );

        if (resObj.response === null) {
          setSession(`Lock: ${resObj.exception}`);
        } else {
          // if we already have a deviceID but no displayName in localStorage, get displayName
          const _deviceID = localStorage.getItem("deviceID");

          if (_deviceID !== null) {
            const res = await trackPromise(
              runFetch(`${spURL}Handheld_DeviceAuth_DisplayName_Get`, {
                method: "POST",
                body: new URLSearchParams({
                  inputJSON: JSON.stringify({
                    DeviceID: _deviceID,
                  }),
                }),
              })
            );

            if (res.response === null) {
              setSession(`Lock: ${res.exception}`);
            } else {
              let spResponse = JSON.parse(res.response);
              if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
                localStorage.setItem("displayName", spResponse.Table[0].DisplayName);
                localStorage.setItem("deviceStoreNo", spResponse.Table[0].StoreNo);
              }
            }
          }

          setSession("Logged");
        }
      })
      // no internet / unable to talk to tmapi-handheld api
      .catch(async (err) => {
        console.log(err);

        // check localStorage & localDB StoreNo

        const res = await runFetch(`${spURL_LocalPOS_Get}SettingStr/StoreNo`, { method: "GET" });
        const resObj = JSON.parse(res);

        if (resObj.status === "ERROR!") {
          console.log(resObj.exception);
          setSession(`Lock: ${resObj.exception}`);
        } else {
          let strStore = resObj.response;
          const _localStorageStoreNo = localStorage.getItem("deviceStoreNo");

          if (_localStorageStoreNo === strStore || _localStorageStoreNo === "HEADOFFICE") {
            setSession("Logged");
          } else {
            setSession(`Lock: ERROR!: Store does not match.`);
          }
        }
      })
  );
};

export const VerifyPage = () => {
  const [session, setSession] = store.useState("session");

  const [verifyResponse, setVerifyResponse] = useState("");

  const refDeviceID = useRef();
  const refDeviceCode = useRef();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("deviceauth-submit-button").click();
    }
  };

  const handleClick_Submit = async () => {
    const resObj = await trackPromise(
      runFetch(`${spURL}Handheld_DeviceAuth_Verify`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            DeviceID: refDeviceID.current.value,
            DeviceAuthCode: refDeviceCode.current.value,
            IPAddress: "",
            Site: "TMV2",
          }),
        }),
      })
    );

    if (resObj.exception !== "") {
      setVerifyResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        localStorage.setItem("displayName", spResponse.Table[0].DisplayName);
        localStorage.setItem("deviceID", refDeviceID.current.value);
        setSession("Logged");
      }
    }
  };

  return (
    <div>
      <Header page="Device Verification" />
      <div className="page-container">
        <div className="handheld-body-container">
          <p>
            <strong>
              This device has not been authorised to use the TOYMATE system. Contact support to
              configure authorisation.
            </strong>
          </p>
          <br />
          <div className="stocktake-create-input-row">
            <p>
              <strong>Device ID:</strong>
            </p>
            <input
              type="text"
              id="deviceauth-deviceid"
              ref={refDeviceID}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Code:</strong>
            </p>
            <input
              type="text"
              id="deviceauth-devicecode"
              ref={refDeviceCode}
              onKeyDown={handleKeyDown}
            />
          </div>
          <button
            className="signin-login-button btnHover"
            id="deviceauth-submit-button"
            onClick={handleClick_Submit}
          >
            Submit
          </button>
          <br />
          <p className="error-message">{verifyResponse}</p>
        </div>
      </div>
    </div>
  );
};

export const LockPage = () => {
  const [session, setSession] = store.useState("session");

  return (
    <div>
      <Header page="Device Locked" />
      <div className="page-container">
        <div className="handheld-body-container">
          <p>
            <strong>
              This device has not been authorised to use the TOYMATE system. Contact support to
              configure authorisation.
            </strong>
          </p>
          <br />
          <p className="error-message">{session.replace("Lock: ", "")}</p>
        </div>
      </div>
    </div>
  );
};

export const Homepage = () => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [storename, setStoreName] = store.useState("storename");
  const [username, setUserName] = store.useState("username");
  const [userid, setUserID] = store.useState("userid");
  const [ip, setIP] = store.useState("ip");
  const [session, setSession] = store.useState("session");
  const [isSignedIn, setIsSignedIn] = store.useState("issignedin");
  const navigate = useNavigate();

  const [Access, setAccess] = useState({});
  const [DeviceAllocatedResponse, setDeviceAllocatedResponse] = useState("");
  const [DeviceAllocatedPermission, setDeviceAllocatedPermission] = useState("");
  const [StoreSelectorResponse, setStoreSelectorResponse] = useState("");
  const [isDeviceAllocated, setIsDeviceAllocated] = useState(false);
  const [ddlStore, setDDLStore] = useState([]);
  const [IsStoreSelected, setIsStoreSelected] = useState(storeno !== "");
  // call IdleTimeout to enable timer for this page if already signed in

  const doLogout = () => {
    setIsSignedIn(0);
    setStoreNo("");
    setStoreName("");
    setUserID("");
    setUserName("");
    setIP("");
    setIsStoreSelected(false);
    navigate("/");
  };

  IdleTimeout(doLogout);

  const refStore = useRef();

  useEffect(() => {
    const getDDLStore = async () => {
      const resObj = await runFetch(`${spURL}Handheld_MainMenu_DDLStore_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            UserID: userid,
          }),
        }),
      });

      if (resObj.response === null) {
        console.log(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setDDLStore(spResponse.Table);
        } else {
          setDDLStore([]);
        }
      }
    };

    async function getAccess() {
      setAccess(await getAccessData(userid));
    }

    // check if user login is the correct user allocated
    async function checkUserAllocated() {
      setDeviceAllocatedResponse("");

      const res = await runFetch(
        `${spURL_LocalPOS}Handheld_DeviceManagement_CheckDeviceAllocated`,
        {
          method: "POST",
          body: new URLSearchParams({
            inputJSON: JSON.stringify({
              UserID: userid,
              StoreNo: storeno,
              DeviceID: localStorage.getItem("deviceID"),
            }),
          }),
        }
      );
      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        setDeviceAllocatedResponse(resObj.exception);
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setDeviceAllocatedPermission(spResponse.Table[0].DeviceAllocationPermission);
        }

        if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
          let deviceAllocatedResponse = spResponse.Table1[0].DeviceAllocatedResponse;

          if (deviceAllocatedResponse === "OK!") {
            setIsDeviceAllocated(true);
          }
          setDeviceAllocatedResponse(deviceAllocatedResponse);
        }
      }
    }

    checkDeviceAuth("Home", ip, userid, setSession);
    setIsDeviceAllocated(false);
    if (process.env.REACT_APP_ENV === "STORE") {
      checkUserAllocated();
    }

    if (process.env.REACT_APP_ENV === "HO") {
      getDDLStore();
    }
    getAccess();
  }, [userid]);

  const homePage = (
    <div className="menu-background">
      <div className="handheld-body-container">
        <img
          src={logo}
          alt="Toymate Logo"
        />
        <br />
        <UserInfo doLogout={doLogout} />
        <div className="stocktake-create-input-row">
          <p>
            <strong>Store</strong>
          </p>
          {process.env.REACT_APP_ENV === "STORE" || IsStoreSelected ? (
            <p>{storename}</p>
          ) : (
            <select ref={refStore}>
              <option
                value=""
                data-storename=""
              ></option>
              {ddlStore.map((store, index) => (
                <option
                  key={index}
                  value={`${store.StoreNo}:${store.StoreName}`}
                  data-storename={store.StoreName}
                >
                  {store.StoreName}
                </option>
              ))}
            </select>
          )}
        </div>
        {process.env.REACT_APP_ENV === "HO" && (
          <>
            <button
              className="signin-login-button"
              onClick={() => {
                setStoreSelectorResponse("");
                if (!IsStoreSelected) {
                  if (refStore.current.value === "") {
                    setStoreSelectorResponse("ERROR!: Store must be selected.");
                  } else {
                    const selectedValue = refStore.current.value;
                    const splittedValue = selectedValue.split(":");

                    setStoreNo(splittedValue[0]);
                    setStoreName(splittedValue[1]);
                    setIsStoreSelected(true);
                  }
                } else {
                  setStoreNo("");
                  setStoreName("");
                  setIsStoreSelected(false);
                }
              }}
            >
              {IsStoreSelected ? "Change" : "Select"}
            </button>
            <br />
            <br />
          </>
        )}
        {!(process.env.REACT_APP_ENV === "STORE" || IsStoreSelected) ? (
          <p className="error-message">{StoreSelectorResponse}</p>
        ) : isDeviceAllocated || !isMobileTablet() || process.env.REACT_APP_ENV === "HO" ? (
          <>
            <div className="home-table">
              {Access.ItemDetails ? (
                <HomepageButton
                  link="/ItemDetails"
                  button="ID"
                  colour={red}
                  title="Item Details"
                />
              ) : (
                ""
              )}
              {Access.ItemDetailsHO && process.env.REACT_APP_ENV === "HO" ? (
                <HomepageButton
                  link="/ItemDetailsHO"
                  button="ID"
                  colour={red}
                  title="Item Details HO"
                />
              ) : (
                ""
              )}
              {Access.StockTake ? (
                <HomepageButton
                  link="/StockTake"
                  button="ST"
                  colour={red}
                  title="Stock Take"
                />
              ) : (
                ""
              )}
              {Access.Budget ? (
                <HomepageButton
                  link="/Budget"
                  button="B"
                  colour={red}
                  title="Budget"
                />
              ) : (
                ""
              )}
            </div>
            <div className="home-table">
              {Access.PODCode ? (
                <HomepageButton
                  link="/PODCode"
                  button="POD"
                  colour={orange}
                  title="POD Code"
                />
              ) : (
                ""
              )}
              {Access.WHReceived ? (
                <HomepageButton
                  link="/WHReceived"
                  button="WH"
                  colour={orange}
                  title="WH Received"
                />
              ) : (
                ""
              )}
              {Access.StoreTransfer ? (
                <HomepageButton
                  link="/StoreTransfer"
                  button="ST"
                  colour={orange}
                  title="Store Transfer"
                />
              ) : (
                ""
              )}
              {Access.PerthWarehouse && process.env.REACT_APP_ENV === "HO" ? (
                <HomepageButton
                  link="/PerthWarehouse"
                  button="PW"
                  colour={orange}
                  title="Perth Warehouse"
                />
              ) : (
                ""
              )}
            </div>
            <div className="home-table">
              {/* {Access.Labels && process.env.REACT_APP_ENV === 'STORE' */}
              {process.env.REACT_APP_ENV === "STORE" ? (
                <HomepageButton
                  link="/Labels"
                  button="L"
                  colour={yellow}
                  title="Labels"
                />
              ) : (
                ""
              )}
              {Access.Merchandising ? (
                <HomepageButton
                  link="/Merchandising"
                  button="M"
                  colour={yellow}
                  title="Merchandising"
                />
              ) : (
                ""
              )}
              {Access.BulkyItemMerch ? (
                <HomepageButton
                  link="/BulkyItemMerch"
                  button="BIM"
                  colour={yellow}
                  title="Bulky Item Merch"
                />
              ) : (
                ""
              )}
            </div>

            <div className="home-table">
              {Access.EComm ? (
                <HomepageButton
                  link="/EComm"
                  button="EC"
                  colour={green}
                  title="eComm"
                />
              ) : (
                ""
              )}
              {Access.IncomingDeliveries ? (
                <HomepageButton
                  link="/IncomingDeliveries"
                  button="ID"
                  colour={green}
                  title="Incoming Deliveries"
                />
              ) : (
                ""
              )}
              {Access.PrintReceipt && process.env.REACT_APP_ENV === "HO" ? (
                <HomepageButton
                  link="/PrintReceipt"
                  button="PR"
                  colour={green}
                  title="Print Receipt"
                />
              ) : (
                ""
              )}
              {Access.StoreAuditTool && (
                // <HomepageButton
                //   link="/StoreAuditTool"
                //   button="SAT"
                //   colour={green}
                //   title="Store Audit Tool"
                // />
                <MenuButton
                  button="SAT"
                  colour={green}
                  title="Store Audit Tool"
                  onClick={() => {
                    window.location.href = `${
                      process.env.NODE_ENV === "production" ? "https://tm.toymate.com.au" : ""
                    }/#/StoreAuditTool?f=${
                      process.env.REACT_APP_ENV
                    }&s=${storeno}&u=${userid}&n=${storename}`;
                  }}
                />
              )}
              <div className="home-table-items"></div>
            </div>

            <div className="home-table">
              {Access.IT && process.env.REACT_APP_ENV === "HO" ? (
                <HomepageButton
                  link="/IT"
                  button="IT"
                  colour={blue}
                  title="IT"
                />
              ) : (
                ""
              )}
              {DeviceAllocatedPermission === "MANAGER" && process.env.REACT_APP_ENV === "STORE" ? (
                <HomepageButton
                  link="/Unallocate"
                  button="U"
                  colour={blue}
                  title="Unallocate"
                />
              ) : (
                ""
              )}
              {Access.POSMessage && process.env.REACT_APP_ENV === "HO" ? (
                <HomepageButton
                  link="/POSMessage"
                  button="PM"
                  colour={blue}
                  title="POS Message"
                />
              ) : (
                ""
              )}
            </div>

            <div className="home-table">
              {Access.Marketing && process.env.REACT_APP_ENV === "HO" ? (
                <HomepageButton
                  link="/Marketing"
                  button="M"
                  colour={purple}
                  title="Marketing"
                />
              ) : (
                ""
              )}
              {DeviceAllocatedPermission === "MANAGER" && process.env.REACT_APP_ENV === "STORE" ? (
                <HomepageButton
                  link="/DeviceStatus"
                  button="DS"
                  colour={purple}
                  title="Device Status"
                />
              ) : (
                ""
              )}
              {Access.StaffManage ? (
                <HomepageButton
                  link="/StaffManage"
                  button="SM"
                  colour={purple}
                  title="Staff Manage"
                />
              ) : (
                ""
              )}
              {Access.CategoryReportManage && process.env.REACT_APP_ENV === "HO" ? (
                <HomepageButton
                  link="/CategoryReportManage"
                  button="CRM"
                  colour={purple}
                  title="Category Report Manage"
                />
              ) : (
                ""
              )}
              {Access.Reports && process.env.REACT_APP_ENV === "HO" ? (
                <HomepageButton
                  link="/Reports"
                  button="R"
                  colour={purple}
                  title="Reports"
                />
              ) : (
                ""
              )}
            </div>
          </>
        ) : DeviceAllocatedResponse === "ALLOCATE" ? (
          <div className="home-table">
            <HomepageButton
              link="/Allocate"
              button="A"
              colour={blue}
              title="Allocate"
            />
            {DeviceAllocatedPermission === "MANAGER" ? (
              <HomepageButton
                link="/DeviceStatus"
                button="DS"
                colour={purple}
                title="Device Status"
              />
            ) : (
              ""
            )}
          </div>
        ) : DeviceAllocatedResponse === "UNALLOCATE" ? (
          <div className="home-table">
            <HomepageButton
              link="/Unallocate"
              button="U"
              colour={blue}
              title="Unallocate"
            />
            {DeviceAllocatedPermission === "MANAGER" ? (
              <HomepageButton
                link="/DeviceStatus"
                button="DS"
                colour={purple}
                title="Device Status"
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          <p className="error-message">{DeviceAllocatedResponse}</p>
        )}
      </div>
    </div>
  );

  const signIn = (
    <div>
      <Header page="Sign In" />
      <SignIn />
    </div>
  );

  const page = () => {
    const _deviceid = localStorage.getItem("deviceID");

    if (_deviceid === null) {
      return <VerifyPage />;
    } else {
      if (session === "Logged" || session === null || session === "") {
        if (isSignedIn === "1") {
          return homePage;
        } else {
          return signIn;
        }
      } else if (session === "Verify") {
        return <VerifyPage />;
      } else if (session.substring(0, 4) === "Lock") {
        return <LockPage />;
      } else {
        return signIn;
      }
    }
  };

  const currentPage = page();

  return currentPage;
};
