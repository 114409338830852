import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton } from "../../components/HomepageButton";

export const PopupConfirm = (props) => {
  const [ConfirmResponse, setConfirmResponse] = useState("");

  const handleClose = () => props.setShow(false);

  const handleClick_No = () => props.setPage("lines-return");

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <strong>This item is not in order. Are you sure to refund this item?</strong>
            <br />
            <br />
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClick_No}
            >
              No
            </button>
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
