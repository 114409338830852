import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { PopupUpdateShipping } from "./PopupUpdateShipping";
import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { runFetch } from "../../functions/RunFetch";

export const Search = (props) => {
  const [MenuResponse, setMenuResponse] = useState("");
  const [LoadingMessage, setLoadingMessage] = useState(false);
  const [Order, setOrder] = useState({
    OrderID: "",
    OrderPlaced: "",
    IPAddress: "",
    OrderStatus: "",
    PaymentMethod: "",
    PaymentStatus: "",
    ProviderID: "",
    CustomerNo: "",
  });

  const [Billing, setBilling] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Street1: "",
    Street2: "",
    City: "",
    Zip: "",
    State: "",
    Country: "",
  });

  const [Shipping, setShipping] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Street1: "",
    Street2: "",
    City: "",
    Zip: "",
    State: "",
    Country: "",
  });

  const [ItemTable, setItemTable] = useState("");
  const [SelectedPickDetails, setSelectedPickDetails] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const pageLoad = async () => {
    setLoadingMessage(true);

    const resObj = await runFetch(`${spURL}FailedOrders_SearchOrder_Details`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          OrderID: props.OrderID,
        }),
      }),
    });

    if (resObj.response === null) {
      props.setMenuResponse(resObj.exception);
      props.setPage("main");
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setOrder((values) => ({
          ...values,
          OrderID: spResponse.Table[0].OrderID,
          OrderPlaced: spResponse.Table[0].OrderPlaced,
          IPAddress: spResponse.Table[0].ip_address,
          OrderStatus: spResponse.Table[0].OrderStatus,
          PaymentMethod: spResponse.Table[0].payment_method,
          PaymentStatus: spResponse.Table[0].payment_status,
          ProviderID: spResponse.Table[0].payment_provider_id,
          CustomerNo: spResponse.Table[0].CustomerNumber,
        }));

        setBilling((values) => ({
          ...values,
          Name: spResponse.Table[0].BillingName,
          Email: spResponse.Table[0].billing_address_email,
          Phone: spResponse.Table[0].billing_address_phone,
          Street1: spResponse.Table[0].billing_address_street_1,
          Street2: spResponse.Table[0].billing_address_street_2,
          City: spResponse.Table[0].billing_address_city,
          Zip: spResponse.Table[0].billing_address_zip,
          State: spResponse.Table[0].billing_address_state,
          Country: spResponse.Table[0].billing_address_country,
        }));

        setShipping((values) => ({
          ...values,
          Name: spResponse.Table[0].ShippingName,
          Email: spResponse.Table[0].email,
          Phone: spResponse.Table[0].phone,
          Street1: spResponse.Table[0].street_1,
          Street2: spResponse.Table[0].street_2,
          City: spResponse.Table[0].city,
          Zip: spResponse.Table[0].zip,
          State: spResponse.Table[0].state,
          Country: spResponse.Table[0].country,
        }));
      } else {
        props.setMenuResponse("ERROR!: Order not found.");
        props.setPage("main");
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setItemTable(spResponse.Table1);
      }
    }
    setLoadingMessage(false);
  };

  const handleClick_Row = (_pickObj) => {
    setMenuResponse("");

    if (SelectedPickDetails.length === 0) {
      setSelectedPickDetails(_pickObj);
    } else {
      if (SelectedPickDetails.map((pick) => pick.PickID).includes(_pickObj.PickID)) {
        setSelectedPickDetails(SelectedPickDetails.concat([_pickObj]));
      } else {
        setMenuResponse("ERROR!: Cannot select item from other pick.");
      }
    }
  };

  // const toggleRowBackground = (event) => {
  //   const _class = event.target.parentElement.className;
  //   const _itemno = event.target.attributes["itemno"].value;
  //   const _pickid = event.target.attributes["pick"].value;

  //   const _value = _pickid + "-" + _itemno;

  //   if (_class === "btnHover background-white") {
  //     document.getElementById("failedorders-selecteditems").value += _value + ",";
  //     event.target.parentElement.className = "btnHover background-lightblue";
  //   } else {
  //     document.getElementById("failedorders-selecteditems").value = document
  //       .getElementById("failedorders-selecteditems")
  //       .value.replace(_value + ",", "");

  //     event.target.parentElement.className = "btnHover background-white";

  //     if (document.getElementById("failedorders-selecteditems").value === "") {
  //       document.getElementById("failedorders-selectedpickid").value = "";
  //     }
  //   }
  // };

  const handleClick_Back = () => {
    props.setOrderID("");
    props.setMenuResponse("");
    props.setPage("main");
  };

  const handleClick_Refund = async () => {
    setLoadingMessage(true);

    const resObj = await runFetch(`${spURL}FailedOrders_SearchOrder_RefundUnallocateItems_Submit`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(
          SelectedPickDetails.map((item) => ({
            orderid: props.OrderID,
            itemid: item.ItemNo,
            description: item.Description,
            quantity: item.Quantity,
            pickid: item.PickID,
          }))
        ),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setMenuResponse(spResponse.Table[0].OutputString);
        props.setPage("main");
      }
    }
    setLoadingMessage(false);
  };

  const handleClick_UpdateShippingAddress = () => {
    // show shipping address
    setShowPopup(true);
  };

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <div className="blockedorders-content-container">
          <h4>
            <strong>Order {props.OrderID} Details</strong>
          </h4>
          {LoadingMessage ? (
            <p>Loading...</p>
          ) : (
            <>
              <table className="blockedorders-order-table">
                <tr>
                  <th className="blockedorders-order-table-col-1">
                    <strong>Order ID:</strong>
                  </th>
                  <td className="blockedorders-order-table-col-2">{props.OrderID}</td>
                  <th className="blockedorders-order-table-col-3">
                    <strong>Payment Method:</strong>
                  </th>
                  <td className="blockedorders-order-table-col-4">{Order.PaymentMethod}</td>
                </tr>
                <tr>
                  <th>
                    <strong>Order Placed:</strong>
                  </th>
                  <td>{Order.OrderPlaced}</td>
                  <th>
                    <strong>Payment Status:</strong>
                  </th>
                  <td>{Order.PaymentStatus}</td>
                </tr>
                <tr>
                  <th>
                    <strong>IP Address:</strong>
                  </th>
                  <td>{Order.IPAddress}</td>
                  <th>
                    <strong>Provider ID:</strong>
                  </th>
                  <td>{Order.ProviderID}</td>
                </tr>
                <tr>
                  <th>
                    <strong>Order Status:</strong>
                  </th>
                  <td>{Order.OrderStatus}</td>
                </tr>
                <br />
                <tr>
                  <th>
                    <strong>Customer No:</strong>
                  </th>
                  <tr>{Order.CustomerNo}</tr>
                </tr>
              </table>
              <br />
              <table className="blockedorders-address-table">
                <thead>
                  <tr>
                    <th className="blockedorders-address-table-col-1">
                      <h4>
                        <strong>Billing Details</strong>
                      </h4>
                    </th>
                    <th className="blockedorders-address-table-col-2">
                      <h4>
                        <strong>Shipping Details</strong>
                      </h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{Billing.Name}</td>
                    <td>{Shipping.Name}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Phone}</td>
                    <td>{Shipping.Phone}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Email}</td>
                    <td>{Shipping.Email}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Street1}</td>
                    <td>{Shipping.Street1}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Street2}</td>
                    <td>{Shipping.Street2}</td>
                  </tr>
                  <tr>
                    <td>{Billing.City}</td>
                    <td>{Shipping.City}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Zip + " " + Billing.State}</td>
                    <td>{Shipping.Zip + " " + Shipping.State}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Country}</td>
                    <td>{Shipping.Country}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              {ItemTable && (
                <table className="itemdetails-table">
                  <thead>
                    <tr>
                      <th>
                        <strong>Pick</strong>
                      </th>
                      <th>
                        <strong>ItemNo</strong>
                      </th>
                      <th>
                        <strong>Description</strong>
                      </th>
                      <th>
                        <strong>Qty</strong>
                      </th>
                      <th>
                        <strong>Avail</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ItemTable.map((item, index) => (
                      <tr
                        key={index}
                        className={`btnHover ${
                          SelectedPickDetails.map(
                            (item) => `${item.pick_id}-${item.ItemNo}`
                          ).includes(`${item.pick_id}-${item.ItemNo}`)
                            ? "background-lightblue"
                            : "background-white"
                        }`}
                        onClick={() =>
                          handleClick_Row({
                            PickID: item.pick_id,
                            ItemNo: item.ItemNo,
                            Description: item.Description,
                            Quantity: item.Quantity,
                          })
                        }
                      >
                        <td>{item.pick_id}</td>
                        <td>{item.ItemNo}</td>
                        <td>{item.Description}</td>
                        <td>{item.Quantity}</td>
                        <td>{item.Available}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <br />
              <div className="blockedorders-option-row">
                <MenuButton
                  title="Update Shipping"
                  colour={green}
                  button="A"
                  onClick={handleClick_UpdateShippingAddress}
                />
                <MenuButton
                  title="Refund"
                  colour={green}
                  button="R"
                  onClick={handleClick_Refund}
                />
              </div>
            </>
          )}

          <br />
          <p className="error-message">{MenuResponse}</p>
          <input
            type="hidden"
            id="failedorders-selectedpickid"
          />
          <input
            type="hidden"
            id="failedorders-selecteditems"
          />

          <PopupUpdateShipping
            show={showPopup}
            setShow={setShowPopup}
            setPage={props.setPage}
            Shipping={Shipping}
            OrderID={props.OrderID}
            setMenuResponse={setMenuResponse}
          />
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
