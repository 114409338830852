import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  getAccessData,
  green,
  orange,
  spURL,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
} from "../../App";
import { MenuButton, FooterButton } from "../../components/HomepageButton";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const Menu = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [MenuResponse, setMenuResponse] = useState(props.WHReceivedResponse);

  const [Access, setAccess] = useState([]);

  const handleClick_Menu_Receive = () => {
    setMenuResponse("");
    props.setWHReceivedResponse("");
    props.setPage("whreceive");
  };

  const handleClick_Menu_Manifest = async () => {
    setMenuResponse("");

    const resObj = await runFetch(`${spURL}Handheld_WHReceived_Manifest`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      const blob = new Blob([spResponse.Table[0].Manifest], {
        type: "application/ms-excel",
      });

      var date = new Date();

      const formattedDate = date
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        })
        .replace(/\//g, "-");

      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = `SSCManifest_${formattedDate}.xls`;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    }
  };

  const handleClick_Menu_Manager = (event) => {
    setMenuResponse("");
    props.setWHReceivedResponse("");
    props.setPage("manager");
  };

  const handleClick_Menu_SSCCException = (event) => {
    setMenuResponse("");
    props.setWHReceivedResponse("");
    props.setPage("ssccexception");
  };

  useEffect(() => {
    async function getAccess() {
      setAccess(await getAccessData(userid));
    }
    getAccess();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          <MenuButton
            onClick={handleClick_Menu_Receive}
            button="R"
            colour={orange}
            title="Receive"
          />
          <MenuButton
            onClick={handleClick_Menu_Manifest}
            button="M"
            colour={orange}
            title="Manifest"
          />
          {Access.WHReceivedManager ? (
            <MenuButton
              onClick={handleClick_Menu_Manager}
              button="M"
              colour={orange}
              title="Manager"
            />
          ) : (
            ""
          )}
        </div>
        <div className="home-table">
          {Access.WHReceivedSSCCException ? (
            <MenuButton
              onClick={handleClick_Menu_SSCCException}
              button="SE"
              colour={orange}
              title="SSCC Exception"
            />
          ) : (
            ""
          )}
          {Access.SSCCSplit ? (
            <Link to="/SSCCSplit">
              <MenuButton
                button="SS"
                colour={orange}
                title="SSCC Split"
              />
            </Link>
          ) : (
            ""
          )}
          {Access.CageAllocation ? (
            <Link to="/CageAllocation">
              <MenuButton
                button="CA"
                colour={orange}
                title="Cage Allocation"
              />
            </Link>
          ) : (
            ""
          )}
        </div>
        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={orange}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
