import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal'

import { store, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from '../../App';
import { trackPromise } from 'react-promise-tracker';

export const PopupProcess = (props) => {

    const handleClose = () => props.setShow(false);

    const handleSubmit = () => {
        const _email = document.getElementById('phoneorders-process-email').value;
        const _mobile = document.getElementById('phoneorders-process-mobile').value;

        async function processOrder(_orderid) {
            // load blocked orders table
            var spResponse = "";

            var data = new FormData();

            data = `OrderID=${props.OrderID}&Mobile=${_mobile}&Email=${_email}`

            // POST request using fetch inside useEffect React hook
            const requestOptions = {
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            };

            const res = await trackPromise(fetch('https://prod-09.australiaeast.logic.azure.com:443/workflows/6e897ee969724aa5a9824355b1069560/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=v_8_8X051prXd0inBhpR5p9ozKqgqmsb6B9pyk6adG4', requestOptions));
            res
                .json()
                .then(data => {
                    if (data.status === 422) {
                        props.setResponse('ERROR!: ' + data.title);
                    } else {
                        props.setResponse('URL: ' + data.carturl);
                    }

                    props.setShow(false)
                })
        }

        processOrder();
    }

    return (
        <div>
            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered>
                <Modal.Body>
                    <div>
                        <div className='stocktake-create-input-row'>
                            <p><strong>Email:</strong></p>
                            <input type='text' id='phoneorders-process-email' />
                        </div>
                        <div className='stocktake-create-input-row'>
                            <p><strong>Mobile:</strong></p>
                            <input type='text' id='phoneorders-process-mobile' />
                        </div>
                    </div>
                    <br />
                    <div className='popup-button-container'>
                        <button className='popup-button' onClick={handleClose}>Close</button>
                        <button className='popup-button' onClick={handleSubmit}>Submit</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}