import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";

export const PopupDeleteTimeslot = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const handleClose = () => {
    props.setShow(false);
    props.getBookingList();
  };

  const handleShow = () => {
    setPopupResponse("");
  };

  const handleSubmit = (event) => {
    deleteTimeslot();
  };

  const deleteTimeslot = async () => {
    const resObj = await runFetch(`${spURL}Handheld_IncomingDeliveries_Delivery_Delete`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          BookingID: props.DeleteBookingID,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setPopupResponse(spResponse.Table[0].OutputString);
        props.getBookingList();
        props.setShow(false);
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <p>
              <strong>Confirm delete timeslot?</strong>
            </p>
            <br />
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>No</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit}
            >
              <strong>Yes</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
