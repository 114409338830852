import React, { useState, useEffect } from "react";

import { Main } from "./Main";
import { Update } from "./Update";
import { Search } from "./Search";
import { Manage } from "./Manage";

export const BlockedOrdersBody = () => {
  const [page, setPage] = useState("main");
  const [OrderID, setOrderID] = useState("");
  const [MenuResponse, setMenuResponse] = useState("");

  const mainPage = (
    <Main
      setPage={setPage}
      setOrderID={setOrderID}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
    />
  );

  const updatePage = (
    <Update
      setPage={setPage}
      OrderID={OrderID}
      setOrderID={setOrderID}
      setMenuResponse={setMenuResponse}
    />
  );

  const searchPage = (
    <Search
      setPage={setPage}
      OrderID={OrderID}
      setOrderID={setOrderID}
      setMenuResponse={setMenuResponse}
    />
  );

  const managePage = <Manage setPage={setPage} />;

  const getCurrentPage = (page) => {
    switch (page) {
      case "main":
        return mainPage;
      case "update":
        return updatePage;
      case "search":
        return searchPage;
      case "manage":
        return managePage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
