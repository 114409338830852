import { BlobServiceClient } from "@azure/storage-blob";
import React, { useState, useEffect, useRef } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import { green, spURL, spURL_NoInputJSON } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import imageCompression from "browser-image-compression";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PopupCameraScreen } from "./PopupCameraScreen";

export const EditIssueMobile = (props) => {
  const [StoreAuditToolResponse, setStoreAuditToolResponse] = useState("");
  const [ddlStore, setDDLStore] = useState([]);
  const [IsImageModified, setIsImageModified] = useState(false);

  const blobServiceClient = new BlobServiceClient(
    `https://tmportalimages.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2050-12-31T13:00:00Z&st=2024-07-10T00:26:54Z&spr=https&sig=ahho9vjnyCei7eSyP5PBNmT3ppPfQajEw10jdi4qD1A%3D`
  ); // create a blobServiceClient
  const containerClient = blobServiceClient.getContainerClient("handheldstoreaudittoolimages"); // create a containerClient

  const emptyIssueDetails = {
    IssueID: 0,
    StoreNo: "",
    StoreName: "",
    IssueSubject: "",
    IssueDescription: "",
    AssignedTo: "",
    Resolved: 0,
    ResolveComment: "",
    DateCreated: "",
    DateResolved: "",
  };
  const [IssueDetails, setIssueDetails] = useState(emptyIssueDetails);
  const emptyIssueImage = {
    ImageLineNo: 0,
    ImageURL: "",
  };
  const [IssueImages, setIssueImages] = useState(emptyIssueImage);

  const emptyUploadedImageFile = {
    blob: "",
  };
  const [UploadedImageFile, setUploadedImageFile] = useState(emptyUploadedImageFile);

  const [showCamera, setShowCamera] = useState(false);

  const refSubject = useRef();
  const refDescription = useRef();
  const refAssignedTo = useRef();
  const refResolveComment = useRef();

  const handleClick_Back = () => {
    props.setStoreAuditToolResponse("");
    props.setSelectedIssueID("");
    props.setPage("issue-list");
  };

  const getIssueDetails = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_IssueDetails_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          IssueID: props.SelectedIssueID,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setIssueDetails({
          IssueID: spResponse.Table[0].ID,
          StoreNo: spResponse.Table[0].StoreNo,
          StoreName: spResponse.Table[0].StoreName,
          IssueSubject: spResponse.Table[0].IssueSubject,
          IssueDescription: spResponse.Table[0].IssueDescription,
          AssignedTo: spResponse.Table[0].AssignedTo,
          Resolved: spResponse.Table[0].Resolved,
          ResolveComment: spResponse.Table[0].ResolveComment,
          DateCreated: spResponse.Table[0]["Date Created"],
          DateResolved: spResponse.Table[0]["Date Resolved"],
        });

        refSubject.current.value = spResponse.Table[0].IssueSubject;
        refDescription.current.value = spResponse.Table[0].IssueDescription;
        refAssignedTo.current.value = spResponse.Table[0].AssignedTo;
        refResolveComment.current.value = spResponse.Table[0]["Resolved Comment"];

        if (spResponse.Table[0].Resolved === 1) {
          refSubject.current.disabled = true;
          refDescription.current.disabled = true;
          refAssignedTo.current.disabled = true;
          refResolveComment.current.disabled = true;
        }
      } else {
        setIssueDetails(emptyIssueDetails);
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setIssueImages({
          ImageLineNo: spResponse.Table1[0].ImageLineNo,
          ImageURL: spResponse.Table1[0].ImageURL,
        });
      } else {
        setIssueImages(emptyIssueImage);
      }
    }
  };

  const handleClick_Resolve = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_Issue_Resolve`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          IssueID: IssueDetails.IssueID,
          ResolveComment: refResolveComment.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStoreAuditToolResponse(spResponse.Table[0].OutputString);
        props.setPage("menu");
      }
    }
  };

  const handleClick_Save = async () => {
    // update
    const resObj = await trackPromise(
      runFetch(`${spURL}Handheld_StoreAuditTool_Issue_${props.IssueActionType}`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(
            props.IssueActionType === "EDIT"
              ? {
                  IssueID: props.SelectedIssueID,
                  IssueSubject: refSubject.current.value,
                  IssueDescription: refDescription.current.value,
                  AssignedTo: refAssignedTo.current.value,
                  ResolveComment: refResolveComment.current.value,
                }
              : {
                  ProjectID: props.SelectedProject.ProjectID,
                  IssueSubject: refSubject.current.value,
                  IssueDescription: refDescription.current.value,
                  AssignedTo: refAssignedTo.current.value,
                }
          ),
        }),
      })
    );

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStoreAuditToolResponse(spResponse.Table[0].OutputString);

        let _issueid = spResponse.Table[0].IssueID;

        // upload image if we wanted to
        if (IsImageModified) {
          if (UploadedImageFile.blob === "") {
            if (props.IssueActionType === "EDIT") {
              handleClick_DeleteImage();
            } else {
              props.setPage("issue-list");
            }
          } else {
            let uploadedImageFileName = `${props.From.StoreName.replaceAll(
              " ",
              ""
            )}_${_issueid}.jpg`;
            const blobClient = containerClient.getBlockBlobClient(uploadedImageFileName);

            trackPromise(blobClient.uploadData(UploadedImageFile.blob))
              .then(async () => {
                const blobURL = `https://tmportalimages.blob.core.windows.net/handheldstoreaudittoolimages/${encodeURIComponent(
                  uploadedImageFileName
                )}`;

                const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_IssueImage_Upload`, {
                  method: "POST",
                  body: new URLSearchParams({
                    inputJSON: JSON.stringify({
                      IssueID: _issueid,
                      ImageURL: blobURL,
                    }),
                  }),
                });

                if (resObj.response === null) {
                  setStoreAuditToolResponse(resObj.exception);
                } else {
                  let spResponse = JSON.parse(resObj.response);

                  if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
                    setStoreAuditToolResponse(spResponse.Table[0].OutputString);
                    props.setPage("issue-list");
                  }
                }
              })
              .catch((err) => {
                setStoreAuditToolResponse(`ERROR!: Image upload error. ${err}`);
              });
          }
        } else {
          props.setPage("issue-list");
        }
      }
    }
  };

  const processSnappedImage = async (imageString) => {
    await trackPromise(
      fetch(imageString)
        .then((data) => data.blob())
        .then((imgBlob) => {
          setIsImageModified(true);
          setUploadedImageFile({ blob: imgBlob });
        })
    );
  };

  const compressFile = (file) => {
    return new Promise(async function (resolve, reject) {
      let compressedFile = await imageCompression(file, {
        maxSizeMB: 2, // max image size = 200kb
        maxWidthOrHeight: 300,
        useWebWorker: true,
      });

      // let compressedFile = file;
      resolve(compressedFile);
    });
  };

  const handleClick_Delete = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_Issue_Delete`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          IssueID: IssueDetails.IssueID,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStoreAuditToolResponse(spResponse.Table[0].OutputString);
      }
    }
  };

  const handleClick_DeleteImage = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_IssueImage_Delete`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          IssueID: IssueDetails.IssueID,
        }),
      }),
    });

    if (resObj.response === null) {
      setStoreAuditToolResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStoreAuditToolResponse(spResponse.Table[0].OutputString);
        props.setPage("issue-list");
      }
    }
  };

  useEffect(() => {
    const loadDDLStore = async () => {
      const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_DDLStore_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            UserID: props.From.UserID,
            StoreNo: props.From.StoreNo,
          }),
        }),
      });
      if (resObj.response === null) {
        setStoreAuditToolResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setDDLStore(spResponse.Table);
        }
      }
    };

    switch (props.IssueActionType) {
      case "CREATE":
        loadDDLStore();
        refResolveComment.current.disabled = true;
        break;
      case "EDIT":
        getIssueDetails();
        refResolveComment.current.disabled = false;
        break;
      default:
        break;
    }
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        {props.IssueActionType !== "CREATE" ? (
          <>
            <div className="stocktake-create-input-row">
              <p>
                <strong>IssueID:</strong>
              </p>
              <p>{IssueDetails.IssueID}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Created on:</strong>
              </p>
              <p>{new Date(IssueDetails.DateCreated).toLocaleString()}</p>
            </div>
            {IssueDetails.Resolved === 1 && (
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Resolved on:</strong>
                </p>
                <p>{new Date(IssueDetails.DateResolved).toLocaleString()}</p>
              </div>
            )}
          </>
        ) : null}
        <div className="stocktake-create-input-row">
          <p>
            <strong>Subject:</strong>
          </p>
          <input
            type="text"
            ref={refSubject}
          />
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Description:</strong>
          </p>
          <textarea
            ref={refDescription}
            rows="10"
            placeholder="Enter issue description here..."
          ></textarea>
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Assigned to:</strong>
          </p>
          <input
            type="text"
            ref={refAssignedTo}
          />
        </div>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Resolve Comment:</strong>
          </p>
          <textarea
            ref={refResolveComment}
            rows="10"
            placeholder="Enter resolve comment here..."
          ></textarea>
        </div>
        <div className="blockedorders-option-row">
          <div className="storeaudittool-fileupload-container">
            {IssueDetails.Resolved === 0 ? (
              IsImageModified ? (
                UploadedImageFile.blob === "" ? (
                  <MenuButton
                    className="no-highlight"
                    button="UP"
                    colour={green}
                    title="Upload Photo"
                    onClick={() => setShowCamera(true)}
                  />
                ) : (
                  <>
                    <img
                      className="storeaudittool-image btnHover"
                      src={URL.createObjectURL(UploadedImageFile.blob)}
                      onClick={() => setShowCamera(true)}
                    />
                    <FontAwesomeIcon
                      className="storeaudittool-deleteimage-icon btnHover"
                      icon="fas fa-times"
                      onClick={() => {
                        setIsImageModified(true);
                        setUploadedImageFile(emptyUploadedImageFile);
                      }}
                    />
                  </>
                )
              ) : IssueImages.ImageLineNo === 0 ? (
                <MenuButton
                  button="UP"
                  colour={green}
                  title="Upload Photo"
                  onClick={() => setShowCamera(true)}
                />
              ) : (
                <>
                  <img
                    className="storeaudittool-image btnHover"
                    src={IssueImages.ImageURL}
                    onClick={() => setShowCamera(true)}
                  />
                  <FontAwesomeIcon
                    className="storeaudittool-deleteimage-icon btnHover"
                    icon="fas fa-times"
                    onClick={() => {
                      setIsImageModified(true);
                      setUploadedImageFile(emptyUploadedImageFile);
                    }}
                  />
                </>
              )
            ) : (
              <img
                className="storeaudittool-image"
                src={IssueImages.ImageURL}
              />
            )}
          </div>
        </div>

        <p className="error-message">{StoreAuditToolResponse}</p>

        <PopupCameraScreen
          show={showCamera}
          setShow={setShowCamera}
          processSnappedImage={processSnappedImage}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <FooterButton
          button="Home"
          colour={green}
          onClick={props.handleClick_Home}
        />
        {IssueDetails.Resolved === 1 ? null : (
          <>
            {props.IssueActionType === "EDIT" && (
              <>
                <FooterButton
                  button="Delete"
                  colour={green}
                  onClick={handleClick_Delete}
                />
                <FooterButton
                  button="Rslve"
                  colour={green}
                  onClick={handleClick_Resolve}
                />
              </>
            )}
            <FooterButton
              button="Save"
              colour={green}
              onClick={handleClick_Save}
            />
          </>
        )}
      </div>
    </div>
  );
};
