import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, blue } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";

export const PopupErrorAcknowledge = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const doAcknowledge = async (_flag) => {
    const resObj = await runFetch(spURL + "POS_Ping_Status_UnacknowledgedErrorList_Acknowledge", {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreName: props.StoreName,
          ErrorDescription: props.ErrorDescription,
          Flag: _flag,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setPopupResponse(spResponse.Table[0].OutputString);
        props.setErrorList([]);
        props.setShow(false);
        props.pageLoad();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="blockedorders-option-row">
              <MenuButton
                button="A"
                title="ACK"
                colour={blue}
                onClick={async () => await doAcknowledge(0)}
              />
              <MenuButton
                button="AE"
                title="ACK + Email"
                colour={blue}
                onClick={async () => await doAcknowledge(1)}
              />
            </div>
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
