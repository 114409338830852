import React, { useState, useEffect } from "react";

import { Menu } from "./Menu";
import { Single } from "./Single";
import { Pick } from "./Pick";
import { Item } from "./Item";
import { Confirm } from "./Confirm";
import { Multiple } from "./Multiple";
import { View } from "./View";
import { LinesView } from "./LinesView";
import { ItemView } from "./ItemView";
import { Verify } from "./Verify";
import { Return } from "./Return";
import { LinesReturn } from "./LinesReturn";
import { ItemReturn } from "./ItemReturn";
import { Manager } from "./Manager";

import { getAccessData, spURL } from "../../App";
import { GiftCardLookup } from "./GiftCardLookup";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";

export const WebOrdersBody = () => {
  const [userid, setUserID] = store.useState("userid");
  const [page, setPage] = useState("menu");

  const [MenuResponse, setMenuResponse] = useState("");

  const [CurrentPicks, setCurrentPicks] = useState([]);
  const [CurrentItemNo, setCurrentItemNo] = useState("");

  const [isPickManager, setIsPickManager] = useState(false);

  useEffect(() => {
    async function getAccess() {
      const arrAccess = await getAccessData(userid);
      setIsPickManager(arrAccess.WebOrdersUnassign === 1);
    }
    getAccess();
  }, []);

  const menuPage = (
    <Menu
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      isPickManager={isPickManager}
    />
  );

  const singlePage = (
    <Single
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
    />
  );

  const multiplePage = (
    <Multiple
      page={page}
      setPage={setPage}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      isPickManager={isPickManager}
    />
  );

  const verifyPage = (
    <Verify
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
    />
  );

  const viewPage = (
    <View
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
    />
  );

  const returnPage = (
    <Return
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
    />
  );

  const pickSinglePage = (
    <Pick
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
      CurrentItemNo={CurrentItemNo}
      setCurrentItemNo={setCurrentItemNo}
      Origin="SINGLE"
    />
  );

  const pickMultiplePage = (
    <Pick
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
      CurrentItemNo={CurrentItemNo}
      setCurrentItemNo={setCurrentItemNo}
      Origin="MULTIPLE"
    />
  );

  const pickVerifyPage = (
    <Pick
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
      CurrentItemNo={CurrentItemNo}
      setCurrentItemNo={setCurrentItemNo}
      Origin="VERIFY"
    />
  );

  const linesViewPage = (
    <LinesView
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
      CurrentItemNo={CurrentItemNo}
      setCurrentItemNo={setCurrentItemNo}
    />
  );

  const linesReturnPage = (
    <LinesReturn
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
      CurrentItemNo={CurrentItemNo}
      setCurrentItemNo={setCurrentItemNo}
    />
  );

  const itemSinglePage = (
    <Item
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
      CurrentItemNo={CurrentItemNo}
      Origin="SINGLE"
    />
  );

  const itemMultiplePage = (
    <Item
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
      CurrentItemNo={CurrentItemNo}
      Origin="MULTIPLE"
    />
  );

  const itemVerifyPage = (
    <Item
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
      CurrentItemNo={CurrentItemNo}
      Origin="VERIFY"
    />
  );

  const itemViewPage = (
    <ItemView
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
      CurrentItemNo={CurrentItemNo}
    />
  );

  const itemReturnPage = (
    <ItemReturn
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
      CurrentItemNo={CurrentItemNo}
    />
  );

  const confirmSinglePage = (
    <Confirm
      page={page}
      setPage={setPage}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      Origin="SINGLE"
    />
  );

  const confirmMultiplePage = (
    <Confirm
      page={page}
      setPage={setPage}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      Origin="MULTIPLE"
    />
  );

  const confirmVerifyPage = (
    <Confirm
      page={page}
      setPage={setPage}
      CurrentPicks={CurrentPicks}
      setCurrentPicks={setCurrentPicks}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
      Origin="VERIFY"
    />
  );

  const managerPage = (
    <Manager
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
    />
  );

  const giftcardlookupPage = (
    <GiftCardLookup
      page={page}
      setPage={setPage}
      MenuResponse={MenuResponse}
      setMenuResponse={setMenuResponse}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "single":
        return singlePage;
      case "multiple":
        return multiplePage;
      case "verify":
        return verifyPage;
      case "view":
        return viewPage;
      case "return":
        return returnPage;
      case "pick-single":
        return pickSinglePage;
      case "pick-multiple":
        return pickMultiplePage;
      case "pick-verify":
        return pickVerifyPage;
      case "lines-view":
        return linesViewPage;
      case "lines-return":
        return linesReturnPage;
      case "item-single":
        return itemSinglePage;
      case "item-multiple":
        return itemMultiplePage;
      case "item-verify":
        return itemVerifyPage;
      case "item-view":
        return itemViewPage;
      case "item-return":
        return itemReturnPage;
      case "confirm-single":
        return confirmSinglePage;
      case "confirm-multiple":
        return confirmMultiplePage;
      case "confirm-verify":
        return confirmVerifyPage;
      case "manager":
        return managerPage;
      case "giftcardlookup":
        return giftcardlookupPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
