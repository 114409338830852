import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { orange, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";

export const Lines = (props) => {
  const [POLinesTable, setPOLinesTable] = useState([]);
  const [HeaderDetail, setHeaderDetail] = useState("");
  const [AllocationResponse, setAllocationResponse] = useState("");

  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sortIcon, setSortIcon] = useState("");

  const handleClick_Back = () => {
    props.setCurrentPO("");
    props.setPage("header");
  };

  useEffect(() => {
    async function getLines() {
      const resObj = await runFetch(`${spURL}Handheld_PerthWarehouse_Lines_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            PONumber: props.currentPO,
          }),
        }),
      });

      if (resObj.response === null) {
        setAllocationResponse(resObj.exception);
      } else {
        const spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          if (spResponse.Table.length === 0) {
            setAllocationResponse("No header detail found.");
          } else {
            setHeaderDetail({
              PO: spResponse.Table[0].PO,
              InvoicedDate: spResponse.Table[0].InvoicedDate,
              SKUs: spResponse.Table[0].SKUs,
              Cartons: spResponse.Table[0].Cartons,
            });
          }
        }
        if (spResponse.Table1 !== undefined) {
          if (spResponse.Table1.length === 0) {
            //setAllocationResponse("No items found.");
          } else {
            setPOLinesTable(
              spResponse.Table1.map((item) => ({
                ItemNo: item.ItemNo,
                ReorderNo: item.ReorderNo,
                Description: item.Description,
                AllocationQty: item.AllocationQty,
                AllocatedQty: item.AllocatedQty,
                RemainingQty: item.RemainingQty,
              }))
            );
          }
        }
      }
    }
    getLines();
  }, []);

  const handleClick_HeaderRow = (event) => {
    let _target;
    switch (event.target.tagName.toUpperCase()) {
      case "TH":
        _target = event.target;
        break;
      case "SVG":
        _target = event.target.parentElement;
        break;
      case "PATH":
        _target = event.target.parentElement.parentElement;
        break;
      default:
        break;
    }

    if (event.target.tagName.toUpperCase() !== "TR") {
      const _colname = _target.attributes["colname"].value;
      const _sortorder = _target.attributes["sortorder"].value;

      setSortColumn(_colname);

      if (_colname === sortColumn) {
        if (_sortorder === "ASC") {
          setSortOrder("DESC");
        } else {
          setSortOrder("ASC");
        }
      } else {
        setSortOrder("ASC");
      }
    }
  };

  useEffect(() => {
    switch (sortOrder) {
      case "ASC":
        setSortIcon(solid("sort-up"));
        break;
      case "DESC":
        setSortIcon(solid("sort-down"));
        break;
      default:
        setSortIcon(solid("sort"));
        break;
    }
  }, [sortOrder]);

  useEffect(() => {
    let newArr = POLinesTable;

    if (newArr.length > 0 && sortColumn !== "") {
      newArr = newArr.sort((a, b) => {
        switch (sortColumn) {
          case "AllocationQty":
          case "AllocatedQty":
          case "RemainingQty":
            return sortOrder === "ASC"
              ? parseFloat(a[sortColumn]) - parseFloat(b[sortColumn])
              : parseFloat(b[sortColumn]) - parseFloat(a[sortColumn]);
          default:
            return sortOrder === "ASC"
              ? a[sortColumn].localeCompare(b[sortColumn])
              : b[sortColumn].localeCompare(a[sortColumn]);
        }
      });

      setPOLinesTable(newArr);
    }
  }, [sortColumn, sortOrder, POLinesTable, setPOLinesTable]);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <h1 className="text-center">LINES</h1>
        <div className="itemdetails-sq-ns-wp-container">
          <div>
            <strong>PO Number: </strong>
            {HeaderDetail.PO}
          </div>
          <div>
            <strong>Invoiced Date: </strong>
            {HeaderDetail.InvoicedDate}
          </div>
          <div>
            <strong>SKUs: </strong>
            {HeaderDetail.SKUs}
          </div>
          <div>
            <strong>Cartons: </strong>
            {HeaderDetail.Cartons}
          </div>
        </div>
        <div className="error-message">{AllocationResponse}</div>

        {POLinesTable.length !== 0 && (
          <>
            <table className="itemdetails-table">
              <thead>
                <tr
                  className="btnHover"
                  onClick={handleClick_HeaderRow}
                >
                  <th
                    colname="ItemNo"
                    sortorder={sortOrder}
                  >
                    ItemNo
                    {sortColumn === "ItemNo" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="ReorderNo"
                    sortorder={sortOrder}
                  >
                    ReorderNo
                    {sortColumn === "ReorderNo" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="Description"
                    sortorder={sortOrder}
                  >
                    Description
                    {sortColumn === "Description" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="AllocationQty"
                    sortorder={sortOrder}
                  >
                    Allocation
                    {sortColumn === "AllocationQty" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="AllocatedQty"
                    sortorder={sortOrder}
                  >
                    Allocated
                    {sortColumn === "AllocatedQty" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                  <th
                    colname="RemainingQty"
                    sortorder={sortOrder}
                  >
                    Remaining
                    {sortColumn === "RemainingQty" ? <FontAwesomeIcon icon={sortIcon} /> : ""}
                  </th>
                </tr>
              </thead>
              <tbody>
                {POLinesTable.map((item, index) => (
                  <tr
                    key={`tablerow-${index}`}
                    onClick={() => {
                      props.setCurrentItemNo(item.ItemNo);
                      props.setPage("details");
                    }}
                    className="btnHover"
                  >
                    <td>{item.ItemNo}</td>
                    <td>{item.ReorderNo}</td>
                    <td>{item.Description}</td>
                    <td>{item.AllocationQty}</td>
                    <td>{item.AllocatedQty}</td>
                    <td>{item.RemainingQty}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />

        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
