import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { green, spURL, spURL_NoInputJSON } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { PopupConfirm } from "./PopupConfirm";

export const ItemReturn = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [WebOrdersResponse, setWebOrdersResponse] = useState("");

  const [ItemDetails, setItemDetails] = useState([]);
  const [ddlReason, setDDLReason] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  const refQty = useRef();
  const refReason = useRef();

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_Return_Item_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          OrderID: props.CurrentPicks[0].OrderID,
          Barcode: props.CurrentItemNo,
        }),
      }),
    });

    if (resObj.response === null) {
      props.setMenuResponse(resObj.exception);
      props.setPage("lines-return");
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setItemDetails({
          ItemNo: spResponse.Table[0].ItemNo,
          Description: spResponse.Table[0].ExtendedDescription,
          Supplier: spResponse.Table[0].Supplier,
          ReorderNumber: spResponse.Table[0].ReorderNumber,
          ImageURL: spResponse.Table[0].ImageURL,
          SOH: spResponse.Table[0].SOH,
          MerchBay: spResponse.Table[0].MerchBay,
        });

        if (spResponse.Table[0].ItemExistsInOrder === "No Exists") {
          setShowConfirm(true);
        }
      }

      await getDDLReason();
    }

    async function getDDLReason() {
      const resObj = await runFetch(
        `${spURL_NoInputJSON}Handheld_CustomerOrder_Return_DDLReason_Get`,
        {
          method: "POST",
        }
      );

      if (resObj.response === null) {
        props.setMenuResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setDDLReason(
            spResponse.Table.map((item, index) => (
              <option
                key={index}
                value={item.Reason}
              >
                {item.Reason}
              </option>
            ))
          );
        }
      }
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Back = () => {
    props.setMenuResponse("");
    props.setPage("lines-return");
  };

  const handleClick_Return = async () => {
    const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_Return_Item_Refund_Process`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          OrderID: props.CurrentPicks[0].OrderID,
          ReturnedItemNo: ItemDetails.ItemNo,
          Quantity: refQty.current.value,
          Reason: refReason.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setWebOrdersResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setMenuResponse(spResponse.Table[0].OutputString);

        props.setPage("return");
      }
    }
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          {ItemDetails === "" ? (
            ""
          ) : (
            <>
              <div className="itemdetails-image-itemno-container">
                <img
                  src={ItemDetails.ImageURL}
                  alt="Item"
                  width="100"
                />
                <div>
                  <p>{`${ItemDetails.ItemNo}, ${ItemDetails.Description}, ${ItemDetails.ReorderNumber}, ${ItemDetails.Supplier}`}</p>
                </div>
              </div>
              <p>
                <strong>StockOnHand</strong> {ItemDetails.SOH}
              </p>
              <p>
                <strong>MerchBay</strong> {ItemDetails.MerchBay}
              </p>
              <br />
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Qty</strong>
                </p>
                <input
                  type="text"
                  id="weborders-return-qty"
                  ref={refQty}
                />
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Reason</strong>
                </p>
                <select
                  name="reason"
                  id="weborders-return-reason"
                  ref={refReason}
                >
                  <option value=""></option>
                  {ddlReason}
                </select>
              </div>
              <br />
              <div className="blockedorders-option-row">
                <MenuButton
                  button="R"
                  title="Return"
                  colour={green}
                  onClick={handleClick_Return}
                />
              </div>
            </>
          )}
        </div>
        <p className="error-message">{WebOrdersResponse}</p>

        <PopupConfirm
          show={showConfirm}
          setShow={setShowConfirm}
          setPage={props.setPage}
        />
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
