import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { orange, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const Add = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [MenuResponse, setMenuResponse] = useState("");
  const [ddlDescription, setDDLDescription] = useState("");

  const refDescription = useRef();

  useEffect(() => {
    async function getTransferList() {
      const resObj = await runFetch(`${spURL}Handheld_Transfer_Send_List`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
          }),
        }),
      });

      if (resObj.response === null) {
        setMenuResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        setDDLDescription(
          spResponse.Table.map((item, index, arr) => (
            <option
              key={index}
              value={item.Description}
            >
              {item.Description}
            </option>
          ))
        );
      }
    }

    getTransferList();
  }, []);

  const handleClick_Back = () => {
    setMenuResponse("");
    props.setPage("menu");
  };

  const handleClick_Ok = () => {
    props.setTONo(refDescription.current.value);
    props.setPage("barcode");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="storetransfer-input-row">
          <p>
            <strong>Transfer No</strong>
          </p>
          <select
            name="description"
            id="storetransfer-add-description"
            ref={refDescription}
          >
            {ddlDescription}
          </select>
        </div>
        <button
          className="signin-login-button"
          onClick={handleClick_Ok}
        >
          Ok
        </button>
        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
