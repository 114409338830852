import React from "react";

import { Logout } from "../functions/Logout";
import store from "../store";

export const UserInfo = (props) => {
  const [username, setUserName] = store.useState("username");
  const [isSignedIn, setIsSignedIn] = store.useState("issignedin");

  const handleClick = (event) => {
    props.doLogout();
  };

  return (
    <div className="user-info-container">
      <p>User: {username}</p>
      <button
        className="btnHover"
        onClick={handleClick}
      >
        Log out
      </button>
    </div>
  );
};
