import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { red, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const SOO = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [onOrderTable, setOnOrderTable] = useState("");

  useEffect(() => {
    async function getPO() {
      const resObj = await runFetch(`${spURL}Handheld_ItemDetailAdmin_Item_StorePurchaseOrders`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            ItemNo: props.itemno,
            StoreID: storeno,
          }),
        }),
      });

      if (resObj.response === null) {
        props.setPage("details");
        props.setItemDetailsResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table.length === 0) {
          props.setPage("details");
          props.setItemDetailsResponse("ERROR!: No stock on order right now.");
        } else if (spResponse.Table !== undefined) {
          setOnOrderTable(spResponse.Table);
        }
      }
      ReactTooltip.rebuild();
    }
    getPO();
  }, []);

  return (
    <div className="page-container">
      <div
        className={
          window.matchMedia("(max-width: 364px)").matches
            ? "handheld-body-container"
            : "desktop-body-container"
        }
      >
        <p>
          <strong>Item: {props.itemno}</strong>
        </p>
        <br />
        <div>
          <p>
            <strong>On Order</strong>
          </p>
          {onOrderTable.length > 0 && (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>Supplier</th>
                  <th>PO Number</th>
                  <th>Quantity</th>
                  <th>Date On Order</th>
                </tr>
              </thead>
              <tbody>
                {onOrderTable.map((item, index) => (
                  <tr key={index}>
                    <td
                      data-tip
                      data-id="supplier"
                    >
                      {item.Supplier}
                    </td>
                    <td
                      data-tip
                      data-id="po"
                    >
                      {item.PO}
                    </td>
                    <td
                      data-tip
                      data-id="qty"
                    >
                      {item.Quantity}
                    </td>
                    <td
                      data-tip
                      data-id="date"
                    >
                      {ConvertDateFormat(item["Expected Receipt Date"])}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={() => props.setPage("details")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>

      <ReactTooltip
        id="supplier"
        place="bottom"
        effect="solid"
      >
        Supplier
      </ReactTooltip>
      <ReactTooltip
        id="po"
        place="bottom"
        effect="solid"
      >
        PO Number
      </ReactTooltip>
      <ReactTooltip
        id="qty"
        place="bottom"
        effect="solid"
      >
        Quantity
      </ReactTooltip>
      <ReactTooltip
        id="date"
        place="bottom"
        effect="solid"
      >
        Date On Order
      </ReactTooltip>
    </div>
  );
};
