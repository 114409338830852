import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { blue, green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { PopupEquipmentTYROEdit } from "./PopupEquipmentTYROEdit";
import { PopupEquipmentDefault } from "./PopupEquipmentDefault";
import { runFetch } from "../../functions/RunFetch";

export const EquipmentListMain = (props) => {
  const [EquipmentListResponse, setEquipmentListResponse] = useState(props.MenuResponse);
  const [EquipmentListTable, setEquipmentListTable] = useState([]);
  const [ddlStore, setDDLStore] = useState([]);
  const [ddlDevice, setDDLDevice] = useState([]);

  const [filterStoreName, setFilterStoreName] = useState("ALL");
  const [filterDevice, setFilterDevice] = useState("");
  const [filterDeviceName, setFilterDeviceName] = useState("");
  const [filterSerialNo, setFilterSerialNo] = useState("");
  const [filterWarranty, setFilterWarranty] = useState("");

  const emptySelectedEquipment = {
    StoreNo: "",
    StoreName: "",
    Device: "",
    DisplayName: "",
    Model: "",
    SerialNo: "",
    MACAddress: "",
    Password: "",
    Warranty: "",
    WarrantyStartDate: "",
    WarrantyEndDate: "",
    Comments: "",
  };
  const [SelectedEquipment, setSelectedEquipment] = useState(emptySelectedEquipment);

  const [showEditTYRO, setShowEditTYRO] = useState(false);
  const [showEditDefault, setShowEditDefault] = useState(false);
  const [showCreateDefault, setShowCreateDefault] = useState(false);

  const pageLoad = async () => {
    // load store ddl
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_EquipmentList_DDLStore_Get`, {
      method: "POST",
    });
    if (resObj.response === null) {
      setEquipmentListResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDDLStore(
          spResponse.Table.map((item, index) => (
            <option
              key={index}
              value={item.Store}
            >
              {item.Store}
            </option>
          ))
        );
      }
    }
    getEquipmentList();
  };

  async function getEquipmentList() {
    const resObj = await runFetch(`${spURL}Handheld_EquipmentList_List_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreName: filterStoreName,
        }),
      }),
    });
    if (resObj.response === null) {
      setEquipmentListResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setEquipmentListTable(spResponse.Table);
      }
    }
  }

  // page first load
  useEffect(() => {
    pageLoad();
    loadDDLDevice();
  }, [showEditDefault, showCreateDefault, showEditTYRO]);

  // when filterStoreName is changed
  useEffect(() => {
    getEquipmentList();
  }, [filterStoreName]);

  const handleClick_Row = (_row) => {
    setEquipmentListResponse("");
    setSelectedEquipment(_row);

    switch (_row.Device) {
      case "Tyro":
        setShowEditTYRO(true);
        break;
      default:
        if (_row.Device === "Printer" && _row.DisplayName !== "Ricoh") {
        } else {
          setShowEditDefault(true);
        }
        break;
    }
  };

  const handleClick_Create = () => {
    setEquipmentListResponse("");
    setSelectedEquipment(emptySelectedEquipment);
    setShowCreateDefault(true);
  };

  const handleClick_Back = () => {
    props.setMenuResponse("");
    props.setPage("menu");
  };

  const loadDDLDevice = async () => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_EquipmentList_DDLDevice_Get`, {
      method: "POST",
    });

    if (resObj.response === null) {
      setEquipmentListResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDDLDevice(spResponse.Table.filter((item) => item.Device !== "New Device..."));
      }
    }
  };

  return (
    <div className="unallocatedorders-page-container">
      <div className="unallocatedorders-top-row">
        <div className="blockedorders-input-row">
          <p>
            <strong>Store:</strong>
          </p>
          <select
            onChange={(e) => {
              setEquipmentListResponse("");
              handleChange_Input(e, setFilterStoreName);
            }}
          >
            {ddlStore}
          </select>
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>Device:</strong>
          </p>
          <select
            onChange={(e) => {
              setEquipmentListResponse("");
              handleChange_Input(e, setFilterDevice);
            }}
          >
            <option value=""></option>
            {ddlDevice.map((device, index) => (
              <option
                value={device.Device}
                key={index}
              >
                {device.Device}
              </option>
            ))}
          </select>
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>DeviceName:</strong>
          </p>
          <input
            type="text"
            onChange={(e) => {
              setEquipmentListResponse("");
              handleChange_Input(e, setFilterDeviceName);
            }}
          />
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>SerialNo:</strong>
          </p>
          <input
            type="text"
            onChange={(e) => {
              setEquipmentListResponse("");
              handleChange_Input(e, setFilterSerialNo);
            }}
          />
        </div>
        <div className="blockedorders-input-row">
          <p>
            <strong>Warranty:</strong>
          </p>
          <select
            onChange={(e) => {
              setEquipmentListResponse("");
              handleChange_Input(e, setFilterWarranty);
            }}
          >
            <option value=""></option>
            <option value="Active">Active</option>
            <option value="Expired">Expired</option>
          </select>
        </div>
        <div>
          <input
            type="button"
            id="unallocatedorders-refresh"
            value="Refresh"
            onClick={getEquipmentList}
          />
        </div>
      </div>
      <div>
        <div className="blockedorders-content-container">
          <p className="error-message">{EquipmentListResponse}</p>
          {EquipmentListTable.length === 0 ? (
            ""
          ) : (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th rowSpan={2}>StoreNo</th>
                  <th rowSpan={2}>StoreName</th>
                  <th rowSpan={2}>Device</th>
                  <th rowSpan={2}>DisplayName</th>
                  <th rowSpan={2}>ConnectedTo</th>
                  <th rowSpan={2}>Model</th>
                  <th rowSpan={2}>SerialNo</th>
                  <th rowSpan={2}>MACAddress</th>
                  <th rowSpan={2}>Password</th>
                  <th colSpan={3}>Warranty</th>
                  <th rowSpan={2}>Comments</th>
                </tr>
                <tr>
                  <th>Status</th>
                  <th>Start</th>
                  <th>End</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: 16 }}>
                {EquipmentListTable.reduce(function (filtered, row, index) {
                  if (
                    row.Device.toUpperCase().includes(filterDevice.toUpperCase()) &&
                    (row.DisplayName.toUpperCase().includes(filterDeviceName.toUpperCase()) ||
                      row.ConnectedTo.toUpperCase().includes(filterDeviceName.toUpperCase())) &&
                    row.SerialNo.toUpperCase().includes(filterSerialNo.toUpperCase()) &&
                    (row.Warranty.includes(filterWarranty) || filterWarranty === "")
                  ) {
                    var tableRow = (
                      <tr
                        key={index}
                        className="btnHover"
                        onClick={() => handleClick_Row(row)}
                      >
                        <td>{row.StoreNo}</td>
                        <td>{row.StoreName}</td>
                        <td>{row.Device}</td>
                        <td>{row.DisplayName}</td>
                        <td>{row.ConnectedTo}</td>
                        <td>{row.Model}</td>
                        <td>{row.SerialNo}</td>
                        <td>{row.MACAddress}</td>
                        <td>{row.Password}</td>
                        <td>{row.Warranty}</td>
                        <td>{row.WarrantyStartDate}</td>
                        <td>{row.WarrantyEndDate}</td>
                        <td>{row.Comments}</td>
                      </tr>
                    );

                    filtered.push(tableRow);
                  }

                  return filtered;
                }, [])}
              </tbody>
            </table>
          )}

          <PopupEquipmentTYROEdit
            show={showEditTYRO}
            setShow={setShowEditTYRO}
            getEquipmentList={getEquipmentList}
            SelectedEquipment={SelectedEquipment}
            setSelectedEquipment={setSelectedEquipment}
            setEquipmentListResponse={setEquipmentListResponse}
          />

          <PopupEquipmentDefault
            Action="EDIT"
            show={showEditDefault}
            setShow={setShowEditDefault}
            getEquipmentList={getEquipmentList}
            SelectedEquipment={SelectedEquipment}
            setSelectedEquipment={setSelectedEquipment}
            setEquipmentListResponse={setEquipmentListResponse}
          />

          <PopupEquipmentDefault
            Action="CREATE"
            show={showCreateDefault}
            setShow={setShowCreateDefault}
            getEquipmentList={getEquipmentList}
            SelectedEquipment={SelectedEquipment}
            setSelectedEquipment={setSelectedEquipment}
            setEquipmentListResponse={setEquipmentListResponse}
          />
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={blue}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
        <FooterButton
          button="Create"
          colour={blue}
          onClick={handleClick_Create}
        />
      </div>
    </div>
  );
};
