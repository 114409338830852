import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  yellow,
  spURL,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
  spURL_LocalPOS,
} from "../../App";
import { ItemBarcode } from "../../components/ItemBarcode";
import { PopupConfirm } from "./PopupConfirm";

import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { PopupHistory } from "./PopupHistory";
import { PopupPrint } from "./PopupPrint";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { useRef } from "react";

export const MerchLabelAdd = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [MenuResponse, setMenuResponse] = useState(props.Response);
  const [ddlBatchList, setDDLBatchList] = useState([]);
  const [isBatchSelected, setIsBatchSelected] = useState(props.currentMerchBatchID !== "");
  const [currentBatch, setCurrentBatch] = useState(props.currentMerchBatchID);
  const [currentShelfPrinter, setCurrentShelfPrinter] = useState(props.currentMerchBatchPrinter);

  const [showHistory, setShowHistory] = useState(false);
  const [showPrint, setShowPrint] = useState(false);

  const refBatch = useRef();

  const pageLoad = async () => {
    const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_BatchList`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });
    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMenuResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);

      setDDLBatchList(spResponse.Table);
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Back = () => {
    setMenuResponse("");
    props.setResponse("");
    props.setCurrentMerchBatchID("");
    props.setCurrentMerchBatchPrinter("");
    props.setBarcode("");

    props.setPage("merchlabel-menu");
  };

  const handleChange_DDL = () => {
    setMenuResponse("");
  };

  const handleClick_Select = () => {
    setMenuResponse("");
    if (isBatchSelected) {
      setCurrentBatch("");
      setIsBatchSelected(false);
    } else {
      const _batchid = refBatch.current.value;

      if (_batchid === "") {
        setMenuResponse("Please select a batch.");
      } else {
        const _batchid = refBatch.current.value;
        setCurrentBatch(_batchid);
        setCurrentShelfPrinter(
          ddlBatchList.filter((batch) => batch.BatchID === _batchid)[0].ShelfPrinter
        );
        setIsBatchSelected(true);
      }
    }
  };

  const handleClick_Barcode = () => {
    setMenuResponse("");

    const _barcode = document.getElementById("itembarcode-barcode").value;

    props.setCurrentMerchBatchID(currentBatch);
    props.setCurrentMerchBatchPrinter(currentShelfPrinter);
    props.setBarcode(_barcode);
    props.setPage("update-merch");
  };

  const handleClick_History = () => {
    setMenuResponse("");
    setShowHistory(true);
  };

  const handleClick_Print = async () => {
    setMenuResponse("");

    const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_History`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          BatchID: currentBatch,
        }),
      }),
    });
    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setMenuResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setCurrentShelfPrinter(spResponse.Table[0].ShelfPrinter);
        setShowPrint(true);
      }
    }
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="input-row">
          <p>
            <strong>Batch:</strong>
          </p>
          {isBatchSelected ? (
            currentBatch
          ) : (
            <select
              name="batch"
              id="labels-merchlabel-add-batchid"
              onChange={handleChange_DDL}
              ref={refBatch}
            >
              <option value=""></option>
              {ddlBatchList.map((item, index, arr) => (
                <option
                  key={index}
                  value={item.BatchID}
                >
                  {item.BatchID}
                </option>
              ))}
            </select>
          )}
        </div>
        <button
          className="signin-login-button btnHover"
          onClick={handleClick_Select}
        >
          {isBatchSelected ? <>Change</> : <>Select</>}
        </button>
        <br />
        <br />
        {isBatchSelected ? (
          <>
            <div className="input-row">
              <p>
                <strong>Printer:</strong>
              </p>
              <p>{currentShelfPrinter}</p>
            </div>
            <ItemBarcode barcodeClick={handleClick_Barcode} />
            <br />
            <div className="blockedorders-option-row">
              <MenuButton
                title="History"
                colour={yellow}
                button="H"
                onClick={handleClick_History}
              />
              <MenuButton
                title="Print"
                colour={yellow}
                button="P"
                onClick={handleClick_Print}
              />
            </div>
          </>
        ) : (
          ""
        )}
        <br />
        <p className="error-message">{MenuResponse}</p>

        <PopupHistory
          show={showHistory}
          setShow={setShowHistory}
          BatchID={currentBatch}
        />

        <PopupPrint
          show={showPrint}
          setShow={setShowPrint}
          From="MERCH"
          BatchID={currentBatch}
          ShelfPrinter={currentShelfPrinter}
          setMenuResponse={setMenuResponse}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={yellow}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
      </div>
    </div>
  );
};
