import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { getAccessData, red, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const Menu = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [MenuResponse, setMenuResponse] = useState(props.WHReceivedResponse);
  const [Access, setAccess] = useState({});

  const [isStockTakeSelected, setIsStockTakeSelected] = useState(
    props.CurrentStockTake === "" ? false : true
  );
  const [ddlStockTake, setDDLStockTake] = useState([]);

  const refStockTake = useRef();

  const getDDL = async () => {
    const resObj = await runFetch(`${spURL}Stocktake_List_StockTake`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setDDLStockTake(
          spResponse.Table.map((item, index) => (
            <option
              key={index}
              value={item.Description}
            >
              {item.Description}
            </option>
          ))
        );
      }
    }
  };

  const handleClick_Select = () => {
    setMenuResponse("");

    if (isStockTakeSelected) {
      setIsStockTakeSelected((values) => !values);
      props.setCurrentStockTake("");
    } else {
      if (refStockTake.current.value !== "") {
        props.setCurrentStockTake(refStockTake.current.value);
        setIsStockTakeSelected((values) => !values);
      } else {
        setMenuResponse("ERROR!: No StockTake selected.");
      }
    }
  };

  useEffect(() => {
    async function getAccess() {
      setAccess(await getAccessData(userid));
    }
    getAccess();
    getDDL();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        {isStockTakeSelected ? (
          <div className="stocktake-create-input-row">
            <p>
              <strong>Stock Take:</strong>
            </p>
            <p>{props.CurrentStockTake}</p>
          </div>
        ) : (
          <div className="stocktake-create-input-row">
            <p>
              <strong>Stock Take:</strong>
            </p>
            <select
              name="stocktake"
              id="stocktake-scan-stocktake"
              ref={refStockTake}
            >
              <option value=""></option>
              {ddlStockTake}
            </select>
          </div>
        )}
        <button
          className="signin-login-button btnHover"
          onClick={handleClick_Select}
        >
          {isStockTakeSelected ? "Cancel" : "Select"}
        </button>
        <br />
        <br />
        <div className="home-table">
          <MenuButton
            onClick={() => props.setPage("scan")}
            button="S"
            colour={red}
            title="Scan"
          />
          <MenuButton
            onClick={() => props.setPage("check")}
            button="C"
            colour={red}
            title="Check"
          />
          {Access.StockTakeAdmin && (
            <MenuButton
              onClick={() => props.setPage("admin-menu")}
              button="A"
              colour={red}
              title="Admin"
            />
          )}
        </div>
        <div className="home-table">
          <MenuButton
            onClick={() => props.setPage("create")}
            button="C"
            colour={red}
            title="Create"
          />
          <MenuButton
            onClick={() => props.setPage("manage")}
            button="M"
            colour={red}
            title="Manage"
          />
          <MenuButton
            onClick={() => props.setPage("reports")}
            button="R"
            colour={red}
            title="Reports"
          />
        </div>
        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={red}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
