import React from "react";
import ReactDOM from "react-dom";

import { Link } from "react-router-dom";

export const HomepageButton = (props) => {
  const _link = props.link;
  const _title = props.title;
  const _colour = props.colour;
  const _button = props.button;

  return (
    <div className="home-table-items">
      <Link to={_link}>
        <div
          className="circle-buttons"
          style={{ backgroundColor: _colour }}
        >
          <h1 className="circle-buttons-content">{_button}</h1>
        </div>
        <p>{_title}</p>
      </Link>
    </div>
  );
};

export const MenuButton = (props) => {
  const _title = props.title;
  const _colour = props.colour;
  const _button = props.button;

  return (
    <div
      className="home-table-items"
      onClick={props.onClick}
    >
      <div
        className="circle-buttons btnHover"
        style={{ backgroundColor: _colour }}
      >
        <h1 className="circle-buttons-content">{_button}</h1>
      </div>
      <p>{_title}</p>
    </div>
  );
};

export const ContentButton = (props) => {
  const _button = props.button;
  const _colour = props.colour;
  const _id = props.id === undefined ? "" : props.id;

  return (
    <div
      className="content-circle-buttons btnHover"
      style={{ backgroundColor: _colour }}
      onClick={props.onClick}
    >
      <h1
        id={_id}
        className="content-circle-buttons-content"
      >
        {_button}
      </h1>
    </div>
  );
};

export const FooterButton = (props) => {
  const _button = props.button;
  const _colour = props.colour;
  const _class = props.class === undefined ? "" : props.class;
  const _id = props.id === undefined ? "" : props.id;

  return (
    <div
      className={`footer-circle-buttons btnHover ${_class}`}
      style={{ backgroundColor: _colour }}
      onClick={props.onClick}
    >
      <h1
        id={_id}
        className="footer-circle-buttons-content"
      >
        {_button}
      </h1>
    </div>
  );
};

export const UnallocateDeviceStatusButton = (props) => {
  const _button = props.button;
  const _id = props.id === undefined ? "" : props.id;

  return (
    <div
      className="unallocate-devicestatus-buttons btnHover"
      onClick={props.onClick}
    >
      <h1
        id={_id}
        className="unallocate-devicestatus-buttons-content"
      >
        {_button}
      </h1>
    </div>
  );
};

export const ExcelFileButton = (props) => {
  const handleClick = () => document.getElementById(props.id).click();

  const _title = props.title;
  const _colour = props.colour;
  const _button = props.button;
  const _id = props.id === undefined ? "" : props.id;

  return (
    <div
      className="home-table-items"
      onClick={handleClick}
    >
      <div
        className="circle-buttons btnHover"
        style={{ backgroundColor: _colour }}
      >
        <h1 className="circle-buttons-content">{_button}</h1>
        <input
          id={_id}
          type="file"
          accept=".xls, .xlsx, .csv"
          onClick={props.onClick}
          onChange={props.onChange}
          style={{ display: "none" }}
        />
      </div>
      <p>{_title}</p>
    </div>
  );
};
