import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, spURL_LocalPOS } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const PopupHistory = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [ConfirmResponse, setConfirmResponse] = useState("");
  const [Items, setItems] = useState([]);

  const handleClose = () => props.setShow(false);

  const handleShow = async () => {
    const res = await runFetch(`${spURL_LocalPOS}Handheld_Label_History`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          BatchID: props.BatchID,
          StoreNo: storeno,
        }),
      }),
    });
    const resObj = JSON.parse(res);
    if (resObj.status === "ERROR!") {
      setConfirmResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setItems(
          spResponse.Table.map((item) => ({
            Item: item.Item,
            Description: item.Description,
            Quantity: item.Quantity,
          }))
        );
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <strong>Label History</strong>
            <br />
            {Items.length === 0 ? (
              <p>No items scanned in this batch.</p>
            ) : (
              <table className="itemdetails-table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Description</th>
                    <th>Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {Items.map((item, index) => (
                    <tr key={index}>
                      <td>{item.Item}</td>
                      <td>{item.Description}</td>
                      <td>{item.Quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
