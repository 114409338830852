import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { green, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { PopupRelease } from "./PopupRelease";

import { PopupSKUList } from "./PopupSKUList";

export const Confirm = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [WebOrdersResponse, setWebOrdersResponse] = useState("");

  const [ConfirmTable, setConfirmTable] = useState([]);

  const [SelectedPicks, setSelectedPicks] = useState([]);

  const [show, setShow] = useState(false);
  const [showRelease, setShowRelease] = useState(false);

  const pageLoad = async () => {
    let uri = "";
    let inputObj = {};
    switch (props.Origin) {
      case "SINGLE":
        uri = "Handheld_CustomerOrder_Pick_Single_Finalise";
        inputObj = props.CurrentPicks.map((item) => ({
          PickID: item.PickID,
        }));
        break;
      case "MULTIPLE":
        uri = "Handheld_CustomerOrder_Pick_Multiple_Orders_Finalise";
        inputObj = {
          UserID: userid,
          StoreNo: storeno,
        };
        break;
      case "VERIFY":
        uri = "Handheld_CustomerOrder_Verify_Orders_Finalise";
        inputObj = props.CurrentPicks.map((item) => ({
          PickID: item.PickID,
        }));
        break;
      default:
        break;
    }

    const resObj = await runFetch(`${spURL}${uri}`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(inputObj),
      }),
    });

    if (resObj.response === null) {
      props.setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setConfirmTable(
          spResponse.Table.map((item) => {
            switch (item.Status) {
              case "Complete":
                return {
                  PickID: item.PickID,
                  OrderID: item.OrderID,
                  Status: item.Status,
                  FontColour: "lightgreen",
                };
              case "Incomplete":
                return {
                  PickID: item.PickID,
                  OrderID: item.OrderID,
                  Status: item.Status,
                  FontColour: "red",
                };
              default:
                return {
                  PickID: item.PickID,
                  OrderID: item.OrderID,
                  Status: item.Status,
                  FontColour: "black",
                };
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Back = () => {
    props.setPage(`pick-${props.Origin.toLowerCase()}`);
  };

  const handleClick_Release = async () => {
    let uri = "";

    switch (props.Origin) {
      case "SINGLE":
        uri = "Handheld_CustomerOrder_Pick_Orders_Commit";
        break;
      case "MULTIPLE":
        uri = "Handheld_CustomerOrder_Pick_Multiple_Orders_Commit";
        break;
      case "VERIFY":
        uri = "Handheld_CustomerOrder_Verify_Orders_Commit";
        break;
      default:
        break;
    }

    const resObj = await runFetch(`${spURL}${uri}`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(
          SelectedPicks.map((item) => ({
            PickID: item.PickID,
            UserID: userid,
            OrderID: item.OrderID,
            Status: item.Status,
            StoreNo: storeno,
          }))
        ),
      }),
    });

    if (resObj.exception !== "") {
      setWebOrdersResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      props.setMenuResponse("Orders Sucessfully Released");
      props.setCurrentPicks([]);
      props.setPage(props.Origin.toLowerCase());
    }
  };

  const handleClick_List = () => {
    setShow(true);
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          {ConfirmTable.length === 0 ? (
            ""
          ) : (
            <>
              <table className="itemdetails-table">
                <thead>
                  <tr>
                    <th>OrderID</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {ConfirmTable.map((item, index) => (
                    <tr
                      onClick={() => {
                        setWebOrdersResponse("");

                        let wasPickSelected = SelectedPicks.map(
                          (pick) => `${pick.OrderID}-${pick.PickID}`
                        ).includes(`${item.OrderID}-${item.PickID}`);

                        if (wasPickSelected) {
                          setSelectedPicks(
                            SelectedPicks.filter(
                              (pick) =>
                                `${pick.OrderID}-${pick.PickID}` !==
                                `${item.OrderID}-${item.PickID}`
                            )
                          );
                        } else {
                          setSelectedPicks((values) => [
                            ...values,
                            {
                              OrderID: item.OrderID,
                              PickID: item.PickID,
                              Status: item.Status,
                            },
                          ]);
                        }
                      }}
                      key={index}
                      className={`btnHover ${
                        SelectedPicks.map((pick) => `${pick.OrderID}-${pick.PickID}`).includes(
                          `${item.OrderID}-${item.PickID}`
                        )
                          ? "background-lightblue"
                          : "background-white"
                      }`}
                    >
                      <td>{item.OrderID}</td>
                      <td style={{ color: item.FontColour }}>{item.Status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <br />
              <p>
                <strong>No. of orders: </strong>
                {ConfirmTable.length}
              </p>
            </>
          )}
        </div>
        <p className="error-message">{WebOrdersResponse}</p>

        <PopupSKUList
          show={show}
          setShow={setShow}
          ConfirmTable={ConfirmTable}
        />

        <PopupRelease
          show={showRelease}
          setShow={setShowRelease}
          handleClick_Release={handleClick_Release}
        />
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
        <FooterButton
          button="List"
          colour={green}
          onClick={handleClick_List}
        />
        <FooterButton
          button="Rel"
          colour={green}
          onClick={() => {
            setShowRelease(true);
          }}
        />
      </div>
    </div>
  );
};
