import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { useRef } from "react";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { runFetch } from "../../functions/RunFetch";

export const PopupEmail = (props) => {
  const [ConfirmResponse, setConfirmResponse] = useState("");

  const refEmail = useRef();

  const handleClose = () => {
    setConfirmResponse("");
    props.setShow(false);
  };

  const handleShow = () => {
    refEmail.current.focus();
  };

  const handleClick_Update = async () => {
    setConfirmResponse("");

    const emailCheck =
      /^[\w!#$%&'*+\-/=?\^_`{|}~]+(\.[\w!#$%&'*+\-/=?\^_`{|}~]+)*@((([\-\w]+\.)+[a-zA-Z]{2,4})|(([0-9]{1,3}\.){3}[0-9]{1,3}))$/;

    let finalEmailString = "";

    props.receipt.forEach((receiptObj, index) => {
      finalEmailString += `Receipt ${index + 1}\n`;
      finalEmailString += receiptObj.ReceiptString;
      finalEmailString += "\n\n\n";
    });

    if (emailCheck.test(refEmail.current.value)) {
      const resObj = await runFetch(`${spURL}SendReceiptEmail`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Email: refEmail.current.value,
            PrintString: finalEmailString,
            ReceiptNo: props.receiptNo,
          }),
        }),
      });

      if (resObj.response === null) {
        setConfirmResponse(resObj.exception);
      } else {
        props.setMenuResponse(resObj.response);
        handleClose();
      }
    } else {
      setConfirmResponse("ERROR!: Invalid email address.");
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Email:</strong>
              </p>
              <input
                type="text"
                id="printreceipt-email"
                ref={refEmail}
                onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Update)}
              />
            </div>
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Update}
            >
              Send
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
