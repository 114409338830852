import { spURL_LocalLabel, spURL_LocalPOS } from "../App";
import { runFetch, runLabelFetch } from "./RunFetch";

export const doPrintShelfLabel = async (_shelf, _barcode, _qty, _from) => {
    let response = "";
    // do print shelf label
    // we first get itemno from barcode
    const requestOptions2 = {
        method: 'POST',
        body: new URLSearchParams({
            inputJSON: JSON.stringify({
                Barcode: _barcode,
                StoreNo: ''
            })
        })
    }
    const res2 = await runFetch(spURL_LocalPOS + 'Handheld_Label_Item_Get', requestOptions2);
    const resObj2 = JSON.parse(res2);

    let _itemno = JSON.parse(resObj2.response).Table[0].ItemNo;

    // off we go, generate print string + print to shelf printer
    await runLabelFetch(`${spURL_LocalLabel}standardshelf/print`, {
        method: 'POST',
        body: new URLSearchParams({
            ShelfPrinter: _shelf,
            ItemNo: _itemno,
            Quantity: _qty,
            From: _from
        })
    },
        (res) => { response = res; },
        (err) => { throw new Error(err) })

    return response;
}

export const doPrintPROMOLabel = async (_shelf, _barcode, _qty, _from) => {
    let response = "";
    // do print shelf label
    // we first get itemno from barcode
    const requestOptions2 = {
        method: 'POST',
        body: new URLSearchParams({
            inputJSON: JSON.stringify({
                Barcode: _barcode,
                StoreNo: ''
            })
        })
    }
    const res2 = await runFetch(spURL_LocalPOS + 'Handheld_Label_Item_Get', requestOptions2);
    const resObj2 = JSON.parse(res2);

    let _itemno = JSON.parse(resObj2.response).Table[0].ItemNo;

    // off we go, generate print string + print to shelf printer
    await runLabelFetch(`${spURL_LocalLabel}dealshelf/print`, {
        method: 'POST',
        body: new URLSearchParams({
            ShelfPrinter: 'PROMO',
            ItemNo: _itemno,
            Quantity: _qty,
            From: _from
        })
    },
        (res) => { response = res; },
        (err) => { throw new Error(err) })

    return response;
}