import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { orange, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";

export const EditLines = (props) => {
  const [PODLinesTable, setPODLinesTable] = useState([]);

  useEffect(() => {
    async function getLines() {
      const resObj = await runFetch(`${spURL}POD_Edit_Lines`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            PODCode: props.PODCode,
          }),
        }),
      });

      if (resObj.response === null) {
        props.setPODCodeResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          setPODLinesTable(spResponse.Table);
        }
      }
    }
    getLines();
  }, []);

  const handleClick_Back = (event) => {
    props.setPODCodeResponse("");
    props.setPage("edit_header");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="podcode-edit-connote-container">
          <div className="podcode-edit-connote-details">
            <div className="input-row">
              <p>
                <strong>POD:</strong>
              </p>
              <p>
                <strong>{props.PODCode}</strong>
              </p>
            </div>

            <div className="input-row">
              <p>
                <strong>Driver:</strong>
              </p>
              <p>
                <strong>{props.ConNoteDetails.Driver}</strong>
              </p>
            </div>

            <div className="input-row">
              <p>
                <strong>Company:</strong>
              </p>
              <p>
                <strong>{props.ConNoteDetails.TransportCompany}</strong>
              </p>
            </div>

            <div className="input-row">
              <p>
                <strong>Quantity:</strong>
              </p>
              <p>
                <strong>{props.ConNoteDetails.QuantityReceived}</strong>
              </p>
            </div>

            <div className="input-row">
              <p>
                <strong>Unit Type:</strong>
              </p>
              <p>
                <strong>{props.ConNoteDetails.UnitType}</strong>
              </p>
            </div>
          </div>
          <div>
            <FooterButton
              button="Edit"
              colour={orange}
              onClick={() => props.setPage("connote_edit")}
            />
          </div>
        </div>

        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>Supplier</th>
              <th>Invoice</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {PODLinesTable.map((item, index) => (
              <tr
                key={`tablerow-${index}`}
                onClick={() => {
                  props.setPODCodeResponse("");

                  props.setOriginalDetails((values) => ({
                    ...values,
                    Supplier: item.Supplier,
                    InvoiceNo: item.InvoiceNo,
                    CartonsExpected: item.CartonsExpected,
                    CartonsReceived: item.CartonsReceived,
                    QuantityUnitsInvoiced: item.QuantityUnitsInvoiced,
                    InvoiceValue_exGST: item.InvoiceValue_exGST,
                  }));

                  props.setPage("update_edit");
                }}
                className="btnHover"
              >
                <td value={item.Supplier}>{item.Supplier}</td>
                <td value={item.InvoiceNo}>{item.InvoiceNo}</td>
                <td value={item.Status}>{item.Status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
        <FooterButton
          button="Add"
          colour={orange}
          onClick={() => props.setPage("update_create")}
        />
      </div>
    </div>
  );
};
