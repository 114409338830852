import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { MenuButton, FooterButton } from "../../components/HomepageButton";
import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { PopupUpdate } from "./PopupUpdate";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";
import { handleChange_Input } from "../../functions/HandleChangeInput";

export const WebBufferBody = (props) => {
  const [MenuResponse, setMenuResponse] = useState("");

  const [ddlDivision, setDDLDivision] = useState([]);
  const [CurrentDivision, setCurrentDivision] = useState("");
  const [WebBufferList, setWebBufferList] = useState([]);

  const [selectedRow, setSelectedRow] = useState("");
  const [show, setShow] = useState(false);

  const pageLoad = async () => {
    async function getOrders() {
      const resObj = await runFetch(`${spURL_NoInputJSON}WebBuffer_Refresh`, { method: "POST" });

      if (resObj.response === null) {
        setMenuResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setWebBufferList(
            spResponse.Table.map((item, index) => ({
              Department: item.Department,
              Category: item.Category,
              SubCategory: item.SubCategory,
              WebBuffer: item.WebBuffer,
            }))
          );
        }
      }
    }

    async function getDDL() {
      const resObj = await runFetch(spURL_NoInputJSON + "WebBuffer_DivisionList", {
        method: "POST",
      });

      if (resObj.response === null) {
        setMenuResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setDDLDivision(spResponse.Table.map((item) => item.Description));

          if (CurrentDivision === "") {
            setCurrentDivision(spResponse.Table[0].Description);
          }
        }
      }
      await getOrders();
    }

    await getDDL();
  };

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <div className="unallocatedorders-page-container">
      <div className="webbuffer-top-row">
        <div className="blockedorders-input-row">
          <p>
            <strong>Division:</strong>
          </p>
          <select
            name="store"
            id="webbuffer-main-division"
            onChange={(e) => handleChange_Input(e, setCurrentDivision)}
          >
            {ddlDivision.map((item, index) => (
              <option
                value={item}
                name={item}
                key={index}
              >
                {item}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <div className="blockedorders-content-container">
          <p className="error-message">{MenuResponse}</p>
          {WebBufferList.length > 0 && (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>Department</th>
                  <th>Category</th>
                  <th>SubCategory</th>
                  <th>Web Buffer</th>
                </tr>
              </thead>
              <tbody style={{ fontSize: 16 }}>
                {WebBufferList.reduce(function (filtered, order, index) {
                  if (order.Department === CurrentDivision) {
                    var tableRow = (
                      <tr
                        key={index}
                        department={order.Department}
                        category={order.Category}
                        subcat={order.SubCategory}
                        webbuffer={order.WebBuffer}
                        onClick={() => {
                          setSelectedRow({
                            Department: order.Department,
                            Category: order.Category,
                            SubCategory: order.SubCategory,
                            WebBuffer: order.WebBuffer,
                          });

                          setMenuResponse("");
                          setShow(true);
                        }}
                        className="btnHover"
                      >
                        <td>{order.Department}</td>
                        <td>{order.Category}</td>
                        <td>{order.SubCategory}</td>
                        <td>{order.WebBuffer}</td>
                      </tr>
                    );

                    filtered.push(tableRow);
                  }

                  return filtered;
                }, [])}
              </tbody>
            </table>
          )}

          <PopupUpdate
            show={show}
            setShow={setShow}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            MenuResponse={MenuResponse}
            setMenuResponse={setMenuResponse}
            pageLoad={pageLoad}
          />
        </div>
      </div>
      <div className="footer-container menu-container">
        <Link to="/EComm">
          <FooterButton
            button="Back"
            colour={green}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
