import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { useRef } from "react";
import { runFetch } from "../../functions/RunFetch";

export const PopupEquipmentTYROEdit = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const refAdmin = useRef();
  const refOperator = useRef();

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleShow = () => {
    splitTYROPassword(props.SelectedEquipment.Password);
    refAdmin.current.select();
  };

  const splitTYROPassword = (_pass) => {
    const splitPasswordType = _pass.split("||");

    splitPasswordType.forEach((pass) => {
      let splitPass = pass.split(":");

      switch (splitPass[0]) {
        case "Adm":
          refAdmin.current.value = splitPass[1];
          break;
        case "Ope":
          refOperator.current.value = splitPass[1];
          break;
        default:
          break;
      }
    });
  };

  const handleSubmit = async () => {
    setPopupResponse("");

    const resObj = await runFetch(`${spURL}Handheld_EquipmentList_Store_TYROPassword_Update`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: props.SelectedEquipment.StoreNo,
          AdminPassword: refAdmin.current.value,
          OperatorPassword: refOperator.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        props.setEquipmentListResponse(spResponse.Table[0].OutputString);
        props.setShow(false);
        props.getEquipmentList();
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <h5>TYRO</h5>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Connected to:</strong>
              </p>
              <p>{props.SelectedEquipment.ConnectedTo}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Admin Password:</strong>
              </p>
              <input
                type="text"
                ref={refAdmin}
                onChange={() => {
                  setPopupResponse("");
                }}
              />
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Operator Password:</strong>
              </p>
              <input
                type="text"
                ref={refOperator}
                onChange={() => {
                  setPopupResponse("");
                }}
              />
            </div>
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
