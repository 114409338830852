import React, { useState, useEffect, useRef } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { orange, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const Create = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [MenuResponse, setMenuResponse] = useState("");
  const [ddlDestinationStore, setDDLDestinationStore] = useState("");

  const refDescription = useRef();
  const refDestinationStore = useRef();

  useEffect(() => {
    async function getStore() {
      const resObj = await runFetch(`${spURL}Handheld_Transfer_Send_StoreList`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
          }),
        }),
      });

      if (resObj.response === null) {
        setMenuResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        setDDLDestinationStore(
          spResponse.Table.map((item, index, arr) => (
            <option
              key={index}
              value={item.StoreName}
            >
              {item.StoreName}
            </option>
          ))
        );
      }
    }
    getStore();
  }, []);

  const handleClick_Back = () => {
    setMenuResponse("");
    props.setPage("menu");
  };

  const handleClick_Ok = async () => {
    const resObj = await runFetch(`${spURL}Handheld_Transfer_Send_Create`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Store: storeno,
          TransferID: refDescription.current.value,
          UserID: userid,
          DestinationStore: refDestinationStore.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      props.setTONo(spResponse.Table[0].TransferNo);
      props.setPage("barcode");
    }
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="storetransfer-input-row">
          <p>
            <strong>Prefix</strong>
          </p>
          <input
            type="text"
            id="storetransfer-create-description"
            ref={refDescription}
            autoFocus
            required
          />
        </div>
        <div className="storetransfer-input-row">
          <p>
            <strong>Receiving Store</strong>
          </p>
          <select
            name="destinationstore"
            id="storetransfer-create-destinationstore"
            ref={refDestinationStore}
          >
            {ddlDestinationStore}
          </select>
        </div>
        <button
          className="signin-login-button"
          onClick={handleClick_Ok}
        >
          Ok
        </button>
        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
