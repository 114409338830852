import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { purple, yellow, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import { PopupImage } from "./PopupImage";

export const PLPMenu = (props) => {
  const [PLPMenuResponse, setPLPMenuResponse] = useState("");
  const [ImageObj, setImageObj] = useState({
    ImageName: "",
    DesktopFileURL: "",
    MobileFileURL: "",
    DesktopFileName: "",
    MobileFileName: "",
    WebsiteFromURL: "",
    WebsiteToURL: "",
  });
  const [showPopupImage, setShowPopupImage] = useState(false);

  const handleClick_Image = async (image) => {
    setPLPMenuResponse("");

    const resObj = await runFetch(`${spURL}Handheld_WebsiteCMS_ImageDetails_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          ImageName: `PLP${image}`,
        }),
      }),
    });

    if (resObj.response === null) {
      setPLPMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setImageObj({
          ImageName: spResponse.Table[0].ImageName,
          DesktopFileURL: spResponse.Table[0].DesktopFileURL,
          MobileFileURL: spResponse.Table[0].MobileFileURL,
          DesktopFileName: spResponse.Table[0].DesktopFileName,
          MobileFileName: spResponse.Table[0].MobileFileName,
          WebsiteFromURL: spResponse.Table[0].WebsiteFromURL,
          WebsiteToURL: spResponse.Table[0].WebsiteToURL,
        });

        setShowPopupImage(true);
      }
    }
  };

  return (
    <>
      <div className="page-container">
        <div className="handheld-body-container">
          <div className="home-table">
            <MenuButton
              onClick={() => {
                handleClick_Image("THIN");
              }}
              button="Thin"
              colour={purple}
              title="Thin"
            />
            <MenuButton
              onClick={() => {
                handleClick_Image("TILE1");
              }}
              button="T1"
              colour={purple}
              title="Tile 1"
            />
            <MenuButton
              onClick={() => {
                handleClick_Image("TILE2");
              }}
              button="T2"
              colour={purple}
              title="Tile 2"
            />
          </div>
          <div className="home-table">
            <MenuButton
              onClick={() => {
                handleClick_Image("TILE3");
              }}
              button="T3"
              colour={purple}
              title="Tile 3"
            />
          </div>
          <br />
          <p className="error-message">{PLPMenuResponse}</p>
        </div>

        <div className="footer-container menu-container">
          <Link to="/Marketing">
            <FooterButton
              button="Back"
              colour={purple}
            />
          </Link>
          <Link to="/">
            <FooterButton
              button="Home"
              colour={purple}
            />
          </Link>
        </div>
      </div>
      <PopupImage
        show={showPopupImage}
        setShow={setShowPopupImage}
        ImageObj={ImageObj}
      />
    </>
  );
};
