import React, { useState, useEffect, setState } from "react";
import { useRef } from "react";
import { trackPromise } from "react-promise-tracker";
import { spURL, spURL_GetIP } from "../App";
import { OnEnterKeyDown } from "../functions/OnEnterKeyDown";
import { runFetch } from "../functions/RunFetch";
import store from "../store";

export const SignIn = () => {
  const [SignInResponse, setSignInResponse] = useState("");

  const [username, setUserName] = store.useState("username");
  const [userid, setUserID] = store.useState("userid");
  const [storeno, setStoreNo] = store.useState("storeno");
  const [storename, setStoreName] = store.useState("storename");
  const [isSignedIn, setIsSignedIn] = store.useState("issignedin");
  const [session, setSession] = store.useState("session");

  const refUserID = useRef();
  const refPassword = useRef();
  const refStaffBarcode = useRef();

  const doLogin = async () => {
    const resObj = await runFetch(
      process.env.REACT_APP_ENV === "STORE"
        ? "http://192.168.15.201/HANDHELDAPI/api/staff/login"
        : `${spURL}Handheld_Staff_Verify`,
      {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            UserID: refUserID.current.value,
            Password: refPassword.current.value,
            IPAddress: "",
            DeviceID: localStorage.getItem("deviceID"),
            StaffBarcode: refStaffBarcode.current.value,
          }),
        }),
      }
    );

    if (resObj.response === null) {
      setSignInResponse(resObj.exception);
      refStaffBarcode.current.value = "";
    } else {
      let spResponse = JSON.parse(resObj.response);

      setUserName(spResponse.Table[0].UserName);
      setUserID(spResponse.Table[0].UserID);

      if (process.env.REACT_APP_ENV === "STORE") {
        setStoreNo(spResponse.Table[0].StoreNo);
        setStoreName(spResponse.Table[0].StoreName);
      }
      setIsSignedIn("1");
    }
  };

  useEffect(() => {
    setIsSignedIn("0");
    setUserID("");
    setUserName("");
    setStoreNo("");
    setStoreName("");
  }, [session]);

  return (
    <div onKeyDown={(e) => OnEnterKeyDown(e, doLogin)}>
      <div className="handheld-body-container">
        <table className="signin-table">
          <tbody>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>UserID:</strong>
                  </p>
                  <input
                    id="signin-userID"
                    name="UserID"
                    autoComplete="off"
                    ref={refUserID}
                  ></input>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>Password:</strong>
                  </p>
                  <input
                    id="signin-password"
                    type="text"
                    className="form-password"
                    name="Password"
                    autoComplete="off"
                    ref={refPassword}
                  ></input>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>Barcode:</strong>
                  </p>
                  <input
                    id="signin-staffbarcode"
                    type="text"
                    className="form-password"
                    name="StaffBarcode"
                    autoComplete="off"
                    autoFocus
                    ref={refStaffBarcode}
                  ></input>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <button
                  className="signin-login-button btnHover"
                  onClick={doLogin}
                >
                  Log In
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="error-message"
          id="SignInResponse"
        >
          {SignInResponse}
        </p>
      </div>
    </div>
  );
};
