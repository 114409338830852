import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { FooterButton } from "../../components/HomepageButton";
import { orange, spURL } from "../../App";
import { runFetch } from "../../functions/RunFetch";
import { PopupAllocateCage } from "./PopupAllocateCage";
import store from "../../store";

export const CageAllocationBody = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [CageAllocationResponse, setCageAllocationResponse] = useState("");
  const [CageList, setCageList] = useState([]);
  const [SelectedCage, setSelectedCage] = useState("");

  const [showAllocateCage, setShowAllocateCage] = useState(false);

  const getCageList = async () => {
    const resObj = await runFetch(`${spURL}Handheld_CageAllocation_CageList_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setCageAllocationResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setCageList(
          spResponse.Table.map((item) => ({
            Cage: item.Cage,
            Department: item.Department,
          }))
        );
      } else {
        setCageList([]);
      }
    }
  };

  useEffect(() => {
    getCageList();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>Cage</th>
              <th>Department</th>
            </tr>
          </thead>
          <tbody>
            {CageList.length > 0
              ? CageList.map((item, index) => (
                  <tr
                    key={index}
                    className="btnHover"
                    onClick={() => {
                      setCageAllocationResponse("");
                      setSelectedCage(item.Cage);
                      setShowAllocateCage(true);
                    }}
                  >
                    <td>{item.Cage}</td>
                    <td>{item.Department}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
        <br />
        <p className="error-message">{CageAllocationResponse}</p>
        <PopupAllocateCage
          show={showAllocateCage}
          setShow={setShowAllocateCage}
          CageAllocationResponse={CageAllocationResponse}
          setCageAllocationResponse={setCageAllocationResponse}
          SelectedCage={SelectedCage}
          getCageList={getCageList}
        />
      </div>
      <div className="footer-container menu-container">
        <Link to="/WHReceived">
          <FooterButton
            button="Back"
            colour={orange}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
