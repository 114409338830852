import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  FooterButton,
  MenuButton,
  UnallocateDeviceStatusButton,
} from "../../components/HomepageButton";
import {
  blue,
  orange,
  spURL,
  spURL_Local,
  spURL_LocalPOS,
  spURL_NoInputJSON,
  spURL_Unallocated_WebOrders,
  Unallocate,
} from "../../App";
import { Logout } from "../../functions/Logout";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const UnallocateBody = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [storename, setStoreName] = store.useState("storename");
  const [userid, setUserID] = store.useState("userid");
  const [username, setUserName] = store.useState("username");
  const [ip, setIP] = store.useState("ip");
  const [isSignedIn, setIsSignedIn] = store.useState("issignedin");
  const navigate = useNavigate();

  const [CurrentDeviceStatus, setCurrentDeviceStatus] = useState([]);
  const [UpdateResponse, setUpdateResponse] = useState("");

  const doLogout = () => {
    setIsSignedIn(0);
    setStoreNo("");
    setStoreName("");
    setUserID("");
    setUserName("");
    setIP("");
    navigate("/");
  };

  const refCurrentDeviceStatus = useRef();

  refCurrentDeviceStatus.current = CurrentDeviceStatus;

  const handleClick_Status = (status) => {
    let newCurrentDeviceStatus = refCurrentDeviceStatus.current;
    if (newCurrentDeviceStatus.includes(status)) {
      newCurrentDeviceStatus = newCurrentDeviceStatus.filter((item) => item !== status);

      document
        .getElementById(`unallocate-devicestatus-${status.replace(" ", "").toLowerCase()}`)
        .parentElement.classList.remove("background-lightblue");
    } else {
      newCurrentDeviceStatus.push(status);

      document
        .getElementById(`unallocate-devicestatus-${status.replace(" ", "").toLowerCase()}`)
        .parentElement.classList.add("background-lightblue");
    }

    setCurrentDeviceStatus(newCurrentDeviceStatus);
  };

  const handleClick_Unallocate = async () => {
    async function unallocateUser() {
      const res = await runFetch(`${spURL_LocalPOS}Handheld_DeviceManagement_DeallocateUser`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            ManagerID: userid,
            StoreNo: storeno,
            DeviceID: localStorage.getItem("deviceID"),
            DeviceStatus: CurrentDeviceStatus.toString(),
          }),
        }),
      });

      const resObj = JSON.parse(res);
      if (resObj.status === "ERROR!") {
        setUpdateResponse(resObj.exception);
      } else if (resObj.status === "OK!") {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          doLogout();
        }
      }
    }

    if (CurrentDeviceStatus.length === 0) {
      setUpdateResponse("ERROR!: No device status selected.");
    } else if (CurrentDeviceStatus.length > 1) {
      if (CurrentDeviceStatus.includes("NO ISSUES")) {
        setUpdateResponse(
          "ERROR!: NO ISSUES device status selected along with ISSUES device status."
        );
      } else {
        unallocateUser();
      }
    } else {
      unallocateUser();
    }
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <h4>
          <strong>UNALLOCATE Device</strong>
        </h4>
        <div className="input-row">
          <p>
            <strong>Device Name:</strong>
          </p>
          <p>{localStorage.getItem("displayName")}</p>
        </div>
        <p>
          <strong>Device Returned Status:</strong>
        </p>
        <div className="itemdetails-sq-ns-wp-container">
          <div className="itemdetails-sq-ns-wp-button">
            <UnallocateDeviceStatusButton
              id="unallocate-devicestatus-noissues"
              button="No Issues"
              onClick={() => handleClick_Status("NO ISSUES")}
            />
          </div>
          <div className="itemdetails-sq-ns-wp-button">
            <UnallocateDeviceStatusButton
              id="unallocate-devicestatus-batteryissues"
              button="Battery Issues"
              onClick={() => handleClick_Status("BATTERY ISSUES")}
            />
          </div>
        </div>
        <div className="itemdetails-sq-ns-wp-container">
          <div className="itemdetails-sq-ns-wp-button">
            <UnallocateDeviceStatusButton
              id="unallocate-devicestatus-damagedscreen"
              button="Damaged Screen"
              onClick={() => handleClick_Status("DAMAGED SCREEN")}
            />
          </div>
          <div className="itemdetails-sq-ns-wp-button">
            <UnallocateDeviceStatusButton
              id="unallocate-devicestatus-touchscreenissues"
              button="Touchscreen Issues"
              onClick={() => handleClick_Status("TOUCHSCREEN ISSUES")}
            />
          </div>
        </div>
        <div className="itemdetails-sq-ns-wp-container">
          <div className="itemdetails-sq-ns-wp-button">
            <UnallocateDeviceStatusButton
              id="unallocate-devicestatus-wifiissues"
              button="WIFI Issues"
              onClick={() => handleClick_Status("WIFI ISSUES")}
            />
          </div>
          <div className="itemdetails-sq-ns-wp-button">
            <UnallocateDeviceStatusButton
              id="unallocate-devicestatus-scanningissues"
              button="Scanning Issues"
              onClick={() => handleClick_Status("SCANNING ISSUES")}
            />
          </div>
        </div>
        <br />
        <p className="error-message">{UpdateResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={blue}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
        <FooterButton
          button="Submit"
          colour={blue}
          onClick={handleClick_Unallocate}
        />
      </div>
    </div>
  );
};
