import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styl
import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { purple, red, spURL, spURL_NoInputJSON } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";

export const DailySalesPerformance = (props) => {
  const [DailySalesPerformanceResponse, setDailySalesPerformanceResponse] = useState("");
  const emptyTotalReportValues = {
    Today: 0,
    Yesterday: 0,
    LastWeek: 0,
    LastWeekTOD: 0,
    LastYear: 0,
    Budget: 0,
    BudgetTOD: 0,
  };
  const [TotalReportValues, setTotalReportValues] = useState(emptyTotalReportValues);
  const [StoreReportValues, setStoreReportValues] = useState([]);

  const [sortColumn, setSortColumn] = useState("Today");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [sortIcon, setSortIcon] = useState(solid("sort-down"));

  const getDailySalesPerformanceReport = async () => {
    const resObj = await runFetch(`${spURL_NoInputJSON}Handheld_Reports_DailySalesPerformance`, {
      method: "POST",
    });

    if (resObj.response === null) {
      setDailySalesPerformanceResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      // Report (by stores)
      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStoreReportValues(spResponse.Table);

        setTotalReportValues(
          spResponse.Table.reduce((a, b) => {
            return {
              Today: a.Today + b.Today,
              Yesterday: a.Yesterday + b.Yesterday,
              LastWeek: a.LastWeek + b.LastWeek,
              LastWeekTOD: a.LastWeekTOD + b.LastWeekTOD,
              LastYear: a.LastYear + b.LastYear,
              Budget: a.Budget + b.Budget,
              BudgetTOD: a.BudgetTOD + b.BudgetTOD,
            };
          }, emptyTotalReportValues)
        );
      }
    }
  };

  const convertToDollars = (_amount) => {
    // Create our number formatter.
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",

      // These options are needed to round to whole numbers if that's what you want.
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(_amount);
  };

  const handleClick_HeaderRow = (event) => {
    let _target;
    switch (event.target.tagName.toUpperCase()) {
      case "TH":
        _target = event.target;
        break;
      case "SVG":
        _target = event.target.parentElement;
        break;
      case "PATH":
        _target = event.target.parentElement.parentElement;
        break;
      default:
        break;
    }

    if (event.target.tagName.toUpperCase() !== "TR") {
      const _colname = _target.attributes["colname"].value;
      const _sortorder = _target.attributes["sortorder"].value;

      if (_colname === "Date") {
      } else {
        setSortColumn(_colname);

        if (_colname === sortColumn) {
          if (_sortorder === "ASC") {
            setSortOrder("DESC");
          } else {
            setSortOrder("ASC");
          }
        } else {
          setSortOrder("ASC");
        }
      }
    }
  };

  useEffect(() => {
    getDailySalesPerformanceReport();
  }, []);

  useEffect(() => {
    let newArr = StoreReportValues;

    if (newArr.length > 0 && sortColumn !== "") {
      newArr = newArr.sort((a, b) => {
        switch (sortColumn) {
          case "Store":
            return (a[sortColumn] > b[sortColumn] ? 1 : -1) * (sortOrder === "ASC" ? 1 : -1);
          case "Today":
          case "Yesterday":
          case "LastWeek":
          case "LastWeekTOD":
          case "LastYear":
          case "Budget":
          case "BudgetTOD":
            return (
              (parseFloat(a[sortColumn]) - parseFloat(b[sortColumn])) *
              (sortOrder === "ASC" ? 1 : -1)
            );
        }
      });

      setStoreReportValues(newArr);
    }
  }, [sortColumn, sortOrder, StoreReportValues, TotalReportValues]);

  useEffect(() => {
    switch (sortOrder) {
      case "ASC":
        setSortIcon(solid("sort-up"));
        break;
      case "DESC":
        setSortIcon(solid("sort-down"));
        break;
      default:
        setSortIcon(solid("sort"));
        break;
    }
  }, [sortOrder]);

  return (
    <div className="page-container">
      <div className="blockedorders-body-container">
        <p className="error-message">{DailySalesPerformanceResponse}</p>
        <div className="dailysalesperformance-total-container">
          <div className="dailysalesperformance-total">
            <p>
              <strong>{convertToDollars(TotalReportValues.Today)}</strong>
            </p>
            <p>Today</p>
          </div>
          <div className="dailysalesperformance-total">
            <p>
              <strong>{convertToDollars(TotalReportValues.LastWeekTOD)}</strong>
            </p>
            <p>LastWeekTOD</p>
          </div>
          <div className="dailysalesperformance-total">
            <p>
              <strong>{convertToDollars(TotalReportValues.BudgetTOD)}</strong>
            </p>
            <p>BudgetTOD</p>
          </div>
          <div className="dailysalesperformance-total">
            <p>
              <strong>{convertToDollars(TotalReportValues.Yesterday)}</strong>
            </p>
            <p>Yesterday</p>
          </div>
          <div className="dailysalesperformance-total">
            <p>
              <strong>{convertToDollars(TotalReportValues.LastWeek)}</strong>
            </p>
            <p>LastWeek</p>
          </div>
          <div className="dailysalesperformance-total">
            <p>
              <strong>{convertToDollars(TotalReportValues.Budget)}</strong>
            </p>
            <p>Budget</p>
          </div>
          <div className="dailysalesperformance-total">
            <p>
              <strong>{convertToDollars(TotalReportValues.LastYear)}</strong>
            </p>
            <p>LastYear</p>
          </div>
        </div>

        <table className="itemdetails-table">
          <thead>
            <tr
              onClick={handleClick_HeaderRow}
              className="no-highlight btnHover"
            >
              <th
                colname="Store"
                sortorder={sortOrder}
              >
                Store
                {sortColumn === "Store" && <FontAwesomeIcon icon={sortIcon} />}
              </th>
              <th
                colname="Today"
                sortorder={sortOrder}
              >
                Today
                {sortColumn === "Today" && <FontAwesomeIcon icon={sortIcon} />}
              </th>
              <th
                colname="Yesterday"
                sortorder={sortOrder}
              >
                Yesterday
                {sortColumn === "Yesterday" && <FontAwesomeIcon icon={sortIcon} />}
              </th>
              <th
                colname="LastWeek"
                sortorder={sortOrder}
              >
                LastWeek
                {sortColumn === "LastWeek" && <FontAwesomeIcon icon={sortIcon} />}
              </th>
              <th
                colname="LastWeekTOD"
                sortorder={sortOrder}
              >
                LastWeekTOD
                {sortColumn === "LastWeekTOD" && <FontAwesomeIcon icon={sortIcon} />}
              </th>
              <th
                colname="LastYear"
                sortorder={sortOrder}
              >
                LastYear
                {sortColumn === "LastYear" && <FontAwesomeIcon icon={sortIcon} />}
              </th>
              <th
                colname="Budget"
                sortorder={sortOrder}
              >
                Budget
                {sortColumn === "Budget" && <FontAwesomeIcon icon={sortIcon} />}
              </th>
              <th
                colname="BudgetTOD"
                sortorder={sortOrder}
              >
                BudgetTOD
                {sortColumn === "BudgetTOD" && <FontAwesomeIcon icon={sortIcon} />}
              </th>
            </tr>
            <tr>
              <th>Total</th>
              <th>{convertToDollars(TotalReportValues.Today)}</th>
              <th>{convertToDollars(TotalReportValues.Yesterday)}</th>
              <th>{convertToDollars(TotalReportValues.LastWeek)}</th>
              <th>{convertToDollars(TotalReportValues.LastWeekTOD)}</th>
              <th>{convertToDollars(TotalReportValues.LastYear)}</th>
              <th>{convertToDollars(TotalReportValues.Budget)}</th>
              <th>{convertToDollars(TotalReportValues.BudgetTOD)}</th>
            </tr>
          </thead>
          <tbody>
            {StoreReportValues.map((row, index) => (
              <tr key={index}>
                <td>{row.Store}</td>
                <td>{convertToDollars(row.Today)}</td>
                <td>{convertToDollars(row.Yesterday)}</td>
                <td>{convertToDollars(row.LastWeek)}</td>
                <td>{convertToDollars(row.LastWeekTOD)}</td>
                <td>{convertToDollars(row.LastYear)}</td>
                <td>{convertToDollars(row.Budget)}</td>
                <td>{convertToDollars(row.BudgetTOD)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={purple}
          onClick={() => props.setPage("menu")}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
      </div>
    </div>
  );
};
