import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { green, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { ItemBarcode } from "../../components/ItemBarcode";
import { runFetch } from "../../functions/RunFetch";

export const LinesReturn = (props) => {
  const [WebOrdersResponse, setWebOrdersResponse] = useState(props.MenuResponse);

  const [LinesTable, setLinesTable] = useState([]);

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL}Handheld_CustomerOrder_Return_Line_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(
          props.CurrentPicks.map((item) => ({
            OrderID: item.OrderID,
          }))
        ),
      }),
    });

    if (resObj.exception !== "") {
      setWebOrdersResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setLinesTable(
          spResponse.Table.map((item) => ({
            Product: item.Product,
            Qty: item.quantity,
            ItemNo: item.ItemNo,
          }))
        );
      }
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Back = () => {
    props.setMenuResponse("");
    props.setPage("return");
  };

  const handleClick_Barcode = () => {
    const _barcode = document.getElementById("itembarcode-barcode").value;

    props.setCurrentItemNo(_barcode);
    props.setPage("item-return");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <ItemBarcode barcodeClick={handleClick_Barcode} />
        <div>
          {LinesTable.length === 0 ? (
            "No items found in order."
          ) : (
            <>
              <p>
                <strong>Order {props.CurrentPicks[0].OrderID} Items List</strong>
              </p>
              <table className="itemdetails-table">
                <thead>
                  <tr>
                    <th>SubCat/Desc/Item</th>
                    <th>Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {LinesTable.map((item, index) => (
                    <tr
                      key={index}
                      itemno={item.ItemNo}
                    >
                      <td dangerouslySetInnerHTML={{ __html: item.Product }}></td>
                      <td>{item.Qty}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
        <p className="error-message">{WebOrdersResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
