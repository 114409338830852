import React, { useState, useEffect } from 'react'

import { Main } from './Main';
import { Item } from './Item';
export const PhoneOrdersBody = () => {

    const [page, setPage] = useState('main');
    const [selectedItem, setSelectedItem] = useState('');
    const [MenuResponse, setMenuResponse] = useState('');

    const mainPage = (
        <Main 
            page={page} setPage={setPage}
            setSelectedItem={setSelectedItem}
            MenuResponse={MenuResponse} />
    )

    const itemPage = (
        <Item
            page={page} setPage={setPage}
            selectedItem={selectedItem}
            setMenuResponse={setMenuResponse} />
    )

    const getCurrentPage = (page) => {
        switch(page) {
            case "main":
                return mainPage;
                break;
            case 'item':
                return itemPage;
                break;
        }
    }

    return getCurrentPage(page);
}