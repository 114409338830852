import React, { useState, useEffect } from "react";

import { Menu } from "./Menu";
import { Create } from "./Create";
import { Barcode } from "./Barcode";
import { Add } from "./Add";
import { Receive } from "./Receive";

export const StoreTransferBody = (props) => {
  const [page, setPage] = useState("menu");
  const [TONo, setTONo] = useState("");

  const menuPage = <Menu setPage={setPage} />;

  const createPage = (
    <Create
      setPage={setPage}
      setTONo={setTONo}
    />
  );

  const barcodePage = (
    <Barcode
      setPage={setPage}
      TONo={TONo}
      setTONo={setTONo}
    />
  );

  const addPage = (
    <Add
      setPage={setPage}
      setTONo={setTONo}
    />
  );

  const receivePage = <Receive setPage={setPage} />;

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "create":
        return createPage;
      case "barcode":
        return barcodePage;
      case "add":
        return addPage;
      case "receive":
        return receivePage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
