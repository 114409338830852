import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { convertToCurrency } from "../functions/ConvertToCurrency";
import { ContentButton, FooterButton, MenuButton } from "../components/HomepageButton";
import { purple, spURL_LocalPOS } from "../App";
import { ConvertDateFormat } from "../functions/ConvertDateFormat";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../functions/RunFetch";
import store from "../store";

export const DeviceManagementStatusBody = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [MenuResponse, setMenuResponse] = useState(props.MenuResponse);

  const [DeviceStatusTable, setDeviceStatusTable] = useState([]);

  const pageLoad = async () => {
    const res = await runFetch(spURL_LocalPOS + "Handheld_DeviceManagement_DeviceStatusList_Get", {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
        }),
      }),
    });

    const resObj = JSON.parse(res);

    if (resObj.status === "ERROR!") {
      setMenuResponse(resObj.exception);
    } else if (resObj.status === "OK!") {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDeviceStatusTable(
          spResponse.Table.map((item) => ({
            IsDeviceAllocated: item.IsDeviceAllocated,
            DeviceID: item.DeviceID,
            DisplayName: item.DisplayName,
            UserAllocated: item.UserAllocated,
            ManagerAllocate: item.ManagerAllocate,
            ManagerDeallocate: item.ManagerDeallocate,
            DateTimeAllocated: new Date(item.DateTimeAllocated).toLocaleString(),
            DateTimeDeallocated: new Date(item.DateTimeDeallocated).toLocaleString(),
            DeviceStatusAllocated: item.DeviceStatusAllocated,
            DeviceStatusDeallocated: item.DeviceStatusDeallocated,
          }))
        );
      }
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <p className="error-message">{MenuResponse}</p>
        <p>
          <strong>Allocated Devices</strong>
        </p>
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>Device</th>
              <th>User</th>
              <th>Manager</th>
              <th>Time</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {DeviceStatusTable.filter((item) => item.IsDeviceAllocated === "ALLOCATED").map(
              (item, index) => (
                <tr key={index}>
                  <td>{item.DisplayName}</td>
                  <td>{item.UserAllocated}</td>
                  <td>{item.ManagerAllocate}</td>
                  <td>{item.DateTimeAllocated}</td>
                  <td>{item.DeviceStatusAllocated}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
        <br />
        <p>
          <strong>Device History</strong>
        </p>
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th rowSpan={2}>Device</th>
              <th rowSpan={2}>User</th>
              <th colSpan={2}>Manager</th>
              <th colSpan={2}>Time</th>
              <th colSpan={2}>Status</th>
            </tr>
            <tr>
              <th>Allocate</th>
              <th>Deallocate</th>
              <th>Allocated</th>
              <th>Deallocated</th>
              <th>Allocated</th>
              <th>Deallocated</th>
            </tr>
          </thead>
          <tbody>
            {DeviceStatusTable.filter((item) => item.IsDeviceAllocated !== "ALLOCATED").map(
              (item, index) => (
                <tr key={index}>
                  <td>{item.DisplayName}</td>
                  <td>{item.UserAllocated}</td>
                  <td>{item.ManagerAllocate}</td>
                  <td>{item.ManagerDeallocate}</td>
                  <td>{item.DateTimeAllocated}</td>
                  <td>{item.DateTimeDeallocated}</td>
                  <td>{item.DeviceStatusAllocated}</td>
                  <td>{item.DeviceStatusDeallocated}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={purple}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
      </div>
    </div>
  );
};
