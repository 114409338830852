import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'




import { convertToCurrency } from '../../functions/ConvertToCurrency'
import { ContentButton, FooterButton, MenuButton } from '../../components/HomepageButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from '../../App'
import { PopupProcess } from './PopupProcess'
import { trackPromise } from 'react-promise-tracker'

library.add(faPlusCircle);
library.add(faMinusCircle);
library.add(faTimesCircle);

export const Main = (props) => {

    const [MenuResponse, setMenuResponse] = useState(props.MenuResponse);
    const [LoadingMessage, setLoadingMessage] = useState(false);

    const [OrderID, setOrderID] = useState('');
    const [OrderTable, setOrderTable] = useState('');

    const [show, setShow] = useState(false);

    const pageLoad = () => {

        async function createOrder() {
            // load blocked orders table
            var spResponse = "";

            const _userid = sessionStorage.getItem('userid');

            var data = new FormData();

            const inputJSON = `[{"UserID":"${_userid}"}]`;

            data.append('inputJSON', inputJSON);

            // POST request using fetch inside useEffect React hook
            const requestOptions = {
                method: 'POST',
                body: new URLSearchParams({
                    inputJSON: inputJSON
                })
            };

            setLoadingMessage(true);

            const res = await trackPromise(fetch(spURL + 'Phone_Orders_Order_Create', requestOptions));
            res
                .json()
                .then(data => {
                    spResponse = JSON.parse(data.response);

                    if (data.response === null) {
                        //console.log(data.exception);

                        setMenuResponse(data.exception);
                    } else {
                        spResponse = JSON.parse(data.response);

                        //console.log(spResponse);

                        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
                            setOrderID(spResponse.Table[0].OrderID);
                            getOrder(spResponse.Table[0].OrderID);
                        }
                    }
                })

        }


        async function getOrder(_orderid) {
            // load blocked orders table
            var spResponse = "";

            var data = new FormData();

            const inputJSON = `[{"OrderID":"${_orderid}"}]`;

            data.append('inputJSON', inputJSON);

            // POST request using fetch inside useEffect React hook
            const requestOptions = {
                method: 'POST',
                body: new URLSearchParams({
                    inputJSON: inputJSON
                })
            };

            setLoadingMessage(true);

            const res = await trackPromise(fetch(spURL + 'Phone_Orders_Order_Get', requestOptions));
            res
                .json()
                .then(data => {
                    spResponse = JSON.parse(data.response);

                    if (data.response === null) {
                        //console.log(data.exception);

                        setMenuResponse(data.exception);
                    } else {
                        spResponse = JSON.parse(data.response);

                        //console.log(spResponse);

                        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
                            setOrderTable(spResponse.Table.map((item, index) => ({
                                ItemNo: item.ItemNo,
                                Description: item.Description,
                                Quantity: item.Quantity,
                                ItemPrice: item.ItemPrice,
                                Price: item.Price,
                                ReasonCode: item.ReasonCode,
                                MatchURL: item.MatchURL,
                                TotalPrice: item.TotalPrice
                            })))
                        }
                    }
                })
                .then(() => {
                    setLoadingMessage(false);
                });
        }

        createOrder();
    }

    useEffect(() => {
        pageLoad();
    }, [])

    const handleClick_Row = (event) => {
        const _itemno = event.target.parentElement.attributes['itemNo'].value;

        const _iteminfo = OrderTable.find(item => item.ItemNo === _itemno);

        props.setSelectedItem({
            Barcode: _itemno,
            Price: _iteminfo.Price,
            Quantity: _iteminfo.Quantity,
            ReasonCode: _iteminfo.ReasonCode,
            MatchURL: _iteminfo.MatchURL,
            Function: 'UPDATE',
            OrderID: OrderID
        });

        props.setPage('item');
    }

    const handleClick_Search = () => {
        const _sku = document.getElementById('phoneorders-main-sku').value;

        props.setSelectedItem({
            Barcode: _sku,
            Price: '',
            Quantity: '',
            ReasonCode: '',
            MatchURL: '',
            Function: 'ADD',
            OrderID: OrderID
        });

        props.setPage('item');
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById('phoneorders-main-enter').click();
        }
    }

    const handleClick_Plus = (event) => {
        const _itemno = event.target.parentElement.parentElement.attributes['itemNo'].value;

        const _iteminfo = OrderTable.find(item => item.ItemNo === _itemno);

        async function addQty(_orderid) {
            // load blocked orders table
            var spResponse = "";

            var data = new FormData();

            const inputJSON = `[{"OrderID":"${OrderID}"` +
                `,"ItemNo":"${_itemno}"` +
                `,"Price":"${_iteminfo.Price}"` +
                `,"Quantity":"${parseInt(_iteminfo.Quantity) + 1}"` +
                `,"Function":"UPDATE"` +
                `,"ReasonCode":"${_iteminfo.ReasonCode}"` +
                `,"MatchURL":"${_iteminfo.MatchURL}"}]`;

            data.append('inputJSON', inputJSON);

            //console.log(inputJSON);

            // POST request using fetch inside useEffect React hook
            const requestOptions = {
                method: 'POST',
                body: new URLSearchParams({
                    inputJSON: inputJSON
                })
            };

            const res = await trackPromise(fetch(spURL + 'Phone_Orders_Item_Update', requestOptions));
            res
                .json()
                .then(data => {
                    spResponse = JSON.parse(data.response);

                    if (data.response === null) {
                        //console.log(data.exception);

                        setMenuResponse(data.exception);
                    } else {
                        spResponse = JSON.parse(data.response);

                        //console.log(spResponse);

                        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
                            setMenuResponse(spResponse.Table[0].Output)
                        }
                    }
                })
                .then(() => {
                    pageLoad();
                })
        }

        addQty();
    }

    const handleClick_Minus = (event) => {
        const _itemno = event.target.parentElement.parentElement.attributes['itemNo'].value;

        const _iteminfo = OrderTable.find(item => item.ItemNo === _itemno);

        async function minusQty(_orderid) {
            // load blocked orders table
            var spResponse = "";

            var data = new FormData();

            const inputJSON = `[{"OrderID":"${OrderID}"` +
                `,"ItemNo":"${_itemno}"` +
                `,"Price":"${_iteminfo.Price}"` +
                `,"Quantity":"${parseInt(_iteminfo.Quantity) - 1}"` +
                `,"Function":"UPDATE"` +
                `,"ReasonCode":"${_iteminfo.ReasonCode}"` +
                `,"MatchURL":"${_iteminfo.MatchURL}"}]`;

            data.append('inputJSON', inputJSON);

            //console.log(inputJSON);

            // POST request using fetch inside useEffect React hook
            const requestOptions = {
                method: 'POST',
                body: new URLSearchParams({
                    inputJSON: inputJSON
                })
            };

            const res = await trackPromise(fetch(spURL + 'Phone_Orders_Item_Update', requestOptions));
            res
                .json()
                .then(data => {
                    spResponse = JSON.parse(data.response);

                    if (data.response === null) {
                        //console.log(data.exception);

                        setMenuResponse(data.exception);
                    } else {
                        spResponse = JSON.parse(data.response);

                        //console.log(spResponse);

                        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
                            setMenuResponse(spResponse.Table[0].Output)
                        }
                    }
                })
                .then(() => {
                    pageLoad();
                })
        }

        minusQty();
    }

    const handleClick_Delete = (event) => {
        const _itemno = event.target.parentElement.parentElement.attributes['itemNo'].value;

        const _iteminfo = OrderTable.find(item => item.ItemNo === _itemno);

        async function minusQty(_orderid) {
            // load blocked orders table
            var spResponse = "";

            var data = new FormData();

            const inputJSON = `[{"OrderID":"${OrderID}"` +
                `,"ItemNo":"${_itemno}"` +
                `,"Price":"${_iteminfo.Price}"` +
                `,"Quantity":"${_iteminfo.Quantity}"` +
                `,"Function":"DELETE"` +
                `,"ReasonCode":"${_iteminfo.ReasonCode}"` +
                `,"MatchURL":"${_iteminfo.MatchURL}"}]`;

            data.append('inputJSON', inputJSON);

            //console.log(inputJSON);

            // POST request using fetch inside useEffect React hook
            const requestOptions = {
                method: 'POST',
                body: new URLSearchParams({
                    inputJSON: inputJSON
                })
            };

            const res = await trackPromise(fetch(spURL + 'Phone_Orders_Item_Update', requestOptions));
            res
                .json()
                .then(data => {
                    spResponse = JSON.parse(data.response);

                    if (data.response === null) {
                        //console.log(data.exception);

                        setMenuResponse(data.exception);
                    } else {
                        spResponse = JSON.parse(data.response);

                        //console.log(spResponse);

                        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
                            setMenuResponse(spResponse.Table[0].Output)
                        }
                    }
                })
                .then(() => {
                    pageLoad();
                })
        }

        minusQty();
    }

    const handleClick_Process = () => {
        setShow(true);
    }

    return (
        <div className='page-container'>
            <div className='desktop-body-container'>
                <div className='blockedorders-input-row'>
                    <p><strong>SKU:</strong></p>
                    <input type='text' id='phoneorders-main-sku' autoFocus onKeyDown={handleKeyDown} />
                    <ContentButton id='phoneorders-main-enter' colour={green} button='Go' onClick={handleClick_Search} />
                </div>
                <br />
                {
                    LoadingMessage
                        ?
                        <p>Loading...</p>
                        :
                        (
                            OrderTable &&
                            <table className='phoneorders-table'>
                                <thead>
                                    <tr>
                                        <th>ItemNo</th>
                                        <th>Description</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Subtotal</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {OrderTable.map((item, index) => (
                                        <tr key={index} className='btnHover' itemno={item.ItemNo}>
                                            <td onClick={handleClick_Row}>{item.ItemNo}</td>
                                            <td onClick={handleClick_Row}>{item.Description}</td>
                                            <td onClick={handleClick_Row}>{item.Quantity}</td>
                                            <td onClick={handleClick_Row}>{convertToCurrency(item.ItemPrice, '$')}</td>
                                            <td onClick={handleClick_Row}>{convertToCurrency(item.Price, '$')}</td>
                                            <td itemno={item.ItemNo}><FontAwesomeIcon icon='fas fa-plus-circle' onClick={handleClick_Plus} /></td>
                                            <td itemno={item.ItemNo}><FontAwesomeIcon icon='fas fa-minus-circle' onClick={handleClick_Minus} /></td>
                                            <td itemno={item.ItemNo}><FontAwesomeIcon icon="far fa-times-circle" onClick={handleClick_Delete} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><strong>{convertToCurrency(OrderTable[0].TotalPrice, '$')}</strong></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        )
                }
                <br />
                <div className='blockedorders-option-row'>
                    <MenuButton title='Process' colour={green} button='P' onClick={handleClick_Process} />
                </div>

                <PopupProcess
                    show={show} setShow={setShow}
                    OrderID={OrderID}
                    setResponse={setMenuResponse}
                    setPage={props.setPage} />

                <br />
                <p className='error-message'>{MenuResponse}</p>
            </div>
            <div className='footer-container menu-container'>
                <Link to='/EComm'><FooterButton button='Back' colour={green} /></Link>
                <Link to='/'><FooterButton button='Home' colour={green} /></Link>
            </div>
        </div>
    )
}