import React, { useState, useEffect } from "react";
import { Manager } from "./Manager";

import { Menu } from "./Menu";
import { SSCCException } from "./SSCCException";
import { SSCReceive } from "./SSCReceive";
import { WHReceive } from "./WHReceive";

export const WHReceivedBody = () => {
  const [page, setPage] = useState("menu");

  const [JobNo, setJobNo] = useState("");

  const [WHReceivedResponse, setWHReceivedResponse] = useState("");

  const menuPage = (
    <Menu
      setPage={setPage}
      WHReceivedResponse={WHReceivedResponse}
      setWHReceivedResponse={setWHReceivedResponse}
    />
  );

  const whReceivePage = (
    <WHReceive
      setPage={setPage}
      setJobNo={setJobNo}
      setWHReceivedResponse={setWHReceivedResponse}
    />
  );

  const sscReceivePage = (
    <SSCReceive
      setPage={setPage}
      JobNo={JobNo}
      setJobNo={setJobNo}
      setWHReceivedResponse={setWHReceivedResponse}
    />
  );

  const managerPage = (
    <Manager
      setPage={setPage}
      setWHReceivedResponse={setWHReceivedResponse}
    />
  );

  const ssccExceptionPage = (
    <SSCCException
      setPage={setPage}
      setWHReceivedResponse={setWHReceivedResponse}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "whreceive":
        return whReceivePage;
      case "sscreceive":
        return sscReceivePage;
      case "manager":
        return managerPage;
      case "ssccexception":
        return ssccExceptionPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
