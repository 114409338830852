import { useRef } from "react";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { orange, spURL, spURL_NoInputJSON } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const ConNote = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [PODcode, setPODCode] = useState("");

  const [ddlTransportCompany, setDDLTransportCompany] = useState([]);

  const refDriver = useRef();
  const refTransportCompany = useRef();
  const refQuantityReceived = useRef();
  const refUnitType = useRef();

  useEffect(() => {
    async function generateCode() {
      const resObj = await runFetch(`${spURL}POD_Code_Generate`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            UserName: userid,
            Password: "TMV3",
            StoreNo: storeno,
          }),
        }),
      });

      if (resObj.response === null) {
        props.setPODCodeResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          const _podcode = spResponse.Table[0].PODCode;

          setPODCode(_podcode);
        }
      }
    }

    async function getDDL_TransportCompany() {
      const resObj = await runFetch(`${spURL_NoInputJSON}POD_TransportCompany_List`, {
        method: "POST",
      });

      if (resObj.response === null) {
        props.setPODCodeResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          setDDLTransportCompany(
            spResponse.Table.map((item, index) => (
              <option
                key={index}
                id={item.Company}
                value={item.Company}
              >
                {item.Company}
              </option>
            ))
          );
        }
      }

      if (props.Type === "EDIT") {
        setPODCode(props.PODCode);

        refDriver.current.value = props.ConNoteDetails.Driver;
        refTransportCompany.current.value = props.ConNoteDetails.TransportCompany;
        refQuantityReceived.current.value = props.ConNoteDetails.QuantityReceived;
        refUnitType.current.value = props.ConNoteDetails.UnitType;
      }
    }

    if (props.Type === "CREATE") {
      generateCode();
    }

    getDDL_TransportCompany();
  }, []);

  const handleClick = async () => {
    const resObj = await runFetch(`${spURL}POD_Edit_Header_Update`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          PODCode: PODcode,
          Driver: refDriver.current.value,
          TransportCompany: refTransportCompany.current.value,
          QuantityReceived: refQuantityReceived.current.value,
          UnitType: refUnitType.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      props.setPODCodeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      props.setPODCode(PODcode);
      props.setConNoteDetails((values) => ({
        ...values,
        Driver: refDriver.current.value,
        TransportCompany: refTransportCompany.current.value,
        QuantityReceived: refQuantityReceived.current.value,
        UnitType: refUnitType.current.value,
      }));

      if (props.Type === "CREATE") {
        props.setOriginalDetails((values) => ({
          ...values,
          Supplier: "",
          InvoiceNo: "",
          CartonsExpected: "",
          CartonsReceived: "",
          QuantityUnitsInvoiced: 0,
          InvoiceValue_exGST: 0,
        }));

        props.setPage("update_edit");
      } else if (props.Type === "EDIT") {
        props.setPage("edit_lines");
      }
    }
  };

  const handleClick_Back = () => {
    props.setPODCodeResponse("");
    props.setPage("edit_lines");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="input-row">
          <p>
            <strong>POD:</strong>
          </p>
          <p>
            <strong>{PODcode}</strong>
          </p>
        </div>
        <div className="input-row">
          <p>
            <strong>Driver Name:</strong>
          </p>
          <input
            type="text"
            name="driverName"
            id="podcode-connote-drivername"
            ref={refDriver}
            required
          />
        </div>
        <div className="input-row">
          <p>
            <strong>Transport Company:</strong>
          </p>
          <select
            name="transportcompany"
            id="podcode-connote-transportcompany"
            ref={refTransportCompany}
            required
          >
            {ddlTransportCompany}
          </select>
        </div>
        <div className="input-row">
          <p>
            <strong>Quantity Received:</strong>
          </p>
          <input
            type="text"
            name="qtyreceived"
            id="podcode-connote-qtyreceived"
            ref={refQuantityReceived}
            required
          />
        </div>
        <div className="input-row">
          <p>
            <strong>Unit Type:</strong>
          </p>
          <select
            name="unittype"
            id="podcode-connote-unittype"
            ref={refUnitType}
            required
          >
            <option value="PALLETS">PALLETS</option>
            <option value="CARTONS">CARTONS</option>
            <option value="UNITS">UNITS</option>
          </select>
        </div>
        <button
          className="signin-login-button btnHover"
          onClick={handleClick}
        >
          OK
        </button>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={orange}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
