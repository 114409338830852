import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { EditIssue } from "./EditIssue";
import { EditIssueMobile } from "./EditIssueMobile";
import { IssueList } from "./IssueList";

import { Menu } from "./Menu";

export const StoreAuditToolBody = () => {
  const [page, setPage] = useState("menu");
  const [StoreAuditToolResponse, setStoreAuditToolResponse] = useState("");

  const [SelectedProject, setSelectedProject] = useState({
    ProjectID: 0,
    ProjectDescription: "",
  });
  const [SelectedIssueID, setSelectedIssueID] = useState("");

  const [From, setFrom] = useState("");

  const handleClick_Home = () => {
    if (process.env.NODE_ENV === "production") {
      let uri = "";
      switch (From.Page) {
        case "STORE":
          uri = "http://192.168.15.201/TM";
          break;
        default:
        case "HO":
          uri = "/#/";
          break;
      }
      window.location.href = uri;
    } else {
      window.location.href = "/#/";
    }
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (searchParams !== null) {
      const params = new URLSearchParams(searchParams);

      const _from = params.get("f") || "";
      const _fromStore = params.get("s") || "";
      const _fromStoreName = params.get("n") || "";
      const _fromUserID = params.get("u") || "";
      setFrom({
        Page: _from,
        StoreNo: _fromStore,
        StoreName: _fromStoreName,
        UserID: _fromUserID,
      });
    }
  }, []);

  const menuPage = (
    <Menu
      page={page}
      setPage={setPage}
      StoreAuditToolResponse={StoreAuditToolResponse}
      setStoreAuditToolResponse={setStoreAuditToolResponse}
      setSelectedProject={setSelectedProject}
      From={From}
      handleClick_Home={handleClick_Home}
    />
  );

  const issueListPage = (
    <IssueList
      page={page}
      setPage={setPage}
      StoreAuditToolResponse={StoreAuditToolResponse}
      setStoreAuditToolResponse={setStoreAuditToolResponse}
      SelectedProject={SelectedProject}
      setSelectedProject={setSelectedProject}
      setSelectedIssueID={setSelectedIssueID}
      From={From}
      handleClick_Home={handleClick_Home}
    />
  );

  const createIssuePage = (
    <EditIssue
      page={page}
      setPage={setPage}
      setStoreAuditToolResponse={setStoreAuditToolResponse}
      IssueActionType="CREATE"
      SelectedIssueID={SelectedIssueID}
      setSelectedIssueID={setSelectedIssueID}
      SelectedProject={SelectedProject}
      setSelectedProject={setSelectedProject}
      handleClick_Home={handleClick_Home}
    />
  );

  const createIssueMobilePage = (
    <EditIssueMobile
      page={page}
      setPage={setPage}
      setStoreAuditToolResponse={setStoreAuditToolResponse}
      IssueActionType="CREATE"
      SelectedIssueID={SelectedIssueID}
      setSelectedIssueID={setSelectedIssueID}
      From={From}
      SelectedProject={SelectedProject}
      setSelectedProject={setSelectedProject}
      handleClick_Home={handleClick_Home}
    />
  );

  const editIssuePage = (
    <EditIssue
      page={page}
      setPage={setPage}
      setStoreAuditToolResponse={setStoreAuditToolResponse}
      IssueActionType="EDIT"
      SelectedIssueID={SelectedIssueID}
      setSelectedIssueID={setSelectedIssueID}
      handleClick_Home={handleClick_Home}
    />
  );

  const editIssueMobilePage = (
    <EditIssueMobile
      page={page}
      setPage={setPage}
      setStoreAuditToolResponse={setStoreAuditToolResponse}
      IssueActionType="EDIT"
      SelectedIssueID={SelectedIssueID}
      setSelectedIssueID={setSelectedIssueID}
      handleClick_Home={handleClick_Home}
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "issue-list":
        return issueListPage;
      case "issue-create":
        return createIssuePage;
      case "issue-create-mobile":
        return createIssueMobilePage;
      case "issue-edit":
        return editIssuePage;
      case "issue-edit-mobile":
        return editIssueMobilePage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
