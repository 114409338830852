import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { useRef } from "react";
import { runFetch } from "../../functions/RunFetch";

export const PopupUpdate = (props) => {
  const [ConfirmResponse, setConfirmResponse] = useState("");

  const refWebBuffer = useRef();

  const handleClose = () => {
    setConfirmResponse("");

    props.setShow(false);
    props.setSelectedRow("");
  };

  const handleShow = () => {
    refWebBuffer.current.focus();
  };

  const handleClick_Update = () => {
    setConfirmResponse("");

    if (isNaN(refWebBuffer.current.value)) {
      setConfirmResponse("ERROR!: Web Buffer must be numeric.");
    } else {
      updateWebBuffer();
    }
  };

  async function updateWebBuffer() {
    const resObj = await runFetch(`${spURL}WebBuffer_Update`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          SubCategory: props.selectedRow.SubCategory,
          Buffer: refWebBuffer.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
    } else {
      props.setMenuResponse("OK!: Web buffer updated.");
      handleClose();
      props.pageLoad();
    }
  }

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Department:</strong>
              </p>
              <p>{props.selectedRow.Department}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Category:</strong>
              </p>
              <p>{props.selectedRow.Category}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>SubCategory:</strong>
              </p>
              <p>{props.selectedRow.SubCategory}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Old Web Buffer:</strong>
              </p>
              <p>{props.selectedRow.WebBuffer}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>New Web Buffer:</strong>
              </p>
              <input
                type="text"
                id="webbuffer-update-webbuffer"
                ref={refWebBuffer}
              />
            </div>
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Update}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
