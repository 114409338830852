import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { PopupErrorAcknowledge } from "./PopupErrorAcknowledge";
import { runFetch } from "../../functions/RunFetch";

export const PopupErrorList = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");
  const [ErrorList, setErrorList] = useState([]);
  const [SelectedErrorDescription, setSelectedErrorDescription] = useState("");

  const [showErrorAck, setShowErrorAck] = useState(false);

  const handleClose = () => {
    cleanPopup();
    props.pageLoad();
    props.setShow(false);
  };

  const handleShow = () => {
    pageLoad();
  };

  const cleanPopup = () => {
    setPopupResponse("");
    setErrorList([]);
  };

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL}POS_Ping_Status_UnacknowledgedErrorList_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreName: props.StoreName,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setErrorList(
          spResponse.Table.map((item, index) => ({
            Index: index,
            ErrorDescription: item.ErrorDescription,
          }))
        );
      } else {
        setPopupResponse("No unacknowledged error found.");
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        fullscreen={true}
        centered
      >
        <Modal.Body>
          <div>
            <p className="error-message">{PopupResponse}</p>
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>Error</th>
                </tr>
              </thead>
              <tbody>
                {ErrorList.length > 0 &&
                  ErrorList.map((item, index) => (
                    <tr
                      key={index}
                      errorindex={item.Index}
                      onClick={() => {
                        setPopupResponse("");
                        setSelectedErrorDescription(item.ErrorDescription);
                        setShowErrorAck(true);
                      }}
                    >
                      <td>{item.ErrorDescription}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <PopupErrorAcknowledge
              show={showErrorAck}
              setShow={setShowErrorAck}
              StoreName={props.StoreName}
              ErrorDescription={SelectedErrorDescription}
              setPopupResponse={setPopupResponse}
              setErrorList={setErrorList}
              pageLoad={pageLoad}
            />
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
