import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { runFetch } from "../../functions/RunFetch";

export const PopupConfirmBarcode = (props) => {
  const [MPQResponse, setMPQResponse] = useState("");

  const handleClose = () => {
    props.setShow(false);
    props.pageLoad();
  };

  const handleShow = () => {
    props.setResponse("");
  };

  const handleSubmit = async () => {
    const resObj = await runFetch(`${spURL}Handheld_ItemDetailAdmin_Item_Barcode_Overwrite`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Barcode: props.Barcode,
          OldItemNo: props.currentItemNo,
          NewItemNo: props.ItemNo,
        }),
      }),
    });

    if (resObj.response === null) {
      setMPQResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table[0].OutputString !== undefined) {
          handleClose();
          props.setResponse(spResponse.Table[0].OutputString);
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <p>
              <strong>Barcode already against other item. Move Barcode to new item?</strong>
            </p>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Current Item:</strong>
              </p>
              <p>{props.currentItemNo}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>New Item:</strong>
              </p>
              <p>{props.ItemNo}</p>
            </div>
            <p className="error-message">{MPQResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
            <button
              className="popup-button"
              onClick={handleSubmit}
            >
              <strong>Submit</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
