import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { useRef } from "react";
import { runFetch } from "../../functions/RunFetch";

export const PopupResendItem = (props) => {
  const [ConfirmResponse, setConfirmResponse] = useState("");
  const [ddlStore, setDDLStore] = useState("");

  const refResendQty = useRef();
  const refResendLocation = useRef();

  const handleClose = () => props.setShow(false);

  const handleShow = () => {
    getDDLStore();

    refResendQty.current.value = props.Resend.ResendQty;
  };

  async function getDDLStore() {
    const resObj = await runFetch(`${spURL}ResendOrders_DDLStore_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: "[{}]",
      }),
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
    } else {
      const spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setDDLStore(
          spResponse.Table.map((item, index) => (
            <option
              key={index}
              value={item.Store}
            >
              {item.Store}
            </option>
          ))
        );

        refResendLocation.current.value = props.ResendLocation;
      }
    }
  }

  const handleClick_Update = () => {
    props.handleOk_Resend(props.Resend.ItemNo, refResendQty.current.value);
    props.setResendLocation(refResendLocation.current.value);
    props.setShow(false);
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <strong>Set Resend Quantity for the item.</strong>
            <br />
            <div className="input-row">
              <p>
                <strong>ItemNo</strong>
              </p>
              <p>{props.Resend.ItemNo}</p>
            </div>
            <div className="input-row">
              <p>
                <strong>Order Qty:</strong>
              </p>
              <p>{props.Resend.OrderQty}</p>
            </div>
            <div className="input-row">
              <p>
                <strong>Resend Qty:</strong>
              </p>
              <input
                type="text"
                id="resendorders-resendqty"
                ref={refResendQty}
              />
            </div>
            <div className="input-row">
              <p>
                <strong>Resend Location:</strong>
              </p>
              <select
                name="store"
                id="resendorders-resendlocation"
                ref={refResendLocation}
              >
                {ddlStore}
              </select>
            </div>
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Update}
            >
              Update
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
