import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { ContentButton, FooterButton, MenuButton } from "../../components/HomepageButton";
import { purple, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { PopupExport } from "./PopupExport";
import { PopupCreate } from "./PopupCreate";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";

export const CompetitionDrawBody = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.MenuResponse);
  const [OpenDrawTable, setOpenDrawTable] = useState([]);
  const [ClosedDrawTable, setClosedDrawTable] = useState([]);
  const [AllowCreate, setAllowCreate] = useState(false);
  const [LoadingMessage, setLoadingMessage] = useState(false);

  const [showExport, setShowExport] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [CurrentDrawName, setCurrentDrawName] = useState("");
  const [CurrentStatus, setCurrentStatus] = useState("");
  const [CurrentPageTitle, setCurrentPageTitle] = useState("");
  const [CurrentURL, setCurrentURL] = useState("");

  const pageLoad = async () => {
    setLoadingMessage(true);

    const resObj = await runFetch(`${spURL_NoInputJSON}CompetitionDraw_Draw_Get`, {
      method: "POST",
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setOpenDrawTable(
          spResponse.Table.map((item, index) => (
            <tr
              key={index}
              onClick={() =>
                handleClick_Row({
                  DrawName: item.DrawName,
                  PageTitle: item.PageTitle,
                  URL: item.URL,
                  DrawStatus: "OPEN",
                })
              }
              className="btnHover background-lightgreen"
            >
              <td>{item.BCPage}</td>
              <td>{item.DrawName}</td>
              <td>{ConvertDateFormat(item.CreateDate)}</td>
            </tr>
          ))
        );
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setClosedDrawTable(
          spResponse.Table1.map((item, index) => (
            <tr
              key={index}
              onClick={() =>
                handleClick_Row({
                  DrawName: item.DrawName,
                  PageTitle: item.PageTitle,
                  URL: item.URL,
                  DrawStatus: "CLOSED",
                })
              }
              className="btnHover"
            >
              <td>{item.BCPage}</td>
              <td>{item.DrawName}</td>
              <td>{ConvertDateFormat(item.CreateDate)}</td>
              <td>{item.CloseDate === "" ? "" : ConvertDateFormat(item.CloseDate)}</td>
            </tr>
          ))
        );
      }

      if (spResponse.Table2 !== undefined && spResponse.Table2.length > 0) {
        setAllowCreate(spResponse.Table2[0].AllowCreate === "Y");
      }
    }
    setLoadingMessage(false);
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_Row = (rowObj) => {
    setMenuResponse("");

    setCurrentDrawName(rowObj.DrawName);
    setCurrentStatus(rowObj.DrawStatus);
    setCurrentPageTitle(rowObj.PageTitle);
    setCurrentURL(rowObj.URL);

    setShowExport(true);
  };

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <h4>
          <strong>Competition Draw List</strong>
        </h4>
        <p className="error-message">{MenuResponse}</p>
        <h5>
          <strong>Current Open Draw</strong>
        </h5>
        {LoadingMessage ? (
          <p>Loading...</p>
        ) : (
          OpenDrawTable && (
            <>
              {OpenDrawTable.length > 0 && (
                <table className="itemdetails-table">
                  <thead>
                    <tr>
                      <th>
                        <strong>BC Page</strong>
                      </th>
                      <th>
                        <strong>DrawName</strong>
                      </th>
                      <th>
                        <strong>Create Date</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{OpenDrawTable}</tbody>
                </table>
              )}
            </>
          )
        )}
        <br />
        {AllowCreate && (
          <div className="blockedorders-option-row">
            <MenuButton
              button="C"
              title="Create"
              colour={purple}
              onClick={() => {
                setMenuResponse("");
                setShowCreate(true);
              }}
            />
          </div>
        )}
        <h5>
          <strong>Past Closed Draws</strong>
        </h5>
        {LoadingMessage ? (
          <p>Loading...</p>
        ) : (
          ClosedDrawTable && (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>
                    <strong>BC Page</strong>
                  </th>
                  <th>
                    <strong>DrawName</strong>
                  </th>
                  <th>
                    <strong>Create Date</strong>
                  </th>
                  <th>
                    <strong>Close Date</strong>
                  </th>
                </tr>
              </thead>
              <tbody>{ClosedDrawTable}</tbody>
            </table>
          )
        )}

        <PopupCreate
          show={showCreate}
          setShow={setShowCreate}
          setMenuResponse={setMenuResponse}
          pageLoad={pageLoad}
        />

        <PopupExport
          show={showExport}
          setShow={setShowExport}
          DrawName={CurrentDrawName}
          Status={CurrentStatus}
          PageTitle={CurrentPageTitle}
          URL={CurrentURL}
          setMenuResponse={setMenuResponse}
          pageLoad={pageLoad}
        />
      </div>
      <div className="footer-container menu-container">
        <Link to="/Marketing">
          <FooterButton
            button="Back"
            colour={purple}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
      </div>
    </div>
  );
};
