import React, { useState, useEffect } from "react";
import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { Link } from "react-router-dom";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { PopupDiscount } from "./PopupDiscount";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";

export const Item = (props) => {
  const [ConfirmResponse, setConfirmResponse] = useState(props.ItemDetailsResponse);
  const [ItemDetails, setItemDetails] = useState("");
  const [edmTable, setEDMTable] = useState("");
  const [PriceMatchTable, setPriceMatchTable] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [ddlReason, setDDLReason] = useState("");
  const [isPriceMatchSelected, setIsPriceMatchSelected] = useState(
    props.selectedItem.ReasonCode === "PRICE MATCH"
  );
  const [showDiscount, setShowDiscount] = useState(false);

  useEffect(() => {
    getItemDetails();
  }, []);

  // get list of suburb from auspost api
  // POST request using fetch inside useEffect React hook

  async function getItemDetails() {
    const resObj = await runFetch(`${spURL}Phone_Orders_Item_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Barcode: props.selectedItem.Barcode,
        }),
      }),
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
    } else {
      const spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setItemDetails({
          ItemNo: spResponse.Table[0].ItemNo,
          ExtendedDescription: spResponse.Table[0].ExtendedDescription,
          Supplier: spResponse.Table[0].Supplier,
          ReorderNumber: spResponse.Table[0].ReorderNumber,
          Department: spResponse.Table[0].Department,
          Category: spResponse.Table[0].Category,
          SubCategory: spResponse.Table[0].SubCategory,
          Price: spResponse.Table[0].Price,
          MSRP: spResponse.Table[0].MSRP,
          ImageURL: spResponse.Table[0].ImageURL,
          WebURL: spResponse.Table[0].WebURL,
          SOH: spResponse.Table[0].SOH,
        });
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setEDMTable(
          spResponse.Table1.map((item, index) => (
            <option
              key={index}
              value={item.Promotion}
            >
              {item.Promotion}
            </option>
          ))
        );
      }

      if (spResponse.Table2 !== undefined && spResponse.Table2.length > 0) {
        setPriceMatchTable(
          spResponse.Table2.map((item, index) => ({
            SellerName: item["Seller Name"],
            Price: item.Price,
          }))
        );
      }

      if (spResponse.Table3 !== undefined && spResponse.Table3.length > 0) {
        setDiscountPrice({
          MinimumPrice: spResponse.Table3[0].MinimumPriceHidden,
          StandardDiscountPrice: spResponse.Table3[0].StandardDiscount,
          MaximumDiscount: spResponse.Table3[0].MaximumDiscount,
        });
      }

      if (spResponse.Table4 !== undefined && spResponse.Table4.length > 0) {
        setDDLReason(
          spResponse.Table4.map((item, index) => (
            <option
              key={index}
              value={item.Option}
            >
              {item.Option}
            </option>
          ))
        );
      }
    }

    document.getElementById("phoneorders-item-qty").value = props.selectedItem.Quantity;
    document.getElementById("phoneorders-item-price").value = props.selectedItem.Price;
    document.getElementById("phoneorders-item-reason").value = props.selectedItem.ReasonCode;
    document.getElementById("phoneorders-item-pricematchurl").value =
      props.selectedItem.PriceMatchURL === undefined ? "" : props.selectedItem.PriceMatchURL;
  }

  const handleClick_Back = () => {
    props.setMenuResponse("");
    props.setPage("main");
  };

  const handleClick_Discount = () => {
    setShowDiscount(true);
  };

  const handleChange_Reason = (event) => {
    const _reason = event.target.value;

    if (_reason === "PRICE MATCH") {
      setIsPriceMatchSelected(true);
    } else {
      setIsPriceMatchSelected(false);
    }
  };

  const handleClick_Ok = () => {
    const _price = document.getElementById("phoneorders-item-price").value;
    const _qty = document.getElementById("phoneorders-item-qty").value;
    const _reasoncode = document.getElementById("phoneorders-item-reason").value;
    const _matchurl = document.getElementById("phoneorders-item-pricematchurl").value;

    async function updateItem() {
      // load blocked orders table
      var spResponse = "";

      var data = new FormData();

      const inputJSON =
        `[{"OrderID":"${props.selectedItem.OrderID}"` +
        `,"ItemNo":"${ItemDetails.ItemNo}"` +
        `,"Price":"${_price}"` +
        `,"Quantity":"${_qty}"` +
        `,"Function":"UPDATE"` +
        `,"ReasonCode":"${_reasoncode}"` +
        `,"MatchURL":"${_matchurl}"}]`;

      data.append("inputJSON", inputJSON);

      console.log(inputJSON);

      //console.log(inputJSON);

      // POST request using fetch inside useEffect React hook
      const requestOptions = {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: inputJSON,
        }),
      };

      const res = await trackPromise(fetch(spURL + "Phone_Orders_Item_Update", requestOptions));
      res.json().then((data) => {
        spResponse = JSON.parse(data.response);

        if (data.response === null) {
          //console.log(data.exception);

          setConfirmResponse(data.exception);
        } else {
          spResponse = JSON.parse(data.response);

          //console.log(spResponse);

          if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
            if (spResponse.Table[0].Output.includes("OK: ")) {
              props.setMenuResponse(spResponse.Table[0].Output);
              props.setPage("main");
            } else {
              setConfirmResponse(spResponse.Table[0].Output);
            }
          }
        }
      });
    }

    var discountprice = 0;

    switch (_reasoncode) {
      case "PRICE MATCH":
        discountprice = discountPrice.MinimumPrice;
        break;
      case "DISCOUNT":
        discountprice = discountPrice.MaximumDiscount;
        break;
      default:
        discountprice = discountPrice.MaximumDiscount;
        break;
    }

    if (parseFloat(_price) >= parseFloat(discountprice)) {
      if (_reasoncode !== "") {
        if (_matchurl === "" && _reasoncode === "PRICE MATCH") {
          setConfirmResponse("URL field required");
        } else {
          updateItem();
        }
      } else {
        setConfirmResponse("Reason field required");
      }
    } else {
      setConfirmResponse("Price too low");
    }
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="itemdetails-image-itemno-container">
          <img
            src={ItemDetails.ImageURL}
            alt="Item"
            width="100"
          />
          <div>
            <p>
              <strong>{ItemDetails.ItemNo}</strong>
            </p>
            <p>
              <strong>{ItemDetails.ReorderNumber}</strong>
            </p>
            <p>{ItemDetails.Supplier}</p>
          </div>
        </div>

        <div className="itemdetails-description-container">
          <a
            className="itemdetails-description-link"
            href={ItemDetails.WebURL === "" ? "" : "https://toymate.com.au" + ItemDetails.WebURL}
            target="_blank"
          >
            <strong>{ItemDetails.ExtendedDescription}</strong>
          </a>
        </div>

        <div>
          <p>{ItemDetails.Department}</p>
          <p>
            {">"}
            {ItemDetails.Category}
          </p>
          <p>
            {">>"}
            {ItemDetails.SubCategory}
          </p>
        </div>

        <div className="itemdetails-price-container">
          <p>
            <strong>
              {convertToCurrency(ItemDetails.Price, "$")} (MSRP:{" "}
              {convertToCurrency(ItemDetails.MSRP, "$")})
            </strong>
          </p>
        </div>

        <div className="itemdetails-soo-sot-container">
          <div
            className="itemdetails-soo-button"
            onClick={handleClick_Discount}
          >
            <strong>5% Discount Price</strong>
            <br />
            {discountPrice.StandardDiscountPrice}
          </div>
          <div className="phoneorders-onlinestock">
            <strong>Online Stock</strong>
            <br />
            {ItemDetails.SOH}
          </div>
        </div>
        <br />
        <div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Quantity:</strong>
            </p>
            <input
              type="text"
              id="phoneorders-item-qty"
            />
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Price:</strong>
            </p>
            <input
              type="text"
              id="phoneorders-item-price"
            />
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Reason:</strong>
            </p>
            <select
              name="reason"
              id="phoneorders-item-reason"
              onChange={handleChange_Reason}
            >
              <option value=""></option>
              {ddlReason}
            </select>
          </div>
          <div className={isPriceMatchSelected ? "stocktake-create-input-row" : "hidden"}>
            <p>
              <strong>Price Match URL:</strong>
            </p>
            <input
              type="text"
              id="phoneorders-item-pricematchurl"
            />
          </div>
        </div>
        <br />
        <div className="blockedorders-option-row">
          <MenuButton
            title=""
            colour={green}
            button="Ok"
            onClick={handleClick_Ok}
          />
        </div>

        <p className="error-message">{ConfirmResponse}</p>

        <PopupDiscount
          show={showDiscount}
          setShow={setShowDiscount}
          setPage={props.setPage}
          PriceMatchTable={PriceMatchTable}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={green}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
