import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCirclePause,
  faClock,
  faPenToSquare,
  faSquarePlus,
} from "@fortawesome/free-regular-svg-icons";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, yellow } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PopupBookingDetails } from "./PopupBookingDetails";
import { PopupBlockReason } from "./PopupBlockReason";
import { PopupDeleteTimeslot } from "./PopupDeleteTimeslot";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { PopupChangeTimeslot } from "./PopupChangeTimeslot";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

library.add(faCirclePause);
library.add(faSquarePlus);
library.add(faPenToSquare);
library.add(faXmark);
library.add(faClock);

export const PopupDeliveries = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [PopupResponse, setPopupResponse] = useState("");
  const [BookingList, setBookingList] = useState([]);

  const [showCreate, setShowCreate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showChangeTimeslot, setShowChangeTimeslot] = useState(false);

  const [DeleteBookingID, setDeleteBookingID] = useState("");

  const emptyEditBookingDetails = {
    BookingID: "",
    StaffName: "",
    Supplier: "",
    Carrier: "",
    PalletQty: "",
    CartonQty: "",
    PalletType: "",
    PalletExchangeYN: "",
  };
  const [EditBookingDetails, setEditBookingDetails] = useState(emptyEditBookingDetails);
  const [ChangeTimeslotBookingID, setChangeTimeslotBookingID] = useState("");

  const handleClose = () => {
    setBookingList([]);
    setEditBookingDetails(emptyEditBookingDetails);
    setDeleteBookingID("");
    setChangeTimeslotBookingID("");
    setPopupResponse("");
    props.getDeliveries();
    props.setShow(false);
  };

  const handleShow = async () => {
    getBookingList();
  };

  const getBookingList = async () => {
    const resObj = await runFetch(`${spURL}Handheld_IncomingDeliveries_Deliveries_Details_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          DeliveryDate: props.selectedDate,
          DeliveryInterval: props.selectedInterval,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setBookingList(
          spResponse.Table.map((item) => ({
            BookingID: item.BookingID,
            StaffName: item.StaffName,
            Supplier: item.Supplier,
            Carrier: item.Carrier,
            PalletQty: item.PalletQty,
            CartonQty: item.CartonQty,
            PalletType: item.PalletType,
            PalletExchangeYN: item.PalletExchangeYN,
          }))
        );
      } else {
        setBookingList([]);
      }
    }
  };

  useEffect(() => {
    if (props.selectedDate !== "" && props.selectedInterval !== "") {
      getBookingList();
    }
  }, [props.selectedDate, props.selectedInterval]);

  const handleClick_Block = () => {
    setShowReason(true);
  };

  const handleClick_Delete = (event) => {
    const clickElement = event.target.tagName.toUpperCase();
    let _bookingID = "";

    switch (clickElement) {
      case "PATH":
        _bookingID =
          event.target.parentElement.parentElement.parentElement.attributes["bookingid"].value;
        break;
      case "SVG":
        _bookingID = event.target.parentElement.parentElement.attributes["bookingid"].value;
        break;
      case "TD":
        _bookingID = event.target.parentElement.attributes["bookingid"].value;
        break;
      case "TR":
        _bookingID = event.target.attributes["bookingid"].value;
        break;
      default:
        break;
    }

    setDeleteBookingID(_bookingID);
    setShowDelete(true);
  };

  const handleClick_Edit = (event) => {
    const clickElement = event.target.tagName.toUpperCase();
    let _bookingID = "";

    switch (clickElement) {
      case "PATH":
        _bookingID =
          event.target.parentElement.parentElement.parentElement.attributes["bookingid"].value;
        break;
      case "SVG":
        _bookingID = event.target.parentElement.parentElement.attributes["bookingid"].value;
        break;
      case "TD":
        _bookingID = event.target.parentElement.attributes["bookingid"].value;
        break;
      case "TR":
        _bookingID = event.target.attributes["bookingid"].value;
        break;
      default:
        break;
    }

    setEditBookingDetails(
      BookingList.filter((item) => item.BookingID.toString() === _bookingID.toString())[0]
    );
    setShowEdit(true);
  };

  const handleClick_Create = () => {
    setShowCreate(true);
  };

  const handleClick_ChangeTimeslot = (event) => {
    const clickElement = event.target.tagName.toUpperCase();
    let _bookingID = "";

    switch (clickElement) {
      case "PATH":
        _bookingID =
          event.target.parentElement.parentElement.parentElement.attributes["bookingid"].value;
        break;
      case "SVG":
        _bookingID = event.target.parentElement.parentElement.attributes["bookingid"].value;
        break;
      case "TD":
        _bookingID = event.target.parentElement.attributes["bookingid"].value;
        break;
      case "TR":
        _bookingID = event.target.attributes["bookingid"].value;
        break;
      default:
        break;
    }

    setChangeTimeslotBookingID(_bookingID);
    setShowChangeTimeslot(true);
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <Modal.Body>
          <div className="incomingdeliveries-popup-container">
            <div className="popup-header-row">
              <p>
                <strong>{`${props.selectedDate} ${props.selectedInterval}`}</strong>
              </p>
            </div>
            <br />
            <div className="delivery-details-table-container">
              {BookingList.length === 0 ? (
                ""
              ) : (
                <table className="delivery-details-table margin-auto">
                  <thead>
                    <tr>
                      <th>Staff Name</th>
                      <th>Supplier</th>
                      <th>Carrier</th>
                      <th>Pallet Qty</th>
                      <th>Carton Qty</th>
                      <th>Pallet Type</th>
                      <th>Pallet exchange?</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {BookingList.map((item, index) => (
                      <tr
                        key={index}
                        bookingid={item.BookingID}
                      >
                        <td>{item.StaffName}</td>
                        <td>{item.Supplier}</td>
                        <td>{item.Carrier}</td>
                        <td>{item.PalletQty}</td>
                        <td>{item.CartonQty}</td>
                        <td>{item.PalletType}</td>
                        <td>{item.PalletExchangeYN}</td>
                        <td>
                          {item.BookingID === 0 ? null : (
                            <>
                              <FontAwesomeIcon
                                className="btnHover footer-icon"
                                size="2x"
                                icon="fa-solid fa-xmark"
                                onClick={handleClick_Delete}
                              />
                              <FontAwesomeIcon
                                className="btnHover footer-icon"
                                size="2x"
                                icon="fa-regular fa-pen-to-square"
                                onClick={handleClick_Edit}
                              />
                              <FontAwesomeIcon
                                className="btnHover footer-icon"
                                size="2x"
                                icon="fa-regular fa-clock"
                                onClick={handleClick_ChangeTimeslot}
                              />
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <br />
            <div className="blockedorders-option-row">
              <FontAwesomeIcon
                className="btnHover footer-icon"
                size="3x"
                icon="fa-regular fa-circle-pause"
                onClick={handleClick_Block}
              />
              <FontAwesomeIcon
                className="btnHover footer-icon"
                size="3x"
                icon="fa-regular fa-square-plus"
                onClick={handleClick_Create}
              />
            </div>
            <p className="error-message">{PopupResponse}</p>

            <PopupBookingDetails
              show={showCreate}
              setShow={setShowCreate}
              from="CREATE"
              EditBookingDetails=""
              selectedDate={props.selectedDate}
              selectedInterval={props.selectedInterval}
              setPopupResponse={setPopupResponse}
              getBookingList={getBookingList}
            />

            <PopupBookingDetails
              show={showEdit}
              setShow={setShowEdit}
              from="EDIT"
              EditBookingDetails={EditBookingDetails}
              selectedDate={props.selectedDate}
              selectedInterval={props.selectedInterval}
              setPopupResponse={setPopupResponse}
              getBookingList={getBookingList}
            />

            <PopupBlockReason
              show={showReason}
              setShow={setShowReason}
              closePopup={handleClose}
              setIncomingDeliveriesResponse={props.setIncomingDeliveriesResponse}
              getDeliveries={props.getDeliveries}
              setPopupResponse={setPopupResponse}
              selectedDate={props.selectedDate}
              selectedInterval={props.selectedInterval}
            />

            <PopupDeleteTimeslot
              show={showDelete}
              setShow={setShowDelete}
              setPopupResponse={setPopupResponse}
              getBookingList={getBookingList}
              DeleteBookingID={DeleteBookingID}
            />

            <PopupChangeTimeslot
              show={showChangeTimeslot}
              setShow={setShowChangeTimeslot}
              closePopup={handleClose}
              setIncomingDeliveriesResponse={props.setIncomingDeliveriesResponse}
              getDeliveries={props.getDeliveries}
              setPopupResponse={setPopupResponse}
              selectedDate={props.selectedDate}
              selectedInterval={props.selectedInterval}
              ChangeTimeslotBookingID={ChangeTimeslotBookingID}
            />
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
