import { trackPromise } from 'react-promise-tracker';

export async function runFetch(url, reqOpt) {
    try {
        const res = await trackPromise(fetch(url, reqOpt));
        return res
            .json()
            .then(data => {
                return data;
            })
            .catch(err => {
                return JSON.stringify({
                    status: 'ERROR!',
                    exception: `ERROR!: ${err.message}`,
                    response: ''
                });
            });
    } catch (err) {
        return JSON.stringify({
            status: 'ERROR!',
            exception: `ERROR!: Server error. Please try again later.`,
            response: ''
        });
    }
}

export async function runLabelFetch(url, reqOpt, cbSuccess, cbErr) {
    await trackPromise(
        fetch(url, reqOpt)
            .then(async (resp) => {
                return {
                    ok: resp.ok,
                    body: await resp.json()
                }
            })
            .then(async (resp) => {
                if (resp.ok) {
                    await cbSuccess(resp.body);
                } else {
                    await cbErr(resp.body.ExceptionMessage);
                }
            })
            .catch((err) => {
                cbErr(err);
            })
    );
}

export async function checkIfOnline() {
    try {
        const res = await trackPromise(fetch('https://tmapi-handheld.azurewebsites.net/api/values/test/internet'));
        return res
            .json()
            .then(data => {
                return JSON.stringify({
                    status: 'OK!',
                    exception: '',
                    response: ''
                });
            })
            .catch(err => {
                return JSON.stringify({
                    status: 'ERROR!',
                    exception: `ERROR!: Internet is currently down.`,
                    response: ''
                });
            });
    } catch (err) {
        return JSON.stringify({
            status: 'ERROR!',
            exception: `ERROR!: Internet is currently down.`,
            response: ''
        });
    }
}