import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { MenuButton, FooterButton, ExcelFileButton } from "../../components/HomepageButton";
import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";

export const SearchTermManageBody = () => {
  const [MenuResponse, setMenuResponse] = useState("");

  const [CurrentImportOption, setCurrentImportOption] = useState("");

  const pageLoad = () => {};

  const handleClick_Download = async () => {
    var XLSX = require("xlsx");
    setMenuResponse("");

    const resObj = await runFetch(`${spURL_NoInputJSON}EComm_SearchTermManage_SearchTerms_Get`, {
      method: "POST",
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        let workbook = XLSX.utils.book_new();

        const date_now = new Date().toLocaleDateString();
        const filename = `SearchTerm_${date_now}.xlsx`;

        let worksheet = XLSX.utils.json_to_sheet(
          spResponse.Table.map((item) => ({
            SearchTerm: item.SearchTerm,
            Category: "",
            SubCat: "",
          }))
        );

        XLSX.utils.book_append_sheet(workbook, worksheet, "Search Terms List");

        await XLSX.writeFileXLSX(workbook, filename);
      } else {
        setMenuResponse("No uncategorised search terms.");
      }
    }
  };

  const importExcel = (file) => {
    var XLSX = require("xlsx");

    var uploadedSearchTerms = "";

    var reader = new FileReader();
    reader.onload = async function (e) {
      try {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 0 });

        /* Convert array to json*/
        if (CurrentImportOption === "STC") {
          uploadedSearchTerms = dataParse.filter(
            (item) => item.Category !== "" || item.SubCat !== ""
          );
        } else if (CurrentImportOption === "SZS") {
          uploadedSearchTerms = dataParse.map((item) => ({
            SearchTerm: item["Query"],
            UniqueSearchesAmount: item["Total Searches"],
          }));
        } else if (CurrentImportOption === "SSR") {
          uploadedSearchTerms = dataParse.map((item) => ({
            SearchTerm: item["Query"],
            UniqueSearchesAmount: item["Total Searches"],
          }));
        }

        let updateMode = "";
        switch (CurrentImportOption) {
          case "STC":
            updateMode = "SearchTermsCategorisation";
            break;
          case "SZS":
            updateMode = "SearchSpringZeroSearches";
            break;
          case "SSR":
            updateMode = "SearchSpringSearchResults";
            break;
          default:
            break;
        }

        const resObj = await runFetch(`${spURL}EComm_SearchTermManage_${updateMode}_Import`, {
          method: "POST",
          body: new URLSearchParams({
            inputJSON: JSON.stringify(uploadedSearchTerms).replace(`'`, ``),
          }),
        });

        if (resObj.response === null) {
          setMenuResponse(resObj.exception);
        } else {
          let spResponse = JSON.parse(resObj.response);

          if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
            setMenuResponse(spResponse.Table[0].OutputString);
          } else {
            setMenuResponse("ERROR!: Import failed.");
          }
        }
      } catch (error) {
        setMenuResponse(error.message);
      }
    };

    reader.readAsBinaryString(file);

    document.getElementById("imported-excel").value = "";
  };

  const handleChange_Import = (e) => {
    setMenuResponse("");

    var files = e.target.files,
      f = files[0];

    if ([".xlsx", ".xls", ".csv"].includes(e.target.value)) {
      importExcel(f);
    } else {
      setMenuResponse("ERROR!: File type not supported. XLSX or XLS filetype only.");
    }
  };

  const handleChange_ImportOption_Categorisation = () => {
    setCurrentImportOption("STC");
    document.getElementById("searchtermmanage-radio-searchtermcategorisation").checked = true;
  };

  const handleChange_ImportOption_ZeroSearches = () => {
    setCurrentImportOption("SZS");
    document.getElementById("searchtermmanage-radio-searchspringzerosearches").checked = true;
  };

  const handleChange_ImportOption_SearchResults = () => {
    setCurrentImportOption("SSR");
    document.getElementById("searchtermmanage-radio-searchspringsearchresults").checked = true;
  };

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <div className="page-container disable-select">
      <div className="desktop-body-container">
        <h6>
          <strong>
            Click 'Download' button to download template, 'Import' button to import Excel template.
          </strong>
        </h6>
        <br />
        <p>
          <strong>Import Options:</strong>
        </p>
        <div className="searchtermmanage-top-row">
          <div
            className="blockedorders-input-row btnHover"
            onClick={handleChange_ImportOption_ZeroSearches}
          >
            <input
              type="radio"
              name="import-option"
              id="searchtermmanage-radio-searchspringzerosearches"
              defaultChecked
            />
            <p>
              <strong>FastSimon Zero Searches</strong>
            </p>
          </div>
          <div
            className="blockedorders-input-row btnHover"
            onClick={handleChange_ImportOption_SearchResults}
          >
            <input
              type="radio"
              name="import-option"
              id="searchtermmanage-radio-searchspringsearchresults"
            />
            <p>
              <strong>FastSimon Search Results</strong>
            </p>
          </div>
          <div
            className="blockedorders-input-row btnHover"
            onClick={handleChange_ImportOption_Categorisation}
          >
            <input
              type="radio"
              name="import-option"
              id="searchtermmanage-radio-searchtermcategorisation"
            />
            <p>
              <strong>Search Term Categorisation</strong>
            </p>
          </div>
        </div>
        <br />
        <div className="blockedorders-content-container">
          <div className="blockedorders-option-row">
            <MenuButton
              title="Download"
              colour={green}
              button="D"
              onClick={handleClick_Download}
            />
            <ExcelFileButton
              id="imported-excel"
              onChange={handleChange_Import}
              title="Import"
              colour={green}
              button="I"
            />
          </div>
          <br />
          <p className="error-message">{MenuResponse}</p>
        </div>
      </div>
      <div className="footer-container menu-container">
        <Link to="/EComm">
          <FooterButton
            button="Back"
            colour={green}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
