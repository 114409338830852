import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PopupHistory } from "./PopupHistory";
import { PopupQuantityScan } from "./PopupQuantityScan";

import ErrorSound from "./../../assets/sound/WindowsError.wav";

import useSound from "use-sound";
import { red, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { ItemBarcode } from "../../components/ItemBarcode";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";
import { useRef } from "react";

export const Scan = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [StockTakeResponse, setStockTakeResponse] = useState("");

  const [isStockTakeSelected, setIsStockTakeSelected] = useState(false);
  const [currentStockTake, setCurrentStockTake] = useState({
    StockTake: "",
    BayNo: "",
  });

  const [currentMode, setCurrentMode] = useState("QUICK");

  const [popupBarcode, setPopupBarcode] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const [showHistory, setShowHistory] = useState(false);

  const [play] = useSound(ErrorSound);

  const refBayNo = useRef();

  useEffect(() => {
    setCurrentStockTake((values) => ({
      ...values,
      StockTake: props.CurrentStockTake,
    }));

    setStockTakeResponse("");
  }, []);

  const handleClick_Select = async () => {
    setStockTakeResponse("");

    async function lockBay() {
      const resObj = await runFetch(`${spURL}Stocktake_Function_Process`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Store: storeno,
            BayNo: currentStockTake.BayNo,
            StockTake: currentStockTake.StockTake,
            Function: "LOCK BAY",
            Pin: "",
            From: "QUICK",
            Quantity: "",
          }),
        }),
      });

      if (resObj.response === null) {
        setStockTakeResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          if (spResponse.Table.length > 0) {
            const _response = spResponse.Table[0].OutputString;

            setStockTakeResponse(_response);

            if (_response === "OK!: Stocktake Bay Locked.") {
              setCurrentStockTake((values) => ({
                ...values,
                BayNo: "",
              }));

              if (currentMode === "QUANTITY") {
                handleClick_Toggle();
              }
            }
          }
        }
      }
    }

    if (isStockTakeSelected) {
      await lockBay();
      setIsStockTakeSelected((values) => !values);
    } else {
      let bayNo = refBayNo.current.value;

      if (bayNo !== "") {
        setCurrentStockTake((values) => ({
          ...values,
          BayNo: bayNo,
        }));
        setIsStockTakeSelected((values) => !values);
      } else {
        setStockTakeResponse("ERROR!: No StockTake or Bay selected.");
      }
    }
  };

  const handleClick_Back = () => {
    document.getElementById("root").classList.remove("background-lightblue");

    if (currentMode === "QUANTITY") {
      handleClick_Toggle();
    }

    props.setPage("menu");
  };

  const handleClick_Barcode = async () => {
    async function getAddItem() {
      const _barcode = document.getElementById("itembarcode-barcode").value;

      const resObj = await runFetch(`${spURL}StockTake_Item_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            Barcode: _barcode,
            StockTakeID: currentStockTake.StockTkae,
            BayNo: currentStockTake.BayNo,
          }),
        }),
      });

      if (resObj.response === null) {
        setStockTakeResponse(resObj.exception);
        play();
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          await addItem(spResponse.Table[0].ItemNo);
        } else {
          setStockTakeResponse("ERROR!: Item not found.");
          play();
        }
      }
      clearBarcode();
    }

    async function addItem(itemno) {
      const resObj = await runFetch(`${spURL}StockTake_Add_Count`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            Store: storeno,
            ItemNo: itemno,
            StockTake: currentStockTake.StockTake,
            Quantity: 1,
            UserID: userid,
            BayNo: currentStockTake.BayNo,
          }),
        }),
      });

      if (resObj.response === null) {
        setStockTakeResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          if (spResponse.Table.length > 0) {
            setStockTakeResponse(spResponse.Table[0].OutputString);
          }

          if (spResponse.Table[0].OutputString.includes("OK!:") === false) {
            play();
          }
        }
      }
    }

    async function checkBlocked() {
      const _barcode = document.getElementById("itembarcode-barcode").value;

      const resObj = await runFetch(`${spURL}Stocktake_QuantityCount_Blocklist_Check`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
            StockTake: currentStockTake.StockTake,
            Barcode: _barcode,
            BayNo: currentStockTake.BayNo,
          }),
        }),
      });

      if (resObj.response === null) {
        setStockTakeResponse("ERROR!: This sub category can not be quantity scanned.");
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          if (spResponse.Table[0].OutputString === "OK!") {
            setPopupBarcode(_barcode);

            setShowPopup(true);
          }
        } else {
          setStockTakeResponse("ERROR!: This sub category can not be quantity scanned.");
        }
      }
    }

    if (currentMode === "QUICK") {
      await getAddItem();
    } else if (currentMode === "QUANTITY") {
      // popup for quantity scan
      await checkBlocked();
    }
  };

  const handleClick_Toggle = () => {
    if (currentMode === "QUICK") {
      setCurrentMode("QUANTITY");

      document.getElementById("root").className = "background-lightblue";
    } else {
      setCurrentMode("QUICK");
      document.getElementById("root").className = "";
    }

    document.getElementById("itembarcode-barcode").focus();
  };

  const handleClick_History = () => {
    setShowHistory(true);
  };

  const clearBarcode = () => {
    document.getElementById("itembarcode-barcode").value = "";
    document.getElementById("itembarcode-barcode").focus();
  };

  return (
    <div
      className="page-container"
      id="modeBackground"
    >
      <div className="handheld-body-container">
        <div>
          {isStockTakeSelected ? (
            <>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Stock Take:</strong>
                </p>
                <p>{currentStockTake.StockTake}</p>
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Bay:</strong>
                </p>
                <p>{currentStockTake.BayNo}</p>
              </div>
            </>
          ) : (
            <>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Stock Take:</strong>
                </p>
                <p>{currentStockTake.StockTake}</p>
              </div>
              <div className="stocktake-create-input-row">
                <p>
                  <strong>Bay:</strong>
                </p>
                <input
                  type="text"
                  id="stocktake-scan-bayno"
                  ref={refBayNo}
                />
              </div>
            </>
          )}

          <p className="error-message">{StockTakeResponse}</p>

          {isStockTakeSelected ? (
            <></>
          ) : (
            <button
              className="signin-login-button btnHover"
              onClick={handleClick_Select}
            >
              Select
            </button>
          )}

          {isStockTakeSelected && (
            <div>
              <br />
              <ItemBarcode barcodeClick={handleClick_Barcode} />
              <br />
              <div className="input-row">
                <p>
                  <strong>Current Mode:</strong>
                </p>
                <p>{currentMode}</p>
              </div>
              <br />
              <div className="blockedorders-option-row">
                <MenuButton
                  title="History"
                  colour={red}
                  button="H"
                  onClick={handleClick_History}
                />
                <MenuButton
                  title="Change Bay"
                  colour={red}
                  button="CB"
                  onClick={handleClick_Select}
                />
                <MenuButton
                  title="Toggle Mode"
                  colour={red}
                  button="TM"
                  onClick={handleClick_Toggle}
                />
              </div>
            </div>
          )}

          <PopupQuantityScan
            show={showPopup}
            setShow={setShowPopup}
            setPage={props.setPage}
            Barcode={popupBarcode}
            StockTake={currentStockTake.StockTake}
            BayNo={currentStockTake.BayNo}
            handleClick_Toggle={handleClick_Toggle}
            clearBarcode={clearBarcode}
            setStockTakeResponse={setStockTakeResponse}
            play={play}
          />

          <PopupHistory
            show={showHistory}
            setShow={setShowHistory}
            StocktakeID={currentStockTake.StockTake}
            BayNo={currentStockTake.BayNo}
            setStockTakeResponse={setStockTakeResponse}
          />
        </div>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
            onClick={() => document.getElementById("root").classList.remove("background-lightblue")}
          />
        </Link>
      </div>
    </div>
  );
};
