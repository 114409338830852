import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { red, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";

import { ItemBarcode } from "../../components/ItemBarcode";
import { PopupItemSearch } from "./PopupItemSearch";

export const Barcode = (props) => {
  useEffect(() => {
    props.setItemDetailsResponse("");
  }, []);

  const [showSearch, setShowSearch] = useState(false);

  const onBarcode_Click = () => {
    const barcode = document.getElementById("itembarcode-barcode").value;
    props.exeBarcodeClick(barcode);
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <ItemBarcode
          barcodeClick={onBarcode_Click}
          ItemDetails={true}
          setShow={setShowSearch}
        />
        <p
          className="error-message"
          id="ItemDetailsResponse"
        >
          {props.ItemDetailsResponse}
        </p>

        <PopupItemSearch
          show={showSearch}
          setShow={setShowSearch}
          exeBarcodeClick={props.exeBarcodeClick}
        />
      </div>
      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={red}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
