import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { red, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { runFetch } from "../../functions/RunFetch";
import { PopupConfirmAddBarcode } from "./PopupConfirmAddBarcode";
import { PopupConfirmBarcode } from "./PopupConfirmBarcode";

export const ItemBarcode = (props) => {
  const [barcodeTable, setBarcodeTable] = useState([]);

  const [response, setResponse] = useState("");

  const [show, setShow] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const [currentItemNo, setCurrentItemNo] = useState("");
  const [barcode, setBarcode] = useState("");

  const [selectedBarcode, setSelectedBarcode] = useState([]);

  const refBarcode = useRef();

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL}Handheld_ItemDetailAdmin_Item_Barcode_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          ItemNo: props.ItemNo,
        }),
      }),
    });

    if (resObj.response === null) {
      props.setPage("barcode");
      props.setItemDetailsResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setBarcodeTable(
          spResponse.Table.map((item) => ({
            GTIN: item.GTIN,
            Barcode: item.Barcode,
          }))
        );
      }
    }
    refBarcode.current.select();
  };

  const handleClick_Add = async () => {
    const resObj = await runFetch(`${spURL}Handheld_ItemDetailAdmin_Item_Barcode_Add`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          ItemNo: props.ItemNo,
          Barcode: refBarcode.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setResponse(resObj.exception);
      refBarcode.current.select();
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        if (spResponse.Table[0].OutputString === "Added") {
          setResponse("SUCCESS!: Barcode added to item.");
          pageLoad();
        } else if (spResponse.Table[0].OutputString === "Other") {
          setCurrentItemNo(spResponse.Table[0].CurrentItem);
          setShow(true);
        } else if (spResponse.Table[0].OutputString === "InvalidChar") {
          setShowAdd(true);
        }
      }
    }
    setBarcode(refBarcode.current.value);
  };

  useEffect(() => {
    pageLoad();
  }, []);

  const handleClick_SOH_Back = () => {
    props.setPage("details");
  };

  const handleClick_Delete = async () => {
    const resObj = await runFetch(`${spURL}Handheld_ItemDetailAdmin_Item_Barcode_Delete`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(selectedBarcode),
      }),
    });

    if (resObj.response === null) {
      setResponse(resObj.exception);
      refBarcode.current.select();
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setResponse(spResponse.Table[0].OutputString);
        pageLoad();
      }
    }
  };

  const handleClick_GTIN = async () => {
    if (selectedBarcode.length !== 1) {
      setResponse("Please select only one barcode to set as GTIN");
    } else {
      const resObj = await runFetch(`${spURL}Handheld_ItemDetailAdmin_Item_GTIN_Update`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(selectedBarcode),
        }),
      });

      if (resObj.response === null) {
        setResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setResponse(spResponse.Table[0].OutputString);
          pageLoad();
        }
      }
    }
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <p>
          <strong>Item: {props.ItemNo}</strong>
        </p>
        <div className="stocktake-create-input-row">
          <p>
            <strong>Barcode:</strong>
          </p>
          <input
            type="text"
            id="itemdetails-itembarcode-barcode"
            ref={refBarcode}
            onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Add)}
          />
        </div>
        <button
          className="signin-login-button"
          id="itemdetails-itembarcode-enter"
          onClick={handleClick_Add}
        >
          Add
        </button>
        <div>
          {barcodeTable.length > 0 && (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>Barcode</th>
                </tr>
              </thead>
              <tbody>
                {barcodeTable.map((item, index) => (
                  <tr
                    key={index}
                    gtin={item.GTIN}
                    barcode={item.Barcode}
                    className={`btnHover ${
                      selectedBarcode.map((item) => item.Barcode).includes(item.Barcode)
                        ? "background-lightblue"
                        : item.GTIN === "Y"
                        ? "background-lightgreen"
                        : "background-white"
                    }`}
                    onClick={() => {
                      const thisBarcode = item.Barcode;
                      const wasRowSelected = selectedBarcode
                        .map((item) => item.Barcode)
                        .includes(item.Barcode);

                      if (wasRowSelected) {
                        setSelectedBarcode((values) =>
                          values.filter((item) => item.Barcode !== thisBarcode)
                        );
                      } else {
                        setSelectedBarcode((values) => [
                          ...values,
                          {
                            ItemNo: props.ItemNo,
                            Barcode: thisBarcode,
                          },
                        ]);
                      }
                    }}
                  >
                    <td
                      className="btnHover"
                      data-tip
                      data-for="on-hand"
                    >
                      {item.Barcode}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <br />
        <div className="blockedorders-option-row">
          <MenuButton
            title="Delete"
            colour={red}
            button="D"
            onClick={handleClick_Delete}
          />
          <MenuButton
            title="GTIN"
            colour={red}
            button="G"
            onClick={handleClick_GTIN}
          />
        </div>
        <br />
        <p className="error-message">{response}</p>

        <PopupConfirmBarcode
          show={show}
          setShow={setShow}
          currentItemNo={currentItemNo}
          pageLoad={pageLoad}
          Barcode={barcode}
          ItemNo={props.ItemNo}
          response={response}
          setResponse={setResponse}
        />

        <PopupConfirmAddBarcode
          show={showAdd}
          setShow={setShowAdd}
          pageLoad={pageLoad}
          Barcode={barcode}
          ItemNo={props.ItemNo}
          response={response}
          setResponse={setResponse}
        />
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_SOH_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
