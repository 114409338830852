import React, { useEffect, useRef, useState } from "react";
import Barcode from "react-barcode";
import { exportComponentAsPNG } from "react-component-export-image";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { purple, spURL, spURL_NoInputJSON } from "../../App";
import { ContentButton, FooterButton, MenuButton } from "../../components/HomepageButton";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";
import { PopupChangePassword } from "./PopupChangePassword";

export const Edit = () => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [EditResponse, setEditResponse] = useState("");

  const [ddlPermissionGroup, setDDLPermissionGroup] = useState([]);

  const [Email, setEmail] = useState("");

  const [StaffID, setStaffID] = useState("");
  const [SearchStaffID, setSearchStaffID] = useState("");
  const [SearchStaffName, setSearchStaffName] = useState("");
  const [ddlStaffName, setDDLStaffName] = useState([]);

  const [StaffBarcode, setStaffBarcode] = useState("");

  const [showChangePassword, setShowChangePassword] = useState(false);

  const refFirstName = useRef();
  const refLastName = useRef();
  const refNameOnReceipt = useRef();
  const refPermissionGroup = useRef();

  useEffect(() => {
    async function getPermissionGroup() {
      const resObj = await runFetch(
        `${spURL_NoInputJSON}Handheld_StaffManage_PermissionGroup_Get`,
        {
          method: "POST",
        }
      );

      if (resObj.response === null) {
        setEditResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          setDDLPermissionGroup(
            spResponse.Table.map((item, index) => (
              <option
                key={index}
                value={item.PermissionGroup}
              >
                {item.PermissionGroup}
              </option>
            ))
          );

          refPermissionGroup.current.value = "";
        }
      }
    }
    getPermissionGroup();
  }, []);

  const handleClick_Edit = async () => {
    setEditResponse("");

    const resObj = await runFetch(`${spURL}Handheld_StaffManage_Staff_EditDetails`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StaffID: StaffID,
          FirstName: refFirstName.current.value,
          LastName: refLastName.current.value,
          NameOnReceipt: refNameOnReceipt.current.value,
          PermissionGroup: refPermissionGroup.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setEditResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);
      setEditResponse(spResponse.Table[0].OutputString);
      cleanStaffDetails();
    }
  };

  const handleClick_SearchStaff = async (event) => {
    cleanStaffDetails();
    setEditResponse("");

    if (
      event.target.id === "staffmanage-edit-staffid-enter" ||
      event.target.id === "staffmanage-edit-searchstaffid"
    ) {
      setSearchStaffName("");
      document.getElementById("staffmanage-edit-searchstaffname").value = "";

      setDDLStaffName([]);
    } else if (
      event.target.id === "staffmanage-edit-staffname-enter" ||
      event.target.id === "staffmanage-edit-staffname"
    ) {
      document.getElementById("staffmanage-edit-searchstaffid").value = "";
    }

    setEditResponse("");

    const resObj = await runFetch(`${spURL}Handheld_StaffManage_Staff_GetDetails`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StaffID: SearchStaffID,
        }),
      }),
    });

    if (resObj.response === null) {
      setEditResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStaffID(spResponse.Table[0].StaffID);
        setEmail(spResponse.Table[0].Email);

        refFirstName.current.value = spResponse.Table[0].FirstName;
        refLastName.current.value = spResponse.Table[0].LastName;
        refNameOnReceipt.current.value = spResponse.Table[0].NameOnReceipt;
        refPermissionGroup.current.value = spResponse.Table[0].PermissionGroup;

        await getStaffBarcode(spResponse.Table[0].StaffID);
      } else {
        setEditResponse("ERROR!: Staff not found.");
      }
    }

    async function getStaffBarcode(_staffid) {
      const resObj = await runFetch(`${spURL}Stocktake_StaffBarcode_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            UserID: _staffid,
          }),
        }),
      });

      if (resObj.response === null) {
        setEditResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setStaffBarcode(spResponse.Table[0].StaffBarcode);
        }
      }
    }
  };

  const handleClick_GetStaffName = async () => {
    cleanStaffDetails();
    document.getElementById("staffmanage-edit-staffname").value = "";
    setSearchStaffID("");
    setEditResponse("");

    const resObj = await runFetch(`${spURL}Handheld_StaffManage_Staff_DDLUser_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          SearchName: SearchStaffName,
          StoreNo: storeno,
        }),
      }),
    });

    if (resObj.response === null) {
      setEditResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setDDLStaffName(
          spResponse.Table.map((item) => ({
            StaffID: item.UserID,
            FullName: item.FirstName + " " + item.LastName,
          }))
        );
      } else {
        setDDLStaffName([]);
      }
    }
  };

  const cleanStaffDetails = () => {
    setStaffID("");
    setEmail("");

    setStaffBarcode("");

    refFirstName.current.value = "";
    refLastName.current.value = "";
    refNameOnReceipt.current.value = "";
    refPermissionGroup.current.value = "";
  };

  const handleClick_ChangePassword = () => {
    setEditResponse("");
    setShowChangePassword(true);
  };

  const ComponentToPrint = React.forwardRef((props, ref) => (
    <div
      ref={ref}
      style={{ width: "fit-content" }}
    >
      <Barcode
        value={props.Barcode}
        displayValue="false"
      />
    </div>
  ));

  const componentRef = useRef();

  const handleClick_CreateBarcode = async () => {
    await exportComponentAsPNG(componentRef, {
      fileName: `${refFirstName.current.value}_${refLastName.current.value}`,
    });
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          <h1>
            <strong>SEARCH STAFF</strong>
          </h1>
          <div className="staffmanage-edit-search-input-row">
            <p>
              <strong>By ID:</strong>
            </p>
            <input
              type="text"
              id="staffmanage-edit-searchstaffid"
              onChange={(e) => handleChange_Input(e, setSearchStaffID)}
              onKeyDown={(e) => OnEnterKeyDown(e, () => handleClick_SearchStaff(e))}
            />
            <ContentButton
              id="staffmanage-edit-staffid-enter"
              colour={purple}
              button="Go"
              onClick={handleClick_SearchStaff}
            />
          </div>
          <div className="staffmanage-edit-search-input-row">
            <p>
              <strong>By Name:</strong>
            </p>
            <input
              type="text"
              id="staffmanage-edit-searchstaffname"
              onChange={(e) => handleChange_Input(e, setSearchStaffName)}
              onKeyDown={(e) => OnEnterKeyDown(e, handleClick_GetStaffName)}
            />
            <ContentButton
              id="staffmanage-edit-searchstaffname-enter"
              colour={purple}
              button="Go"
              onClick={handleClick_GetStaffName}
            />
          </div>
          <div className="staffmanage-edit-search-input-row">
            <p>
              <strong>Staff Name:</strong>
            </p>
            <select
              name="staffname"
              id="staffmanage-edit-staffname"
              onChange={(e) => handleChange_Input(e, setSearchStaffID)}
              onKeyDown={(e) => OnEnterKeyDown(e, () => handleClick_SearchStaff(e))}
            >
              <option value=""></option>
              {ddlStaffName.map((item, index) => (
                <option
                  key={index}
                  value={item.StaffID}
                >
                  {item.FullName}
                </option>
              ))}
            </select>
            <ContentButton
              id="staffmanage-edit-staffname-enter"
              colour={purple}
              button="Go"
              onClick={handleClick_SearchStaff}
            />
          </div>

          <div className={StaffID === "" ? "hidden" : ""}>
            <h1>
              <strong>STAFF DETAILS</strong>
            </h1>
            <div className="storetransfer-input-row">
              <p>
                <strong>Staff ID:</strong>
              </p>
              <p>{StaffID}</p>
            </div>
            <div className="storetransfer-input-row">
              <p>
                <strong>Email:</strong>
              </p>
              <p>{Email}</p>
            </div>
            <div className="storetransfer-input-row">
              <p>
                <strong>First Name:</strong>
              </p>
              <input
                type="text"
                id="staffmanage-edit-firstname"
                ref={refFirstName}
              />
            </div>
            <div className="storetransfer-input-row">
              <p>
                <strong>Last Name:</strong>
              </p>
              <input
                type="text"
                id="staffmanage-edit-lastname"
                ref={refLastName}
              />
            </div>
            <div className="storetransfer-input-row">
              <p>
                <strong>Name on Receipt:</strong>
              </p>
              <input
                type="text"
                id="staffmanage-edit-nameonreceipt"
                ref={refNameOnReceipt}
              />
            </div>
            <div className="storetransfer-input-row">
              <p>
                <strong>Permission:</strong>
              </p>
              <select
                name="permission"
                id="staffmanage-edit-permissiongroup"
                ref={refPermissionGroup}
              >
                <option value=""></option>
                {ddlPermissionGroup}
              </select>
            </div>

            <div className="blockedorders-option-row">
              <MenuButton
                title="Change Password"
                colour={purple}
                button="CP"
                onClick={handleClick_ChangePassword}
              />
              <MenuButton
                title="Create Barcode"
                colour={purple}
                button="CB"
                onClick={handleClick_CreateBarcode}
              />
            </div>
          </div>
          <div style={{ position: "absolute", top: -9999, left: -9999 }}>
            <ComponentToPrint
              Barcode={StaffBarcode}
              ref={componentRef}
            />
          </div>
        </div>

        <PopupChangePassword
          show={showChangePassword}
          setShow={setShowChangePassword}
          StaffID={StaffID}
          setEditResponse={setEditResponse}
        />

        <p className="error-message">{EditResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={purple}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
        <FooterButton
          button="Edit"
          colour={purple}
          onClick={handleClick_Edit}
        />
      </div>
    </div>
  );
};
