import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { MenuButton } from "../../components/HomepageButton";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { useRef } from "react";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";
import { runFetch } from "../../functions/RunFetch";

export const PopupGiftCardScan = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [PopupResponse, setPopupResponse] = useState("");

  const refGiftCardScan = useRef();

  const handleShow = () => {
    setPopupResponse("");
    refGiftCardScan.current.focus();
  };

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleClick_Cancel = () => {
    handleClose();
  };

  const handleClick_Scan = async () => {
    const gcCheck = /^534860\d{6}\d$/;

    if (gcCheck.test(refGiftCardScan.current.value)) {
      let uri = "";

      switch (props.Origin) {
        case "VERIFY":
          uri = "Handheld_CustomerOrder_Verify_UpdateQty_GiftCard";
          break;
        default:
          uri = "Handheld_CustomerOrder_Pick_Single_UpdateQty_GiftCard";
          break;
      }

      const resObj = await runFetch(`${spURL}${uri}`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            GiftCardBarcode: refGiftCardScan.current.value,
            UserID: userid,
            StoreNo: storeno,
          }),
        }),
      });

      if (resObj.response === null) {
        setPopupResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          handleClose();
          props.pageLoad();
        } else {
          setPopupResponse("ERROR!: Server error.");
        }
      }
    } else {
      setPopupResponse("ERROR!: Gift Card is invalid.");
    }

    refGiftCardScan.current.value = "";
    refGiftCardScan.current.focus();
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Gift Card Barcode:</strong>
              </p>
              <input
                type="text"
                id="weborders-pick-giftcardscan"
                ref={refGiftCardScan}
                onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Scan)}
              />
            </div>
            <br />
            <br />
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClick_Cancel}
            >
              Cancel
            </button>
            <button
              className="popup-button"
              id="weborders-pick-giftcardscan-scan"
              onClick={handleClick_Scan}
            >
              Scan
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
