import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import { useRef } from "react";
import { runFetch } from "../../functions/RunFetch";

export const PopupCreateProject = (props) => {
  const [PopupResponse, setPopupResponse] = useState("");

  const refProjectDescription = useRef();

  const handleClose = () => {
    setPopupResponse("");
    props.setShow(false);
  };

  const handleShow = () => {
    setPopupResponse("");
    refProjectDescription.current.focus();
  };

  const handleClick_Create = async () => {
    const resObj = await runFetch(`${spURL}Handheld_StoreAuditTool_Project_Create`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: props.From.StoreNo,
          ProjectDescription: refProjectDescription.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (spResponse.Table[0].OutputString !== undefined) {
          props.setSelectedProject({
            ProjectID: spResponse.Table[0].ProjectID,
            ProjectDescription: spResponse.Table[0].ProjectDescription,
          });
          props.setShow(false);
          props.setPage("issue-list");
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Store:</strong>
              </p>
              <p>{props.From.StoreName}</p>
            </div>
            <div className="stocktake-create-input-row">
              <p>
                <strong>Description:</strong>
              </p>
              <input
                type="text"
                ref={refProjectDescription}
              />
            </div>
            <br />
            <p className="error-message">{PopupResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Create}
            >
              Create
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
