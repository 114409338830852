import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { convertToCurrency } from "../../functions/ConvertToCurrency";
import { ContentButton, FooterButton, MenuButton } from "../../components/HomepageButton";
import { blue, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { ConvertDateFormat } from "../../functions/ConvertDateFormat";
import { PopupErrorList } from "./PopupErrorList";
import { runFetch } from "../../functions/RunFetch";

export const POSStatusMain = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.MenuResponse);

  const [POSStatusTable, setPOSStatusTable] = useState([]);

  const [SelectedStoreName, setSelectedStoreName] = useState("");
  const [showErrorList, setShowErrorList] = useState(false);

  const pageLoad = async () => {
    const resObj = await runFetch(`${spURL_NoInputJSON}POS_Ping_Status`, {
      method: "POST",
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setPOSStatusTable(
          spResponse.Table.map((item) => ({
            State: item.State,
            NickName: item.NickName,
            StoreName: item.StoreName,
            POS1DataSyncColour: item["POS1Sync Colour"],
            POS2DataSyncColour: item["POS2Sync Colour"],
            ErrorCount: item.ErrorCount,
          }))
        );
      } else {
        setPOSStatusTable([]);
      }
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <p className="error-message">{MenuResponse}</p>
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>State</th>
              <th>Store</th>
              <th>POS 1</th>
              <th>POS 2</th>
              <th>Errors</th>
            </tr>
          </thead>
          <tbody>
            {POSStatusTable.map((item, index) => (
              <tr
                key={index}
                className="btnHover"
                storename={item.StoreName}
                onClick={() => {
                  setMenuResponse("");
                  setSelectedStoreName(item.StoreName);
                  setShowErrorList(true);
                }}
              >
                <td>{item.State}</td>
                <td>{item.StoreName}</td>
                <td style={{ backgroundColor: item.POS1DataSyncColour }}></td>
                <td style={{ backgroundColor: item.POS2DataSyncColour }}></td>
                <td>{item.ErrorCount}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <PopupErrorList
          show={showErrorList}
          setShow={setShowErrorList}
          StoreName={SelectedStoreName}
          pageLoad={pageLoad}
        />
      </div>

      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          onClick={() => props.setPage("menu")}
          colour={blue}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
      </div>
    </div>
  );
};
