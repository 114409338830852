import { useRef } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { red, spURL } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const Check = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");
  const [userid, setUserID] = store.useState("userid");

  const [StockTakeResponse, setStockTakeResponse] = useState("");

  const [CheckItemTable, setCheckItemTable] = useState([]);

  const [total, setTotal] = useState({
    totalItems: "",
    totalQuantity: "",
  });

  const refBayNo = useRef();

  const handleClick = async () => {
    setStockTakeResponse("");

    const resObj = await runFetch(`${spURL}Stocktake_Count_History`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Store: storeno,
          StockTake: props.CurrentStockTake,
          BayNo: refBayNo.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setStockTakeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table2 === undefined) {
        setCheckItemTable([]);
        setStockTakeResponse(spResponse.Table[0].OutputString);
      } else {
        if (spResponse.Table.length > 0) {
          setCheckItemTable(
            spResponse.Table.map((item, index) => (
              <tr key={index}>
                <td>{item.Item}</td>
                <td>{item.Description}</td>
                <td>{item.Counted}</td>
              </tr>
            ))
          );
        } else {
          setStockTakeResponse("No items scanned.");
        }

        if (spResponse.Table1.length > 0) {
          setTotal((values) => ({
            ...values,
            totalItems: spResponse.Table1[0].TotalItems,
            totalQuantity: spResponse.Table1[0].TotalQuantity,
          }));
        }
      }
    }
  };

  const handleClick_Back = () => {
    props.setStockTakeBay("");
    props.setFrom("");
    props.setPage("menu");
  };

  const handleClick_Confirm = async (status) => {
    setStockTakeResponse("");

    const resObj = await runFetch(`${spURL}StockTake_RecountStatus_Set`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Store: storeno,
          StockTake: props.CurrentStockTake,
          BayNo: refBayNo.current.value,
          RecountStatus: status,
          UserID: userid,
        }),
      }),
    });

    if (resObj.response === null) {
      setStockTakeResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setStockTakeResponse(spResponse.Table[0].OutputString);

        if (status === "RECOUNT") {
          props.setStockTakeBay({
            StockTake: props.CurrentStockTake,
            BayNo: refBayNo.current.value,
          });
          props.setFrom("CHECK");
          props.setPage("manage");
        }
      }
    }
  };

  return (
    <div className="page-container">
      <div
        className={
          window.matchMedia("(max-width: 364px)").matches
            ? "handheld-body-container"
            : "desktop-body-container"
        }
      >
        <div className="stocktake-check-container">
          <div className="stocktake-create-input-row">
            <p>
              <strong>Stock Take:</strong>
            </p>
            <p>{props.CurrentStockTake}</p>
          </div>
          <div className="stocktake-create-input-row">
            <p>
              <strong>Bay:</strong>
            </p>
            <input
              type="text"
              id="stocktake-check-bayno"
              ref={refBayNo}
            />
          </div>
          <button
            className="signin-login-button btnHover"
            onClick={handleClick}
          >
            Check
          </button>
          <br />
          {CheckItemTable === "" ? (
            ""
          ) : (
            <div>
              <div className="input-row">
                <p>
                  <strong>Total Items:</strong> {total.totalItems}
                </p>
              </div>
              <div className="input-row">
                <p>
                  <strong>Total Quantity:</strong> {total.totalQuantity}
                </p>
              </div>
              <table className="itemdetails-table">
                <thead>
                  <tr>
                    <th>
                      <strong>Item</strong>
                    </th>
                    <th>
                      <strong>Description</strong>
                    </th>
                    <th>
                      <strong>Qty</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>{CheckItemTable}</tbody>
              </table>
              <br />
              <p>
                <strong>Confirm Count?</strong>
              </p>
              <div className="blockedorders-option-row">
                <MenuButton
                  title="Yes"
                  colour={red}
                  button="Y"
                  onClick={() => handleClick_Confirm("CONFIRMED")}
                />
                <MenuButton
                  title="No"
                  colour={red}
                  button="N"
                  onClick={() => handleClick_Confirm("RECOUNT")}
                />
              </div>
            </div>
          )}
        </div>
        <p className="error-message">{StockTakeResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
