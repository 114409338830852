import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { trackPromise } from "react-promise-tracker";
import store from "../../store";
import { runFetch } from "../../functions/RunFetch";

export const PopupHistory = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [historyTable, setHistoryTable] = useState("");

  const handleClose = () => props.setShow(false);

  const handleShow = async () => {
    const resObj = await runFetch(`${spURL}Handheld_Transfer_Send_History`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Store: storeno,
          TransferID: props.TONo,
        }),
      }),
    });

    if (resObj.response === null) {
      props.setMenuResponse(resObj.exception);
      handleClose();
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table.length > 0) {
        setHistoryTable(
          spResponse.Table.map((item, index) => (
            <tr key={index}>
              <td>{item.Item}</td>
              <td>{item.Description}</td>
              <td>{item.Quantity}</td>
            </tr>
          ))
        );
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Description</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody>{historyTable}</tbody>
            </table>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
