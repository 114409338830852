import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { blue, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { FooterButton, MenuButton } from "../../components/HomepageButton";

export const Menu = (props) => {
  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          <MenuButton
            onClick={() => props.setPage("deviceauth-main")}
            button="DA"
            colour={blue}
            title="Device Auth"
          />
          <MenuButton
            onClick={() => props.setPage("handheldpermission-main")}
            button="HP"
            colour={blue}
            title="Handheld Permission"
          />
          <MenuButton
            onClick={() => props.setPage("posstatus-main")}
            button="PM"
            colour={blue}
            title="POS Message"
          />
        </div>
        <div className="home-table">
          <MenuButton
            onClick={() => props.setPage("posstatus-main")}
            button="PS"
            colour={blue}
            title="POS Status"
          />
          <MenuButton
            onClick={() => props.setPage("storelist-main")}
            button="SL"
            colour={blue}
            title="Store List"
          />
          <MenuButton
            onClick={() => props.setPage("equipmentlist-main")}
            button="EL"
            colour={blue}
            title="Equipment List"
          />
        </div>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={blue}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={blue}
          />
        </Link>
      </div>
    </div>
  );
};
