import React, { useState, useEffect } from "react";

import { Menu } from "./Menu";
import { Scan } from "./Scan";

export const BulkyItemMerchBody = () => {
  const [page, setPage] = useState("menu");

  const menuPage = (
    <Menu
      page={page}
      setPage={setPage}
    />
  );

  const floorPage = (
    <Scan
      page={page}
      setPage={setPage}
      mode="Floor"
    />
  );

  const storeroomPage = (
    <Scan
      page={page}
      setPage={setPage}
      mode="Storeroom"
    />
  );

  const getCurrentPage = (page) => {
    switch (page) {
      case "menu":
        return menuPage;
      case "floor":
        return floorPage;
      case "storeroom":
        return storeroomPage;
      default:
        break;
    }
  };

  return getCurrentPage(page);
};
