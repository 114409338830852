import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";

import { purple, spURL, spURL_NoInputJSON } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { runFetch } from "../../functions/RunFetch";

export const Create = (props) => {
  const [CreateResponse, setCreateResponse] = useState("");

  const [ddlPermissionGroup, setDDLPermissionGroup] = useState([]);

  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [NameOnReceipt, setNameOnReceipt] = useState("");
  const [Password, setPassword] = useState("");
  const [PermissionGroup, setPermissionGroup] = useState("");

  useEffect(() => {
    async function getPermissionGroup() {
      const resObj = await runFetch(
        `${spURL_NoInputJSON}Handheld_StaffManage_PermissionGroup_Get`,
        {
          method: "POST",
        }
      );

      if (resObj.response === null) {
        setCreateResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined) {
          setDDLPermissionGroup(
            spResponse.Table.map((item, index) => (
              <option
                key={index}
                value={item.PermissionGroup}
              >
                {item.PermissionGroup}
              </option>
            ))
          );
        }
      }
    }
    getPermissionGroup();
  }, []);

  const handleClick_Create = async () => {
    const resObj = await trackPromise(
      fetch(`${spURL}Handheld_StaffManage_Staff_Create`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            FirstName: FirstName,
            LastName: LastName,
            NameOnReceipt: NameOnReceipt,
            Password: Password,
            PermissionGroup: PermissionGroup,
          }),
        }),
      })
    );

    if (resObj.response === null) {
      setCreateResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);
      props.setMenuResponse(spResponse.Table[0].OutputString);
      props.setPage("menu");
    }
  };

  const handleClick_Back = () => {
    props.setMenuResponse("");
    props.setPage("menu");
  };

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div>
          <h1>
            <strong>ONBOARD STAFF</strong>
          </h1>
          <div className="storetransfer-input-row">
            <p>
              <strong>First Name:</strong>
            </p>
            <input
              type="text"
              id="staffmanage-create-firstname"
              onChange={(e) => handleChange_Input(e, setFirstName)}
            />
          </div>
          <div className="storetransfer-input-row">
            <p>
              <strong>Last Name:</strong>
            </p>
            <input
              type="text"
              id="staffmanage-create-lastname"
              onChange={(e) => handleChange_Input(e, setLastName)}
            />
          </div>
          <div className="storetransfer-input-row">
            <p>
              <strong>Name on Receipt:</strong>
            </p>
            <input
              type="text"
              id="staffmanage-create-nameonreceipt"
              onChange={(e) => handleChange_Input(e, setNameOnReceipt)}
            />
          </div>
          <div className="storetransfer-input-row">
            <p>
              <strong>Password:</strong>
            </p>
            <input
              type="text"
              id="staffmanage-create-password"
              onChange={(e) => handleChange_Input(e, setPassword)}
            />
          </div>
          <div className="storetransfer-input-row">
            <p>
              <strong>Permission:</strong>
            </p>
            <select
              name="permission"
              id="staffmanage-create-permissiongroup"
              onChange={(e) => handleChange_Input(e, setPermissionGroup)}
            >
              <option value=""></option>
              {ddlPermissionGroup}
            </select>
          </div>
        </div>

        <p className="error-message">{CreateResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={purple}
          onClick={handleClick_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={purple}
          />
        </Link>
        <FooterButton
          button="Create"
          colour={purple}
          onClick={handleClick_Create}
        />
      </div>
    </div>
  );
};
