import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, spURL_LocalPOS } from "../../App";
import { runFetch } from "../../functions/RunFetch";

export const PopupConfirmMerch = (props) => {
  const [ConfirmResponse, setConfirmResponse] = useState("");

  const handleClose = () => props.setShow(false);

  const handleClick_Yes = () => {
    props.actionOk();
    handleClose();
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <strong>{props.ConfirmMessage}</strong>
            <br />

            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              No
            </button>
            <button
              className="popup-button"
              onClick={handleClick_Yes}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
