import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { MenuButton, ContentButton, FooterButton } from "../../components/HomepageButton";
import { PopupUpdateShipping } from "./PopupUpdateShipping";
import { PopupResendItem } from "./PopupResendItem";
import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { useRef } from "react";
import { runFetch } from "../../functions/RunFetch";

export const ResendOrdersBody = (props) => {
  const [MenuResponse, setMenuResponse] = useState("");
  const [Order, setOrder] = useState({
    OrderID: "",
    OrderPlaced: "",
    IPAddress: "",
    OrderStatus: "",
    PaymentMethod: "",
    PaymentStatus: "",
    ProviderID: "",
    CustomerNo: "",
  });

  const [Billing, setBilling] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Street1: "",
    Street2: "",
    City: "",
    Zip: "",
    State: "",
    Country: "",
  });

  const [Shipping, setShipping] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Street1: "",
    Street2: "",
    City: "",
    Zip: "",
    State: "",
    Country: "",
  });

  const [ItemTable, setItemTable] = useState("");
  const [currentResendItemNo, setCurrentResendItemNo] = useState("");
  const [ResendLocation, setResendLocation] = useState("Online 3PL");

  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [showResendPopup, setShowResendPopup] = useState(false);

  const refOrderID = useRef();

  const handleClick_Search = async () => {
    const resObj = await runFetch(`${spURL}ResendOrders_SearchOrder_Details`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          OrderID: refOrderID.current.value,
        }),
      }),
    });

    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        // load data
        setOrder((values) => ({
          ...values,
          OrderID: spResponse.Table[0].OrderID,
          OrderPlaced: spResponse.Table[0].OrderPlaced,
          IPAddress: spResponse.Table[0].ip_address,
          OrderStatus: spResponse.Table[0].OrderStatus,
          PaymentMethod: spResponse.Table[0].payment_method,
          PaymentStatus: spResponse.Table[0].payment_status,
          ProviderID: spResponse.Table[0].payment_provider_id,
          CustomerNo: spResponse.Table[0].CustomerNumber,
        }));

        setBilling((values) => ({
          ...values,
          Name: spResponse.Table[0].BillingName,
          Email: spResponse.Table[0].billing_address_email,
          Phone: spResponse.Table[0].billing_address_phone,
          Street1: spResponse.Table[0].billing_address_street_1,
          Street2: spResponse.Table[0].billing_address_street_2,
          City: spResponse.Table[0].billing_address_city,
          Zip: spResponse.Table[0].billing_address_zip,
          State: spResponse.Table[0].billing_address_state,
          Country: spResponse.Table[0].billing_address_country,
        }));

        setShipping((values) => ({
          ...values,
          Name: spResponse.Table[0].ShippingName,
          Email: spResponse.Table[0].email,
          Phone: spResponse.Table[0].phone,
          Street1: spResponse.Table[0].street_1,
          Street2: spResponse.Table[0].street_2,
          City: spResponse.Table[0].city,
          Zip: spResponse.Table[0].zip,
          State: spResponse.Table[0].state,
          Country: spResponse.Table[0].country,
        }));
      } else {
        setMenuResponse("ERROR!: Order not found.");
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        setItemTable(
          spResponse.Table1.map((item) => ({
            ItemNo: item.ItemNo,
            Description: item.Description,
            OrderQty: item.Quantity,
            ResendQty: 0,
          }))
        );
      }
    }
  };

  const handleOk_Resend = (itemno, resendqty) => {
    setItemTable(
      ItemTable.map((item) =>
        item.ItemNo === itemno ? { ...item, ResendQty: resendqty } : { ...item }
      )
    );
  };

  const handleClick_Resend = async () => {
    const itemsToResend = ItemTable.filter(
      (values) => values.ResendQty !== "0" && values.OrderQty >= values.ResendQty
    );

    if (itemsToResend.length > 0) {
      const resObj = await runFetch(`${spURL}ResendOrders_SearchOrder_Resend`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(
            itemsToResend.map((values) => ({
              OrderID: Order.OrderID,
              ItemNo: values.ItemNo,
              ResendQty: values.ResendQty,
              Store: ResendLocation,
            }))
          ),
        }),
      });

      if (resObj.response === null) {
        setMenuResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          props.setMenuResponse(spResponse.Table[0].OutputString);
        }
      }
    } else {
      setMenuResponse("ERROR!: ResendQty must be between 0 and OrderQty.");
    }
  };

  const handleClick_UpdateShippingAddress = () => {
    // show shipping address
    setShowAddressPopup(true);
  };

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <div className="blockedorders-input-row">
          <p>
            <strong>OrderID:</strong>
          </p>
          <input
            type="text"
            id="resendorders-orderid"
            ref={refOrderID}
            autoFocus
          />
          <ContentButton
            id="blockedorders-main-enter"
            colour={green}
            button="Go"
            onClick={handleClick_Search}
          />
        </div>
        <div className="blockedorders-content-container">
          {Order.OrderID !== "" && (
            <>
              <h4>
                <strong>Order {Order.OrderID} Details</strong>
              </h4>
              <table className="blockedorders-order-table">
                <tr>
                  <th className="blockedorders-order-table-col-1">
                    <strong>Order ID:</strong>
                  </th>
                  <td className="blockedorders-order-table-col-2">{Order.OrderID}</td>
                  <th className="blockedorders-order-table-col-3">
                    <strong>Payment Method:</strong>
                  </th>
                  <td className="blockedorders-order-table-col-4">{Order.PaymentMethod}</td>
                </tr>
                <tr>
                  <th>
                    <strong>Order Placed:</strong>
                  </th>
                  <td>{Order.OrderPlaced}</td>
                  <th>
                    <strong>Payment Status:</strong>
                  </th>
                  <td>{Order.PaymentStatus}</td>
                </tr>
                <tr>
                  <th>
                    <strong>IP Address:</strong>
                  </th>
                  <td>{Order.IPAddress}</td>
                  <th>
                    <strong>Provider ID:</strong>
                  </th>
                  <td>{Order.ProviderID}</td>
                </tr>
                <tr>
                  <th>
                    <strong>Order Status:</strong>
                  </th>
                  <td>{Order.OrderStatus}</td>
                </tr>
                <br />
                <tr>
                  <th>
                    <strong>Customer No:</strong>
                  </th>
                  <tr>{Order.CustomerNo}</tr>
                </tr>
              </table>
              <br />
              <table className="blockedorders-address-table">
                <thead>
                  <tr>
                    <th className="blockedorders-address-table-col-1">
                      <h4>
                        <strong>Billing Details</strong>
                      </h4>
                    </th>
                    <th className="blockedorders-address-table-col-2">
                      <h4>
                        <strong>Shipping Details</strong>
                      </h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{Billing.Name}</td>
                    <td>{Shipping.Name}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Phone}</td>
                    <td>{Shipping.Phone}</td>
                    <td rowSpan="5">
                      <MenuButton
                        title="Update Shipping"
                        colour="black"
                        button="A"
                        onClick={handleClick_UpdateShippingAddress}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{Billing.Email}</td>
                    <td>{Shipping.Email}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Street1}</td>
                    <td>{Shipping.Street1}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Street2}</td>
                    <td>{Shipping.Street2}</td>
                  </tr>
                  <tr>
                    <td>{Billing.City}</td>
                    <td>{Shipping.City}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Zip + " " + Billing.State}</td>
                    <td>{Shipping.Zip + " " + Shipping.State}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Country}</td>
                    <td>{Shipping.Country}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              {ItemTable && (
                <table className="itemdetails-table">
                  <thead>
                    <tr>
                      <th>
                        <strong>Item</strong>
                      </th>
                      <th>
                        <strong>Description</strong>
                      </th>
                      <th>
                        <strong>Order Qty</strong>
                      </th>
                      <th>
                        <strong>Resend Qty</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ItemTable.map((item, index) => (
                      <tr
                        id={`tablerow-${item.ItemNo}`}
                        key={index}
                        className="btnHover"
                        onClick={() => {
                          setMenuResponse("");

                          setCurrentResendItemNo({
                            ItemNo: item.ItemNo,
                            OrderQty: item.OrderQty,
                            ResendQty: item.ResendQty,
                          });

                          setShowResendPopup(true);
                        }}
                      >
                        <td>{item.ItemNo}</td>
                        <td>{item.Description}</td>
                        <td>{item.OrderQty}</td>
                        <td>{item.ResendQty}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <br />
              <div className="blockedorders-option-row">
                <MenuButton
                  title="Resend"
                  colour="black"
                  button="R"
                  onClick={handleClick_Resend}
                />
              </div>
            </>
          )}
          <br />
          <p className="error-message">{MenuResponse}</p>

          <PopupUpdateShipping
            show={showAddressPopup}
            setShow={setShowAddressPopup}
            Shipping={Shipping}
            OrderID={Order.OrderID}
            setMenuResponse={setMenuResponse}
          />

          <PopupResendItem
            show={showResendPopup}
            setShow={setShowResendPopup}
            Resend={currentResendItemNo}
            OrderID={Order.OrderID}
            ResendLocation={ResendLocation}
            setResendLocation={setResendLocation}
            handleOk_Resend={handleOk_Resend}
            setMenuResponse={setMenuResponse}
          />
        </div>
      </div>
      <div className="footer-container menu-container">
        <Link to="/EComm">
          <FooterButton
            button="Back"
            colour={green}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
