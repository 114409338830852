import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { runFetch } from "../../functions/RunFetch";

export const PopupLines = (props) => {
  const [ConfirmResponse, setConfirmResponse] = useState("");
  const [Items, setItems] = useState([]);

  const handleClose = () => props.setShow(false);

  const handleShow = async () => {
    const resObj = await runFetch(`${spURL_Unallocated_WebOrders}Unallocated_WebOrders_Lines_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify(
          props.selectedOrders.map((item) => ({
            OrderID: item.orderid,
          }))
        ),
      }),
    });

    if (resObj.response === null) {
      setConfirmResponse(resObj.exception);
    } else {
      const spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        setItems(
          spResponse.Table.map((item, index) => ({
            OrderID: item.OrderID,
            ItemNo: item.ItemNo,
            ProdID: item.ProdID,
            Description: item.Description,
            Quantity: item.Quantity,
            Store: item.Store,
          }))
        );
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div>
            <strong>Selected orders item list</strong>
            <br />
            {Items.length === 0 ? (
              <p>No items</p>
            ) : (
              <table
                className="itemdetails-table"
                style={{ fontSize: 12 }}
              >
                <thead>
                  <tr>
                    <th>OrderID</th>
                    <th>ItemNumber</th>
                    <th>ProductID</th>
                    <th>Description</th>
                    <th>Qty</th>
                    <th>Store</th>
                  </tr>
                </thead>
                <tbody>
                  {Items.map((item, index) => (
                    <tr key={index}>
                      <td>{item.OrderID}</td>
                      <td>{item.ItemNo}</td>
                      <td>{item.ProdID}</td>
                      <td>{item.Description}</td>
                      <td>{item.Quantity}</td>
                      <td>{item.Store}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <p className="error-message">{ConfirmResponse}</p>
          </div>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
