import React, { useRef, useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { red, spURL } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const BudgetBody = () => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [todayBody, setTodayBody] = useState("");
  const [weekToDateBody, setWeekToDateBody] = useState("");

  useEffect(() => {
    async function getHTML() {
      const resObj = await runFetch(`${spURL}Handheld_Budget_Details_Get`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            StoreNo: storeno,
          }),
        }),
      });

      if (resObj.response === null) {
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
          setTodayBody(spResponse.Table[0].Today);
        }

        if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
          setWeekToDateBody(spResponse.Table1[0]["Week to Date"]);
        }
      }
    }
    getHTML();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <p>
          <strong>Today</strong>
        </p>
        <div dangerouslySetInnerHTML={{ __html: todayBody }}></div>
        <br />
        <p>
          <strong>Week to Date</strong>
        </p>
        <div dangerouslySetInnerHTML={{ __html: weekToDateBody }}></div>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={red}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>
    </div>
  );
};
