import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { ContentButton, FooterButton } from "../../components/HomepageButton";
import { green, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { useRef } from "react";
import { runFetch } from "../../functions/RunFetch";
import { OnEnterKeyDown } from "../../functions/OnEnterKeyDown";

export const CustomerServiceBody = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.MenuResponse);
  const [Order, setOrder] = useState({
    OrderID: "",
    OrderPlaced: "",
    IPAddress: "",
    PaymentMethod: "",
    PaymentStatus: "",
    ProviderID: "",
    CustomerNo: "",
  });

  const [Billing, setBilling] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Street1: "",
    Street2: "",
    City: "",
    Zip: "",
    State: "",
    Country: "",
  });

  const [Shipping, setShipping] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Street1: "",
    Street2: "",
    City: "",
    Zip: "",
    State: "",
    Country: "",
  });
  const [PickHeader, setPickHeader] = useState("");
  const [PickLines, setPickLines] = useState("");

  const refOrderID = useRef();

  const handleClick_Search = async () => {
    const resObj = await runFetch(`${spURL}Handheld_CustomerService_Line_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          OrderID: refOrderID.current.value,
        }),
      }),
    });
    if (resObj.response === null) {
      setMenuResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined && spResponse.Table.length > 0) {
        setOrder((values) => ({
          ...values,
          OrderID: spResponse.Table[0].id,
          OrderPlaced: spResponse.Table[0].OrderPlaced,
          IPAddress: spResponse.Table[0].ip_address,
          PaymentMethod: spResponse.Table[0].payment_method,
          PaymentStatus: spResponse.Table[0].payment_status,
          ProviderID: spResponse.Table[0].payment_provider_id,
          CustomerNo: spResponse.Table[0].CustomerNumber,
        }));

        setBilling((values) => ({
          ...values,
          Name: spResponse.Table[0].BillingName,
          Email: spResponse.Table[0].billing_address_email,
          Phone: spResponse.Table[0].billing_address_phone,
          Street1: spResponse.Table[0].billing_address_street_1,
          Street2: spResponse.Table[0].billing_address_street_2,
          City: spResponse.Table[0].billing_address_city,
          Zip: spResponse.Table[0].billing_address_zip,
          State: spResponse.Table[0].billing_address_state,
          Country: spResponse.Table[0].billing_address_country,
        }));

        setShipping((values) => ({
          ...values,
          Name: spResponse.Table[0].ShippingName,
          Email: spResponse.Table[0].email,
          Phone: spResponse.Table[0].phone,
          Street1: spResponse.Table[0].street_1,
          Street2: spResponse.Table[0].street_2,
          City: spResponse.Table[0].city,
          Zip: spResponse.Table[0].zip,
          State: spResponse.Table[0].state,
          Country: spResponse.Table[0].country,
        }));
      }

      if (spResponse.Table1 !== undefined && spResponse.Table1.length > 0) {
        if (spResponse.Table2 !== undefined && spResponse.Table2.length > 0) {
          setPickHeader(
            spResponse.Table1.map((item) => ({
              PickID: item.PickID,
              OrderStatus: item.OrderStatus,
              PickStatus: item.PickStatus,
              Courier: item.Courier,
              TrackingURL: item.TrackingURL,
              TrackingNo: item.TrackingNo,
              DispatchCentre: item.DispatchCentre,
            }))
          );

          setPickLines(
            spResponse.Table2.reduce(function (r, a) {
              r[a.PickID] = r[a.PickID] || [];
              r[a.PickID].push(a);
              return r;
            }, Object.create(null))
          );
        }
      }
    }
  };

  return (
    <div className="page-container">
      <div className="desktop-body-container">
        <div className="blockedorders-input-row">
          <p>
            <strong>OrderID:</strong>
          </p>
          <input
            type="text"
            id="customerservice-orderid"
            ref={refOrderID}
            onKeyDown={(e) => OnEnterKeyDown(e, handleClick_Search)}
            autoFocus
          />
          <ContentButton
            id="customerservice-enter"
            colour={green}
            button="Go"
            onClick={handleClick_Search}
          />
        </div>
        <div className="blockedorders-content-container">
          {Order.OrderID !== "" && (
            <>
              <h4>
                <strong>Order {Order.OrderID} Details</strong>
              </h4>
              <table className="blockedorders-order-table">
                <tr>
                  <th className="blockedorders-order-table-col-1">
                    <strong>Order ID:</strong>
                  </th>
                  <td className="blockedorders-order-table-col-2">{Order.OrderID}</td>
                  <th className="blockedorders-order-table-col-3">
                    <strong>Payment Method:</strong>
                  </th>
                  <td className="blockedorders-order-table-col-4">{Order.PaymentMethod}</td>
                </tr>
                <tr>
                  <th>
                    <strong>Order Placed:</strong>
                  </th>
                  <td>{Order.OrderPlaced}</td>
                  <th>
                    <strong>Payment Status:</strong>
                  </th>
                  <td>{Order.PaymentStatus}</td>
                </tr>
                <tr>
                  <th>
                    <strong>IP Address:</strong>
                  </th>
                  <td>{Order.IPAddress}</td>
                  <th>
                    <strong>Provider ID:</strong>
                  </th>
                  <td>{Order.ProviderID}</td>
                </tr>
                <tr>
                  <th>
                    <strong>Customer No:</strong>
                  </th>
                  <td>{Order.CustomerNo}</td>
                  <th></th>
                  <td></td>
                </tr>
              </table>
              <br />
              <table className="blockedorders-address-table">
                <thead>
                  <tr>
                    <th className="blockedorders-address-table-col-1">
                      <h4>
                        <strong>Billing Details</strong>
                      </h4>
                    </th>
                    <th className="blockedorders-address-table-col-2">
                      <h4>
                        <strong>Shipping Details</strong>
                      </h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{Billing.Name}</td>
                    <td>{Shipping.Name}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Phone}</td>
                    <td>{Shipping.Phone}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Email}</td>
                    <td>{Shipping.Email}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Street1}</td>
                    <td>{Shipping.Street1}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Street2}</td>
                    <td>{Shipping.Street2}</td>
                  </tr>
                  <tr>
                    <td>{Billing.City}</td>
                    <td>{Shipping.City}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Zip + " " + Billing.State}</td>
                    <td>{Shipping.Zip + " " + Shipping.State}</td>
                  </tr>
                  <tr>
                    <td>{Billing.Country}</td>
                    <td>{Shipping.Country}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              {PickHeader && (
                <>
                  {PickHeader.map((item, index) => (
                    <>
                      <table className="customerservice-pickheader">
                        <tbody>
                          <tr>
                            <td>
                              <strong>Pick ID: </strong>
                              {item.PickID}
                            </td>
                            <td>
                              <strong>Dispatch Centre: </strong>
                              {item.DispatchCentre}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Pick Status: </strong>
                              {item.PickStatus}
                            </td>
                            <td>
                              <strong>Order Status: </strong>
                              {item.OrderStatus}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Courier: </strong>
                              {item.Courier}
                            </td>
                            <td>
                              <strong>Tracking No: </strong>
                              <a href={item.TrackingURL}>{item.TrackingNo}</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="itemdetails-table">
                        <tr>
                          <th>
                            <strong>Product</strong>
                          </th>
                          <th>
                            <strong>Qty</strong>
                          </th>
                          <th>
                            <strong>Pkd</strong>
                          </th>
                        </tr>
                        <tbody>
                          {PickLines &&
                            PickLines[item.PickID].map((items) => (
                              <tr>
                                <td
                                  style={{ textAlign: "left" }}
                                  dangerouslySetInnerHTML={{ __html: items.Product }}
                                ></td>
                                <td>{items.QtyRequired}</td>
                                <td>{items.QtyPicked}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                  ))}
                </>
              )}
              <br />
            </>
          )}
          <br />
          <p className="error-message">{MenuResponse}</p>
        </div>
      </div>
      <div className="footer-container menu-container">
        <Link to="/EComm">
          <FooterButton
            button="Back"
            colour={green}
          />
        </Link>
      </div>
    </div>
  );
};
