import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ItemBarcode } from "../../components/ItemBarcode";

import { yellow, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

import { FooterButton } from "../../components/HomepageButton";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const Scan = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [ScanResponse, setScanResponse] = useState("");
  const [scanTable, setScanTable] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const handleClick_Delete = async () => {
    setScanResponse("");

    if (selectedItems.length === 0) {
      setScanResponse("ERROR!: No item selected.");
    } else {
      const resObj = await runFetch(`${spURL}Handheld_BulkyItemMerch_Item_Delete`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify(
            selectedItems.map((item) => ({
              StoreNo: storeno,
              ItemNo: item,
              Mode: props.mode,
            }))
          ),
        }),
      });
      if (resObj.exception !== null) {
        setScanResponse(resObj.exception);
      } else {
        setScanResponse("Item is successfully added.");
      }
      pageLoad();
    }
  };

  const handleClick_Row = (_itemno) => {
    setScanResponse("");

    const currentBackground = document.querySelectorAll('[data-item="' + _itemno + '"]')[0]
      .className;
    const currentID = document.querySelectorAll('[data-item="' + _itemno + '"]')[0].id;

    if (currentBackground === "background-lightblue btnHover") {
      setSelectedItems((value) => [...value.filter((value) => value !== _itemno)]);

      const elements = document.querySelectorAll('[data-item="' + _itemno + '"]');

      elements.forEach((el) => {
        if (currentID === "tablerow-0") {
          el.className = "background-lightgreen btnHover";
        } else {
          el.className = "background-white btnHover";
        }
      });
    } else {
      setSelectedItems((value) => [...value, _itemno]);

      const elements = document.querySelectorAll('[data-item="' + _itemno + '"]');

      elements.forEach((el) => {
        el.className = "background-lightblue btnHover";
      });
    }
  };

  const barcodeClick = async () => {
    setScanResponse("");

    const resObj = await runFetch(`${spURL}Handheld_BulkyItemMerch_Item_Add`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          Barcode: document.getElementById("itembarcode-barcode").value,
          Mode: props.mode,
          Qty: 1,
        }),
      }),
    });

    if (resObj.exception !== null) {
      setScanResponse(resObj.exception);
    } else {
      setScanResponse("Item is successfully added.");
    }
    pageLoad();
  };

  const pageLoad = async () => {
    document.getElementById("itembarcode-barcode").value = "";

    const resObj = await runFetch(`${spURL}Handheld_BulkyItemMerch_Line_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          StoreNo: storeno,
          Mode: props.mode,
        }),
      }),
    });
    // reset table content before updating
    setScanTable("");

    if (resObj.response === null) {
      setScanResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);

      if (spResponse.Table !== undefined) {
        if (props.mode === "Floor") {
          setScanTable(
            spResponse.Table.map((item, index) => (
              <tr
                key={index}
                id={`tablerow-${index}`}
                className="background-white btnHover"
                onClick={() => handleClick_Row(item.ItemNo)}
              >
                <td>{item.ItemNo}</td>
                <td>{item.ItemDescription}</td>
              </tr>
            ))
          );
        } else if (props.mode === "Storeroom") {
          setScanTable(
            spResponse.Table.map((item, index) => (
              <tr
                key={index}
                id={`tablerow-${index}`}
                className="background-white btnHover"
                onClick={() => handleClick_Row(item.ItemNo)}
              >
                <td>{item.ItemNo}</td>
                <td>{item.ItemDescription}</td>
                <td>{item.Scanned}</td>
              </tr>
            ))
          );
        }

        document.getElementById("tablerow-0").className = "background-lightgreen btnHover";
      }
    }
  };

  useEffect(() => {
    pageLoad();
  }, []);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <p>
          <strong>Current Mode: {props.mode}</strong>
        </p>
        <ItemBarcode barcodeClick={barcodeClick} />
        <table className="itemdetails-table">
          <thead>
            <tr>
              <th>ItemNo</th>
              <th>Description</th>
              {props.mode === "Floor" && <th>Floor</th>}
            </tr>
          </thead>
          <tbody>{scanTable}</tbody>
        </table>
        <p className="error-message">{ScanResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={yellow}
          onClick={() => {
            setScanResponse("");
            props.setPage("menu");
          }}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={yellow}
          />
        </Link>
        <FooterButton
          button="Delete"
          colour={yellow}
          onClick={handleClick_Delete}
        />
      </div>
    </div>
  );
};
