import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal'

import { store, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from '../../App';

export const PopupDiscount = (props) => {

    const handleClose = () => props.setShow(false);

    return (
        <div>
            <Modal 
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered>
                <Modal.Body>
                    <div>
                        <br/>
                        {
                            props.PriceMatchTable.length === 0
                            ?
                            <p>No price matches found.</p>
                            :
                            (
                                <table className='itemdetails-table'>
                                    <thead>
                                        <tr>
                                            <th>Seller ID</th>
                                            <th>Seller Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props.PriceMatchTable.map((item, index) => (
                                                <tr>
                                                    <td>{item.SellerName}</td>
                                                    <td>{item.Price}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            )
                        }
                    </div>
                    <br/>
                    <div className='popup-button-container'>
                        <button className='popup-button' onClick={handleClose}>Close</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}