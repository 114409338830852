import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { red, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";
import { FooterButton } from "../../components/HomepageButton";
import { runFetch } from "../../functions/RunFetch";
import store from "../../store";

export const SOO = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [onOrderTable, setOnOrderTable] = useState("");
  const [MenuResponse, setMenuResponse] = useState("");

  const handleClick_SOO_Back = () => {
    props.setItemDetailsResponse("");
    props.setPage("details");
  };

  useEffect(() => {
    props.setItemDetailsResponse("");

    async function getStorePO() {
      const resObj = await runFetch(`${spURL}Handheld_ItemDetail_Item_StorePurchaseOrders`, {
        method: "POST",
        body: new URLSearchParams({
          inputJSON: JSON.stringify({
            ItemNo: props.itemno,
            StoreID: storeno,
          }),
        }),
      });

      if (resObj.response === null) {
        setMenuResponse(resObj.exception);
      } else {
        let spResponse = JSON.parse(resObj.response);

        if (spResponse.Table.length === 0) {
          setMenuResponse("ERROR!: No stock on order right now.");
        } else if (spResponse.Table !== undefined) {
          setOnOrderTable(
            spResponse.Table.map((item, index) => (
              <tr key={index}>
                <td
                  data-tip
                  data-id="supplier"
                >
                  {item.Supplier}
                </td>
                <td
                  data-tip
                  data-id="po"
                >
                  {item.PO}
                </td>
                <td
                  data-tip
                  data-id="qty"
                >
                  {item.Quantity}
                </td>
                <td
                  data-tip
                  data-id="date"
                >
                  {item["Expected Receipt Date"]}
                </td>
              </tr>
            ))
          );
        }
      }
      ReactTooltip.rebuild();
    }

    getStorePO();
  }, []);

  return (
    <div className="page-container">
      <div
        className={
          window.matchMedia("(max-width: 364px)").matches
            ? "handheld-body-container"
            : "desktop-body-container"
        }
      >
        <p>
          <strong>Item: {props.itemno}</strong>
        </p>
        <br />
        <div>
          <p>
            <strong>On Order</strong>
          </p>
          {onOrderTable.length > 0 && (
            <table className="itemdetails-table">
              <thead>
                <tr>
                  <th>Supplier</th>
                  <th>PO Number</th>
                  <th>Quantity</th>
                  <th>Date On Order</th>
                </tr>
              </thead>
              <tbody>{onOrderTable}</tbody>
            </table>
          )}
        </div>
        <p className="error-message">{MenuResponse}</p>
      </div>
      <div className="footer-container menu-container">
        <FooterButton
          button="Back"
          colour={red}
          onClick={handleClick_SOO_Back}
        />
        <Link to="/">
          <FooterButton
            button="Home"
            colour={red}
          />
        </Link>
      </div>

      <ReactTooltip
        id="supplier"
        place="bottom"
        effect="solid"
      >
        Supplier
      </ReactTooltip>
      <ReactTooltip
        id="po"
        place="bottom"
        effect="solid"
      >
        PO Number
      </ReactTooltip>
      <ReactTooltip
        id="qty"
        place="bottom"
        effect="solid"
      >
        Quantity
      </ReactTooltip>
      <ReactTooltip
        id="date"
        place="bottom"
        effect="solid"
      >
        Date On Order
      </ReactTooltip>
    </div>
  );
};
