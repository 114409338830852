import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { FooterButton, MenuButton } from "../../components/HomepageButton";
import { orange, spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders } from "../../App";

export const Menu = (props) => {
  const [MenuResponse, setMenuResponse] = useState(props.PODCodeResponse);

  return (
    <div className="page-container">
      <div className="handheld-body-container">
        <div className="home-table">
          <MenuButton
            onClick={() => props.setPage("connote_create")}
            button="C"
            colour={orange}
            title="Create"
          />
          <MenuButton
            onClick={() => props.setPage("check")}
            button="C"
            colour={orange}
            title="Check"
          />
          <MenuButton
            onClick={() => props.setPage("edit_header")}
            button="E"
            colour={orange}
            title="Edit"
          />
        </div>

        <p className="error-message">{MenuResponse}</p>
      </div>

      <div className="footer-container menu-container">
        <Link to="/">
          <FooterButton
            button="Back"
            colour={orange}
          />
        </Link>
        <Link to="/">
          <FooterButton
            button="Home"
            colour={orange}
          />
        </Link>
      </div>
    </div>
  );
};
