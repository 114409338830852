import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { spURL, spURL_NoInputJSON, spURL_Unallocated_WebOrders, spURL_LocalPOS } from "../../App";
import { handleChange_Input } from "../../functions/HandleChangeInput";
import { trackPromise } from "react-promise-tracker";
import { runFetch } from "../../functions/RunFetch";
import { convertToCurrency } from "../../functions/ConvertToCurrency";
import store from "../../store";

export const PopupItemDetails = (props) => {
  const [storeno, setStoreNo] = store.useState("storeno");

  const [PopupResponse, setPopupResponse] = useState("");
  const emptyItemDetails = {
    ExtendedDescription: "",
    ItemNo: "",
    ReorderNumber: "",
    Description: "",
    Supplier: "",
    Department: "",
    Category: "",
    SubCategory: "",
    Price: "",
    MSRP: "",
    ImageURL: "",
    WebURL: "",
    SOH: "",
    MerchInstruction: "",
    LastReceivedAmount: "",
    MerchSpot: "",
    MerchAreaAmount: "",
    YouHadAmount: "",
    IsOHSTagNeeded: "",
  };
  const [ItemDetails, setItemDetails] = useState(emptyItemDetails);

  const emptyMerchDetails = {
    BayLocation: "",
    AisleNo: "",
    FrontSectionQuantity: "",
    LeadSaleQuantity: "",
    StandardShelfQuantity: "",
    DepartmentExcessQuantity: "",
    StoreRoomBehindCounterQuantity: "",
  };

  const [MerchSpotDetails_1, setMerchSpotDetails_1] = useState(emptyMerchDetails);

  const handleClose = () => {
    props.setItemNo("");
    setPopupResponse("");
    setItemDetails(emptyItemDetails);
    setMerchSpotDetails_1(emptyMerchDetails);
    props.setShow(false);
  };

  const handleShow = async () => {
    const resObj = await runFetch(`${spURL}Handheld_Merchandising_Details_Get`, {
      method: "POST",
      body: new URLSearchParams({
        inputJSON: JSON.stringify({
          Barcode: props.ItemNo,
          StoreNo: storeno,
        }),
      }),
    });
    if (resObj.response === null) {
      setPopupResponse(resObj.exception);
    } else {
      let spResponse = JSON.parse(resObj.response);
      if (spResponse.Table !== undefined) {
        setItemDetails((values) => ({
          ...values,
          ExtendedDescription: spResponse.Table[0].ExtendedDescription,
          ItemNo: spResponse.Table[0].ItemNo,
          ReorderNumber: spResponse.Table[0].ReorderNumber,
          Description: spResponse.Table[0].Description,
          Supplier: spResponse.Table[0].Supplier,
          Department: spResponse.Table[0].Department,
          Category: spResponse.Table[0].Category,
          SubCategory: spResponse.Table[0].SubCategory,
          Price: spResponse.Table[0].Price,
          MSRP: spResponse.Table[0].MSRP,
          ImageURL: spResponse.Table[0].ImageURL,
          WebURL: spResponse.Table[0].WebURL,
          SOH: spResponse.Table[0].SOH,
          SOO: spResponse.Table[0].SOO,
          SOT: spResponse.Table[0].SOT,
          MerchInstruction: spResponse.Table[0].MerchInstruction,
          IsOHSTagNeeded: spResponse.Table[0].IsOHSTagNeeded,
          MerchSpot: spResponse.Table[0].MerchSpot,
          MerchAreaAmount: spResponse.Table[0].MerchAreaAmount,
        }));
      }

      if (spResponse.Table1 !== undefined) {
        setMerchSpotDetails_1({
          BayLocationStandard: spResponse.Table1[0].BayLocationStandard,
          BayLocationLead: spResponse.Table1[0].BayLocationLead,
          AisleNo: spResponse.Table1[0].AisleNo,
          FrontSectionQuantity: spResponse.Table1[0].FrontSectionQuantity,
          LeadSaleQuantity: spResponse.Table1[0].LeadSaleQuantity,
          StandardShelfQuantity: spResponse.Table1[0].StandardShelfQuantity,
          DepartmentExcessQuantity: spResponse.Table1[0].DepartmentExcessQuantity,
          StoreRoomBehindCounterQuantity: spResponse.Table1[0].StoreRoomBehindCounterQuantity,
        });
      }
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="popup-container">
            <div className="itemdetails-image-itemno-container">
              <img
                src={ItemDetails.ImageURL}
                alt="Item"
                width="100"
              />
              <div className="merchandising-itemno-container">
                <p>
                  <strong>{ItemDetails.ItemNo}</strong>
                </p>
                <p>
                  <strong>{ItemDetails.ReorderNumber}</strong>
                </p>
                <p>{ItemDetails.Supplier}</p>
              </div>
              <div className="merchandising-department-container">
                <p>{ItemDetails.Department.substring(0, 25)}</p>
                <p>
                  {">"}
                  {ItemDetails.Category.substring(0, 25)}
                </p>
                <p>
                  {">>"}
                  {ItemDetails.SubCategory.substring(0, 25)}
                </p>
              </div>
            </div>

            <div className="merchandising-description-container">
              <a
                className="itemdetails-description-link"
                href={
                  ItemDetails.WebURL === "" ? "" : "https://toymate.com.au" + ItemDetails.WebURL
                }
                target="_blank"
              >
                <strong>{ItemDetails.ExtendedDescription}</strong>
                <br />
                Click for website
              </a>
            </div>

            <div className="itemdetails-price-container">
              <p>
                <strong>
                  {convertToCurrency(ItemDetails.Price, "$")} (MSRP:{" "}
                  {convertToCurrency(ItemDetails.MSRP, "$")})
                </strong>
              </p>
            </div>

            <table className="merchandising-stock-table">
              <thead>
                <tr>
                  <th>SOO</th>
                  <th>SOT</th>
                  <th>SOH</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{ItemDetails.SOO}</td>
                  <td>{ItemDetails.SOT}</td>
                  <td>{ItemDetails.SOH}</td>
                </tr>
              </tbody>
            </table>
            <div className="merchandising-aisleno">
              <h5>
                <strong>
                  {ItemDetails.Category}
                  <br />
                  Section {MerchSpotDetails_1.AisleNo}
                </strong>
              </h5>
              <p>({ItemDetails.SubCategory})</p>
            </div>

            <p>
              This item is a <strong>{ItemDetails.MerchSpot}</strong> item.
            </p>
            <p>
              This item is to be in <strong>{ItemDetails.MerchAreaAmount}</strong> areas.
            </p>
            <br />
            <p>Instruction:</p>
            <p>
              <strong>{ItemDetails.MerchInstruction}</strong>
            </p>
            {ItemDetails.IsOHSTagNeeded.toString() === "1" ? (
              <p className="error-message">
                <strong>DANGER TOO LARGE FOR TOP SHELF</strong>
              </p>
            ) : null}
            <br />
            <div className="merchandising-merchspot-container">
              <div>
                <p>
                  <strong>Standard Section</strong>
                </p>
                <p>
                  Max PCS: <strong>{MerchSpotDetails_1.StandardShelfQuantity}</strong>
                </p>
                <p>
                  Bay: <strong>{MerchSpotDetails_1.BayLocationStandard}</strong>
                </p>
              </div>
              <div>
                <p>
                  <strong>Lead Sale Section</strong>
                </p>
                <p>
                  Max PCS: <strong>{MerchSpotDetails_1.LeadSaleQuantity}</strong>
                </p>
                <p>
                  Bay: <strong>{MerchSpotDetails_1.BayLocationLead}</strong>
                </p>
              </div>
              <div>
                <p>
                  <strong>Front Section</strong>
                </p>
                <p>
                  Max PCS: <strong>{MerchSpotDetails_1.FrontSectionQuantity}</strong>
                </p>
              </div>
              <div>
                <p>
                  <strong>Department Excess</strong>
                </p>
                <p>
                  Max PCS: <strong>{MerchSpotDetails_1.DepartmentExcessQuantity}</strong>
                </p>
              </div>
              <div>
                <p>
                  <strong>StoreRoom / Behind Counter</strong>
                </p>
                <p>
                  Max PCS: <strong>{MerchSpotDetails_1.StoreRoomBehindCounterQuantity}</strong>
                </p>
              </div>
              {["SIDECAP", "CLIPSTRIP", "PLUSHSTRIP"].includes(ItemDetails.MerchSpot) ? (
                <></>
              ) : (
                /*<div>
                                     <p><strong>{ItemDetails.MerchSpot}</strong></p>
                                     <p>Max PCS: <strong>{MerchSpotDetails_1.FrontActivation}</strong></p>
                                </div>*/
                <></>
                /*<div>
                                    <p><strong>FRONT ACTIVATION</strong></p>
                                    <p>Max PCS: <strong>{MerchSpotDetails_1.FrontActivation}</strong></p>
                                    <p>Bay Location: <strong>{MerchSpotDetails_1.FrontActivationBayLocation}</strong></p>
                                </div>*/
              )}
            </div>
          </div>
          <p className="error-message">{PopupResponse}</p>
          <br />
          <div className="popup-button-container">
            <button
              className="popup-button"
              onClick={handleClose}
            >
              <strong>Close</strong>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
